import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useTranslate from '../hooks/useTranslate';
import api_url from '../libary/axios-home';
import Button from './Common/Button';
import Modal from './Common/Modal';

const LoginComponent = () => {
  const { loggedIn, setUser, user } = useAuth();
  const t = useTranslate();

  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const navigate = useNavigate();

  const emailRef = useRef('');
  const passwordRef = useRef('');

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    global: '',
  });

  useEffect(() => {
    setErrors({ ...errors, global: '' });
  }, [emailRef?.current?.value, passwordRef?.current?.value]);

  const handleEmailValidation = () => {
    if (!emailRef.current.value) {
      setErrors({ ...errors, email: t('thisFieldIsRequired') });
    } else if (
      !emailRef.current.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    ) {
      setErrors({ ...errors, email: t('invalidEmail') });
    } else {
      setErrors({ ...errors, email: '' });
    }
  };

  const handlePasswordValidation = () => {
    if (!passwordRef.current.value) {
      setErrors({ ...errors, password: t('thisFieldIsRequired') });
    } else if (passwordRef.current.value.length < 6) {
      setErrors({ ...errors, password: t('passwordShort') });
    } else {
      setErrors({ ...errors, password: '' });
    }
  };

  useEffect(() => {
    if (loggedIn && user.role_id !== 3) {
      navigate('/d/dashboard');
    } else if (loggedIn && user.role_id === 3)
      navigate('/d/cases/ongoing-cases');
  }, []);

  const handleSubmit = async () => {
    if (
      !errors.email &&
      !errors.password &&
      emailRef.current.value &&
      passwordRef.current.value
    ) {
      try {
        const response = await api_url.post(
          'login/admin',
          {
            email: emailRef?.current?.value,
            password: passwordRef?.current?.value,
          },
          {
            headers: { 'Content-Type': 'application/json' },
          }
        );
        localStorage.setItem('token', response?.data?.access_token);
        localStorage.setItem('user', JSON.stringify(response?.data?.user));

        setUser(response?.data?.user);
        if (response?.data?.user?.role_id === 3)
          navigate('/d/cases/ongoing-cases');
        else {
          navigate('/d/dashboard');
        }
      } catch (err) {
        if (err.response?.status === 401) {
          if (err.response?.data === 'waiting') {
            setModalMessage(t('notReviewed'));
            setIsOpen(true);
          } else if (err.response?.data === 'denied') {
            setModalMessage(t('rejectedReview'));
            setIsOpen(true);
          } else {
            setErrors({
              ...errors,
              global: t('invalidUserOrPass'),
            });
          }
        } else {
          setErrors({ ...errors, global: t('loginFailed') });
        }
      }
    } else {
      if (!emailRef.current.value) {
        handleEmailValidation();
      } else if (!passwordRef.current.value) handlePasswordValidation();
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();

        handleSubmit();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="max-w-lg">
        <div className="p-4 text-center">
          <h1 className="text-2xl font-medium mb-4">{t('loginInfo')}</h1>
          <p className="text-lg font-poppins mb-4 text-left">{modalMessage}</p>
          <span
            className="cursor-pointer underline text-blue-600 text-lg"
            onClick={() => window.open('https://mendy.se/kontakt/', '_blank')}
          >
            {t('contactSupport')}
          </span>
        </div>
      </Modal>
      <div
        className="h-screen flex bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div
          className="w-4/6 m-auto bg-white rounded-lg shadow-default py-10 px-32"
          style={{ color: '#13044F' }}
        >
          <h1
            className="text-primary mt-4 mb-12 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('Expertinlogg')}
          </h1>
          <div>
            <label htmlFor="email" className="text-sm">
              {t('email')}
            </label>
            <input
              ref={emailRef}
              type="email"
              name="email"
              className="w-full p-2 text-primary border rounded-md outline-none transition duration-150 ease-in-out mb-4 text-base"
              id="email"
              placeholder={t('yourEmail')}
              style={{ color: '#13044F' }}
              onBlur={() => handleEmailValidation()}
            />
            {errors.email && (
              <p className="text-red-600 mb-2">{errors?.email}</p>
            )}
          </div>
          <div>
            <label htmlFor="password" className="text-sm">
              {t('password')}
            </label>
            <input
              ref={passwordRef}
              type="password"
              name="password"
              className="w-full p-2 text-primary border rounded-md outline-none  transition duration-150 ease-in-out mb-4 text-base"
              id="password"
              placeholder={t('yourPassword')}
              style={{ color: '#13044F' }}
              onBlur={() => handlePasswordValidation()}
            />
            {errors.password && (
              <p className="text-red-600">{errors?.password}</p>
            )}
            {errors.global && (
              <p className="text-red-600 mt-2">{errors?.global}</p>
            )}
          </div>

          <div className="flex justify-end items-center mt-6">
            <Button type="submit" onClick={handleSubmit}>
              {t('login')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginComponent;
