import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';

const variants = {
  transparent:
    'bg-transparent border-2 border-solid border-violet-700 text-violet-700',
  modalTransparent:
    'bg-transparent border-2 border-solid border-neutral-400 text-neutral-400',
  modalContained: 'bg-red-600 border-2 border-solid border-red-600 text-white',
};

const Button = ({
  children,
  icon,
  onClick,
  variant,
  type,
  className,
  isLoading,
  form,
  disabled,
}) => {
  return (
    <div
      onClick={onClick ? (disabled ? null : onClick) : null}
      className={
        (variant
          ? variants[variant]
          : disabled
          ? ` bg-[#d4d4cb] text-white border-2 border-[#d4d4cb] border-solid `
          : ` gradient-purple text-white border-gradient`) +
        ` text-xl font-poppins
          font-bold
           px-6 py-2 rounded-lg justify-center flex items-center
          min-w-0 focus:border-none ` +
        (disabled ? `cursor-default ` : `cursor-pointer `) +
        className
      }
    >
      {icon}
      {type === 'button' ? (
        <div className="font-poppins font-normal">
          {capitalizeFirstLetter(children)}
        </div>
      ) : (
        <button
          type={type ? type : 'button'}
          form={form ? form : ''}
          disabled={disabled ? disabled : false}
          className="flex items-center"
        >
          {isLoading ? (
            <CircularProgress color="secondary" size={28} />
          ) : (
            capitalizeFirstLetter(children)
          )}
        </button>
      )}
    </div>
  );
};

export default Button;
