import { CheckBox } from "@mui/icons-material";
import React from "react";
import Button from "../../../Common/Button";
import { ReactComponent as Battery } from "../../../../assets/Battery.svg";
import { ReactComponent as WifiSignal } from "../../../../assets/WifiSignal.svg";
import { ReactComponent as SignalBars } from "../../../../assets/SignalBars.svg";
import useTranslate from "../../../../hooks/useTranslate";

const TermsConditionsPreview = ({
  termsValue,
  RoTData,
  termsPreviewValue,
}) => {
  const t = useTranslate()
  return (
    <div className="bg-headingActive py-8 h-full mt-12 min-w rounded-xl px-12 flex flex-col flex-wrap">
      <span className="inline-block mb-3 font-poppins text-white">{t('preview')}</span>
      <div className="p-3 pb-4 w-full h-full bg-white previewToS rounded-lg">
        <div className="py-1 px-2 flex w-full font-medium font-poppins text-black items-center gap-1">
          <span className=" mr-auto ">9:41</span>
          <SignalBars />
          <WifiSignal />
          <Battery />
        </div>
        <div className="w-full flex flex-col px-3">
          <div className="overflow-y-auto h-96 flex flex-col">
            <span className="font-poppins text-xl  inline-block mb-3 mt-2 text-violetPrimary">
              {termsValue?.title}
            </span>
             <div className="break-words">
              <p className="text-fontActive pr-4 ">
                {termsPreviewValue
                  ? termsPreviewValue.replace(/<\/?p[^>]*>/g, "")
                  : termsValue?.text?.replace(/<\/?p[^>]*>/g, "")}
              </p>
             </div>
            <span className="font-poppins text-xl inline-block mb-3 mt-4 text-violetPrimary">
             {t('ROTDeductionRules')}
            </span>
            <span className="text-fontActive pr-4">
              {RoTData[0]?.item_value.replace(/<\/?p[^>]*>/g, "")}
            </span>
          </div>
          <div className="flex items-center gap-3 my-3 text-sm">
            <CheckBox />
            <span className="text-violetPrimary">
              {t('acceptToS')}
            </span>
          </div>
          <Button>{t('start')}</Button>
        </div>
      </div>
    </div>
  );
};

export default TermsConditionsPreview;
