import React, { useEffect } from "react";
import "./Filter.css";
import { filterArray } from "./FilterConf";
import FilterDropdown from "../../components/Dropdowns/FilterDropdown";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import refreshButton from "../../assets/logo/refresh-icon.svg";
import filterButton from "../../assets/logo/filter-icon.svg";
import { useLocation } from "react-router-dom";
import {
  fetchCategoriesApi,
  fetchMunicipalitiesApi,
  fetchSubcategoriesApi,
} from "../../features/filterSlice";
import { useDispatch, useSelector } from "react-redux";

export const FilterButton = styled(Button)(() => ({
  background: "linear-gradient(90deg, #3912D6 0%, #A312D6 100%) !important",
  borderRadius: "6px !important",
  color: "#FEFEFE !important",
  padding: "0.5rem 1.5rem !important",
  fontWeight: "500 !important",
  fontSize: "18px !important",
  fontFamily: "Poppins !important",
}));

const Filters = (props) => {
  const filters = filterArray.map((item, index) => (
    <FilterDropdown key={index} item={item} />
  ));

  return (
    <div className="flex gray-background">
      <div className="flex justify-between rounded-xl mx-4  mb-4 px-4 py-2 box-shadow-filter w-full filter-background">
        <div className="px-2 py-4  flex w-3/6">{filters}</div>
        <div className="flex items-center pt-8 px-2">
          <FilterButton variant="contained">Filter</FilterButton>
          <div
            className="pl-4 pr-2 button-background cursor-pointer"
            title="Clear all filters"
          >
            <img src={refreshButton}></img>
          </div>
          <div className="px-2 button-background cursor-pointer">
            <img src={filterButton}></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Filters;
