import Home from './views/home';
import Login from './views/login';
import Register from './views/register';
import { Toaster } from 'react-hot-toast';
import { Routes, Route, Navigate } from 'react-router-dom';
import DeleteModal from './components/Experts/DeleteModal';
import useAuth from './hooks/useAuth';
import AdminPanel from './views/AdminPanel';
import VideoCall from './components/VideoCall/WebVideo';
import WorkDescription from './components/Experts/ExpertAdmin/VideoCall/WorkDescription';
import UserRating from './components/Experts/ExpertAdmin/VideoCall/UserRating';
import { useEffect, useState } from 'react';

function App() {
  const { loggedIn } = useAuth();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1000;

  return (
    <>
      {!isMobile ? (
        <div className=" font-poppins">
          <Toaster />
          <Routes>
            {loggedIn === true ? (
              <Route>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/d/*" element={<AdminPanel />} />
                <Route path="/register" element={<Navigate to="/d/*" />} />
              </Route>
            ) : (
              <Route>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/d/*" element={<Navigate to="/login" />} />
              </Route>
            )}
            <Route path="/modal" element={<DeleteModal id={1} />} />
            <Route path="/video-call" element={<VideoCall />} />
            <Route path="/review" element={<UserRating />} />
            <Route path="/work-description" element={<WorkDescription />} />
          </Routes>
        </div>
      ) : (
        <div className="flex justify-center items-ceneter py-8 px-2 text-center">
          Sorry, this website is only available on desktop devices.
        </div>
      )}
    </>
  );
}

export default App;
