import api_url from '../../libary/axios-home';

export const expertCaseStatus = async () => {
  const response = await api_url.get(
    '/problems/dashboard/super-admin/expert-statuses'
  );
  return response.data;
};

export const handymanCaseStatus = async () => {
  const response = await api_url.get(
    '/problems/dashboard/super-admin/handyman-statuses'
  );
  return response.data;
};

export const urgentCasesStatuses = async () => {
  const response = await api_url.get(
    '/problems/dashboard/super-admin/urgent-statuses'
  );
  return response.data;
};

export const videoStatus = async () => {
  const response = await api_url.get(
    '/problems/dashboard/super-admin/video-home'
  );
  return response.data;
};
