import { useForm } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Common/Button';

const RegisterComponentPart3 = ({ formData, setFormData, setValue }) => {
  const {
    register,
    getFieldState,
    trigger,
    formState: { errors },
  } = useForm();
  const t = useTranslate();

  const goToNext = async () => {
    await trigger(['adminName', 'adminLastName', 'adminEmail', 'adminPhone']);
    if (
      !getFieldState('adminName').invalid &&
      !getFieldState('adminLastName').invalid &&
      !getFieldState('adminEmail').invalid &&
      !getFieldState('adminPhone').invalid
    ) {
      setValue(3);
    }
  };
  const goToPrevious = () => {
    setValue(1);
  };

  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            Admin info
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('InfoCompanyAdmin')}
          </p>

          <div>
            <label htmlFor="adminName" className="text-sm">
              {t('name')}
            </label>
            <input
              type="text"
              name="adminName"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="adminName"
              placeholder={`${t('enterAdminName')}...`}
              style={{ color: '#13044F' }}
              {...register('adminName', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 2,
                  message: t('nameIsTooShort'),
                },
                pattern: {
                  value: /[a-zA-Z]$/,
                  message: 'Invalid name!',
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  adminName: e.target.value,
                });
              }}
              value={formData.adminName}
            />
            {errors.adminName && (
              <span className="text-red-600 text-sm">
                {errors.adminName?.message}
              </span>
            )}
          </div>

          <div>
            <label htmlFor="adminLastName" className="text-sm">
              {t('surname')}
            </label>
            <input
              type="text"
              name="adminLastName"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="adminLastName"
              placeholder={`${t('enterAdminSurname')}`}
              style={{ color: '#13044F' }}
              {...register('adminLastName', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 2,
                  message: t('nameIsTooShort'),
                },
                pattern: {
                  value: /[a-zA-Z]$/,
                  message: t('invalidSurname'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  adminLastName: e.target.value,
                });
              }}
              value={formData.adminLastName}
            />
            {errors.adminLastName && (
              <span className="text-red-600 text-sm">
                {errors.adminLastName?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="adminPhone" className="text-sm">
              {t('phoneNumber')}
            </label>
            <input
              type="tel"
              name="adminPhone"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="adminPhone"
              placeholder={`${t('enterAdminPhone')}...`}
              style={{ color: '#13044F' }}
              maxLength={12}
              {...register('adminPhone', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                pattern: {
                  value: /[+]?[0-9]{9}$/,
                  message: t('invalidPhoneNumber'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  adminPhone: e.target.value,
                });
              }}
              value={formData.adminPhone}
            />
            {errors.adminPhone && (
              <span className="text-red-600 text-sm">
                {errors.adminPhone?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="admin_email" className="text-sm">
              {t('email')}
            </label>
            <input
              type="email"
              name="adminEmail"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="adminEmail"
              placeholder={`${t('enterAdminEmail')}`}
              style={{ color: '#13044F' }}
              {...register('adminEmail', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('invalidEmail'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  adminEmail: e.target.value,
                });
              }}
              value={formData.adminEmail}
            />

            {errors.adminEmail && (
              <span className="text-red-600 text-sm">
                {errors.adminEmail?.message}
              </span>
            )}
          </div>

          <div className="flex justify-end items-center mt-6 gap-4">
            <Button onClick={goToPrevious} variant="transparent">
              {t('Föregående')}
            </Button>
            <Button onClick={goToNext}>{t('next')}</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComponentPart3;
