import { TableRow } from '@material-ui/core';
import { Box, LinearProgress } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { fetchOngoingCasesApi } from '../../../features/cases/ongoingCasesSlice';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import CommonDetailView from '../../../containers/MainContentContainer/CommonDetailViewComponent/CommonDetailView';
import { fetchCompanyCasesApi } from '../../../features/cases/companyCases/companyCasesSlice';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import { FetchCategoryIcon } from '../../../helpers/categoryIcons';
import { DateTime } from 'luxon';

const OngoingCases = () => {
  const { user } = useAuth();
  const role = user.role_id;
  const companyId = user.company_id;
  const ongoingCases = useSelector((state) => state.ongoingCases.ongoingCases);
  const companyOngoingCases = useSelector(
    (state) => state.getCompanyCases.companyCases
  );
  const [userDetails, setUserDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const [value, setValue] = useState(t('case'));
  const status = useSelector((state) => state.getCompanyCases.status);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const caseStatus = 'ongoing';
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.role_id === 1) {
      dispatch(fetchOngoingCasesApi());
    } else {
      dispatch(fetchCompanyCasesApi({ id: companyId, status: caseStatus }));
    }
  }, []);

  const header = [t('user'), t('reviewedBy'), t('category'), t('status')];

  const superTabs = [t('case'), t('details'), t('chat'), t('ratings')];
  const companyTabs = [t('case'), t('details'), t('chat')];

  header[1] = role === 2 ? t('handyman') : t('reviewedBy');
  header[3] = role === 2 ? t('nonUrgent') : t('status');

  const tableViewData =
    role === 1 ? ongoingCases : role === 2 ? companyOngoingCases : null;

  const dataPreview = (
    rowsPerPage > 0
      ? tableViewData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableViewData
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow === index && 'active-row'}`}
      onClick={() => {
        setUserDetails(row);
        setActiveRow(index);
      }}
    >
      {role === 1 ? (
        activeRow === null && (
          <StyledTableCell>
            <div className="flex w-72 gap-3">
              <img
                src={
                  row?.users_profile_image === null
                    ? PlaceholderImage
                    : row?.users_profile_image
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = PlaceholderImage;
                }}
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="user"
              />
              <div className="w-72 truncate">
                <p className="font-medium text-base leading-6 font-color truncate">
                  {row?.user_name} {row?.user_last_name}
                </p>
                <span className="font-color truncate">
                  {row?.user_post_code} {row?.user_street}
                </span>
              </div>
            </div>
          </StyledTableCell>
        )
      ) : role === 2 ? (
        <StyledTableCell>
          <div className="flex w-72 gap-3">
            <img
              src={
                row?.user?.profile_image === null
                  ? PlaceholderImage
                  : row?.user?.profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="user"
            />
            <div className="w-72 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.user?.name ? row?.user?.name : '-'}{' '}
                {row?.user?.last_name ? row?.user?.last_name : '-'}
              </p>
              <span className="font-color truncate">
                {row?.user?.post_code ? row?.user?.post_code : '-'}{' '}
                {row?.user?.street ? row?.user?.street : '-'}
              </span>
            </div>
          </div>
        </StyledTableCell>
      ) : (
        ''
      )}
      {role === 1 ? (
        <StyledTableCell>
          <div className="flex gap-3">
            <img
              src={
                row?.handyman_profile_image
                  ? row?.handyman_profile_image
                  : row?.expert_profile_image
                  ? row?.expert_profile_image
                  : PlaceholderImage
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="handyman"
            />
            <div className="w-32 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.handyman_name && row?.handyman_last_name
                  ? row?.handyman_name + ' ' + row?.handyman_last_name
                  : row?.expert_name && row?.expert_last_name
                  ? row?.expert_name + ' ' + row?.expert_last_name
                  : '-'}
              </p>
              <span className="font-color truncate">
                {row?.handyman_role
                  ? row?.handyman_role
                  : row?.expert_role
                  ? row?.expert_role
                  : '-'}
              </span>
            </div>
          </div>
        </StyledTableCell>
      ) : role === 2 ? (
        activeRow === null && (
          <StyledTableCell>
            <div className="flex items-center gap-3">
              <img
                src={
                  row?.appointments?.length > 0
                    ? row?.appointments[0]?.handyman?.profile_image
                      ? row?.appointments[0]?.handyman?.profile_image
                      : PlaceholderImage
                    : row?.expert
                    ? row?.expert.profile_image
                      ? row?.expert?.profile_image
                      : PlaceholderImage
                    : PlaceholderImage
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = PlaceholderImage;
                }}
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="handyman"
              />
              <div className="w-32 truncate">
                <p className="font-medium text-base leading-6 font-color truncate">
                  {row?.appointments?.length > 0
                    ? row?.appointments[0]?.handyman?.name &&
                      row?.appointments[0]?.handyman?.last_name
                      ? row?.appointments[0]?.handyman?.name +
                        ' ' +
                        row?.appointments[0]?.handyman?.last_name
                      : '-'
                    : row?.expert
                    ? row?.expert?.name && row?.expert?.last_name
                      ? row?.expert?.name + ' ' + row?.expert?.last_name
                      : '-'
                    : '-'}
                </p>
              </div>
            </div>
          </StyledTableCell>
        )
      ) : (
        ''
      )}

      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-48 gap-3 items-center">
            <FetchCategoryIcon
              strokeColor={'strokeBlue'}
              name={row?.problem_category_icon}
            />
            <div className="px-4">
              <p className="font-medium text-base leading-6 font-color">
                {row?.problem_parent_category_name}
              </p>
              <span className="font-color">{row?.problem_category_name}</span>
            </div>
          </div>
        ) : role === 2 ? (
          <div className="flex w-48 gap-3 items-center">
            <FetchCategoryIcon
              strokeColor={'strokeBlue'}
              name={row?.category?.icon}
            />
            <div className="px-4">
              <p className="font-medium text-base leading-6 font-color">
                {row?.category?.parent?.name}
              </p>
              <span className="font-color">{row?.category?.name}</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>

      {role === 1 ? (
        <StyledTableCell>
          <div className="flex justify-center">
            <span
              className={
                row?.problem_status_name === 'ready_for_handyman'
                  ? 'not-answered'
                  : row?.problem_status_name === 'reviewed_by_handyman'
                  ? 'status-non-urgent'
                  : row?.problem_status_name === 'reviewed_by_expert'
                  ? 'status-urgent'
                  : ''
              }
            >
              {row?.problem_status_name === 'ready_for_handyman'
                ? t('readyForHandyman')
                : row?.problem_status_name === 'reviewed_by_handyman'
                ? t('reviewedByHandyman')
                : row?.problem_status_name === 'reviewed_by_expert'
                ? t('reviewedByExpert')
                : ''}
            </span>
          </div>
        </StyledTableCell>
      ) : role === 2 ? (
        <StyledTableCell>
          {row?.time_slots ? (
            <div className="flex flex-col">
              <span className="font-medium text-base">
                {DateTime.fromISO(row?.time_slots?.time_from).toFormat('HH:mm')}
                -{DateTime.fromISO(row?.time_slots?.time_to).toFormat('HH:mm')}
              </span>

              <span>{row?.time_slots?.date_from}</span>
            </div>
          ) : (
            '-'
          )}
        </StyledTableCell>
      ) : (
        ''
      )}
    </TableRow>
  ));
  return (
    <div className={`px-8 my-4 ${userDetails && 'flex'}`}>
      <div className={`rounded-xl ${userDetails && 'table-width-onclick'}`}>
        <Fragment>
          {status === 'loading' ? (
            <Box sx={{ width: '100%', padding: '20px 0' }}>
              <LinearProgress />
            </Box>
          ) : ongoingCases ? (
            <CommonTable
              header={
                userDetails
                  ? role === 1
                    ? header.filter((i) => i !== header[0])
                    : role === 2
                    ? header.filter((i) => i !== header[1])
                    : header
                  : header
              }
              tableData={dataPreview}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={tableViewData ? tableViewData.length : 0}
            />
          ) : (
            <h1 className="text-center text-2xl font-medium p-6">
              {t('noData')}
            </h1>
          )}
        </Fragment>
      </div>
      {userDetails && (
        <CommonDetailView
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          setActiveRow={setActiveRow}
          tabs={user.role_id === 1 ? superTabs : companyTabs}
          setValue={setValue}
          role={role}
          caseStatus={caseStatus}
        />
      )}
    </div>
  );
};

export default OngoingCases;
