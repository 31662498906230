import api_url from "../../libary/axios-home";

export const changeAdminPassword = async (formData) => {
  const { data } = await api_url.put(`change-password/admin`, formData);
  return data;
};

export const fetchPlatformProfit = async () => {
  const { data } = await api_url.get(`administrators/profit`);
  return data;
};

