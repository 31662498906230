import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchCompletedProblemsExpert } from '../../../../api/Experts/Experts';
import CommonTable from '../../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import useTranslate from '../../../../hooks/useTranslate';
import FinishedTable from './FinishedTable';
import useAuth from '../../../../hooks/useAuth';

const ExpertFinishedCases = () => {
  const [caseDetails, setCaseDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const { user } = useAuth();

  const header = [
    t('user'),
    t('category'),
    t('descriptionByCustomer'),
    t('expertDescription'),
    t('images'),
    t('bookedTime'),
  ];

  const {
    data: finishedCases,
    isLoading,
    isError,
    refetch: refetchProblems,
  } = useQuery(['finishedExpertCases', user.id], fetchCompletedProblemsExpert);

  const distantFuture = new Date(8640000000000000);

  finishedCases?.sort((a, b) => {
    let dateA =
      a?.time_slots?.date_from && a?.time_slots?.time_from
        ? new Date(a?.time_slots?.date_from + 'T' + a?.time_slots?.time_from)
        : distantFuture;
    let dateB =
      b?.time_slots?.date_from && b?.time_slots?.time_from
        ? new Date(b?.time_slots?.date_from + 'T' + b?.time_slots?.time_from)
        : distantFuture;
    return dateA.getTime() - dateB.getTime();
  });

  const dataPreview = (
    rowsPerPage > 0
      ? finishedCases?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : finishedCases
  )?.map((row, index) => (
    <FinishedTable
      key={index}
      row={row}
      index={index}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      setCaseDetails={setCaseDetails}
      refetchProblems={refetchProblems}
    />
  ));

  if (isError) return <div>{t('errorRequest')}</div>;

  return (
    <div className="flex px-8 my-4">
      <div className="rounded-xl w-full">
        <CommonTable
          header={header}
          tableData={dataPreview}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          count={finishedCases ? finishedCases.length : 0}
        />
      </div>
    </div>
  );
};

export default ExpertFinishedCases;
