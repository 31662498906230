import React, { useState } from 'react';
import Button from '../../Common/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteDeniedReason,
  deleteMessageSlide,
  editDeniedReason,
  editMessageSlide,
} from '../../../api/Settings/MessageSlides/MessageSlides';
import useTranslate from '../../../hooks/useTranslate';

const SingleMessage = ({ message, deniedReasons }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [messageInput, setMessageInput] = useState(
    message.item_value || message.text
  );
  const queryClient = useQueryClient();
  const id = message?.id;
  const t = useTranslate();

  // Mutation for editing message slides
  const {
    mutateAsync: editMessageMutation,
    isLoading: editMessageLoading,
  } = useMutation(
    deniedReasons ? editDeniedReason : editMessageSlide,
    {
      onSuccess: () => {
        setIsEditing(false);
      },
    }
  );

  // Mutation for deleting message slides
  const {
    mutateAsync: deleteMessageMutation,
    isLoading: deleteMessageLoading,
  } = useMutation(
    deniedReasons ? deleteDeniedReason : deleteMessageSlide,
    {
      onSuccess: () => {
        setIsEditing(false);
        queryClient.invalidateQueries([
          deniedReasons ? 'deniedReasons' : 'messageSlides',
        ]);
      },
    }
  );

  // Handler for cancel button
  const handleCancel = () => {
    setIsEditing(false);
    setMessageInput(message?.item_value || message.text);
  };

  // Event handler for textArea
  const handleTextAreaClick = () => {
    setIsEditing(true);
  };

  // OnChange handler for textArea
  const handleInputChange = (e) => {
    setMessageInput(e.target.value);
  };

  // Handler for submitting the editMessageMutation
  const handleEditSubmit = async () => {
    await editMessageMutation({ messageInput, id });
  };

  // Handler for submitting the deleteMessageMutation
  const handleDeleteMessage = async () => {
    await deleteMessageMutation(id);
  };
  return (
    <div className="w-full">
      <textarea
        onClick={handleTextAreaClick}
        className="border resize-none hover:border-indigo-500 focus:border-indigo-500 outline-none focus:outline-none
        transition-all cursor-pointer w-full rounded-lg border-slate-300 p-3 text-sm text-left font-poppins text-fontActive"
        value={messageInput}
        onChange={handleInputChange}
      />
      {isEditing && (
        <div className="flex w-full my-2">
          <Button onClick={handleDeleteMessage}>{t('delete')}</Button>
          <div className="flex gap-3 ml-auto">
            <Button onClick={handleCancel}>{t('cancel')}</Button>
            <Button onClick={handleEditSubmit}>{t('save')}</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleMessage;
