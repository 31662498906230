import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


export const initialState = {
    ongoingCases: [],
};

export const fetchOngoingCasesApi = createAsyncThunk(
  'ongoingCases/fetchOngoingCasesApi',
  async () => {
    try {
      const response = await api_url.get('problems/status/ongoing' );
      return response.data;
     
    } catch (error) {
      console.error(error);
    }
  }
)

const ongoingCasesSlice = createSlice({
  name: "ongoingCasesSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchOngoingCasesApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchOngoingCasesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.ongoingCases = action.payload;
    })
    builder.addCase(fetchOngoingCasesApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default ongoingCasesSlice.reducer;