import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


export const initialState = {
  expertsList: []
};


export const fetchExpertsApi = createAsyncThunk(
  'getExperts/fetchExpertsApi',
  async () => {
    try {
      const response = await api_url.get('expert/categories/rating' );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

)

const getExpertsSlice = createSlice({
  name: "getExpertsSlice ",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchExpertsApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchExpertsApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.expertsList = action.payload;
      
    })
    builder.addCase(fetchExpertsApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default getExpertsSlice.reducer;