import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DeleteModal from './Modals/DeleteModal';
import { fetchCompanyByIdApi } from '../../features/companies/getCompanyByIdSlice';
import { updateCompanyByIdApi } from '../../features/companies/putCompanyByIdSlice';
import { LinearProgress, Box } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import './Admin.css';
import CompanyForm from '../Common/CompanyForm';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Common/Button';
import Modal from '../Common/Modal';

export default function CompanyInfoForm() {
  const company = useSelector((state) => state.getCompanyById.company);
  const status = useSelector((state) => state.getCompanyById.status);
  const updateStatus = useSelector((state) => state.putCompanyById.status);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editForm, setEditForm] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const t = useTranslate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ defaultValues: company, mode: 'onChange' });

  useEffect(() => {
    reset(company);
    setValue('logo', '', { shouldDirty: false });
    setValue('documents', [], { shouldDirty: false });
  }, [company]);

  const onSubmit = (data) => {
    if (isDirty && isValid) {
      delete data.approval_status;
      dispatch(updateCompanyByIdApi({ id: user.company_id, data })).then(() => {
        dispatch(fetchCompanyByIdApi({ id: user.company_id }));
      });
    }
  };

  useEffect(() => {
    dispatch(fetchCompanyByIdApi({ id: user.company_id }));
  }, []);

  return (
    <Fragment>
      {status === 'loading' ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <div className="w-3/6">
          <CompanyForm
            role={2}
            company={company}
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            setValue={setValue}
            errors={errors}
            reset={reset}
            editForm={editForm}
            formName="company-form"
          />
          <div className="flex py-4 px-4">
            <div className="flex justify-start w-full">
              <Button
                variant="transparent"
                onClick={() => setDeleteModal(true)}
              >
                {t('delete')}
              </Button>
              <Modal
                isOpen={deleteModal}
                setIsOpen={setDeleteModal}
                className="max-w-md"
              >
                <DeleteModal
                  setIsOpen={setDeleteModal}
                  companyId={user?.company_id}
                />
              </Modal>

              {updateStatus === 'loading' ? (
                <Box sx={{ width: '100%', padding: '20px 0' }}>
                  <LinearProgress />
                </Box>
              ) : (
                <Button
                  disabled={
                    (editForm && !isValid) || updateStatus === 'loading'
                  }
                  type="submit"
                  form="company-form"
                  name="edit"
                  className="ml-auto"
                  onClick={
                    !editForm
                      ? () => setEditForm(true)
                      : () => setEditForm(false)
                  }
                >
                  {!editForm ? t('edit') : t('save')}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
