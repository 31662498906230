import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import CommonTable from '../../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import useTranslate from '../../../../hooks/useTranslate';
import OngoingTable from './OngoingTable';
import useAuth from '../../../../hooks/useAuth';
import Modal from '../../../Common/Modal';
import ConfirmCall from '../ConfirmCall';
import AcceptedTable from './AcceptedTable';
import {
  fetchExpertProblems,
  fetchOngoingProblemsExpert,
} from '../../../../api/Experts/Experts';
import { useEffect } from 'react';

const ExpertAcceptedCases = () => {
  const [caseDetails, setCaseDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableOverlay, setDisableOverlay] = useState(false);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const { user } = useAuth();
  const id = user?.id;

  const header = [
    t('user'),
    t('category'),
    t('descriptionByCustomer'),
    t('images'),
    t('bookedTime'),
    '',
  ];

  const {
    data: problemsData,
    isLoading,
    isError,
  } = useQuery(['expertOngoingProblems', id], fetchOngoingProblemsExpert);

  const distantFuture = new Date(8640000000000000);

  problemsData?.sort((a, b) => {
    let dateA =
      a?.time_slots?.date_from && a?.time_slots?.time_from
        ? new Date(a?.time_slots?.date_from + 'T' + a?.time_slots?.time_from)
        : distantFuture;
    let dateB =
      b?.time_slots?.date_from && b?.time_slots?.time_from
        ? new Date(b?.time_slots?.date_from + 'T' + b?.time_slots?.time_from)
        : distantFuture;
    return dateA.getTime() - dateB.getTime();
  });

  const dataPreview = (
    rowsPerPage > 0
      ? problemsData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : problemsData
  )?.map((row, index) => (
    <AcceptedTable
      key={index}
      row={row}
      index={index}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      setCaseDetails={setCaseDetails}
      setIsModalOpen={setIsModalOpen}
    />
  ));

  if (isError) return <div>{t('errorRequest')}</div>;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        phonecall={disableOverlay}
      >
        <ConfirmCall
          userName={
            caseDetails?.user?.name + ' ' + caseDetails?.user?.last_name
          }
          setModalOpen={setIsModalOpen}
          setDisableOverlay={setDisableOverlay}
          problem={caseDetails}
        />
      </Modal>
      <div className="flex px-8 my-4">
        <div className="rounded-xl w-full">
          <CommonTable
            header={header}
            tableData={dataPreview}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={problemsData ? problemsData.length : 0}
          />
        </div>
      </div>
    </>
  );
};

export default ExpertAcceptedCases;
