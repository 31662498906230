import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import ChangePasswordModal from "./Modals/ChangePasswordModal";
import { useForm } from "react-hook-form";
import { updateAdminByIdApi } from "../../features/admin/putAdminByIdSlice";
import { fetchAdminByIdApi } from "../../features/admin/getAdminByIdSlice";

import "./Admin.css";
import AdminForm from "../Common/AdminForm";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import useTranslate from "../../hooks/useTranslate";
import Button from "../Common/Button";

export default function AdminInfoForm() {
  const { user, setUser } = useAuth();
  const admin = useSelector((state) => state.getAdminById.admin);
  const status = useSelector((state) => state.getAdminById.status);
  const updateStatus = useSelector((state) => state.putAdminById.status);
  const [editForm, setEditForm] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm({ defaultValues: admin, mode: "onChange" });
  const t = useTranslate();

  useEffect(() => {
    reset(admin);
  }, [admin]);

  useEffect(() => {
    dispatch(fetchAdminByIdApi({ id: user.id }));
  }, []);

  const onSubmit = (data) => {
    if (isDirty && isValid) {
      dispatch(updateAdminByIdApi({ id: data.id, data })).then(() => {
        dispatch(fetchAdminByIdApi({ id: user.id }));
      });
      setUser(data);
    }
  };
  return (
    <Fragment>
      {status === "loading" ? (
        <Box sx={{ width: "100%", padding: "20px 0" }}>
          <LinearProgress />
        </Box>
      ) : (
        <div className="w-3/6">
          <AdminForm
            user={user}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            register={register}
            isDirty={isDirty}
            isValid={isValid}
            errors={errors}
            editForm={editForm}
            formName="admin-form"
          />
          <div className="flex mt-16 px-4 pt-10">
            <div className="flex justify-start w-full items-center">
              <span
                className="text-linear cursor-pointer text-base"
                onClick={() => setChangePasswordModal(true)}
              >
                {t("changePassword")}
              </span>
              <span className="border-linear rounded-md p-0.5 ml-auto">
                <Button
                  disabled={
                    (editForm && !isValid) || updateStatus === "loading"
                  }
                  form="admin-form"
                  type="submit"
                  name="edit"
                  onClick={
                    !editForm
                      ? () => setEditForm(true)
                      : () => setEditForm(false)
                  }
                >
                  {updateStatus === "loading" ? (
                    <CircularProgress />
                  ) : !editForm ? (
                    t("edit")
                  ) : (
                    t("save")
                  )}
                </Button>
              </span>
            </div>
          </div>
          <ChangePasswordModal
            changePasswordModal={changePasswordModal}
            setChangePasswordModal={setChangePasswordModal}
            user={user}
          />
        </div>
      )}
    </Fragment>
  );
}
