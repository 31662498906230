import React from 'react';

const variants = {
  user: 'bg-gray-300 p-3 w-fit rounded-lg mr-auto',
  handyman: 'gradient-purple p-3 w-fit rounded-lg text-white ml-auto',
};

const nameVariants = {
  user: 'w-full text-left inline-block text-gray-400 text-sm',
  handyman: 'w-full text-right inline-block text-gray-400 text-sm',
};

const timeVariants = {
  user: 'w-full text-left inline-block text-gray-400 mt-1',
  handyman: 'w-full text-right inline-block text-gray-400 mt-1',
};

const ChatBubble = ({ variant, children, name, timeStamp }) => {
  return (
    <div className="my-5 font-poppins">
      <span className={variant ? nameVariants[variant] : null}>{name}</span>
      <div className={variant ? variants[variant] : null}>{children}</div>
      <span className={variant ? timeVariants[variant] : null}>{timeStamp}</span>
    </div>
  );
};

export default ChatBubble;
