import api_url from "../../../libary/axios-home";

export const getAllFaqs = async () => {
  const { data } = await api_url.get("/faq");
  return data;
};

export const getFaqById = async (id) => {
  const { data } = await api_url.get(`/faq/${id}`);
  return data;
};

export const createFaq = async (formData) => {
  const { data } = await api_url.post("/faq/create", formData);
  return data;
};

export const updateFaq = async ({ question, answer, id }) => {
  const { data } = await api_url.put(`faq/update/${id}`, {
    question,
    answer,
  });
  return data;
};

export const deleteFaq = async (id) => {
  const { data } = await api_url.post(`faq/delete/${id}`);
  return data;
};
