import '../Admin.css';
import { useState } from 'react';
import Button from '../../Common/Button';
import { deleteCompanyById } from '../../../api/Company/Company';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useAuth from '../../../hooks/useAuth';
import { fetchLogoutApi } from '../../../features/logoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Checkbox, LinearProgress } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';

export default function DeleteModal({ setIsOpen, companyId }) {
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const t = useTranslate();
  const status = useSelector((state) => state.logout.status);

  const navigate = useNavigate();
  const { setLoggedIn, user } = useAuth();

  //Mutation for deleting company
  const {
    mutateAsync: deleteCompanyMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useMutation(deleteCompanyById, {
    onSuccess: () => {
      if (user?.role_id === 2) {
        dispatch(
          fetchLogoutApi({ access_token: localStorage.getItem('token') })
        ).then(() => {
          setLoggedIn(false);
          localStorage.clear();
          navigate('/login');
        });
      } else {
        queryClient.invalidateQueries(['activeCompanies']);
        queryClient.invalidateQueries(['waitingCompanies']);
        queryClient.invalidateQueries(['deniedCompanies']);
      }
      setIsOpen(false);
    },
  });

  const handleDeleteCompany = () => {
    deleteCompanyMutation(companyId);
  };

  return (
    <>
      <div className="text-center py-10 px-6 flex flex-col items-center">
        <h2 className="delete-title">{t('deleteCompany')}</h2>
        <p className="delete-description mt-2">{t('deleteCompanyDescription')}</p>
        <div className="flex items-center mt-8">
          <Checkbox
            checked={confirmCheckbox}
            onChange={() => setConfirmCheckbox(!confirmCheckbox)}
            sx={{
              color: '#919191!important',
              '&.Mui-checked': {
                color: '#fe0000',
              },
              padding: '5px!important',
            }}
            label={t('deleteCompanyCheckbox')}
          />
          <p className="delete-confirm">{t('deleteCompanyCheckbox')}</p>
        </div>
        {deleteIsLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <div className="flex justify-evenly mt-8 gap-6">
            <Button variant="modalTransparent" onClick={() => setIsOpen(false)}>
              {t('cancel')}
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
                handleDeleteCompany();
              }}
              variant="modalContained"
              disabled={!confirmCheckbox}
            >
              {t('delete')}
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
