import api_url from '../../libary/axios-home';

export const fetchCaseById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`problems/${selectedId}`);
  return data;
};

export const fetchCaseDetailsById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`problems/details/${selectedId}`);
  return data;
};

export const fetchCaseOfferById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`problems/offers-and-bill/${selectedId}`);
  return data;
};

export const sendNewOffer = async ({
  problem_id,
  handyman_id,
  date_from,
  date_to,
  time_from,
  time_to,
  items,
  description,
}) => {
  const { data } = await api_url.post(`appointments/create`, {
    problem_id,
    handyman_id,
    date_from,
    date_to,
    time_from,
    time_to,
    items,
    description,
  });
  return data;
};

export const cancelExpertCall = async ({ id }) => {
  const { data } = await api_url.post(`problems/cancel-expert-call/${id}`, {});
  return data;
};

export const fetchNewCases = async ({ queryKey }) => {
  const { data } = await api_url.get(`problems/status/new`);
  return data;
};
