import React from 'react';

const Card = ({ title, title2, width, height, center, children }) => {
  return (
    <div
      className={`bg-white rounded-xl mt-4 cardShadow ` + width + ` ${height}`}
    >
      {title && (
        <>
          <div className="font-poppins text-textBlack font-medium text-lg px-10 pt-8 pb-6">
            {title2 ? (
              <div className="flex text-left">
                <span className="w-1/2">{title}</span>
                <span className="w-1/2 text-right">{title2}</span>
              </div>
            ) : (
              <span>{title}</span>
            )}
          </div>
          <hr className="bg-slate-200" />
        </>
      )}
      <div className="flex items-center h-full">{children}</div>
    </div>
  );
};

export default Card;
