import React from 'react';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';

const IconUpload = ({ setModalOpen, selectedIcon }) => {
  return (
    <div
      className="w-12 mb-1 h-9 rounded-lg border border-dashed border-gray-400 items-center flex cursor-pointer justify-center"
      onClick={() => {
        setModalOpen(true);
      }}
    >
      {selectedIcon ? (
        <FetchCategoryIcon
          name={selectedIcon}
          strokeColor={'strokeBlue'}
        />
      ) : (
        <Plus className="stroke-gray-400" />
      )}
    </div>
  );
};

export default IconUpload;
