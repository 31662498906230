import waiting_img from '../../assets/images/registration_waiting.png';
import useTranslate from '../../hooks/useTranslate';
const RegisterWaitingComponent = () => {
  const t = useTranslate();
  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('waitingAccept')}
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('successWaitingAdmin')}
          </p>

          <div>
            <img className="pl-28" src={waiting_img} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterWaitingComponent;
