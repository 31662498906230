import { LinearProgress } from '@material-ui/core';
import {
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { editRoT, fetchRoT } from '../../../../api/Settings/RoT/RoT';
import { getTermsByUserRole } from '../../../../api/Settings/TermsAndConditions/TermsAndConditions';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import Input from '../../../Common/Input';
import ToSDropdown from '../../../Dropdowns/ToSDropdown';

const TermsConditions = ({
  setIsEditing,
  setTermsPreview,
  setTermsValue,
  setRotValue,
}) => {
  const [roleId, setRoleId] = useState(2);
  const [isEditingRot, setIsEditingRot] = useState(false);
  const queryClient = useQueryClient();
  const t = useTranslate()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'all' });

  const {
    data: ToSData,
    isLoading,
    isError,
  } = useQuery(['ToS', roleId], getTermsByUserRole, {
    onSuccess: () => {
      setTermsValue(ToSData);
    },
  });

  const {
    data: RoTData,
    isLoading: rotLoading,
    isError: rotError,
  } = useQuery(['RoT'], fetchRoT, {
    onSuccess: (data) => {
      setRotValue(data);
      reset(data[0]);
    },
  });

  const { mutateAsync: editRoTMutation } = useMutation(editRoT, {
    onSuccess: () => {
      setIsEditingRot(false);
      queryClient.invalidateQueries(['RoT']);
    },
  });

  const submitHandler = ({ item_value, id }) => {
    editRoTMutation({ text: item_value, id });
  };

  return (
    <div>
      <ToSDropdown setRoleId={setRoleId} roleId={roleId} />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div className="flex flex-col">
          <span className="inline-block font-poppins text-fontActive text-left">
            {ToSData?.title}
          </span>
          <span className="w-full text-gray-400 text-left my-3">
            {ToSData?.text}
          </span>
          <Button
            className="ml-auto"
            onClick={() => {
              setIsEditing(true);
              setTermsPreview(true);
              setTermsValue(ToSData);
            }}
          >
            {t('edit')}
          </Button>
        </div>
      )}

      <hr className="my-6 h-px bg-gray-300 w-full" />
      <div className="flex flex-col">
        <span className="inline-block font-poppins text-fontActive text-left">
          {t('ROTDeductionRules')}
        </span>
        <form
          onSubmit={handleSubmit(submitHandler)}
          className="w-full flex flex-col"
        >
          <Input
            register={register}
            placeholder={`${t('description')}...`}
            type="Text"
            name="item_value"
            required={t('thisFieldIsRequired')}
            isError={errors?.item_value?.message}
            disabled={!isEditingRot}
            noLabel
          />
          <ErrorMessage>{errors?.item_value?.message}</ErrorMessage>
          {isEditingRot ? (
            <div className="flex gap-3 ml-auto mt-3">
              <Button
                type="button"
                onClick={() => {
                  setIsEditingRot(false);
                  reset();
                }}
              >
                {t('cancel')}
              </Button>
              <Button type="submit">{t('save')}</Button>
            </div>
          ) : (
            <Button
              className="ml-auto"
              onClick={() => {
                setIsEditingRot(true);
              }}
            >
              {t('edit')}
            </Button>
          )}
        </form>
      </div>
    </div>
  );
};

export default TermsConditions;
