import { Box, LinearProgress, TableRow } from '@material-ui/core';
import { useState } from 'react';
import CommonTable, {
  StyledTableCell,
} from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import rateImageUrl from '../../../assets/images/tabler_star.svg';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import { useQuery } from '@tanstack/react-query';
import { fetchHandymenByCompanyId } from '../../../api/Handyman/Handyman';
import { FetchCategoryIcon } from '../../../helpers/categoryIcons';

const CompanyHandymen = ({ companyDetails }) => {
  //Add when delete finished
  const [handymanDetails, setHandymanDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const header = [t('handyman'), t('info'), t('category'), t('cases')];

  // Fetch all company handymen
  const {
    data: handymen,
    isLoading: handymenLoading,
    isError,
  } = useQuery(['handymenAll', companyDetails?.id], fetchHandymenByCompanyId, {
    onSuccess: () => {},
  });

  const dataPreview = (
    rowsPerPage > 0
      ? handymen?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : handymen
  )?.map((row, index) => (
    <TableRow
      key={row?.id}
      onClick={() => {
        setActiveRow(index);
      }}
      row={row}
      className={`${activeRow === index && 'active-row'}`}
    >
      {/*<StyledTableCell padding="checkbox">
        <Checkbox
          color="primary"
          inputProps={{
            "aria-label": "select all companies",
          }}
        />
        </StyledTableCell>*/}
      <StyledTableCell sx={{ padding: '0px' }}>
        <div className="flex">
          <img
            src={row?.profile_image ? row?.profile_image : PlaceholderImage}
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            alt="Handyman"
            className="w-10 h-10 rounded-full"
          />
          <div className="px-4">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}
            </p>
            <span className="font-color">
              {row?.post_code} {row?.street}
            </span>
          </div>
        </div>
      </StyledTableCell>

      <StyledTableCell>
        <div className="flex">
          <div>
            <p className="font-medium text-base leading-6 font-color">
              {row?.phone}
            </p>
            <span className="font-color">{row?.email}</span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex gap-1 items-center">
          {row?.categories
            ?.filter((i, index) => index < 3)
            .map((category, index) => (
              <FetchCategoryIcon
                key={index}
                strokeColor={'strokeBlue'}
                name={category?.icon}
              />
            ))}
          <p className="text-indigo-800 font-medium text-base leading-6">
            {row?.categories.length > 3 ? `+${row?.categories.length - 3}` : ''}
          </p>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex">
          <div className="px-2 pl-6">
            <p className="font-color">{row?.completed_cases}</p>
          </div>
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <div className={`outside-container-background`}>
      <div className="table-container w-full">
        {handymenLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <CommonTable
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={handymen ? handymen.length : 0}
            header={
              handymanDetails
                ? header.filter((item, index) => index < 1)
                : header
            }
            tableData={dataPreview}
          />
        )}
      </div>
    </div>
  );
};

export default CompanyHandymen;
