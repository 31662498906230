export default function TextArea({
  type,
  name,
  id,
  placeholder,
  value,
  onChange,
  register,
  required,
  pattern,
  max,
  isError,
  disabled,
  readOnly
}) {
  return (
    <textarea
      type={type}
      name={name}
      id={id}
      value={value}
      max={max}
      disabled={disabled}
      readOnly={readOnly}
      className={
        (isError
          ? "ring-red-500 border-red-500"
          : "focus:ring-indigo-500  focus:border-indigo-500") +
        `
            shadow-sm border block 
            w-full sm:text-sm rounded-md py-2 px-2 outline-none mb-2 mt-1 h-full self-stretch`
      }
      placeholder={placeholder}
      pattern={pattern}
      {...register(name, {
        required: required ? "This field is required" : false,
        onChange: onChange
      })}
    />
  );
}
