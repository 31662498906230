import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../libary/axios-home";
const initialState = {};

export const fetchLogoutApi = createAsyncThunk(
  "logout/fetchLogoutApi",
  async ({ data }) => {
    try {
      const response = await api_url.post("logout/admin", data);

      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const logoutSlice = createSlice({
  name: "logoutSlice",
  initialState,

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchLogoutApi.pending, (state, action) => {
      // Add user to the state array
      state.status = true;
    });
    builder.addCase(fetchLogoutApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = false;
    });
    builder.addCase(fetchLogoutApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default logoutSlice.reducer;
