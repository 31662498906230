import React from 'react';
import { FormControl, TextField } from '@mui/material';
import useTranslate from '../../hooks/useTranslate';

const AdminForm = ({
  user,
  handleSubmit,
  onSubmit,
  register,
  errors,
  editForm,
  formName,
}) => {
  const t = useTranslate();
  return (
    <form id={formName} onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="pb-6 w-full">
        <div className="flex flex-col py-4">
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('adminName')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="admin-name"
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={user?.name}
                {...register('name', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 2,
                    message: t('nameIsTooShort'),
                  },
                })}
              />
              {errors.name && (
                <span className="text-red-600 text-sm">
                  {errors.name?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('adminSurname')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="admin-surname"
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={user?.last_name}
                {...register('last_name', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 2,
                    message: t('nameIsTooShort'),
                  },
                })}
              />
              {errors.last_name && (
                <span className="text-red-600 text-sm">
                  {errors.last_name?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              Admin {t('phoneNumber')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="admin-phone"
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={user?.phone}
                inputProps={{ maxLength: 12 }}
                {...register('phone', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  pattern: {
                    value: /^\+(?:[0-9] ?){6,14}[0-9]$/,
                    message: t('invalidPhoneNumber'),
                  },
                })}
              />
              {errors.phone && (
                <span className="text-red-600 text-sm">
                  {errors.phone?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              Admin {t('email')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="admin-email"
                variant="outlined"
                type="email"
                fullWidth
                disabled={!editForm}
                defaultValue={user?.email}
                {...register('email', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('invalidEmail'),
                  },
                })}
              />
              {errors.email && (
                <span className="text-red-600 text-sm">
                  {errors.email?.message}
                </span>
              )}
            </span>
          </div>
        </div>
      </FormControl>
    </form>
  );
};

export default AdminForm;
