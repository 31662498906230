import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../libary/axios-home";
const initialState = {
  user: "",
  isLoggedin: false,
  errorMessage: "",
};

export const fetchRegisterApi = createAsyncThunk(
  "register/fetchRegisterApi",
  async ({
    company_name,
    code,
    company_phone,
    company_email,
    company_address,
    name,
    last_name,
    phone,
    email,
    password,
  }) => {
    const response = await api_url.post(
      "register/admin",
      JSON.stringify({
        company_name,
        code,
        company_phone,
        company_email,
        company_address,
        name,
        last_name,
        phone,
        email,
        password,
      }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );

    const response2 = await response.json();
    //store token in local

    return response2;
  }
);

export const registerSlice = createSlice({
  name: "registerSlice",
  initialState,

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchRegisterApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchRegisterApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.user = action.payload;
      state.status = "succeeded";
      if (action.payload.access_token) state.isLoggedin = true;
      else {
        state.isLoggedin = false;
        state.errorMessage = "Invalid Username or Password";
      }
    });
    builder.addCase(fetchRegisterApi.rejected, (state, action) => {
      // Add user to the state array
      state.status = "failed";
    });
  },
});

export default registerSlice.reducer;
