import { Link } from "react-router-dom";
import liIcon from "../../assets/images/li-icon.svg";
import ttIcon from "../../assets/images/tt-icon.svg";
import igIcon from "../../assets/images/ig-icon.svg";
import fbIcon from "../../assets/images/fb-icon.svg";
import ytIcon from "../../assets/images/yt-icon.svg";

const FooterLanding = () => {
  return (
    <footer
      className="inset-x-0 bottom-0 p-4 font-extrabold text-white py-12 lg:px-40 px-8 relative"
      style={{
        background: " linear-gradient(90deg, #3912D6 0%, #A312D6 100%)",
      }}
    >
      <div className="flex w-full custom-container items-center text-center flex-col lg:flex-col">
        <p
          className="flex w-full justify-center text-base lg:text-xl"
          style={{
            alignItems: "center",
            fontWeight: "400",
          }}
        >
          Vår tjänst och app kommer lanseras i december 2022.
        </p>
        <p
          className="flex w-full justify-center text-base lg:text-xs py-4"
          style={{
            alignItems: "center",
            fontWeight: "400",
          }}
        >
          Följ oss på våra sociala medier för att inte missa något!
        </p>
        <div className="flex w-full justify-center">
          <a
            className="mx-2 cursor-pointer"
            href="https://www.linkedin.com/company/hemingo/"
          >
            <img src={liIcon} />
          </a>
          <img className="mx-2 cursor-pointer" src={ttIcon} />
          <a
            className="mx-2 cursor-pointer"
            href="https://www.instagram.com/mendy.se/"
          >
            <img src={igIcon} />
          </a>
          <img className="mx-2 cursor-pointer" src={fbIcon} />
          <img className="mx-2 cursor-pointer" src={ytIcon} />
        </div>
      </div>
    </footer>
  );
};

export default FooterLanding;
