import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  linkName: "Dashboard",
  sublinkName: ""
};
const linkSlice = createSlice({
  name: "linkSlice",
  initialState,
  reducers: {
    changeName: (state, action) => {
      state.linkName = action.payload;   
    },
    changeSubName: (state, action) => {
      state.sublinkName = action.payload;   
    },

  },
});

export const { changeName, changeSubName } = linkSlice.actions;

export default linkSlice.reducer;
