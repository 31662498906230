import React from 'react';
import Search from '../../containers/Breadcrumb/Search/Search';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Common/Button';
import Heading from '../Common/Heading';

const PageHeading = ({
  title,
  hasSearch,
  addButtonName,
  userRole,
  buttonClick,
  noButton,
  setSearchData,
  setSearchDataLoading,
}) => {
  const t = useTranslate();

  return (
    <div className="flex w-full items-center px-8 mb-4">
      <Heading>{title}</Heading>
      <div className="ml-auto mr-4">
        {!noButton && <Button onClick={buttonClick}>{addButtonName}</Button>}
      </div>
      {hasSearch && (
        <Search
          userRole={userRole}
          setSearchData={setSearchData}
          setSearchDataLoading={setSearchDataLoading}
          placeholder={`${t('search')}...`}
        />
      )}
    </div>
  );
};

export default PageHeading;
