import React from "react";
import { useEffect } from "react";
import SingleSubCategory from "./SingleSubCategory";

const SubCategoriesSelect = ({
  originCategory,
  setTempCategory,
  tempCategory,
}) => {
  useEffect(() => {
    setTempCategory(originCategory);
  }, []);

  return (
    <>
      <div className="w-full mt-4">
        {tempCategory?.subcategories
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          ?.filter((item) => item.assigned !== true)
          .map((subCategory, index) => (
            <SingleSubCategory
              key={index}
              subCategory={subCategory}
              setTempCategory={setTempCategory}
            />
          ))}
      </div>
    </>
  );
};

export default SubCategoriesSelect;
