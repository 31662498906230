import React, { useState } from 'react';
import { useEffect } from 'react';
import useTranslate from '../../../hooks/useTranslate';

const HorizontalBarChart = ({ data }) => {
  const t = useTranslate();
  const dataArray = Object.values(data).sort((a, b) => b - a);
  const [width1, setWidth1] = useState((dataArray[1] / dataArray[0]) * 100);
  const [width2, setWidth2] = useState((dataArray[2] / dataArray[0]) * 100);
  const width = 100;

  useEffect(() => {
    if (width1 === 0) setWidth1(20);
    if (width2 === 0) setWidth2(20);
  }, []);

  const labels = [t('newCases'), t('ongoingCases'), t('finishedCases')];

  return (
    <div className="flex flex-col gap-3 py-8 pb-10 w-1/2 h-full">
      {labels.map((label, index) => {
        return (
          <div key={index} className="flex flex-col px-4 pl-10">
            <span className="mb-2 text-sm text-black font-normal text-left">
              {label}
            </span>
            <div
              className={
                `h-12 px-4 mb-1 rounded-xl text-white font-normal flex items-center text-base transition-all animate-grow origin-left ` +
                (index === 0
                  ? ' bg-lilaDark'
                  : index === 1
                  ? ' bg-pinkDark'
                  : ' bg-pinkLight')
              }
              style={{
                width: `${
                  index === 0 ? width : index === 1 ? width1 : width2
                }%`,
              }}
            >
              {dataArray[index]}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HorizontalBarChart;
