import React, { useEffect } from 'react';
import Rating from '@mui/material/Rating';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCaseRatingsByIdApi } from '../../../features/cases/companyCases/getCaseRatingsByIdSlice';
import './DetailsComponent.css';
import { Box, LinearProgress } from '@material-ui/core';
import useTranslate from '../../../hooks/useTranslate';
import useAuth from '../../../hooks/useAuth';

export default function RatingsComponent({ userDetails, caseStatus }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const ratings = useSelector((state) => state.getCaseRatingsById.ratings);
  const status = useSelector((state) => state.getCaseRatingsById.status);
  const t = useTranslate();

  useEffect(() => {
    dispatch(
      fetchCaseRatingsByIdApi({
        id: user?.role_id !== 2 ? userDetails.problem_id : userDetails.id,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchCaseRatingsByIdApi({
        id: user?.role_id !== 2 ? userDetails.problem_id : userDetails.id,
      })
    );
  }, [userDetails]);

  return (
    <>
      {status === 'loading' ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {user?.role_id === 1 && (
            <div className={caseStatus === 'completed' ? 'mb-12' : ''}>
              <div className="flex flex-row justify-between items-center py-4 px-6 gradient-background">
                <span className="case-header-name font-normal text-lg">
                  {t('videoCall')}
                </span>
              </div>
              <div className="flex flex-col py-4 px-6 case-detail-bottom-border">
                <span className="flex flex-row justify-between content-start">
                  <span className="case-header-name font-normal text-base pb-4">
                    Expert ({t('byUser')})
                  </span>
                  <Rating
                    name="read-only"
                    value={
                      ratings?.expert_by_user?.length > 0
                        ? ratings?.expert_by_user[0]?.rating
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </span>
                <span className="font-normal expert-title text-sm max-w-xs ">
                  {ratings?.expert_by_user?.length > 0
                    ? ratings?.expert_by_user[0]?.review
                    : t('noReview')}
                </span>
              </div>
              <div className="flex flex-col py-4 px-6 ">
                <span className="flex flex-row justify-between content-start">
                  <span className="case-header-name font-normal text-base pb-4">
                    {t('user')} ({t('byExpert')})
                  </span>
                  <Rating
                    name="read-only"
                    value={
                      ratings?.user_by_expert?.length > 0
                        ? ratings?.user_by_expert[0].rating
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </span>
                <span className="font-normal expert-title text-sm max-w-xs ">
                  {ratings?.user_by_expert?.length > 0
                    ? ratings?.user_by_expert[0]?.review
                    : t('noReview')}
                </span>
              </div>
            </div>
          )}
          {caseStatus === 'completed' && (
            <>
              <div className="flex flex-row justify-between items-center py-4 px-6 fuchsa-background">
                <span className="case-header-name font-normal text-lg">
                  Home visit
                </span>
              </div>
              <div className="flex flex-col py-4 px-6 case-detail-bottom-border">
                <span className="flex flex-row justify-between content-start">
                  <span className="case-header-name font-normal text-base pb-4">
                    Handyman ({t('byUser')})
                  </span>
                  <Rating
                    name="read-only"
                    value={
                      ratings?.handyman_by_user?.length > 0
                        ? ratings?.handyman_by_user[0]?.rating
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </span>
                <span className="font-normal expert-title text-sm max-w-xs ">
                  {ratings?.handyman_by_user?.length > 0
                    ? ratings?.handyman_by_user[0]?.review
                    : t('noReview')}
                </span>
              </div>
              <div className="flex flex-col py-4 px-6 ">
                <span className="flex flex-row justify-between content-start">
                  <span className="case-header-name font-normal text-base pb-4">
                    {t('user')} ( by handyman )
                  </span>
                  <Rating
                    name="read-only"
                    value={
                      ratings?.user_by_handyman?.length > 0
                        ? ratings?.user_by_handyman[0].rating
                        : 0
                    }
                    precision={0.1}
                    readOnly
                  />
                </span>
                <span className="font-normal expert-title text-sm max-w-xs ">
                  {ratings?.user_by_handyman?.length > 0
                    ? ratings?.user_by_handyman[0]?.review
                    : t('noReview')}
                </span>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
