import '../../assets/css/expert.css';
import PlaceholderImage from '../../assets/placeholderImg.png';
import CommonTable from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { Tabs } from '../Common/Tabs';
import { TabPanel } from '@mui/lab';
import ExpertForm from './ExpertForm';
import closeButton from '../../assets/close-button.svg';
import { useQuery } from '@tanstack/react-query';
import { fetchAllExperts, fetchExpertById } from '../../api/Experts/Experts';
import { useOutletContext } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import ExpertCases from './ExpertCases';

const ExpertEdit = () => {
  const [toggleCreate, setToggleCreate] = useOutletContext();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  let params = useParams();
  const id = params.id;
  const t = useTranslate();

  const header_small = [t('expert')];
  const tabsArray = [{ label: t('expertInfo') }, { label: t('cases') }];

  // Fetch all experts
  const {
    data: expertsList,
    isLoading: expertListLoading,
    isError,
  } = useQuery(['expertsEdit'], fetchAllExperts, {
    onSuccess: () => {},
  });

  // Fetch targeted Expert
  const {
    data: expertData,
    isLoading: expertLoading,
    refetch,
  } = useQuery(['expert', id], fetchExpertById, {
    refetchOnWindowFocus: false,
  });

  const dataPreview_small = (
    rowsPerPage > 0
      ? expertsList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : expertsList
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={id == row.id ? 'table-highlight-purple' : ''}
      onClick={() => {
        navigate('/d/experts/edit/' + row.id);
      }}
    >
      <StyledTableCell component={Link} to={'/d/experts/edit/' + row.id}>
        <div className="flex">
          <div className="w-10 h-10 rounded-full object-cover">
            <img
              src={row?.profile_image ? row?.profile_image : PlaceholderImage}
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full"
              alt="user"
            />
          </div>
          <div className="px-4 text-tablePrimary">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}{' '}
            </p>
            <p className="font-color">
              {row?.post_code ? row?.post_code : '-'}{' '}
              {row?.street ? row?.street : '-'}
            </p>
          </div>
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <div className="flex w-full px-4 gap-4">
      <div id="expert_small_table_container" className="w-3/4">
        {expertListLoading ? (
          <div>{t('loading')}</div>
        ) : (
          <CommonTable
            header={header_small}
            tableData={dataPreview_small}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={dataPreview_small ? dataPreview_small.length : 0}
            page={page}
          />
        )}
      </div>
      <div
        className="rounded-xl mx-4  mb-4 px-4 py-2 box-shadow-filter filter-background w-2/4 h-fit"
        style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
      >
        <div className="flex justify-end pt-6 px-6">
          <img
            className="cursor-pointer"
            src={closeButton}
            height="12"
            width="12"
            onClick={() => {
              navigate('/d/experts');
            }}
            alt="closeButton"
          />
        </div>
        <Tabs tabs={tabsArray}>
          <TabPanel value="1">
            <ExpertForm
              isEdit={toggleCreate ? false : true}
              isLoading={expertLoading}
              expertData={expertData}
              isCreate={toggleCreate}
              setToggleCreate={setToggleCreate}
              refetch={refetch}
            />
          </TabPanel>
          <TabPanel value="2">
            <ExpertCases />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default ExpertEdit;
