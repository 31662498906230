import { createContext, useEffect, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [client, setClient] = useState(null);

  if (localStorage.hasOwnProperty('token') && loggedIn === false) {
    setLoggedIn(true);
    setUser(JSON.parse(localStorage.getItem('user')));
  }
  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        user,
        setUser,
        client,
        setClient,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
