import { Box, LinearProgress, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { updateProblem } from '../../../../api/Experts/Experts';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import useAuth from '../../../../hooks/useAuth';

const AcceptCaseModal = ({
  selectedCaseDetails,
  refetchProblems,
  setAcceptModal,
}) => {
  const t = useTranslate();

  const { user } = useAuth();
  const [callTime, setCallTime] = useState(
    DateTime.fromISO(selectedCaseDetails?.time_slots?.time_from)
  );

  const { mutateAsync: approveCase, isLoading: approveLoading } = useMutation(
    updateProblem,
    {
      onSuccess: () => {
        refetchProblems();
        toast.success(t('accepted'), { duration: 5000 });
        setAcceptModal(false);
      },
      onError: (error) => {
        if (error.response.status) {
          toast.error(t('expertBusy'), { duration: 5000 });
        }
      },
    }
  );

  const handleApprove = () => {
    approveCase({
      problem_id: selectedCaseDetails?.id,
      body: {
        status_id: 2,
        expert_id: user.id,
        user_date_from: selectedCaseDetails?.time_slots?.date_from,
        user_time_from: new DateTime(callTime).toFormat('HH:mm'),
      },
    });
  };

  return (
    <div className="p-4 flex flex-col gap-4">
      <div className="case-header-name font-medium w-full text-center my-2">
        <span>{t('selectCallTime')}</span>
      </div>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <TimePicker
          ampm={false}
          label={t('callTime')}
          minTime={DateTime.fromISO(selectedCaseDetails?.time_slots?.time_from)}
          maxTime={DateTime.fromISO(
            selectedCaseDetails?.time_slots?.time_to
          ).minus({ minutes: 15 })}
          value={callTime}
          onChange={(newValue) => {
            setCallTime(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} onFocus={(e) => e.target.blur()} />
          )}
        />
      </LocalizationProvider>
      {approveLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <Button onClick={() => handleApprove()}>{t('accept')}</Button>
      )}
    </div>
  );
};

export default AcceptCaseModal;
