import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import ImageUpload from '../../../containers/MainContentContainer/CommonImageUpload/ImageUpload';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import CategoryDropdown from '../../Dropdowns/CategoryDropdown';
import { ReactComponent as Minus } from '../../../assets/Minus.svg';
import { ReactComponent as Plus } from '../../../assets/Plus.svg';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import Modal from '../../Common/Modal';
import CategoryModal from '../../Organisms/CategoryModal';
import ErrorMessage from '../../Common/ErrorMessage';
import DocumentUpload from '../../Common/DocumentUpload';
import pdfIcon from '../../../assets/images/pdf-icon.svg';
import {
  cleanCategories,
  getCompanyCategories,
  removeCategory,
} from '../../../helpers/Expert/filterExpertCategories';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createHandyman, updateHandyman } from '../../../api/Handyman/Handyman';
import DocumentDeleteModal from '../../Common/DocumentDeleteModal';
import { Box, LinearProgress } from '@mui/material';
import HandymanInputFields from './HandymanInputFields';
import useTranslate from '../../../hooks/useTranslate';
import useAuth from '../../../hooks/useAuth';
import { fetchCompanyCategoriesById } from '../../../api/Company/Company';
import DeleteUserModal from '../../Common/DeleteUserModal';

const HandymanForm = ({ isCreate, handymanData, handymanLoading }) => {
  const [categories, setCategories] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [newCategories, setNewCategories] = useState([
    handymanData?.categories,
  ]);
  const [documentsList, setDocumentsList] = useState(
    handymanData?.documents || []
  );
  const [toggleCreate, setToggleCreate, searchData] = useOutletContext();

  const queryClient = useQueryClient();
  let params = useParams();
  const id = params.id;
  const { user } = useAuth();
  const t = useTranslate();
  const location = useLocation();
  const { disabledform } = location.state != null ? location.state : false;

  const baseUrl = process.env.REACT_APP_BASE_URL.slice(0, -4);

  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState(null);
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [subParentId, setSubParentId] = useState(null);
  const [isEditing, setIsEditing] = useState(disabledform);
  const [isOpen, setIsOpen] = useState(false);
  const inputFields = HandymanInputFields();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: 'all' });

  const resetFields = () => {
    setValue('name', '');
    setValue('last_name', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('profile_image', PlaceholderImage);
  };

  //Get company categories if new handyman
  const { data: categoriesList, isLoading: allCategoriesLoading } = useQuery(
    ['companyCategories', user?.company_id],
    fetchCompanyCategoriesById,
    {
      enabled: !!isCreate,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        let temp = getCompanyCategories(data);
        setCategories(getCompanyCategories(data));
        setNewCategories(cleanCategories(temp));
      },
    }
  );

  //Update handyman
  const {
    mutateAsync: updateHandymanMutation,
    isLoading: updateHandymanLoading,
  } = useMutation(updateHandyman, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userSearch']);
      queryClient.invalidateQueries(['handyman']);
      setToggleCreate(false);
      setIsEditing(false);
      setNewCategories(handymanData?.categories);
    },
    onError: (error) => {
      if (error.response.status === 422 || error.response.status === 409) {
        setErrorMsg(t('emailTaken'));
      }
    },
  });

  //Create handyman
  const {
    mutateAsync: createHandymanMutation,
    isLoading: createHandymanLoading,
  } = useMutation(createHandyman, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userSearch']);
      setToggleCreate(false);
    },
    onError: (error) => {
      if (error.response.status === 422 || error.response.status === 409) {
        setErrorMsg(t('emailTaken'));
      }
    },
  });

  useEffect(() => {
    setValue('documents', uploadDocuments, { shouldDirty: true });
  }, [uploadDocuments]);

  useEffect(() => {
    if (isCreate) {
      setIsEditing(true);
      reset({});
      setDocumentsList([]);
      resetFields();
      setNewCategories(cleanCategories(categoriesList));
    } else {
      setCategories(handymanData?.categories);
      setNewCategories(handymanData?.categories);
      setDocumentsList(handymanData?.documents);
      reset(handymanData);
      setValue('profile_image', '', { shouldDirty: false });
      setValue('documents', [], { shouldDirty: false });
    }
  }, [handymanData, isCreate, id]);

  const submitHandler = (data) => {
    delete data.company_id;
    setErrorMsg('');
    let documentArray;
    if (data.documents === handymanData?.documents) {
      documentArray = [];
    } else {
      documentArray = data.documents;
    }
    if (isCreate) {
      if (isValid) {
        createHandymanMutation({
          ...data,
          categories: newCategories,
          documents: documentArray,
        });
        setUploadDocuments([]);
      }
    } else {
      if (isValid) {
        updateHandymanMutation({
          ...data,
          categories: newCategories,
          documents: documentArray,
        });
        setUploadDocuments([]);
      }
    }
  };

  const handleDeleteModal = (document) => {
    setDeleteDocument(document);
    setDeleteDocumentModal(true);
  };

  const setSubContent = (id) => {
    setSubParentId(id);
    setSubCategoryModal(true);
    setIsOpen(true);
  };

  return (
    <>
      {handymanLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackdrop
            className="px-4 py-8 w-full max-w-md"
          >
            <CategoryModal
              allCategoriesLoading={isCreate ? allCategoriesLoading : false}
              setIsOpen={setIsOpen}
              editableCategories={newCategories}
              setEditableCategories={setNewCategories}
              originCategories={categories}
              setSubCategoryModal={setSubCategoryModal}
              subCategoryModal={subCategoryModal}
              subParentId={subParentId}
              setSubParentId={setSubParentId}
            />
          </Modal>
          <form
            id="handyman-form"
            onSubmit={handleSubmit((data) => submitHandler(data))}
          >
            <div className="items-center gap-3 flex mb-3">
              <label
                className={`block text-sm font-medium w-52 ${
                  !isEditing ? 'text-gray-400 ' : 'text-gray-700 '
                }`}
              >
                {t('handyman')} {t('image')}:
              </label>
              <div className="w-full">
                <ImageUpload
                  setValue={setValue}
                  valueName="profile_image"
                  editForm={isEditing}
                  currentImg={
                    isCreate
                      ? PlaceholderImage
                      : handymanData?.profile_image
                      ? handymanData?.profile_image
                      : PlaceholderImage
                  }
                />
              </div>
            </div>
            {inputFields.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Input
                    register={register}
                    placeholder={item.placeholder}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    max={item.max}
                    pattern={item.pattern ? item.pattern : null}
                    required={item.required ? item.required : false}
                    disabled={!isEditing}
                    isError={item.error(errors)}
                    labelWidth="w-52"
                  />
                  <ErrorMessage>{item.error(errors)}</ErrorMessage>
                </Fragment>
              );
            })}
            {isCreate && (
              <>
                <Input
                  register={register}
                  placeholder={t('password')}
                  type={'password'}
                  name={'password'}
                  label={t('password')}
                  max={20}
                  min={8}
                  minValidation={t('passwordShort')}
                  required={isCreate ? t('thisFieldIsRequired') : false}
                  disabled={!isEditing}
                  isError={errors?.password?.message}
                  labelWidth="w-52"
                />
                <ErrorMessage>{errors?.password?.message}</ErrorMessage>
              </>
            )}

            <div className="flex gap-3 items-center mb-2 mt-4">
              <div className="flex w-full">
                <label
                  className={`block text-sm font-medium w-52 mr-3 ${
                    !isEditing ? 'text-gray-400 ' : 'text-gray-700 '
                  }`}
                >
                  {t('categories')}:
                </label>
                <div className="w-full flex flex-col gap-3 ml-1">
                  {newCategories?.filter((cat) =>
                    cat?.subcategories?.some((subC) => subC.assigned)
                  ).length === 0 && !isEditing ? (
                    <span className="text-sm">{t('noCategories')}</span>
                  ) : (
                    newCategories
                      ?.filter((cat) =>
                        cat?.subcategories?.some((subC) => subC.assigned)
                      )
                      ?.map((item, index) => {
                        return (
                          <div key={index} className="flex items-center gap-4">
                            <CategoryDropdown
                              category={item}
                              data={item?.subcategories}
                              disabled={!isEditing}
                              setEditableCategories={setNewCategories}
                              setCategories={setNewCategories}
                              categoryIdx={index}
                              setIsOpen={setIsOpen}
                              setSubContent={setSubContent}
                            />
                            {isEditing && (
                              <Minus
                                className="w-5 cursor-pointer"
                                onClick={() => {
                                  setNewCategories(
                                    removeCategory(newCategories, item.id)
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })
                  )}
                  {isEditing && (
                    <div
                      className="flex items-center gap-2 text-textGray cursor-pointer w-fit"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <Plus className="w-5 h-5" />
                      <span>{t('addCategory')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-3 items-center my-2 mt-5 ">
              <div className="flex w-full">
                <label
                  className={`block text-sm font-medium w-52 mr-3 mt-3 ${
                    !isEditing ? 'text-gray-400 ' : 'text-gray-700 '
                  }`}
                >
                  {t('documents')}:
                </label>
                <div className="w-full flex flex-col">
                  {documentsList?.length > 0
                    ? documentsList?.map((document, index) => (
                        <span
                          key={index}
                          className="flex justify-between items-center"
                        >
                          <a
                            href={baseUrl + '/storage/' + document?.path}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <span>
                              <img
                                src={pdfIcon}
                                className="inline-block py-2"
                                alt="pdf"
                              />
                              {document?.title}
                            </span>
                          </a>
                          {isEditing && (
                            <Minus
                              className="cursor-pointer float-right ml-4"
                              onClick={() =>
                                isEditing
                                  ? handleDeleteModal(document)
                                  : undefined
                              }
                            />
                          )}
                        </span>
                      ))
                    : !isEditing && (
                        <p className="text-sm pt-3 ml-1">{t('noDocuments')}</p>
                      )}
                  <DocumentUpload
                    editForm={isEditing}
                    setValue={setValue}
                    documentsList={documentsList}
                    setDocumentsList={setDocumentsList}
                    uploadDocuments={uploadDocuments}
                    setUploadDocuments={setUploadDocuments}
                    valueName="documents"
                  />
                  <DocumentDeleteModal
                    setDeleteDocumentModal={setDeleteDocumentModal}
                    deleteDocumentModal={deleteDocumentModal}
                    deleteDocument={deleteDocument}
                    setDeleteDocument={setDeleteDocument}
                    setUploadDocuments={setUploadDocuments}
                    setDocumentsList={setDocumentsList}
                  />
                </div>
              </div>
            </div>
            {errorMsg !== '' && (
              <div className="text-red-600 text-sm w-full my-4 text-right">
                {errorMsg}
              </div>
            )}
            {updateHandymanLoading || createHandymanLoading ? (
              <LinearProgress className="mt-12" />
            ) : (
              <div
                className={`flex w-full mt-12 ${
                  !isCreate ? 'justify-between' : 'justify-end'
                }`}
              >
                {!isCreate && (
                  <Button
                    type="button"
                    variant="transparent"
                    onClick={() => setDeleteModal(true)}
                  >
                    {t('delete')}
                  </Button>
                )}
                <div className="flex gap-5">
                  {isEditing ? (
                    <>
                      <Button
                        type="button"
                        variant="transparent"
                        onClick={() => {
                          setIsEditing(false);
                          setToggleCreate(false);
                          setNewCategories(handymanData?.categories);
                        }}
                      >
                        {t('cancel')}
                      </Button>
                      <Button type="submit" form="handyman-form">
                        {t('save')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </form>
          <Modal
            isOpen={deleteModal}
            setIsOpen={setDeleteModal}
            className="p-4 max-w-md w-full"
          >
            <DeleteUserModal
              userDetails={handymanData}
              setDeleteModal={setDeleteModal}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default HandymanForm;
