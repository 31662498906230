import { TableRow, Box, LinearProgress } from '@material-ui/core';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import categoryIcon from '../../../assets/category-icon.svg';
import React, { useState, Fragment } from 'react';
import { fetchCompletedCasesByHandymanId } from '../../../api/Handyman/Handyman';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import PlaceholderImage from '../../../assets/placeholderImg.png';

const header = ['User', 'Category', 'Status'];

export default function HandymanCases() {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { user } = useAuth();
  let params = useParams();
  const id = params.id;
  const t = useTranslate();

  const { data: casesList, isLoading: casesListLoading } = useQuery(
    ['cases', id],
    fetchCompletedCasesByHandymanId,
    {
      onSuccess: () => {},
    }
  );

  const dataPreview = (
    rowsPerPage > 0
      ? casesList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : casesList
  )?.map((row, index) => (
    <TableRow row={row} key={index + row?.user?.name}>
      <StyledTableCell>
        <div className="flex">
          <img
            src={
              row?.user?.profile_image
                ? row?.user?.profile_image
                : PlaceholderImage
            }
            className="w-10 h-10 rounded-full min-w-[40px] object-cover"
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
          />
          <div className="px-4">
            <p className="font-semibold text-base leading-6 font-color">
              {row?.user?.name} {row?.user?.last_name}
            </p>
            <span className="font-color">
              {row?.user?.post_code} {row?.user?.street}
            </span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex">
          <img src={categoryIcon}></img>
          <div className="px-4">
            <p className="font-semibold text-base leading-6 font-color">
              {row?.category?.parent?.name}
            </p>
            <span className="font-color">{row?.category?.name}</span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell className="case-status">
        <span
          className={
            row?.status.name === 'completed_by_handyman'
              ? 'status-urgent whitespace-nowrap'
              : 'status-non-urgent whitespace-nowrap'
          }
        >
          {row?.status.name === 'completed_by_handyman'
            ? t('completedByHandyman')
            : t('closed')}
        </span>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <Fragment>
      {casesListLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <CommonTable
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          count={casesList ? casesList.length : 0}
          header={header}
          tableData={dataPreview}
        />
      )}
    </Fragment>
  );
}
