import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { getAllFaqs } from "../../../api/Settings/FAQ/FAQ";
import useTranslate from "../../../hooks/useTranslate";
import Button from "../../Common/Button";
import FAQForm from "./FAQs/FAQForm";
import SingleFAQ from "./FAQs/SingleFAQ";

const FAQ = ({ selectedFAQ }) => {
  const [isCreating, setIsCreating] = useState(false);
  const t = useTranslate()

  let renderFAQs;
  let userTypeTitle;

  const { data, isLoading, isError } = useQuery(["Faqs"], getAllFaqs);

  switch (selectedFAQ) {
    case 2:
      renderFAQs = data?.filter((item) => item.for_user_role === 2);
      userTypeTitle = t('companies');
      break;
    case 3:
      renderFAQs = data?.filter((item) => item.for_user_role === 3);
      userTypeTitle = t('experts');
      break;
    case 4:
      renderFAQs = data?.filter((item) => item.for_user_role === 4);
      userTypeTitle = t('handymen');
      break;
    case 5:
      renderFAQs = data?.filter((item) => item.for_user_role === 5);
      userTypeTitle = t('users');
      break;
  }

  const handleCancel = () => {
    setIsCreating(false);
  };

  if (isError)
    return <div>{t('errorRequest')} </div>;

  return (
    <div >
      <div className="w-full flex justify-between items-center pb-6 border-b border-slate-200">
        <span className="font-poppins text-violetPrimary font-medium">
          {t('frequentlyAskedQuestionsBy')} {userTypeTitle}
        </span>
        <Button
          onClick={() => {
            setIsCreating(true);
          }}
        >
         {`+ ${t('addAQuestion')}`}
        </Button>
      </div>
      <div className="mt-4 settings-tab_maxheight overflow-auto">
        {isCreating && (
          <div className=" border-b border-slate-200 pb-4">
            <FAQForm cancelForm={handleCancel} FAQType={selectedFAQ} />
          </div>
        )}
        <div className="w-full">
          {renderFAQs?.map((item, index) => (
            <SingleFAQ item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
