import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import complete from '../../assets/images/complete.svg';

import RegisterComponentPart1 from './RegisterComponentPart1';
import RegisterComponentPart2 from './RegisterComponentPart2';
import RegisterComponentPart3 from './RegisterComponentPart3';
import RegisterComponentPart4 from './RegisterComponentPart4';
import RegisterComponentPart5 from './RegisterComponentPart5';
import RegisterWaitingComponent from './RegisterWaitingComponent';
import useTranslate from '../../hooks/useTranslate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function RegisterTabsComponent() {
  const [value, setValue] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const t = useTranslate();

  const onSubmit = (data) => {};

  const [formData, setFormData] = useState({
    companyName: '',
    organisationalNumber: '',
    companyPhone: '',
    companyEmail: '',
    companyAddress: '',
    adminName: '',
    adminLastName: '',
    adminPhone: '',
    adminEmail: '',
    password: '',
    passwordConfirm: '',
    companyName_error: false,
    organisationalNumber_error: false,
    companyPhone_error: false,
    companyEmail_error: false,
    companyAddress_error: false,
    adminName_error: false,
    adminLastName_error: false,
    adminPhone_error: false,
    adminEmail_error: false,
    password_error: false,
    passwordConfirm_error: false,
    form_valid: true,
  });

  return (
    <Box
      className="register-form items-start"
      sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        height: '100%',
      }}
    >
      <Tabs
        orientation="vertical"
        className="w-1/4 px-8 py-20 "
        value={value}
        scrollButtons={false}
        textColor="secondary"
        aria-label="Vertical tabs example"
        TabIndicatorProps={{
          sx: { backgroundColor: 'unset !important' },
        }}
      >
        <Tab
          label={
            <React.Fragment>
              <p className="register-tab-label">
                <span
                  className={
                    formData.companyName !== '' &&
                    formData.organisationalNumber !== ''
                      ? 'inline-flex justify-center items-center w-10 h-10 mr-6 completed'
                      : 'inline-flex justify-center items-center w-10 h-10 mr-6'
                  }
                  style={{ borderRadius: '50%' }}
                >
                  {formData.companyName !== '' &&
                  formData.organisationalNumber !== '' ? (
                    <img src={complete} />
                  ) : (
                    '1'
                  )}
                </span>
                {t('companyName').toUpperCase()}
              </p>
            </React.Fragment>
          }
          {...a11yProps(0)}
          style={{
            fontSize: '18px',
            paddingBottom: '3rem',
            width: '100%',
            alignItems: 'flex-start',
          }}
        />
        <Tab
          label={
            <React.Fragment>
              <p className="register-tab-label">
                <span
                  className={
                    formData.companyEmail !== '' &&
                    formData.companyAddress &&
                    formData.companyPhone
                      ? 'inline-flex justify-center items-center w-10 h-10 mr-6 completed'
                      : 'inline-flex justify-center items-center w-10 h-10 mr-6'
                  }
                  style={{ borderRadius: '50%' }}
                >
                  {formData.companyEmail !== '' &&
                  formData.companyAddress &&
                  formData.companyPhone ? (
                    <img src={complete} />
                  ) : (
                    '2'
                  )}
                </span>
                {`${t('company')} Info`.toUpperCase()}
              </p>
            </React.Fragment>
          }
          {...a11yProps(1)}
          style={{
            fontSize: '18px',
            paddingBottom: '3rem',
            width: '100%',
            alignItems: 'flex-start',
          }}
        />
        <Tab
          label={
            <React.Fragment>
              <p className="register-tab-label">
                <span
                  className={
                    formData.adminName !== '' &&
                    formData.adminLastName &&
                    formData.adminEmail &&
                    formData.adminPhone
                      ? 'inline-flex justify-center items-center w-10 h-10 mr-6 completed'
                      : 'inline-flex justify-center items-center w-10 h-10 mr-6'
                  }
                  style={{ borderRadius: '50%' }}
                >
                  {formData.adminName !== '' &&
                  formData.adminLastName &&
                  formData.adminEmail &&
                  formData.adminPhone ? (
                    <img src={complete} />
                  ) : (
                    '3'
                  )}
                </span>
                {`${t('admin')} ${t('info')}`.toUpperCase()}
              </p>
            </React.Fragment>
          }
          {...a11yProps(2)}
          style={{
            fontSize: '18px',
            paddingBottom: '3rem',
            width: '100%',
            alignItems: 'flex-start',
          }}
        />
        <Tab
          label={
            <React.Fragment>
              <p className="register-tab-label">
                <span
                  className={
                    formData.password !== '' &&
                    formData.passwordConfirm !== '' &&
                    formData.password === formData.passwordConfirm
                      ? 'inline-flex justify-center items-center w-10 h-10 mr-6 completed'
                      : 'inline-flex justify-center items-center w-10 h-10 mr-6'
                  }
                  style={{ borderRadius: '50%' }}
                >
                  {formData.password !== '' &&
                  formData.passwordConfirm !== '' &&
                  formData.password === formData.passwordConfirm ? (
                    <img src={complete} />
                  ) : (
                    '4'
                  )}
                </span>
                {t('createPassword').toUpperCase()}
              </p>
            </React.Fragment>
          }
          {...a11yProps(3)}
          style={{
            fontSize: '18px',
            paddingBottom: '3rem',
            width: '100%',
            alignItems: 'flex-start',
          }}
        />
        <Tab
          label={
            <React.Fragment>
              <p className="register-tab-label">
                <span
                  className={
                    formData.companyName !== '' &&
                    formData.organisationalNumber !== '' &&
                    formData.companyEmail !== '' &&
                    formData.companyAddress !== '' &&
                    formData.companyPhone !== '' &&
                    formData.adminName !== '' &&
                    formData.adminLastName !== '' &&
                    formData.adminEmail !== '' &&
                    formData.adminPhone !== '' &&
                    formData.password !== '' &&
                    formData.passwordConfirm !== '' &&
                    formData.password === formData.passwordConfirm
                      ? 'inline-flex justify-center items-center w-10 h-10 mr-6 completed'
                      : 'inline-flex justify-center items-center w-10 h-10 mr-6'
                  }
                  style={{ borderRadius: '50%' }}
                >
                  {formData.companyName !== '' &&
                  formData.organisationalNumber !== '' &&
                  formData.companyEmail !== '' &&
                  formData.companyAddress !== '' &&
                  formData.companyPhone !== '' &&
                  formData.adminName !== '' &&
                  formData.adminLastName !== '' &&
                  formData.adminEmail !== '' &&
                  formData.adminPhone !== '' &&
                  formData.password !== '' &&
                  formData.passwordConfirm !== '' &&
                  formData.password === formData.passwordConfirm ? (
                    <img src={complete} />
                  ) : (
                    '5'
                  )}
                </span>
                {t('finish').toUpperCase()}
              </p>
            </React.Fragment>
          }
          {...a11yProps(4)}
          style={{
            fontSize: '18px',
            paddingBottom: '3rem',
            width: '100%',
            alignItems: 'flex-start',
          }}
        />
        <span className="w-full text-right flex mt-16">
          <p
            style={{
              color: '#909090',
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('havingProblem')}{' '}
            <span
              className="cursor-pointer underline text-blue-600"
              onClick={() => window.open('https://mendy.se/kontakt/', '_blank')}
            >
              {t('contactSupport')}
            </span>
          </p>
        </span>
      </Tabs>
      <form onSubmit={handleSubmit(onSubmit)} className="w-3/4">
        <TabPanel value={value} index={0} className="w-3/4">
          <RegisterComponentPart1
            formData={formData}
            setFormData={setFormData}
            setValue={setValue}
          />
        </TabPanel>

        <TabPanel value={value} index={1} className="w-3/4">
          <RegisterComponentPart2
            formData={formData}
            setFormData={setFormData}
            setValue={setValue}
          />
        </TabPanel>

        <TabPanel value={value} index={2} className="w-3/4">
          <RegisterComponentPart3
            formData={formData}
            setFormData={setFormData}
            setValue={setValue}
          />
        </TabPanel>

        <TabPanel value={value} index={3} className="w-3/4">
          <RegisterComponentPart4
            formData={formData}
            setFormData={setFormData}
            setValue={setValue}
          />
        </TabPanel>

        <TabPanel value={value} index={4} className="w-3/4">
          <RegisterComponentPart5 formData={formData} setValue={setValue} />
        </TabPanel>

        <TabPanel value={value} index={5} className="w-3/4">
          <RegisterWaitingComponent />
        </TabPanel>
      </form>
    </Box>
  );
}
