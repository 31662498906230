import { TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CommonTable from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { StyledTableCell } from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../assets/placeholderImg.png';
import rateImageUrl from '../../assets/images/tabler_star.svg';
import iconEdit from '../../assets/images/tabler_edit.svg';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';
import closeButton from '../../assets/close-button.svg';
import iconDelete from '../../assets/images/tabler_trash.svg';
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchAllExperts,
  fetchExpertById,
  updateApprovalExpert,
} from '../../api/Experts/Experts';
import useTranslate from '../../hooks/useTranslate';
import { Tabs } from '../Common/Tabs';
import { TabPanel } from '@mui/lab';
import ExpertForm from './ExpertForm';
import ExpertCases from './ExpertCases';
import Modal from '../Common/Modal';
import DeleteUserModal from '../Common/DeleteUserModal';
import { Avatar, Badge, Box, LinearProgress, styled } from '@mui/material';

const StatusBadge = styled(Badge)(() => ({
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.0)',
      opacity: 0,
    },
  },
  '& .MuiBadge-badge': {
    height: 12,
    minWidth: 12,
    borderRadius: '50%',
    boxShadow: `0 0 0 1px #fff`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
}));

const Experts = () => {
  const [toggleCreate, setToggleCreate, searchData, searchDataLoading] =
    useOutletContext();
  const [expertDetails, setExpertDetails] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  let params = useParams();
  const t = useTranslate();
  const id = params.id;

  useEffect(() => {
    setPage(0);
  }, [searchData]);

  const header = [
    t('expert'),
    t('info'),
    t('category'),
    t('rating'),
    t('approvalStatus'),
    '',
  ];
  const header_small = [t('expert')];
  const tabsArray = [
    { label: t('expertInfo') },
    !toggleCreate && { label: t('cases') },
  ];

  const { mutateAsync: setApprovalMutation } = useMutation(
    updateApprovalExpert,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSearch']);
      },
    }
  );

  // Fetch targeted Expert
  const {
    data: expertData,
    isLoading: expertLoading,
    refetch,
  } = useQuery(['expert', id], fetchExpertById, {
    refetchOnWindowFocus: false,
    enabled: id ? true : false,
  });

  // const deleteExpert = (id) => {};

  const approvalHandler = (e, status, id) => {
    e.stopPropagation();
    setApprovalMutation({ status, id });
  };

  const dataPreview_small = (
    rowsPerPage > 0
      ? searchData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : searchData
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={id == row.id ? 'table-highlight-purple' : ''}
    >
      <StyledTableCell
        component={Link}
        to={'/d/experts/edit/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-full">
            <StatusBadge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              overlap="circular"
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  color: row?.is_online_super_admin ? '#44b700' : '#D80000',
                  backgroundColor: row?.is_online_super_admin
                    ? '#44b700'
                    : '#D80000 ',
                },
              }}
            >
              <Avatar
                src={row?.profile_image ? row?.profile_image : PlaceholderImage}
                alt="user"
              />
            </StatusBadge>
          </div>
          <div className="px-4 text-tablePrimary">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}{' '}
            </p>
          </div>
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  const dataPreview = (
    rowsPerPage > 0
      ? searchData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : searchData
  )?.map((row, index) => (
    <TableRow row={row} key={index}>
      <StyledTableCell
        component={Link}
        to={'/d/experts/edit/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex items-center">
          <div className="w-10 h-10 rounded-full">
            <StatusBadge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              overlap="circular"
              variant="dot"
              sx={{
                '& .MuiBadge-badge': {
                  color: row?.is_online_super_admin ? '#44b700' : '#D80000',
                  backgroundColor: row?.is_online_super_admin
                    ? '#44b700'
                    : '#D80000 ',
                },
              }}
            >
              <Avatar
                src={row?.profile_image ? row?.profile_image : PlaceholderImage}
                alt="user"
              />
            </StatusBadge>
          </div>
          <div className="px-4 text-tablePrimary">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}{' '}
            </p>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell
        component={Link}
        to={'/d/experts/edit/' + row.id}
        state={{ disabledform: false }}
        className="flex-1"
      >
        <div className="flex ">
          <div className="pr-4">
            <p className="name-title font-medium">{row?.phone}</p>
            <p className="text-sm">{row?.email}</p>
          </div>
        </div>
      </StyledTableCell>

      <StyledTableCell
        component={Link}
        to={'/d/experts/edit/' + row.id}
        state={{ disabledform: false }}
        className="flex-1"
      >
        {row?.categories?.length > 0 ? (
          <div className="flex gap-1 items-center">
            {row?.categories
              ?.filter((i, index) => index < 3)
              .map((category, index) => (
                <FetchCategoryIcon
                  key={index}
                  strokeColor={'strokeBlue'}
                  name={category?.icon}
                />
              ))}
            <p className="text-indigo-800 font-medium text-base leading-6">
              {row?.categories.length > 3
                ? `+${row?.categories.length - 3}`
                : ''}
            </p>
          </div>
        ) : (
          <p className="font-color">{t('noData')}</p>
        )}
      </StyledTableCell>
      <StyledTableCell
        component={Link}
        to={'/d/experts/edit/' + row.id}
        state={{ disabledform: false }}
        className="flex-1"
      >
        <div className="flex ">
          {row?.avg_rating ? (
            <>
              <img src={rateImageUrl} alt="Rating" />
              <div className="px-4">
                <p className="name-title">{row?.avg_rating}</p>
              </div>
            </>
          ) : (
            <p className="font-color">{t('noData')}</p>
          )}
        </div>
      </StyledTableCell>
      <StyledTableCell className="flex-1">
        <div className="flex gap-6 w-full font-medium text-base leading-6 items-center">
          {row?.approval_status === 2 ? (
            <span>{t('approved')}</span>
          ) : row?.approval_status === 3 ? (
            <span>{t('denied')}</span>
          ) : (
            <>
              <span
                className="text-violetPrimary hover:brightness-200 transition-all"
                onClick={(e) => {
                  e.stopPropagation();
                  approvalHandler(e, true, row.id);
                }}
              >
                {t('approve')}
              </span>
              <span
                className="text-red-500 hover:brightness-200 transition-all"
                onClick={(e) => {
                  e.stopPropagation();
                  approvalHandler(e, false, row.id);
                }}
              >
                {t('deny')}
              </span>
            </>
          )}
        </div>
      </StyledTableCell>

      <StyledTableCell className="flex-1">
        <div className="flex justify-end pr-8">
          <Link to={'/d/experts/edit/' + row.id} state={{ disabledform: true }}>
            <img className="px-4 w-full" src={iconEdit} alt="Edit" />
          </Link>
          <img
            src={iconDelete}
            onClick={(event) => {
              event.stopPropagation();
              setDeleteModal(true);
              setExpertDetails(row);
            }}
            alt="Delete icon"
          />
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <div className="flex w-full px-8 gap-4">
      <div className={id || toggleCreate ? 'w-7/12' : 'w-full'}>
        {searchDataLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <CommonTable
              header={id || toggleCreate ? header_small : header}
              tableData={id || toggleCreate ? dataPreview_small : dataPreview}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={searchData ? searchData?.length : 0}
              page={page}
            />
            <Modal
              isOpen={deleteModal}
              setIsOpen={setDeleteModal}
              className="p-4 max-w-md w-full"
            >
              <DeleteUserModal
                userDetails={expertDetails}
                setDeleteModal={setDeleteModal}
              />
            </Modal>
          </>
        )}
      </div>
      {(id || toggleCreate) && (
        <div
          className="rounded-xl mb-4  py-2 box-shadow-filter filter-background w-8/12 h-fit"
          style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
        >
          <div className="float-right pt-4 px-6">
            <img
              className="cursor-pointer"
              src={closeButton}
              height="12"
              width="12"
              onClick={() => {
                navigate('/d/experts');
                setToggleCreate(false);
              }}
            />
          </div>
          <Tabs tabs={tabsArray}>
            <TabPanel value="1">
              <ExpertForm
                expertLoading={expertLoading}
                expertData={expertData}
                isCreate={toggleCreate}
                setToggleCreate={setToggleCreate}
                refetch={refetch}
              />
            </TabPanel>
            {!toggleCreate && (
              <TabPanel value="2">
                <ExpertCases />
              </TabPanel>
            )}
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default Experts;
