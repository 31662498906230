import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { updateCallTime } from '../../../api/VideoCalls/Reviews';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import Button from '../../Common/Button';
import history from '../../../helpers/customRoutes/history';
import CallRinging from './VideoCall/CallRinging';
import { sendCallNotification } from '../../../api/VideoCalls/Notification';

const ConfirmCall = ({
  userName,
  setModalOpen,
  setDisableOverlay,
  problem,
}) => {
  const [callConfirmed, setCallConfirmed] = useState(false);
  const [agoraClient, setAgoraClient] = useState(null);
  const { user } = useAuth();
  const location = useLocation();
  const isCalling = location?.state?.callContent;
  const { client } = useAuth();
  const t = useTranslate();

  const { mutateAsync: updateProblem } = useMutation(updateCallTime);

  const content = {
    expert_id: user.id,
    user_id: problem.user_id.toString(),
    problem_id: problem.id,
    room_name: `mendy-${problem?.problem_id}`,
    expert_avatar:
      process.env.REACT_APP_BASE_URL.replace('api', 'storage/') +
      user.profile_image,
    expert_name: user.name + ' ' + user.last_name,
    user_avatar:
      process.env.REACT_APP_BASE_URL.replace('api', 'storage/') +
      problem.user.profile_image,
    user_name: problem.user.name + ' ' + problem.user.last_name,
  };

  return callConfirmed && isCalling ? (
    <CallRinging
      isShowing={callConfirmed}
      setModalOpen={setModalOpen}
      setDisableOverlay={setDisableOverlay}
      problem={problem}
    />
  ) : (
    <div className="flex flex-col gap-4 justify-center items-center p-6 w-80 relative">
      <div
        className="absolute right-5 top-2 cursor-pointer"
        onClick={() => {
          setDisableOverlay(false);
          setModalOpen(false);
        }}
      >
        x
      </div>
      <span className="font-poppins text-xl text-indigo-800 mt-2">
        {t('startVideoCall')}
      </span>
      <div className="flex flex-col gap-2 text-sm font-poppins items-center justify-center">
        <span className="text-gray-500 ">{t('willYouCall')}</span>
        <span className="text-indigo-800 font-medium">{userName}?</span>
      </div>
      <Button
        onClick={() => {
          const notificationData = {
            user_image: problem.user.profile_image,
            expert_image: user.profile_image,
            problem_id: problem.id,
            expert_id: user.id,
            user_id: problem.user.id,
            room_name: `mendy-${problem.id}`,
            expert_name: `${user.name} ${user.last_name}`,
            user_name: `${problem.user.name} ${problem.user.last_name}`,
            type:"receive_call"
          };
          updateProblem({ id: problem.id });
          sendCallNotification(problem.user_id, notificationData);
          history.push('/video-call', { callContent: notificationData });
          // setDisableOverlay(true);
          // setCallConfirmed(true);
        }}
      >{`Ring ${userName.substring(0, userName.indexOf(' '))}`}</Button>
    </div>
  );
};

export default ConfirmCall;

