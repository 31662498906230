import React from "react";

const variants = {
  inactive: "opacity-70 text-xl",
  active: "text-3xl",
};

const Heading = ({ variant = "active", children }) => {
  return (
    <h1
      className={
        " font-poppins text-fontActive text-3xl " + variant && variants[variant]
      }
    >
      {children}
    </h1>
  );
};

export default Heading;
