import { Box, LinearProgress, TableRow } from '@material-ui/core';
import { useEffect, useState } from 'react';
import CommonTable, {
  StyledTableCell,
} from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import iconDelete from '../../assets/images/tabler_trash.svg';
import iconEdit from '../../assets/images/tabler_edit.svg';
import rateImageUrl from '../../assets/images/tabler_star.svg';
import DeleteUserModal from '../Common/DeleteUserModal';
import useAuth from '../../hooks/useAuth';
import { Link, useOutletContext } from 'react-router-dom';
import Modal from '../Common/Modal';
import HandymanForm from './HandymanInfo/HandymanForm';
import useTranslate from '../../hooks/useTranslate';
import PlaceholderImage from '../../assets/placeholderImg.png';
import { useQuery } from '@tanstack/react-query';
import { fetchHandymenByCompanyId } from '../../api/Handyman/Handyman';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';

const Handymen = () => {
  const [toggleCreate, setToggleCreate, searchData, searchDataLoading] =
    useOutletContext();

  //Add when delete finished
  const [handymanDetails, setHandymanDetails] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [editForm, setEditForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { user } = useAuth();
  const t = useTranslate();
  const [value, setValue] = useState(`${t('handyman')} Info`);
  const header = [t('handyman'), t('info'), t('category'), t('rating'), ''];

  useEffect(() => {
    setPage(0);
  }, [searchData]);

  const dataPreview = (
    rowsPerPage > 0
      ? searchData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : searchData
  )?.map((row, index) => (
    <TableRow
      key={row?.id}
      onClick={() => {
        setActiveRow(index);
      }}
      row={row}
      className={`${activeRow === index && 'active-row'}`}
    >
      {/*<StyledTableCell padding="checkbox">
        <Checkbox
          color="primary"
          inputProps={{
            "aria-label": "select all companies",
          }}
        />
        </StyledTableCell>*/}
      <StyledTableCell
        component={Link}
        to={'/d/handymen/info/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex items-center">
          <img
            src={row?.profile_image ? row?.profile_image : PlaceholderImage}
            alt="Handyman"
            className="w-10 h-10 rounded-full min-w-[40px] object-cover"
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
          />
          <div className="px-4 w-72 truncate">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}
            </p>
          </div>
        </div>
      </StyledTableCell>

      <StyledTableCell
        component={Link}
        to={'/d/handymen/info/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex">
          <div>
            <p className="font-medium text-base leading-6 font-color">
              {row?.phone}
            </p>
            <span className="font-color">{row?.email}</span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell
        component={Link}
        to={'/d/handymen/info/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex gap-1 items-center">
          {row?.categories
            ?.filter((i, index) => index < 3)
            .map((category, index) => (
              <FetchCategoryIcon
                key={index}
                strokeColor={'strokeBlue'}
                name={category?.icon}
              />
            ))}
          <p className="text-indigo-800 font-medium text-base leading-6">
            {row?.categories.length > 3 ? `+${row?.categories.length - 3}` : ''}
          </p>
        </div>
      </StyledTableCell>
      <StyledTableCell
        component={Link}
        to={'/d/handymen/info/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex">
          {row?.avg_rating ? (
            <>
              <img src={rateImageUrl} alt="Rating" />
              <div className="px-4">
                <p className="name-title">{row?.avg_rating}</p>
              </div>
            </>
          ) : (
            <p className="font-color">{t('noData')}</p>
          )}
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex">
          <Link
            to={'/d/handymen/info/' + row.id}
            state={{ disabledform: true }}
          >
            <img
              onClick={() => {
                setActiveRow(index);
                setEditForm(true);
              }}
              src={iconEdit}
              className="pr-2"
            />
          </Link>
          <img
            src={iconDelete}
            onClick={(event) => {
              event.stopPropagation();
              setDeleteModal(true);
              setHandymanDetails(row);
            }}
            alt="Delete icon"
          />
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <div className={` outside-container-background my-4 px-8`}>
      <div className="table-container rounded-xl w-full">
        {searchDataLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <CommonTable
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={searchData ? searchData?.length : 0}
            header={
              handymanDetails
                ? header.filter((item, index) => index < 1)
                : header
            }
            tableData={dataPreview}
          />
        )}
      </div>
      <Modal
        isOpen={toggleCreate}
        setIsOpen={setToggleCreate}
        className="p-8 max-w-xl w-full"
      >
        <HandymanForm
          editForm={true}
          isCreate={true}
          setToggleCreate={setToggleCreate}
        />
      </Modal>
      <Modal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        className="p-4 max-w-md w-full"
      >
        <DeleteUserModal
          userDetails={handymanDetails}
          setDeleteModal={setDeleteModal}
        />
      </Modal>
    </div>
  );
};

export default Handymen;
