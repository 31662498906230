import { Box, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchCaseDetailsById } from '../../../api/Case/Case';
import useAuth from '../../../hooks/useAuth';
import DetailsComponent from './DetailsComponent';
import HomeVisitComponent from './HomeVisitComponent';

export default function DetailsTab({ userDetails, caseStatus }) {
  const { user } = useAuth();
  const { t } = useTranslation();

  const {
    data: selectedCaseDetails,
    isLoading: selectedCaseDetailsLoading,
    isError: selectedCaseDetailsError,
  } = useQuery(
    [
      'selectedCaseDetails',
      user?.role_id !== 2 ? userDetails.problem_id : userDetails.id,
    ],
    fetchCaseDetailsById,
    {}
  );

  return (
    <>
      {selectedCaseDetailsLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : selectedCaseDetails?.status_id === 1 ? (
        <div className="flex items-center w-full justify-center font-poppins font-medium mb-4">
          {t('noData')}
        </div>
      ) : (
        <Fragment>
          <DetailsComponent
            userDetails={userDetails}
            selectedCaseDetails={selectedCaseDetails}
          />
          <HomeVisitComponent
            userDetails={userDetails}
            selectedCaseDetails={selectedCaseDetails}
            caseStatus={caseStatus}
          />
        </Fragment>
      )}
    </>
  );
}
