import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  cases: [],
};

export const fetchCompletedCasesByHandyman = createAsyncThunk(
  "getCompletedCasesByHandyman/fetchCompletedCasesByHandyman",
  async (id) => {
    try {
      const response = await api_url.get(
        "problems/handyman/" + id + "/status/completed"
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getCompletedCasesByHandymanSlice = createSlice({
  name: "getCompletedCasesByHandyman",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCompletedCasesByHandyman.pending, (state, action) => {
      // Add user to the state array
      state.status = "loading";
    });
    builder.addCase(
      fetchCompletedCasesByHandyman.fulfilled,
      (state, action) => {
        // Add user to the state array
        state.status = "succeded";
        state.cases = action.payload;
      }
    );
    builder.addCase(fetchCompletedCasesByHandyman.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getCompletedCasesByHandymanSlice.reducer;
