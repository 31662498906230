import React, { useEffect, useState } from 'react';
import chevronUp from '../../../../assets/arrows/chevron-up.svg';
import chevronDown from '../../../../assets/arrows/chevron-down.svg';
import useTranslate from '../../../../hooks/useTranslate';
import { useQuery } from '@tanstack/react-query';
import useAuth from '../../../../hooks/useAuth';
import { fetchCaseOfferById } from '../../../../api/Case/Case';

export default function ({ userDetails }) {
  const [subitemActive, setSubitemActive] = useState(false);
  const t = useTranslate();
  const { user } = useAuth();

  const {
    data: selectedCaseOffer,
    isLoading: selectedCaseOfferLoading,
    isError: selectedCaseOfferError,
  } = useQuery(
    [
      'selectedCaseOffer',
      user?.role_id !== 2 ? userDetails.problem_id : userDetails.id,
    ],
    fetchCaseOfferById,
    {}
  );

  return (
    <>
      <div className="flex flex-row justify-between py-4 px-6 ">
        <span className="case-header-name font-medium text-base">
          {t('billCreated')}:
        </span>
        <span className="case-header-name font-normal text-base ">
          {selectedCaseOffer?.appointments?.length > 0
            ? selectedCaseOffer?.appointments[0]?.accepted_date
              ? selectedCaseOffer?.appointments[0]?.accepted_date
              : '-'
            : '-'}
        </span>
      </div>
      <div className="flex  justify-between  py-4 px-6 case-detail-bottom-border case-detail-top-border">
        <span className="case-header-name font-medium text-base">
          {t('price')} ({t('perHour')})
        </span>
        <span className="case-header-name font-normal text-base ">
          {selectedCaseOffer?.appointments?.length > 0
            ? selectedCaseOffer?.appointments[0]?.price_per_hour
              ? selectedCaseOffer?.appointments[0]?.price_per_hour
              : '-'
            : '-'}{' '}
          SEK
        </span>
      </div>
      <div className="flex justify-between  py-4 px-6 case-detail-bottom-border">
        <span className="case-header-name font-medium text-base">
          {t('durationOfRepair')}
        </span>
        <span className="case-header-name font-normal text-base ">
          {selectedCaseOffer?.appointments?.length > 0
            ? selectedCaseOffer?.appointments[0]?.duration
              ? selectedCaseOffer?.appointments[0]?.duration
              : '-'
            : '-'}{' '}
          min
        </span>
      </div>
      <div
        className="flex flex-col py-4 px-6 case-detail-bottom-border cursor-pointer"
        onClick={() => {
          setSubitemActive(subitemActive === false ? true : false);
        }}
      >
        <span className="flex flex-row justify-between case-header-name font-medium text-base">
          Material
          <img src={subitemActive ? chevronUp : chevronDown}></img>
        </span>
        {subitemActive === true && selectedCaseOffer?.appointments?.length > 0
          ? selectedCaseOffer?.appointments[0]?.items?.length > 0
            ? selectedCaseOffer?.appointments[0]?.items?.map((item, index) => (
                <div
                  className="  py-4 pr-6 case-detail-bottom-border"
                  key={index}
                >
                  <span className="case-header-name pt-6 font-medium text-base ">
                    {item?.name}
                  </span>
                  <span className="font-normal expert-title text-sm max-w-xs py-4">
                    {item?.description}
                  </span>
                  <span className="flex flex-row justify-between font-normal text-base case-header-name">
                    <span>
                      {' '}
                      {item?.price} {item?.currency}
                    </span>
                    <span>
                      {' '}
                      {item?.quantity} {t('pieces')}
                    </span>
                  </span>
                </div>
              ))
            : '-'
          : selectedCaseOffer?.appointments?.length > 0
          ? selectedCaseOffer?.appointments[0]?.items?.length > 0
            ? selectedCaseOffer?.appointments[0]?.items?.map((item, index) => (
                <div
                  className="  py-4 pr-6 case-detail-bottom-border"
                  key={index}
                >
                  <span className="flex flex-row justify-between font-normal text-base text-neutral-400">
                    <span>{item?.name}</span>
                    <span>
                      {' '}
                      {item?.quantity} {t('pieces')}
                    </span>
                  </span>
                </div>
              ))
            : '-'
          : '-'}
      </div>
      <div className="flex flex-col  py-4 px-6 ">
        <span className="case-header-name font-medium pb-4 text-base">
          {t('descriptionByHandyman')}
        </span>
        <span className="font-normal expert-title text-sm max-w-xs ">
          {selectedCaseOffer?.description
            ? selectedCaseOffer?.description
            : '-'}
        </span>
      </div>

      <div className="flex flex-row justify-between mt-4 px-6 rounded-b-xl billing-footer">
        <span className="text-xl py-4">{t('total')}</span>
        <span className="text-xl py-4">
          {selectedCaseOffer?.appointments?.length > 0
            ? selectedCaseOffer?.appointments[0]?.total_price
              ? selectedCaseOffer?.appointments[0]?.total_price
              : '-'
            : '-'}{' '}
          kr
        </span>
      </div>
    </>
  );
}
