import { Box, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Faq from "./Faq";
import Support from "./Support";
import Prices from "./Prices";
import useAuth from "../../hooks/useAuth";
import "./Settings.css";
import TermsConditionsTab from "./SuperAdmin/TermsConditionsTab";
import Messages from "./SuperAdmin/Messages";
import SupportAdmin from "./SuperAdmin/SupportAdmin";
import Categories from "./SuperAdmin/Categories";
import PricesTime from "./SuperAdmin/PricesTime";
import FAQ from "./SuperAdmin/FAQ";
import Heading from "../Common/Heading";
import TermsConditionsPreview from "./SuperAdmin/TermsConditions/TermsConditionsPreview";
import DropdownFAQs from "../Dropdowns/DropdownFAQs";
import useTranslate from "../../hooks/useTranslate";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="w-full"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default function Settings() {
  const { user } = useAuth();
  const [value, setValue] = useState(user.role_id === 2 ? "FAQ" : "T&C");
  const [termsPreview, setTermsPreview] = useState(false);
  const [termsValue, setTermsValue] = useState();
  const [rotValue, setRotValue] = useState("");
  const [selectedFAQ, setSelectedFAQ] = useState(5);
  const [termsPreviewValue, setTermsPreviewValue] = useState(null);
  const t = useTranslate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTermsPreview(false);
  };

  const tabsCompany = ["FAQ", t("support"), t("prices")];
  const tabsAdmin = ["T&C", t("messages"), t("categories"), t("pricesAndTime")];

  return (
    <div className="settings px-8 flex gap-6">
      <div className="w-full">
        <div className=" mb-4 w-full">
          <Heading>{t('settings')}</Heading>
        </div>
        {user.role_id === 2 ? (
          <div className="bg-white py-2 rounded-lg shadow-md">
            <Tabs
              value={value}
              onChange={handleChange}
              className="border-b border-slate-800/[.1]"
            >
              {tabsCompany.map((tab, index) => (
                <Tab value={tab} label={tab} key={index} />
              ))}
            </Tabs>

            <div>
              <TabPanel value={value} index={tabsCompany[0]}>
                <Faq />
              </TabPanel>
              <TabPanel value={value} index={tabsCompany[1]}>
                <Support />
              </TabPanel>
              <TabPanel value={value} index={tabsCompany[2]}>
                <Prices />
              </TabPanel>
            </div>
          </div>
        ) : (
          <div className="bg-white py-2 rounded-lg shadow-md ">
            <Tabs
              value={value}
              onChange={handleChange}
              className="border-b border-slate-800/[.1] overflow-visible"
            >
              {tabsAdmin.map((tab, index) => (
                <Tab value={tab} label={tab} key={index} />
              ))}
              <Tab
                value={"FAQ"}
                label={<DropdownFAQs setSelectedFAQ={setSelectedFAQ} />}
              />
            </Tabs>

            <div>
              <TabPanel
                value={value}
                index={tabsAdmin[0]}
                className="w-full p-8 text-center overflow-auto"
              >
                <TermsConditionsTab
                  setTermsPreview={setTermsPreview}
                  description={termsValue}
                  setTermsValue={setTermsValue}
                  rotValue={rotValue}
                  setRotValue={setRotValue}
                  termsValue={termsValue}
                  setTermsPreviewValue={setTermsPreviewValue}
                />
              </TabPanel>
              <TabPanel
                value={value}
                index={tabsAdmin[1]}
                className="w-full p-8 text-center"
              >
                <Messages />
              </TabPanel>
              <TabPanel
                value={value}
                index={tabsAdmin[2]}
                className="w-full text-center"
              >
                <Categories />
              </TabPanel>
              <TabPanel
                value={value}
                index={tabsAdmin[3]}
                className="w-full p-8 text-center"
              >
                <PricesTime />
              </TabPanel>
              {/* <TabPanel
                value={value}
                index={tabsAdmin[4]}
                className="w-full p-8 text-center"
              >
                <SupportAdmin />
              </TabPanel> */}
              <TabPanel
                value={value}
                index={"FAQ"}
                className="w-full p-8 text-center"
              >
                <FAQ selectedFAQ={selectedFAQ} />
              </TabPanel>
            </div>
          </div>
        )}
      </div>
      {termsPreview && (
        <TermsConditionsPreview
          termsValue={termsValue}
          RoTData={rotValue}
          termsPreviewValue={termsPreviewValue}
        />
      )}
    </div>
  );
}
