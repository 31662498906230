import { Box, LinearProgress, TableRow } from '@material-ui/core';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { fetchFinishedCasesApi } from '../../../features/cases/finishedCasesSlice';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import userImage from '../../../assets/user-image.svg';
import useAuth from '../../../hooks/useAuth';
import '../Cases.css';
import CommonDetailView from '../../../containers/MainContentContainer/CommonDetailViewComponent/CommonDetailView';
import categoryIcon from '../../../assets/category-icon.svg';
import { fetchCompanyCasesApi } from '../../../features/cases/companyCases/companyCasesSlice';
import useTranslate from '../../../hooks/useTranslate';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import { FetchCategoryIcon } from '../../../helpers/categoryIcons';
import { DateTime } from 'luxon';

const FinishedCases = () => {
  const { user } = useAuth();
  const role = user?.role_id;
  const caseStatus = 'completed';

  const companyId = user.company_id;
  const finishedCases = useSelector(
    (state) => state.finishedCases.finishedCases
  );
  const companyFinishedCases = useSelector(
    (state) => state.getCompanyCases.companyCases
  );
  const status = useSelector((state) => state.getCompanyCases.status);
  const t = useTranslate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [value, setValue] = useState(t('case'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const header = [
    t('user'),
    `Expert/${t('handyman')}`,
    t('category'),
    t('status'),
  ];
  const tabs = [t('case'), t('details'), t('chat'), t('ratings')];

  useEffect(() => {
    if (user.role_id === 1) {
      dispatch(fetchFinishedCasesApi());
    } else {
      dispatch(
        fetchCompanyCasesApi({
          id: user.company_id,
          status: caseStatus,
        })
      );
    }
  }, []);

  header[1] = role === 2 ? t('handyman') : `Expert/${t('handyman')}`;
  header[3] = role === 2 ? t('caseDuration') : t('status');

  const tableViewData =
    role === 1 ? finishedCases : role === 2 ? companyFinishedCases : null;

  const dataPreview = (
    rowsPerPage > 0
      ? tableViewData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableViewData
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow == index && 'active-row'}`}
      onClick={() => {
        setUserDetails(row);
        setActiveRow(index);
      }}
    >
      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-72 gap-3">
            <img
              src={
                row?.users_profile_image === null
                  ? PlaceholderImage
                  : row?.users_profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="user"
            />
            <div className="w-72 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.user_name} {row?.user_last_name}
              </p>
              <span className="font-color truncate">
                {row?.user_post_code} {row?.user_street}
              </span>
            </div>
          </div>
        ) : role === 2 ? (
          <div className="flex w-72 gap-3">
            <img
              src={
                row?.user?.profile_image === null
                  ? PlaceholderImage
                  : row?.user?.profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="user"
            />
            <div className="w-72 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.user?.name} {row?.user?.last_name}
              </p>
              <span className="font-color truncate">
                {row?.user?.post_code} {row?.user?.street}
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>
      {activeRow === null && (
        <StyledTableCell>
          {role === 1 ? (
            <div className="flex gap-3">
              <img
                src={
                  row?.handyman_profile_image
                    ? row?.handyman_profile_image
                    : row?.expert_profile_image
                    ? row?.expert_profile_image
                    : PlaceholderImage
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = PlaceholderImage;
                }}
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="handyman"
              />
              <div className="w-32 truncate">
                <p className="font-medium text-base leading-6 font-color truncate">
                  {row?.handyman_name && row?.handyman_last_name
                    ? row?.handyman_name + ' ' + row?.handyman_last_name
                    : row?.expert_name && row?.expert_last_name
                    ? row?.expert_name + ' ' + row?.expert_last_name
                    : '-'}
                </p>
                <span className="font-color truncate">
                  {row?.handyman_role
                    ? row?.handyman_role
                    : row?.expert_role
                    ? row?.expert_role
                    : '-'}
                </span>
              </div>
            </div>
          ) : role === 2 ? (
            <div className="flex items-center gap-3">
              <img
                src={
                  row?.appointments?.length > 0
                    ? row?.appointments[0]?.handyman?.profile_image
                      ? row?.appointments[0]?.handyman?.profile_image
                      : PlaceholderImage
                    : row?.expert
                    ? row?.expert.profile_image
                      ? row?.expert?.profile_image
                      : PlaceholderImage
                    : PlaceholderImage
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = PlaceholderImage;
                }}
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="handyman"
              />
              <div className="w-32 truncate">
                <p className="font-medium text-base leading-6 font-color truncate">
                  {row?.appointments?.length > 0
                    ? row?.appointments[0]?.handyman?.name &&
                      row?.appointments[0]?.handyman?.last_name
                      ? row?.appointments[0]?.handyman?.name +
                        ' ' +
                        row?.appointments[0]?.handyman?.last_name
                      : '-'
                    : row?.expert
                    ? row?.expert?.name && row?.expert?.last_name
                      ? row?.expert?.name + ' ' + row?.expert?.last_name
                      : '-'
                    : '-'}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
        </StyledTableCell>
      )}

      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-44 gap-3 items-center">
            <FetchCategoryIcon
              strokeColor={'strokeBlue'}
              name={row?.problem_category_icon}
            />
            <div className="">
              <p className="font-medium text-base leading-6 font-color">
                {row?.problem_parent_category_name}
              </p>
              <span className="font-color">{row?.problem_category_name}</span>
            </div>
          </div>
        ) : role === 2 ? (
          <div className="flex w-44 gap-3 items-center">
            <FetchCategoryIcon
              strokeColor={'strokeBlue'}
              name={row?.category?.icon}
            />
            <div className="px-4">
              <p className="font-medium text-base leading-6 font-color">
                {row?.category?.parent?.name}
              </p>
              <span className="font-color">{row?.category?.name}</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>
      {role === 1 ? (
        <StyledTableCell>
          <div className="flex justify-center">
            <span
              className={
                row.problem_status_id === 7
                  ? 'closed'
                  : row?.problem_status_id === 6
                  ? 'status-non-urgent'
                  : row?.problem_status_id === 5
                  ? 'status-urgent'
                  : ''
              }
            >
              {row?.problem_status_id === 7
                ? t('closed')
                : row?.problem_status_id === 6
                ? t('completedByHandyman')
                : row?.problem_status_id === 5
                ? t('completedByExpert')
                : ''}
            </span>
          </div>
        </StyledTableCell>
      ) : role === 2 ? (
        <StyledTableCell>
          {row?.appointments[0] ? (
            <p className="font-medium text-base leading-6 font-color">
              {row?.appointments[0].duration} min
            </p>
          ) : (
            '-'
          )}
        </StyledTableCell>
      ) : (
        ''
      )}
    </TableRow>
  ));

  return (
    <div className={`px-8 my-4 ${userDetails && 'flex'}`}>
      <div className={`rounded-xl ${userDetails && 'table-width-onclick'}`}>
        <Fragment>
          {status === 'loading' ? (
            <Box sx={{ width: '100%', padding: '20px 0' }}>
              <LinearProgress />
            </Box>
          ) : (
            <CommonTable
              header={
                userDetails ? header.filter((i) => i !== header[1]) : header
              }
              tableData={dataPreview}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={tableViewData ? tableViewData.length : 0}
              page={page}
            />
          )}
        </Fragment>
      </div>
      {userDetails && (
        <CommonDetailView
          caseStatus={caseStatus}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          setActiveRow={setActiveRow}
          tabs={tabs}
          setValue={setValue}
          role={role}
        />
      )}
    </div>
  );
};

export default FinishedCases;
