import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {};

export const updateUserByIdApi = createAsyncThunk(
  "putUserById/updateUserByIdApi",
  async ({ id, data }) => {
    try {
      const response = await api_url.put("user/update/" + id, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const putUserByIdSlice = createSlice({
  name: "putUserByIdSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(updateUserByIdApi.pending, (state, action) => {
      state.status = "loading";
      // Add user to the state array
    });
    builder.addCase(updateUserByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeeded";
    });
    builder.addCase(updateUserByIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default putUserByIdSlice.reducer;
