import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../Admin.css";
import { CircularProgress, FormControl } from "@mui/material";
import { useForm } from "react-hook-form";
import ShowHidePassword from "./ShowHidePassword";
import { useState } from "react";
import { changeAdminPassword } from "../../../api/Admin/Admin";
import { useMutation } from "@tanstack/react-query";
import Button from "../../Common/Button";
import useTranslate from "../../../hooks/useTranslate";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 560,
  bgcolor: "#F9F9F9",
  border: "none",
  borderRadius: "10px",
  p: 4,
  fontFamily: "Poppins",
};

export default function ChangePasswordModal({
  changePasswordModal,
  setChangePasswordModal,
}) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm();
  const [errorMsg, setErrorMsg] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(false);
  const t = useTranslate();

  const {
    mutateAsync: changeAdminPasswordMutation,
    isLoading: changePasswordLoading,
  } = useMutation(changeAdminPassword, {
    onSuccess: () => {
      setChangePasswordModal(false);
      setConfirmPassword(false);
      setErrorMsg("");
      reset();
    },
    onError: (error) => {
      if (
        error?.response?.data ===
        "Password failed validation. Error: Your old password is not correct"
      ) {
        setErrorMsg(t('oldPasswordWrong'));
      } else if (
        error?.response?.data ===
        "Password failed validation. Error: You cannot use the old password."
      ) {
        setErrorMsg(t('cannotUseOldPassword'));
      } else {
        setErrorMsg(t('passwordChangeFailed'));
      }
    },
  });

  const onSubmit = (data) => {
    if (getValues("new_password") !== getValues("new_confirm_password")) {
      setErrorMsg(t('passwordMustMatch'));
      setConfirmPassword(true);
      return;
    }
    changeAdminPasswordMutation({
      current_password: getValues("current_password"),
      new_password: getValues("new_password"),
      new_confirm_password: getValues("new_confirm_password"),
    });
  };

  const handleClose = () => {
    setChangePasswordModal(false);
  };

  return (
    <div>
      <Modal keepMounted open={changePasswordModal} onClose={handleClose}>
        <Box sx={style} className="change-password flex flex-col">
          <div className="max-w-sm w-full text-center py-10 px-6 flex flex-col items-center">
            <h2 className="change-password-title">{t('changePassword')}</h2>
            <div className="flex items-center mt-8 w-full">
              <form
                id="change-password"
                className="m-auto w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl className="pb-6 w-full">
                  <div className="flex flex-col py-2">
                    <span className="text-base text-sm text-left">
                      {t('yourPassword')}
                    </span>
                    <ShowHidePassword
                      name="current_password"
                      register={register}
                    />
                    {errors.current_password && (
                      <span className="text-red-600 text-sm text-left">
                        {errors.current_password?.message}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col py-2">
                    <span className="text-base text-sm text-left">
                      {t('newPassword')}
                    </span>
                    <ShowHidePassword name="new_password" register={register} />
                    {errors.new_password && (
                      <span className="text-red-600 text-sm text-left">
                        {errors.new_password?.message}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col py-2">
                    <span className="text-base text-sm text-left">
                      {t('confirmPassword')}
                    </span>
                    <ShowHidePassword
                      name="new_confirm_password"
                      register={register}
                    />
                    {errors.new_confirm_password && (
                      <span className="text-red-600 text-sm text-left">
                        {errors.new_confirm_password?.message}
                      </span>
                    )}
                    {errorMsg !== "" && (
                      <span className="text-red-600 text-sm text-left">
                        {errorMsg}
                      </span>
                    )}
                  </div>
                </FormControl>
              </form>
            </div>
            <div className="flex justify-evenly mt-8">
              {changePasswordLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Button
                    variant="transparent"
                    className="mr-6"
                    onClick={() => {
                      reset();
                      setChangePasswordModal(false);
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button type="submit" form="change-password">
                  {t('confirm')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
