import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import  api_url  from "../../libary/axios-home";


const filter_list = ["Municipality", "Category", "Subcategory"]


export const initialState = {
    caseById: []
};

export const fetchCaseByIdApi = createAsyncThunk(
  'case/fetchCaseByIdApi',
  async ({id}) => {
    try {
      const response = await api_url.get('problems/' + id );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)


const getCaseByIdSlice = createSlice({
  name: "getCaseByIdSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCaseByIdApi.pending, (state, action) => {
      // Add user to the state array
      state.status = "loading";
    })
    builder.addCase(fetchCaseByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeded";
      state.caseById = action.payload;
    })
    builder.addCase(fetchCaseByIdApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default getCaseByIdSlice.reducer;