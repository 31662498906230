import api_url from "../../../libary/axios-home";

export const getAllPrices = async () => {
  const { data } = await api_url.get("/settings/prices-and-time");
  return data;
};

export const updatePrices = async ({ price1, price2, id1, id2 }) => {
  await api_url.put(`/settings/update/${id1}`, {
    item_key: "expert_video_call_1",
    item_value: price1,
  });
  const { data } = await api_url.put(`/settings/update/${id2}`, {
    item_key: "expert_video_call_2",
    item_value: price2,
  });

  return data;
};
