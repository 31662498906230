import React from 'react';
import { deleteDocumentById } from '../../api/Document/Document';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Modal from './Modal';
import Button from './Button';
import useTranslate from '../../hooks/useTranslate';

export default function DocumentDeleteModal({
  deleteDocumentModal,
  setDeleteDocumentModal,
  deleteDocument,
  setDeleteDocument,
  setDocumentsList,
  setUploadDocuments,
}) {
  const queryClient = useQueryClient();
  const t = useTranslate();
  //Mutation for deleting document
  const {
    mutateAsync: deleteDocumentMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useMutation(deleteDocumentById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents']);
    },
  });

  const handleRemoveDocument = (document) => {
    if (document.id) {
      deleteDocumentMutation(deleteDocument?.id);
    } else {
      setUploadDocuments((current) =>
        current.filter((item) => item.title !== document.title)
      );
    }

    setDocumentsList((current) =>
      current.filter((item) => item.title !== document.title)
    );
    setDeleteDocumentModal(false);
    setDeleteDocument(null);
  };

  return (
    <Modal
      isOpen={deleteDocumentModal}
      setIsOpen={setDeleteDocumentModal}
      className="py-8"
    >
      <div className="text-center font-poppins w-7/12 mx-auto">
        <h3 style={{ color: '#FE0000', fontSize: '22px' }}>
          {t('delete')} {t('document')}
        </h3>
        <p style={{ color: '#909090' }} className="text-base">
          {t('confirmDeleteDocument')} {deleteDocument?.title}{' '}
          {t('document')}?
        </p>
      </div>
      <div className="flex justify-center mt-6">
        <Button
          className="mx-4"
          onClick={() => setDeleteDocumentModal(false)}
        >
          {t('cancel')}
        </Button>
        <Button
          className="mx-4"
          onClick={() => handleRemoveDocument(deleteDocument)}
        >
          {t('delete')}
        </Button>
      </div>
    </Modal>
  );
}
