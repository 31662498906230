import React, { useState, useCallback } from 'react';
import { CircularProgress, TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../../assets/placeholderImg.png';
import Button from '../../../Common/Button';
import useTranslate from '../../../../hooks/useTranslate';
import { useMutation } from '@tanstack/react-query';
import { updateProblem } from '../../../../api/Experts/Experts';
import useAuth from '../../../../hooks/useAuth';
import toast from 'react-hot-toast';
import ImageViewer from 'react-simple-image-viewer';
import { FetchCategoryIcon } from '../../../../helpers/categoryIcons';
import { DateTime } from 'luxon';
import Modal from '../../../Common/Modal';
import AcceptCaseModal from './AcceptCaseModal';
import { Tooltip } from '@mui/material';

const ActiveCompaniesTable = ({
  row,
  index,
  activeRow,
  setActiveRow,
  setCaseDetails,
  refetchProblems,
  setIsModalOpen,
}) => {
  const t = useTranslate();
  const { user } = useAuth();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);

  const images = [...row?.images];
  const filteredImages = images?.map((image) => image.path);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  // var image = document.querySelector('img');
  //   var isLoaded = image.complete && image.naturalHeight !== 0;
  //   if (!isLoaded) {
  //       image.src = PlaceholderImage;
  //   }

  const { mutateAsync: approveCase, isLoading: approveLoading } = useMutation(
    updateProblem,
    {
      onSuccess: () => {
        refetchProblems();
        toast.success(t('accepted'), { duration: 5000 });
      },
    }
  );

  const handleApprove = () => {
    approveCase({
      problem_id: row?.id,
      body: { status_id: 2, expert_id: user.id },
    });
  };
  return (
    <>
      <TableRow
        row={row}
        key={row?.id}
        onClick={() => {
          setCaseDetails(row);
          setActiveRow(index);
        }}
      >
        <StyledTableCell>
          <div className="flex w-56 gap-3">
            <div className="min-photo rounded">
              <img
                src={
                  row?.user?.profile_image === null
                    ? PlaceholderImage
                    : row?.user?.profile_image
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = PlaceholderImage;
                }}
                alt="user"
                className="w-full h-full rounded-full object-cover"
              />
            </div>
            <div className="truncate w-56">
              <p className="font-semibold text-base leading-6 font-color ">
                {row?.user?.name} {row?.user?.last_name}
              </p>
              <span className="font-color">
                {row?.user?.post_code} {row?.user?.street}
              </span>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <div className="flex w-36 gap-3 items-center">
            <FetchCategoryIcon
              name={row?.category?.icon}
              strokeColor="strokePurple"
            />
            <div className="">
              <p className="font-semibold text-base leading-6 font-color">
                {row?.category?.parent?.name}
              </p>
              <span className="font-color">{row?.category?.name}</span>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <Tooltip title={<p className="text-base">{row?.description}</p>}>
            <div className="flex flex-wrap w-40">
              <span className="line-clamp-3">{row?.description}</span>
            </div>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell>
          <div className="flex items-center">
            {filteredImages.slice(0, 2).map((src, index) => (
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                className="max-w-70 h-14"
                key={index}
                style={{ margin: '2px' }}
                alt=""
              />
            ))}

            {isViewerOpen && (
              <ImageViewer
                backgroundStyle={{
                  backgroundColor: '#7209b760',
                  zIndex: '99',
                  transition: '0.3s ease-out',
                }}
                src={filteredImages}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}
            <p className="text-indigo-800 font-medium text-base leading-6">
              {filteredImages?.length > 2
                ? `+${filteredImages?.length - 2}`
                : ''}
            </p>
          </div>
        </StyledTableCell>
        <StyledTableCell>
          {row?.time_slots ? (
            <div className="flex flex-col">
              {!row?.is_urgent ? (
                <span className="font-medium text-base">
                  {DateTime.fromISO(row?.time_slots?.time_from).toFormat(
                    'HH:mm'
                  )}
                  -
                  {DateTime.fromISO(row?.time_slots?.time_to).toFormat('HH:mm')}
                </span>
              ) : (
                <span className="font-medium text-base">
                  {DateTime.fromISO(row?.time_slots?.created_at, {
                    zone: 'Europe/Stockholm',
                  }).toFormat('HH:mm')}
                </span>
              )}
              <span>{row?.time_slots?.date_from}</span>
            </div>
          ) : (
            '-'
          )}
        </StyledTableCell>
        <StyledTableCell className="w-56">
          <div className="w-full pr-4">
            {row?.status_id === 1 ? (
              <Button
                isLoading={approveLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  row?.is_urgent ? handleApprove() : setAcceptModal(true);
                }}
              >
                {row?.is_urgent ? t('accept') : t('nonUrgent')}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                {t('ring')}
              </Button>
            )}
          </div>
        </StyledTableCell>
      </TableRow>
      <Modal
        isOpen={acceptModal}
        setIsOpen={setAcceptModal}
        className="max-w-lg"
      >
        <AcceptCaseModal
          selectedCaseDetails={row}
          refetchProblems={refetchProblems}
          setAcceptModal={setAcceptModal}
        />
      </Modal>
    </>
  );
};

export default ActiveCompaniesTable;
