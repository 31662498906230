import api_url from "../../libary/axios-home";

export const getAllCategories = async () => {
  const { data } = await api_url.get("categories");
  return data;
};

export const getAllCategoriesWithSubcategories = async () => {
  const { data } = await api_url.get("categories/subcategories");
  return data;
};

export const updateCategoryById = async ({ id, name, icon }) => {
  const { data } = await api_url.put(`categories/update/${id}`, {
    id,
    name,
    icon
  });
  return data;
};

export const deleteCategoryById = async (id) => {
  const { data } = await api_url.delete(`categories/delete/${id}`);
  return data;
};

export const createCategory = async ({
  name,
  parent_id,
  icon,
  description,
}) => {
  const { data } = await api_url.post(`categories/create`, {
    name,
    icon,
    parent_id: parent_id ? parent_id : null,
    description,
  });
  return data;
};
