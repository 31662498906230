import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ReactComponent as ChevronDown } from "../../assets/arrows/chevron-down.svg";
import useTranslate from "../../hooks/useTranslate";

const DropdownFAQs = ({ setSelectedFAQ }) => {
  const t = useTranslate();
  const dropdownList = [
    { label: t("experts"), value: 3 },
    { label: t("companies"), value: 2 },
    { label: t("handymen"), value: 4 },
    { label: t("users"), value: 5 },
  ];
  return (
    <Menu as="div" className="relative">
      <div>
        <Menu.Button as="span" className="flex gap-3 items-center">
          FAQs <ChevronDown />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-50 right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            {dropdownList?.map((item, index) => {
              return (
                <Menu.Item as="div" key={index}>
                  {({ active }) => (
                    <span
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm font-normal`}
                      onClick={() => {
                        setSelectedFAQ(item.value);
                      }}
                    >
                      {item.label}
                    </span>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownFAQs;
