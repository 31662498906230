import api_url from '../../libary/axios-home';

export const postReview = async (formData) => {
  const { data } = await api_url.post('reviews/create', {
    rating: formData.rating,
    review: formData.review,
    problem_id: formData.problem_id,
    giver_id: formData.giver_id,
    receiver_id: formData.receiver_id,
  });
  return data;
};

export const postWorkDescription = async (formData) => {
  const { data } = await api_url.put(
    `problems/update/${formData.id}`,
    {
      expert_opinion: formData.expert_opinion,
    }
  );
  return data;
};

export const updateCallTime = async (formData) => {
  var x = new Date().getTimezoneOffset() * 60000;
  var localISOTime = new Date(Date.now() - x)
    .toISOString()
    .slice(0, -1);
  const { data } = await api_url.put(
    `problems/update/${formData.id}`,
    {
      call_time: localISOTime,
    }
  );
  return data;
};
