import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PageHeading from '../../components/Organisms/PageHeading';
import useTranslate from '../../hooks/useTranslate';

const CompaniesPage = () => {
  const [createToggle, setCreateToggle] = useState(false);
  const t = useTranslate();

  return (
    <div className="w-full">
      <PageHeading title={t('companies')} noButton />
      <Outlet />
    </div>
  );
};

export default CompaniesPage;
