import React, { useEffect, useState } from 'react';
import chevronUp from '../../../../assets/arrows/chevron-up.svg';
import chevronDown from '../../../../assets/arrows/chevron-down.svg';
import useTranslate from '../../../../hooks/useTranslate';
import useAuth from '../../../../hooks/useAuth';
import { fetchCaseOfferById } from '../../../../api/Case/Case';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../Common/Button';
import Modal from '../../../Common/Modal';
import CreateOfferModal from './CaseOffer/CreateOfferModal';
import { Box, LinearProgress } from '@mui/material';

export default function OfferComponent({ caseStatus, selectedCaseDetails }) {
  const [subitemActive, setSubitemActive] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const t = useTranslate();
  const { user } = useAuth();

  const {
    data: selectedCaseOffer,
    isLoading: selectedCaseOfferLoading,
    isError: selectedCaseOfferError,
  } = useQuery(
    ['selectedCaseOffer', selectedCaseDetails?.id],
    fetchCaseOfferById,
    {}
  );

  return (
    <>
      {selectedCaseOfferLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          {user?.role_id === 2 &&
          caseStatus === 'new' &&
          selectedCaseDetails?.appointments.length <= 0 ? (
            <>
              <Button
                className="ml-auto mt-2"
                onClick={() => setOfferModal(true)}
              >
                {t('sendOffer')}
              </Button>
              <Modal
                isOpen={offerModal}
                setIsOpen={setOfferModal}
                className="w-[32rem]"
              >
                <CreateOfferModal
                  setOfferModal={setOfferModal}
                  selectedCaseDetails={selectedCaseDetails}
                />
              </Modal>
            </>
          ) : selectedCaseDetails?.appointments?.length > 0 ? (
            <>
              {caseStatus !== 'new' && (
                <div className="flex flex-row justify-between py-4 px-6 ">
                  <span className="case-header-name font-medium text-base">
                    {t('offerAccepted')}:
                  </span>
                  <span className="case-header-name font-normal text-base ">
                    {selectedCaseOffer?.appointments?.length > 0
                      ? selectedCaseOffer?.appointments[0]?.accepted_date
                        ? selectedCaseOffer?.appointments[0]?.accepted_date
                        : '-'
                      : '-'}
                  </span>
                </div>
              )}
              <div className="left-border pl-8 ml-6 ">
                <div className="flex  justify-between py-4 pr-6 case-detail-bottom-border">
                  <span className="case-header-name font-medium text-base">
                    {t('price')} ({t('perHour')})
                  </span>
                  <span className="case-header-name font-normal text-base ">
                    {selectedCaseOffer?.appointments?.length > 0
                      ? selectedCaseOffer?.appointments[0]?.price_per_hour
                        ? selectedCaseOffer?.appointments[0]?.price_per_hour
                        : '-'
                      : '-'}{' '}
                    SEK
                  </span>
                </div>
                <div className="flex justify-between py-4 pr-6 case-detail-bottom-border">
                  <span className="case-header-name font-medium text-base">
                    {caseStatus === 'ongoing'
                      ? t('ongoingDurationOfRepair')
                      : caseStatus == 'new'
                      ? t('newDurationOfRepair')
                      : t('durationOfRepair')}
                  </span>
                  <span className="case-header-name font-normal text-base ">
                    {selectedCaseOffer?.appointments?.length > 0
                      ? selectedCaseOffer?.appointments[0]?.duration
                        ? selectedCaseOffer?.appointments[0]?.duration
                        : '-'
                      : '-'}{' '}
                    min
                  </span>
                </div>
                <div
                  className="flex flex-col  py-4 pr-6 mb-4 cursor-pointer"
                  onClick={() => {
                    setSubitemActive(subitemActive === false ? true : false);
                  }}
                >
                  <span className="flex flex-row justify-between case-header-name font-medium text-base">
                    Material
                    <img src={subitemActive ? chevronUp : chevronDown}></img>
                  </span>
                  {subitemActive === true &&
                  selectedCaseOffer?.appointments?.length > 0
                    ? selectedCaseOffer?.appointments[0]?.items?.length > 0
                      ? selectedCaseOffer?.appointments[0]?.items?.map(
                          (item, index) => (
                            <div
                              className="  py-4 pr-6 case-detail-bottom-border"
                              key={index}
                            >
                              <span className="case-header-name pt-6 font-medium text-base ">
                                {item?.name}
                              </span>
                              <span className="font-normal expert-title text-sm max-w-xs py-4">
                                {item?.description}
                              </span>
                              <span className="flex flex-row justify-between font-normal text-base case-header-name">
                                <span>
                                  {' '}
                                  {item?.price} {item?.currency}
                                </span>
                                <span>
                                  {' '}
                                  {item?.quantity} {t('pieces')}
                                </span>
                              </span>
                            </div>
                          )
                        )
                      : '-'
                    : selectedCaseOffer?.appointments?.length > 0
                    ? selectedCaseOffer?.appointments[0]?.items?.length > 0
                      ? selectedCaseOffer?.appointments[0]?.items?.map(
                          (item, index) => (
                            <div
                              className="  py-4 pr-6 case-detail-bottom-border"
                              key={index}
                            >
                              <span className="flex flex-row justify-between font-normal text-base text-neutral-400">
                                <span>{item?.name}</span>
                                <span>
                                  {' '}
                                  {item?.quantity} {t('pieces')}
                                </span>
                              </span>
                            </div>
                          )
                        )
                      : '-'
                    : '-'}
                </div>

                {caseStatus === 'ongoing' && (
                  <>
                    <Button
                      className="ml-auto mt-2"
                      onClick={() => setOfferModal(true)}
                    >
                      {t('edit')}
                    </Button>
                    <Modal
                      isOpen={offerModal}
                      setIsOpen={setOfferModal}
                      className="w-[32rem]"
                    >
                      <CreateOfferModal
                        setOfferModal={setOfferModal}
                        selectedCaseDetails={selectedCaseDetails}
                        caseStatus={caseStatus}
                        editOffer={selectedCaseOffer}
                      />
                    </Modal>
                  </>
                )}
              </div>
            </>
          ) : (
            <div className="w-full text-center pt-6">{t('noData')}</div>
          )}
        </>
      )}
    </>
  );
}
