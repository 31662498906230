import { useForm } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Common/Button';

const RegisterComponentPart1 = ({ formData, setFormData, setValue }) => {
  const {
    register,
    getFieldState,
    trigger,
    formState: { errors },
  } = useForm();
  const t = useTranslate();

  const goToNext = async () => {
    await trigger(['companyPhone', 'companyEmail', 'companyAddress']);
    if (
      !getFieldState('companyPhone').invalid &&
      !getFieldState('companyEmail').invalid &&
      !getFieldState('companyAddress').invalid
    ) {
      setValue(2);
    }
  };
  const goToPrevious = () => {
    setValue(0);
  };
  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('regCompanyInfo')}
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('moreInfoCompany')}
          </p>
          <div>
            <label className="text-sm" htmlFor="companyPhone">
              {t('phoneNumber')}
            </label>
            <input
              type="tel"
              name="companyPhone"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="companyPhone"
              placeholder={`${t('phoneNumber')}...`}
              style={{ color: '#13044F' }}
              maxLength={12}
              {...register('companyPhone', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                pattern: {
                  value: /[+]?[0-9]{9}$/,
                  message: t('invalidPhoneNumber'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyPhone: e.target.value,
                });
              }}
              value={formData.companyPhone}
            />
            {errors.companyPhone && (
              <span className="text-red-600 text-sm">
                {errors.companyPhone?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="companyEmail" className="text-sm">
              {t('email')}
            </label>
            <input
              type="email"
              name="companyEmail"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="companyEmail"
              placeholder={`${t('email')}...`}
              {...register('companyEmail', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: t('invalidEmail'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyEmail: e.target.value,
                });
              }}
              value={formData.companyEmail}
            />
            {errors.companyEmail && (
              <span className="text-red-600 text-sm">
                {errors.companyEmail?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="companyAddress" className="text-sm">
              {t('address')}
            </label>
            <input
              type="text"
              name="companyAddress"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="companyAddress"
              placeholder={`${t('address')}...`}
              style={{ color: '#13044F' }}
              {...register('companyAddress', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 5,
                  message: t('enterValidAdress'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyAddress: e.target.value,
                });
              }}
              value={formData.companyAddress}
            />
            {errors.companyAddress && (
              <span className="text-red-600 text-sm">
                {errors.companyAddress?.message}
              </span>
            )}
          </div>

          <div className="flex justify-end items-center mt-6 gap-4">
            <Button onClick={goToPrevious} variant="transparent">
              {t('previous')}
            </Button>
            <Button onClick={goToNext} type="submit">
              {t('next')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComponentPart1;
