import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  deleteCategoryById,
  updateCategoryById,
} from '../../../../api/Categories/Categories';
import { ReactComponent as EditIcon } from '../../../../assets/images/tabler_edit.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/images/tabler_trash.svg';
import { FetchCategoryIcon } from '../../../../helpers/categoryIcons';
import useTranslate from '../../../../hooks/useTranslate';

import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import IconUpload from '../../../Common/IconUpload';
import Input from '../../../Common/Input';

const SelectedCategory = ({
  selectedCategory,
  toggleCreating,
  setIsOpen,
  selectedIcon,
  setSelectedIcon,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({ mode: 'all' });
  const t = useTranslate();

  useEffect(() => {
    clearErrors();
  }, [selectedIcon]);

  const {
    mutateAsync: updateCategory,
    isLoading,
    isError,
  } = useMutation(updateCategoryById, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['settingsCategories']);
      setIsEditing(false);
    },
  });

  const {
    mutateAsync: deleteCategoryMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useMutation(deleteCategoryById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['settingsCategories']);
    },
  });

  const submitHandler = (data) => {
    if (selectedIcon === '') {
      setError('name', {
        type: 'custom',
        message: t('anIconIsRequired'),
      });
      return;
    } else {
      updateCategory({
        id: selectedCategory.id,
        name: data.name,
        icon: selectedIcon,
      });
      setSelectedIcon('');
    }
  };

  const deleteHandler = () => {
    deleteCategoryMutation(selectedCategory.id);
  };

  return (
    <>
      <div className="border-b border-slate-200 flex items-center justify-between py-4 pl-4 gap-3 pr-5">
        {isEditing ? (
          <form className="flex flex-col flex-1">
            <div className="flex flex-col w-1/2">
              <div className="flex gap-4 w-full items-center">
                <IconUpload
                  setModalOpen={setIsOpen}
                  selectedIcon={selectedIcon}
                />
                <Input
                  register={register}
                  placeholder={`${t('categoryName')}...`}
                  type="Text"
                  name="name"
                  required={t('thisFieldIsRequired')}
                  isError={errors?.name?.message}
                  noLabel
                />
              </div>
              <ErrorMessage>{errors?.name?.message}</ErrorMessage>
            </div>
            <div className="flex gap-3 ml-auto">
              <Button
                onClick={() => {
                  setIsEditing(false);
                  toggleCreating(false);
                  setSelectedIcon('');
                }}
              >
                {t('cancel')}
              </Button>
              <Button onClick={handleSubmit(submitHandler)}>{t('save')}</Button>
            </div>
          </form>
        ) : (
          <>
            <div className="flex gap-3">
              <FetchCategoryIcon
                name={selectedCategory.icon}
                strokeColor={'strokeBlue'}
              />
              <span className="font-medium font-poppins text-violetPrimary">
                {selectedCategory.name}
              </span>
              <EditIcon
                className="cursor-pointer"
                onClick={() => {
                  setIsEditing(true);
                  reset(selectedCategory);
                }}
              />
              <TrashIcon
                className="inline-block mr-5 cursor-pointer"
                onClick={deleteHandler}
              />
            </div>
            <Button
              onClick={() => {
                toggleCreating(true);
              }}
            >
              {`+ ${t('addASubcategory')}`}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default SelectedCategory;
