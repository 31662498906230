import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


export const initialState = {
  categoriesList: []
};


export const fetchCategoriesApi = createAsyncThunk(
  'getCategories/fetchCategoriesApi',
  async () => {
    try {
      const response = await api_url.get("categories");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)

const getCategoriesSlice = createSlice({
  name: "getCategoriesSlice ",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCategoriesApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchCategoriesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.categoriesList = action.payload;
    })
    builder.addCase(fetchCategoriesApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default getCategoriesSlice.reducer;