import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


const filter_list = ["Municipality", "Category", "Subcategory"]


export const initialState = {
  newCases: []
};

export const fetchNewCasesApi = createAsyncThunk(
  'newCases/fetchNewCasesApi',
  async () => {
    try {
      const response = await api_url.get('problems/status/new' );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)


const newCasesSlice = createSlice({
  name: "newCasesSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchNewCasesApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchNewCasesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.newCases = action.payload;
    })
    builder.addCase(fetchNewCasesApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default newCasesSlice.reducer;