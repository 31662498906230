import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteFaq, updateFaq } from '../../../../api/Settings/FAQ/FAQ';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import useTranslate from '../../../../hooks/useTranslate';

const SingleFAQ = ({ item }) => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const t = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      question: item.question,
      answer: item.answer,
    },
  });

  const { mutateAsync: editFAQMutation } = useMutation(updateFaq, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Faqs']);
      setIsEditing(false);
    },
  });

  const { mutateAsync: deleteFAQMutation } = useMutation(deleteFaq, {
    onSuccess: () => {
      queryClient.invalidateQueries(['Faqs']);
      setIsEditing(false);
    },
  });

  const submitHandler = (data) => {
    editFAQMutation({ ...data, id: item.id });
  };

  const handleDelete = () => {
    deleteFAQMutation(item.id);
  };

  return (
    <div
      key={item.id}
      className="border-b border-slate-200 py-4 flex flex-col gap-2 "
    >
      <form>
        <div
          className={isEditing ? 'cursor-pointer' : 'group cursor-pointer'}
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <input
            className={
              (errors?.question?.message
                ? 'ring-red-500 border-red-500'
                : 'focus:border-indigo-500 hover:border-indigo-500 border-transparent') +
              ` border resize-none  h-fit group-hover:border-indigo-500  outline-none focus:outline-none
               transition-all cursor-pointer w-full rounded-lg  p-3 text-sm text-left font-poppins text-fontActive font-medium mb-1`
            }
            type="text"
            name="question"
            readOnly={!isEditing}
            aria-invalid={true}
            placeholder={`${t('question')}...`}
            {...register('question', {
              required: t('thisFieldIsRequired'),
            })}
          />
          <ErrorMessage>{errors?.question?.message}</ErrorMessage>
          <textarea
            className={
              (errors?.answer?.message
                ? 'ring-red-500 border-red-500 '
                : 'focus:border-indigo-500 hover:border-indigo-500 border-transparent') +
              ` border resize-none group group-hover:border-indigo-500  outline-none focus:outline-none
               transition-all cursor-pointer w-full   rounded-lg p-3 text-sm text-left font-poppins text-gray-400 mb-1 mt-2`
            }
            type="text"
            name="answer"
            readOnly={!isEditing}
            {...register('answer', {
              required: t('thisFieldIsRequired'),
            })}
          />
          <ErrorMessage>{errors?.answer?.message}</ErrorMessage>
        </div>
        {isEditing && (
          <>
            <div className="flex gap-3">
              <Button
                type="button"
                onClick={() => {
                  setIsEditing(false);
                  reset(item);
                }}
              >
                {t('cancel')}
              </Button>
              <Button onClick={handleSubmit(submitHandler)}>{t('save')}</Button>
              <Button className="ml-auto" type="button" onClick={handleDelete}>
                {t('delete')}
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

export default SingleFAQ;
