import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../../libary/axios-home";

const filter_list = ["Municipality", "Category", "Subcategory"];

export const initialState = {
  companyCases: [],
};

export const fetchCompanyCasesApi = createAsyncThunk(
  "companyCases/fetchCompanyCasesApi",
  async ({ id, status }) => {
    try {
      const response = await api_url.get(
        "problems/company/" + id + "/status/" + status
      );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const companyCasesSlice = createSlice({
  name: "companyCasesSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCompanyCasesApi.pending, (state, action) => {
      // Add user to the state array
      state.status = "loading";
    });
    builder.addCase(fetchCompanyCasesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeded";
      state.companyCases = action.payload;
    });
    builder.addCase(fetchCompanyCasesApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default companyCasesSlice.reducer;
