import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { postWorkDescription } from '../../../../api/VideoCalls/Reviews';
import history from '../../../../helpers/customRoutes/history';
import useAuth from '../../../../hooks/useAuth';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';

const WorkDescription = ({
  setIsDescribing,
  setIsReviewing,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const t = useTranslate();
  const { state: params } = useLocation();
  const problem_id = params.problem_id;
  const { mutateAsync: submitWorkDescription } = useMutation(
    postWorkDescription,
    {
      onSuccess: async () => {
        history.push('/review', params);
      },
    }
  );

  const submitHandler = ({ description }) => {
    submitWorkDescription({
      expert_opinion: description,
      id: problem_id,
    });
  };

  return (
    <div className="flex flex-col font-poppins w-6/12 h-full p-5 items-center justify-center m-auto mt-20">
      <span className="text-3xl violetPrimary mb-6">
        {t('describeTheWork')}
      </span>
      <div className="flex flex-col w-full px-4 h-60">
        <span className="text-xs text-gray-400">
          {t('workDescription')}
        </span>
        <form>
          <textarea
            defaultValue=""
            placeholder={`${t('description')}...`}
            className="shadow-sm focus:ring-indigo-500 border focus:border-indigo-500 block
            w-full sm:text-sm rounded-md py-2 px-2 outline-none mb-2 mt-2 h-full self-stretch"
            {...register('description', {
              required: t('thisFieldIsRequired'),
            })}
            name="description"
          />
          <ErrorMessage>{errors?.description?.message}</ErrorMessage>
          <Button
            className="mt-3 w-full"
            type="button"
            onClick={handleSubmit(submitHandler)}
          >
            {t('save')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default WorkDescription;
