import React from 'react';
import CountUp from 'react-countup';

const DataDisplay = ({ data, description }) => {
  return (
    <div className="flex flex-col gap-2 items-center px-8 py-11 h-full justify-center">
      <span className="font-poppins text-violetPrimary text-xl">
        <CountUp start={0} end={data} delay={0} duration={1} />
      </span>
      <span className="font-poppins text-textBlack text-sm w-40 flex-wrap text-center">
        {description}
      </span>
    </div>
  );
};

export default DataDisplay;
