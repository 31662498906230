import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CompanyForm from "../../Common/CompanyForm";
import { updateCompanyByIdApi } from "../../../features/companies/putCompanyByIdSlice";
import { fetchCompanyByIdApi } from "../../../features/companies/getCompanyByIdSlice";
import { Box, LinearProgress } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import useTranslate from "../../../hooks/useTranslate";
import Button from "../../Common/Button";

const CompanyFormTab = ({ companyDetails }) => {
  const [editForm, setEditForm] = useState(false);
  const company = useSelector((state) => state.getCompanyById.company);
  const status = useSelector((state) => state.getCompanyById.status);
  const updateStatus = useSelector((state) => state.putCompanyById.status);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ defaultValues: company, mode: "onChange" });
  const t = useTranslate();

  useEffect(() => {
    reset(company);
    setValue("logo", "", { shouldDirty: false });
    setValue("documents", [], { shouldDirty: false });
  }, [company]);

  useEffect(() => {
    dispatch(fetchCompanyByIdApi({ id: companyDetails.id }));
  }, [companyDetails]);

  const onSubmit = (data) => {
    if (isDirty && isValid) {
      delete data.approval_status;
      dispatch(updateCompanyByIdApi({ id: companyDetails.id, data })).then(
        () => {
          dispatch(fetchCompanyByIdApi({ id: companyDetails.id }));
        }
      );
    }
  };

  return (
    <Fragment>
      {status === "loading" ? (
        <Box sx={{ width: "100%", padding: "20px 0" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <CompanyForm
            role={1}
            company={company}
            register={register}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            setValue={setValue}
            errors={errors}
            reset={reset}
            editForm={editForm}
            formName="company-form"
          />
          <div className="flex py-4 px-4">
            <div className="flex justify-start w-full">
              {/*<span className="border-linear mr-4 rounded-md p-0.5">
          <Button
            sx={{
              background: "#FEFEFE !important",
              fontSize: "16px",
              borderRadius: "4px",
            }}
            onClick={() => setDeleteModal(true)}
          >
            <span className="text-linear">Delete</span>
          </Button>
          <DeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
          />
          </span> */}
              {updateStatus === "loading" ? (
                <Box sx={{ width: "100%", padding: "20px 0" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <span className="border-linear rounded-md p-0.5 ml-auto">
                  <Button
                    disabled={editForm && !isValid}
                    type="submit"
                    form="company-form"
                    name="edit"
                    onClick={
                      !editForm
                        ? () => setEditForm(true)
                        : () => setEditForm(false)
                    }
                  >
                    {!editForm ? t("edit") : t("save")}
                  </Button>
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default CompanyFormTab;
