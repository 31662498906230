import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { searchUser } from '../../../api/Users/Users';
import searchLoop from '../../../assets/search-loop.svg';
import useDebounce from '../../../hooks/useDebounce';
import './Search.css';

const Search = ({
  userRole,
  placeholder,
  setSearchData,
  setSearchDataLoading,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Query for user search
  const { data, isLoading, isError } = useQuery(
    [
      'userSearch',
      {
        roleId: userRole,
        searchTerm: searchTerm ? debouncedSearchTerm : '',
      },
    ],
    searchUser,
    {
      refetchInterval: 300000,
      onSuccess: (data) => {
        setSearchData(data);
        setSearchDataLoading(false);
      },
    }
  );

  return (
    <div className="flex">
      <input
        onChange={(event) => setSearchTerm(event.target.value)}
        type="search"
        className="outline-none max-w-xs rounded-l-md px-4 py-1.5 search-bar-font-color  placeholder:text-lg min-w-0 flex-1 flex-shrink"
        placeholder={placeholder}
      />
      <div className=" rounded-md flex items-center  loop-background p-3.5 cursor-pointer">
        <img className="align-right w-6" src={searchLoop} alt="loop" />
      </div>
    </div>
  );
};
export default Search;
