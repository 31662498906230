import { Checkbox } from '@material-ui/core';
import React, { useState } from 'react';

const DeniedReasons = ({ reason, handleChange }) => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="flex gap-3  items-center w-11/12 ">
      <Checkbox
        className="text-indigo-600"
        onChange={() => {
          if (!isChecked) {
            handleChange(reason?.id, true);
            setIsChecked(true);
          } else {
            handleChange(reason?.id, false);
            setIsChecked(false);
          }
        }}
      />
      <span className="truncate w-full">{reason.text}</span>
    </div>
  );
};

export default DeniedReasons;
