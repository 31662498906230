import React from 'react'
import { emailRegex } from '../../../helpers/emailRegex';
import useTranslate from '../../../hooks/useTranslate';

const UserInputFields = () => {
  const t = useTranslate()

  const inputArray = [
    {
      placeholder:`${t('name')}...`,
      type: 'text',
      name: 'name',
      label: t('name'),
      required: t('thisFieldIsRequired'),
      max: {
        value: 20,
        message: t('20CharValidation'),
      },
      error: (errors) => errors.name?.message,
    },
    {
      placeholder: `${t('surname')}...`,
      type: 'text',
      name: 'last_name',
      label: t('surname'),
      required: t('thisFieldIsRequired'),
      max: {
        value: 20,
        message: t('20CharValidation'),
      },
      error: (errors) => errors.last_name?.message,
    },
    {
      placeholder:`${t('email')}...`,
      type: 'email',
      name: 'email',
      label: t('email'),
      pattern: {
        value: emailRegex,
        message: t('enterValidEmail'),
      },
      required: t('thisFieldIsRequired'),
      error: (errors) => errors.email?.message,
    },
    {
      placeholder:`${t('phoneNumber')}...`,
      type: 'tel',
      name: 'phone',
      label:t('phoneNumber'),
      // required: "This field is required.",
      error: (errors) => errors.phone?.message,
    },
    {
      placeholder: `${t('postalNumber')}...`,
      type: 'number',
      name: 'post_code',
      label: t('postalNumber'),
      required: t('thisFieldIsRequired'),
      error: (errors) => errors.post_code?.message,
    },
  ];
  return inputArray
}

export default UserInputFields