import TopbarLanding from "../containers/Topbar/TopbarLanding";
import LoginComponent from "../components/LoginComponent";


const Login = ()=>{

    return <>
        <TopbarLanding />
        <LoginComponent />
        
    </>
}

export default Login;