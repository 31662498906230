import React, { useState } from 'react';
import '../../Cases/Cases.css';
import './DetailsComponent.css';
import { useTranslation } from 'react-i18next';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import useAuth from '../../../hooks/useAuth';
import Button from '../../Common/Button';
import iconEdit from '../../../assets/images/tabler_edit.svg';
import Modal from '../../Common/Modal';
import EditDescriptionModal from '../ExpertModal/EditDescriptionModal';
import { DateTime } from 'luxon';

export default function DetailsComponent({ userDetails, selectedCaseDetails }) {
  const { user } = useAuth();
  const [expertOpinionModal, setExpertOpinionModal] = useState(false);
  const { t } = useTranslation();

  if (selectedCaseDetails?.status_id === 1)
    return (
      <div className="flex items-center w-full justify-center font-poppins font-medium mb-4">
        {t('noData')}
      </div>
    );

  return (
    <>
      {selectedCaseDetails?.status_id === 1 ? (
        <div className="flex items-center w-full justify-center font-poppins font-medium">
          {t('noData')}
        </div>
      ) : (
        <>
          <div className="flex flex-row justify-between items-center py-4 px-6 gradient-background">
            <span className="case-header-name font-normal text-lg ">
              {t('videoCall')}
            </span>
            <span
              className={` text-sm py-2 px-6
            ${
              selectedCaseDetails?.is_urgent === true
                ? 'status-urgent-case'
                : 'status-non-urgent-case'
            }`}
            >
              {selectedCaseDetails?.is_urgent === true
                ? t('urgent')
                : t('nonUrgent')}
            </span>
          </div>
          <div className="flex flex-row justify-between py-4 px-6 case-detail-bottom-border">
            <span className="case-header-name font-normal text-base">
              {selectedCaseDetails?.call_time
                ? DateTime.fromSQL(selectedCaseDetails?.call_time).toFormat(
                    'yyyy-MM-dd'
                  )
                : '-'}
            </span>
            <span className="case-header-name font-normal text-base ">
              {' '}
              {selectedCaseDetails?.call_time
                ? DateTime.fromSQL(selectedCaseDetails?.call_time).toFormat(
                    'HH:mm'
                  )
                : '-'}
            </span>
          </div>
          {user?.role_id === 1 && (
            <div className="flex flex-row justify-between py-4 px-6 case-detail-bottom-border">
              <span className="case-header-name font-medium ">
                {t('price')} ({t('perCall')})
              </span>
              <span className="case-header-name font-normal ">
                {selectedCaseDetails?.payment?.amount
                  ? selectedCaseDetails?.payment?.amount / 1000
                  : '-'}{' '}
                {selectedCaseDetails?.payment?.currency
                  ? selectedCaseDetails?.payment?.currency
                  : '-'}
              </span>
            </div>
          )}
          <div className="flex flex-col items-start py-4 px-6 case-detail-bottom-border">
            <span className="case-header-name font-medium pb-4">Expert</span>
            <div className="flex ">
              <img
                src={
                  selectedCaseDetails?.expert?.profile_image
                    ? selectedCaseDetails?.expert?.profile_image
                    : PlaceholderImage
                }
                style={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
                alt="Expert"
              />
              <span className="flex flex-col px-2.5">
                <span className="case-header-name font-medium text-sm">
                  {selectedCaseDetails?.expert?.name}{' '}
                  {selectedCaseDetails?.expert?.last_name}
                </span>
                <span className="text-sm font-normal expert-title">
                  {userDetails?.problem_expert_role}
                </span>
              </span>
            </div>
          </div>
          <div className="flex flex-col  py-4 px-6 case-detail-bottom-border">
            <div className="flex items-center pb-4">
              <span className="case-header-name font-medium w-full">
                {selectedCaseDetails?.status_id === 3
                  ? t('newCaseDescriptionByExper')
                  : t('descriptionByExpert')}
              </span>
              {user?.role_id === 1 && (
                <img
                  src={iconEdit}
                  alt="edit"
                  className="w-7 h-7 cursor-pointer"
                  onClick={() => setExpertOpinionModal(true)}
                />
              )}
            </div>
            <span className="font-normal expert-title text-sm max-w-xs w-full">
              {selectedCaseDetails?.expert_opinion}
            </span>
          </div>
          {user?.role_id === 1 && (
            <Modal
              isOpen={expertOpinionModal}
              setIsOpen={setExpertOpinionModal}
              className="max-w-md w-full"
            >
              <EditDescriptionModal
                expertDescription={selectedCaseDetails?.expert_opinion}
                caseId={selectedCaseDetails?.id}
                setExpertOpinionModal={setExpertOpinionModal}
              />
            </Modal>
          )}
        </>
      )}
    </>
  );
}
