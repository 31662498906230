import TopbarLanding from "../containers/Topbar/TopbarLanding";
import RegisterTabsComponent from "../components/Register/RegisterTabsComponent";
import "../assets/css/register.css";

const Register = () => {
  return (
    <>
      <TopbarLanding />

      <div className="flex">
        <div className="mt-16 w-full">
          <RegisterTabsComponent />
        </div>
      </div>
    </>
  );
};

export default Register;
