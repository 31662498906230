import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CompanyInfoForm from './CompanyInfoForm';
import AdminInfoForm from './AdminInfoForm';

import './Admin.css';
import useTranslate from '../../hooks/useTranslate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="company-form">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
export default function Admin() {
  const t = useTranslate();
  const [value, setValue] = useState(t('regCompanyInfo'));
  const tabs = [t('regCompanyInfo'), 'Admin info'];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="mt-12">
      <p className="breadcrumb-title px-4 my-8">{t('profile')}</p>
      <div className="bg-white py-2 rounded-lg shadow-md">
        <Tabs
          value={value}
          onChange={handleChange}
          className="border-b border-slate-800/[.1]"
        >
          {tabs.map((tab, index) => (
            <Tab value={tab} label={tab} key={index} />
          ))}
        </Tabs>

        <TabPanel value={value} index={tabs[0]}>
          <CompanyInfoForm />
        </TabPanel>
        <TabPanel value={value} index={tabs[1]}>
          <AdminInfoForm />
        </TabPanel>
      </div>
    </div>
  );
}
