import '../Companies.css';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import companyImage from '../../../assets/company-img.svg';
import CompanyDetails from '../CompanyInfo/CompanyDetails';
import WaitingCompaniesTable from './WaitingCompaniesTable';
import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchCompaniesWaiting,
  setCompanyApprovalStatus,
} from '../../../api/Company/Company';
import Modal from '../../Common/Modal';
import ModalAccepted from './Modals/ModalAccepted';
import ModalDenied from './Modals/ModalDenied';
import useTranslate from '../../../hooks/useTranslate';
import { Box, LinearProgress } from '@mui/material';

const WaitingCompanies = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const t = useTranslate();

  const header = [
    t('company'),
    t('organisationalNumber'),
    t('adminContact'),
    '',
  ];
  const tabs = [t('companyInfo'), t('adminInfo')];

  const {
    data: waitingCompanies,
    isLoading: waitingCompaniesLoading,
    isError,
    refetch: fetchCompanies,
  } = useQuery(['waitingCompanies'], fetchCompaniesWaiting);

  const { mutateAsync: changeApproval } = useMutation(
    setCompanyApprovalStatus,
    {
      onSuccess: (data) => {
        setIsEmailValid(data?.incorrect_addresses?.length > 0 ? false : true);
        fetchCompanies();
      },
    }
  );

  const handleDeny = (id) => {
    setSelectedId(id);
    setIsAccepted(false);
    setIsOpen(true);
    setIsDenied(true);
  };

  const handleApproval = (deniedReasons, description) => {
    changeApproval({
      id: selectedId,
      state: false,
      deniedReasons,
      description,
    });
  };

  const handleAccept = (id, state) => {
    setIsDenied(false);
    setIsAccepted(true);
    setIsOpen(true);
    changeApproval({ id, state });
  };

  const dataPreview = (
    rowsPerPage > 0
      ? waitingCompanies?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : waitingCompanies
  )?.map((row, index) => (
    <WaitingCompaniesTable
      key={index}
      row={row}
      index={index}
      setCompanyDetails={setCompanyDetails}
      setActiveRow={setActiveRow}
      activeRow={activeRow}
      companyImage={companyImage}
      setIsDenied={setIsDenied}
      handleAccept={handleAccept}
      handleDeny={handleDeny}
    />
  ));
  return (
    <div className="flex px-8 my-4">
      {waitingCompaniesLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            className="max-w-lg w-screen py-6"
          >
            {isAccepted && (
              <ModalAccepted
                setIsOpen={setIsOpen}
                isEmailValid={isEmailValid}
              />
            )}
            {isDenied && (
              <ModalDenied
                setIsOpen={setIsOpen}
                handleApproval={handleApproval}
              />
            )}
          </Modal>
          <div className="rounded-xl w-full">
            <CommonTable
              header={
                companyDetails
                  ? header.filter((item, index) => index < 1)
                  : header
              }
              tableData={dataPreview}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={waitingCompanies ? waitingCompanies.length : 0}
            />
          </div>
          {activeRow !== null && (
            <CompanyDetails
              companyDetails={companyDetails}
              setCompanyDetails={setCompanyDetails}
              setActiveRow={setActiveRow}
              tabs={tabs}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WaitingCompanies;
