import React from 'react';
import { emailRegex } from '../../../helpers/emailRegex';
import useTranslate from '../../../hooks/useTranslate';

const ExpertInputFields = () => {
  const t = useTranslate();
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const arrayFields = [
    {
      placeholder: `${t('name')}...`,
      type: 'text',
      name: 'name',
      label: `Expert ${t('name')}:`,
      required: t('thisFieldIsRequired'),
      max: {
        value: 20,
        message: t('20CharsValidation'),
      },
      error: (errors) => errors.name?.message,
    },
    {
      placeholder: `${t('surname')}...`,
      type: 'text',
      name: 'last_name',
      label: `Expert ${t('surname')}:`,
      required: t('thisFieldIsRequired'),
      max: {
        value: 20,
        message: t('20CharsValidation'),
      },
      error: (errors) => errors.last_name?.message,
    },
    {
      placeholder: t('phoneNumber'),
      type: 'tel',
      name: 'phone',
      label: `${t('phoneNumber')}...`,
      pattern: {
        value: phoneRegex,
        message: t('invalidPhoneNumber'),
      },
      required: t('thisFieldIsRequired'),
      error: (errors) => errors.phone?.message,
    },
    {
      placeholder: `${t('email')}...`,
      type: 'text',
      name: 'email',
      label: `${t('email')}:`,
      pattern: {
        value: emailRegex,
        message: t('enterValidEmail'),
      },
      required: t('thisFieldIsRequired'),
      error: (errors) => errors.email?.message,
    },
  ];
  return arrayFields;
};

export default ExpertInputFields;
