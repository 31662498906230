import api_url from '../../libary/axios-home';

export const fetchAllUsers = async () => {
  const { data } = await api_url.get('user/role/5');
  return data;
};

export const fetchUserById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`user/${selectedId}`);
  return data;
};

export const fetchUserCategoriesById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`user/${selectedId}/categories`);
  return data;
};

export const editUser = async (formData) => {
  const { id } = formData;
  const { data } = await api_url.put(`user/update/${id}`, {
    dwelling_type: formData?.dwelling
      ? formData.dwelling.name
      : formData.dwelling_type,
    email: formData.email,
    id: formData.id,
    last_name: formData.last_name,
    name: formData.name,
    phone: formData.phone,
    post_code: formData.post_code.toString(),
    city_id: formData?.city ? formData.city.id : formData.city_id,
    categories: [],
  });
  return data;
};

export const deleteUser = async (id) => {
  const { data } = await api_url.delete(`/user/delete/${id}`);
  return data;
};

export const searchUser = async ({ queryKey }) => {
  const [_, searchData] = queryKey;
  const { data } = await api_url.get(`user/filter/${searchData.roleId}`, {
    params: {
      search: searchData.searchTerm,
    },
  });
  return data;
};
