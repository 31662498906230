import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import CategoriesSelect from '../Categories/CategoriesSelect';
import SubCategoriesSelect from '../Categories/SubCategoriesSelect';
import Button from '../Common/Button';
import { addCategory } from '../../helpers/Expert/filterExpertCategories';
import useTranslate from '../../hooks/useTranslate';
import { LinearProgress } from '@material-ui/core';

const CategoryModal = ({
  setIsOpen,
  editableCategories,
  setEditableCategories,
  originCategories,
  subCategoryModal,
  setSubCategoryModal,
  subParentId,
  setSubParentId,
  allCategoriesLoading,
}) => {
  const [activeCategory, setActiveCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [tempCategory, setTempCategory] = useState();
  const t = useTranslate();

  const title = subCategoryModal ? t('addSubcategories') : t('addCategory');

  return (
    <>
      <Dialog.Title
        as="h3"
        className="text-lg font-poppins text-purple-600 inline-block w-full text-center font-medium"
      >
        {title}
      </Dialog.Title>
      {subCategoryModal ? (
        <SubCategoriesSelect
          originCategory={originCategories.find(
            (c) =>
              c.id === (subParentId !== null ? subParentId : selectedCategory)
          )}
          setTempCategory={setTempCategory}
          tempCategory={tempCategory}
        />
      ) : allCategoriesLoading ? (
        <LinearProgress />
      ) : (
        <CategoriesSelect
          categories={editableCategories.filter(
            (cat) => !cat?.subcategories?.some((subC) => subC.assigned)
          )}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      <div className="flex w-full justify-center mt-6 gap-4">
        <Button
          onClick={() => {
            setIsOpen(false);
            setSubParentId(null);
            setSubCategoryModal(false);
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          disabled={!selectedCategory && !subParentId}
          onClick={() => {
            if (!subCategoryModal) {
              setSubCategoryModal(true);
              setTempCategory({
                ...originCategories[activeCategory],
              });
            } else {
              setSubParentId(null);
              setEditableCategories(
                addCategory(editableCategories, tempCategory)
              );
              setIsOpen(false);
              setSubCategoryModal(false);
            }
          }}
        >
          {subCategoryModal ? t('add') : t('next')}
        </Button>
      </div>
    </>
  );
};

export default CategoryModal;
