import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import PageHeading from '../../components/Organisms/PageHeading';
import useTranslate from '../../hooks/useTranslate';

const UsersPage = () => {
  const [createToggle, setCreateToggle] = useState(false);
  const [searchDataLoading, setSearchDataLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const t = useTranslate();

  return (
    <div className="w-full">
      <PageHeading
        userRole={5}
        title={t('users')}
        addButtonName={`+ ${t('add')} ${t('user')}`}
        hasSearch
        noButton
        buttonClick={() => {
          setCreateToggle(true);
        }}
        setSearchData={setSearchData}
        setSearchDataLoading={setSearchDataLoading}
      />
      <Outlet context={[createToggle, setCreateToggle, searchData, searchDataLoading]} />
    </div>
  );
};

export default UsersPage;
