import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


export const initialState = {
  finishedCases: []
};

export const fetchFinishedCasesApi = createAsyncThunk(
  'finishedCases/fetchFinishedCasesApi',
  async () => {
    try {
      const response = await api_url.get('problems/status/completed' );
      return response.data;

    } catch (error) {
      console.error(error);
    }

  }
)

const finishedCasesSlice = createSlice({
  name: "finishedCasesSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchFinishedCasesApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchFinishedCasesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.finishedCases = action.payload;
    })
    builder.addCase(fetchFinishedCasesApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default finishedCasesSlice.reducer;