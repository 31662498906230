import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { fetchCompanyAppointmentsHomeVisit } from '../../../../api/Company/Company';
import { useQuery } from '@tanstack/react-query';
import useAuth from '../../../../hooks/useAuth';
import { Box, CircularProgress } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#13044F',
      padding: 10,
      usePointStyle: false,
      titleColor: '#ffffff',
      titleAlign: 'center',
      titleFont: { size: '14px' },
      callbacks: {
        labelColor: function (context) {
          return {
            borderWidth: 2,
            borderRadius: 2,
          };
        },
        labelTextColor: function (context) {
          return '#ffffff';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        borderDash: [5, 2],
      },
    },
    y: {
      grid: {
        borderDash: [5, 2],
      },
    },
  },
};

const HomevisitsChart = () => {
  const { user } = useAuth();

  // Fetch home visits
  const {
    data: companyHomeVisits,
    isLoading: companyHomeVisistsLoading,
    isError: companyHomeVisitsError,
  } = useQuery(
    ['companyHomeVisits', user?.company_id],
    fetchCompanyAppointmentsHomeVisit,
    {
      onSuccess: () => {},
    }
  );

  const data = {
    labels: ['Pågående', 'Avklarade'],
    datasets: [
      {
        label: '# of Votes',
        data: [
          companyHomeVisits?.ongoingHomeVisits,
          companyHomeVisits?.completedHomeVisits,
        ],
        backgroundColor: ['#480CA8', '#B5179E'],
        borderColor: ['#480CA8', '#B5179E'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      {companyHomeVisistsLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '24rem',
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div className="p-10 h-96">
            <Doughnut
              data={data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                },
              }}
              height={50}
              width={200}
            />
          </div>
          <div className="flex h-full items-center justify-center flex-col flex-1">
            <div className="flex flex-col gap-3">
              <div className="flex gap-3 items-center font-poppins">
                <div className="w-6 h-6 bg-lilaDark rounded-md" />
                <span className="w-32">Pågående</span>
                <span className="ml-4 text-lilaDark font-medium">
                  {companyHomeVisits?.ongoingHomeVisits}
                </span>
              </div>
              <div className="flex gap-3 items-center font-poppins">
                <div className="w-6 h-6 bg-pinkDark rounded-md" />
                <span className="w-32">Avklarade</span>
                <span className="ml-4 text-pinkDark font-medium">
                  {companyHomeVisits?.completedHomeVisits}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomevisitsChart;
