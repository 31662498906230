import { useNavigate } from 'react-router-dom';
import TopbarLanding from '../containers/Topbar/TopbarLanding';
import FooterLanding from '../containers/Footer/FooterLanding';
import LandingContentPart1 from '../containers/Landing/LandingContentPart1';
import LandingContentPart2 from '../containers/Landing/LandingContentPart2';
import LandingContentPart3 from '../containers/Landing/LandingContentPart3';
import LandingContentPart4 from '../containers/Landing/LandingContentPart4';
import LandingContentPart5 from '../containers/Landing/LandingContentPart5';
import '../assets/css/home.css';
import LandingContentPart6 from '../containers/Landing/LandingContentPart6';
import { useEffect } from 'react';
import useAuth from '../hooks/useAuth';

const Home = () => {
  const { loggedIn, user } = useAuth();
  const role_id = user ? user.role_id : null;
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn && role_id === 3) navigate('/d/cases/ongoing-cases');
    if (!loggedIn) navigate('/login');
  }, [navigate, loggedIn, role_id]);
  return (
    <>
      {/* <button onClick={()=>navigate('/d')}> IDI NA DASHBORd</button> */}
      <TopbarLanding />
      <LandingContentPart1 />
      <LandingContentPart2 />
      <LandingContentPart3 />
      <LandingContentPart4 />
      <LandingContentPart5 />
      <LandingContentPart6 />
      <FooterLanding />
    </>
  );
};

export default Home;
