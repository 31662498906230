import React from 'react';
import iconDelete from '../../../assets/images/tabler_trash.svg';
import iconEdit from '../../../assets/images/tabler_edit.svg';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../assets/placeholderImg.png';

const ActiveCompaniesTable = ({
  row,
  index,
  activeRow,
  setActiveRow,
  setCompanyDetails,
  setIsOpen,
  setCompanyId,
}) => {
  return (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow === index && 'active-row'}`}
    >
      <StyledTableCell
        onClick={() => {
          setCompanyDetails(row);
          setActiveRow(index);
        }}
      >
        <div className="flex gap-3 w-64">
          <img
            src={row?.logo ? row?.logo : PlaceholderImage}
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            alt="companyLogo"
            className="rounded-full w-10 h-10 object-cover"
          />
          <div className="w-64">
            <p className="font-medium text-base leading-6 font-color">
              {row?.company_name}
            </p>
            <span className="font-color">
              {row?.post_code} {row?.company_address}
            </span>
          </div>
        </div>
      </StyledTableCell>
      {activeRow === null && (
        <>
          <StyledTableCell
            onClick={() => {
              setCompanyDetails(row);
              setActiveRow(index);
            }}
          >
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.code}
                </p>
                <span className="font-color">{row?.company_email}</span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell
            onClick={() => {
              setCompanyDetails(row);
              setActiveRow(index);
            }}
          >
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.name
                      ? row?.administrators[0]?.name
                      : '-'
                    : '-'}{' '}
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.last_name
                      ? row?.administrators[0]?.last_name
                      : '-'
                    : '-'}
                </p>
                <span className="font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.phone
                      ? row?.administrators[0]?.phone
                      : 'No admin phone'
                    : 'No admin phone'}
                </span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell
            onClick={() => {
              setCompanyDetails(row);
              setActiveRow(index);
            }}
          >
            {row?.handymen?.length > 0 ? (
              <div className="flex">
                <div className="flex">
                  {row?.handymen?.slice(0, 3).map((handyman, index) => (
                    <img
                      key={index}
                      src={
                        handyman?.profile_image
                          ? handyman?.profile_image
                          : PlaceholderImage
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.src = PlaceholderImage;
                      }}
                      alt="handyman"
                      className="ml-3 rounded-full w-10 h-10 -ml-3 object-cover bg-white first:ml-0"
                    />
                  ))}
                </div>
                {row?.handymen?.length > 3 && (
                  <span
                    className="ml-3 rounded-full w-10 h-10 -ml-3 text-white flex justify-center items-center font-poppins text-sm font-normal"
                    style={{
                      background:
                        'linear-gradient(90deg, #3912D6 0%, #A312D6 100%)',
                    }}
                  >
                    +{row?.handymen?.length - 3}
                  </span>
                )}
              </div>
            ) : (
              'No handymen'
            )}
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex">
              <img
                src={iconEdit}
                className="pr-2"
                alt="edit"
                onClick={() => {
                  setCompanyDetails(row);
                  setActiveRow(index);
                }}
              />
              <img
                src={iconDelete}
                alt="delete"
                onClick={() => {
                  setIsOpen(true);
                  setCompanyId(row?.id);
                }}
              />
            </div>
          </StyledTableCell>
        </>
      )}
    </TableRow>
  );
};

export default ActiveCompaniesTable;
