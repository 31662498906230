import React from "react";
import { styled } from "@mui/material/styles";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import iconDelete from "../../assets/images/tabler_trash.svg";

const NextButton = styled(Button)(() => ({
  background: "#FE0000",
  borderRadius: "6px",
  color: "#FEFEFE",
  padding: "0.5rem 2rem",
  fontSize: "22px",
}));

const CancelButton = styled(Button)(() => ({
  background: "#fff",
  borderRadius: "6px",
  color: "#909090",
  padding: "0.5rem 2rem",
  border: "2px solid #909090",
  marginRight: "20px",
  fontSize: "22px",
}));
const DeleteButton = styled(Button)(() => ({
  background: "#fff",
  borderRadius: "6px",
  color: "#3912D6",
  padding: "0.5rem 2rem",
  border: "2px solid #3912D6",
  marginRight: "20px",
}));

export default function DeleteModal({ id, is_edit, deleteExpert }) {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      {is_edit == 1 ? (
        <DeleteButton onClick={() => setShowModal(true)} variant="contained">
          DELETE
        </DeleteButton>
      ) : (
        <p id="open_modal" onClick={() => setShowModal(true)}>
          {" "}
          <img id={id} src={iconDelete}></img>
        </p>
      )}

      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-1/4 my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="px-8 py-10 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col-reverse items-start justify-between p-5 border-solid border-slate-200 rounded-t">
                  <h3
                    className="text-center w-full"
                    style={{ color: "#FE0000", fontSize: "22px" }}
                  >
                    Delete expert?
                  </h3>
                  <button
                    style={{ color: "#909090" }}
                    className=" absolute right-0 top-0 p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span
                      style={{ color: "#909090" }}
                      className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
                    >
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative">
                  <p className="text-center" style={{ color: "#909090" }}>
                    Are you sure you want to delete this expert?
                  </p>
                </div>
                {/*footer*/}

                <div className="flex items-center justify-center p-6 border-solid border-slate-200 rounded-b">
                  <CancelButton
                    style={{
                      background: "#fff",
                      marginRight: "20px",
                      border: "2px solid #909090",
                      color: "#909090",
                      fontSize: "22px",
                    }}
                    className="w-36 h-12"
                    variant="contained"
                    onClick={() => setShowModal(false)}
                  >
                    CANCEL
                  </CancelButton>
                  <NextButton
                    onClick={() => deleteExpert(id)}
                    style={{
                      background: "#FE0000",
                      color: "#FEFEFE",
                      fontSize: "22px",
                    }}
                    className="w-36 h-12"
                    variant="contained"
                  >
                    DELETE
                  </NextButton>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-70 fixed inset-0 z-40"
            style={{ backgroundColor: "#13044F" }}
          ></div>
        </>
      ) : null}
    </>
  );
}
