import { Alert } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import addCircleIcon from '../../assets/images/add_icon_circle.svg';
import useTranslate from '../../hooks/useTranslate';

const DocumentUpload = ({
  editForm,
  documentsList,
  setDocumentsList,
  uploadDocuments,
  setUploadDocuments,
}) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const document = useRef(null);
  const t = useTranslate();

  const onUploadDocumentClick = () => {
    document.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = '';
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (file) {
      getBase64(file)
        .then((result) => {
          file['base64'] = result;
          setUploadDocuments([
            ...uploadDocuments,
            {
              title: file.name,
              extension: file.type,
              content: result,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [file]);

  const handleUploadDocument = (event) => {
    if (event.target.files[0].type !== 'application/pdf') {
      setError('Only PDF files can be uploaded!');
      return;
    }
    //handle document store
    if (documentsList?.length >= 2) {
      setError("Can't upload file. File limit reached!");
      return;
    } else if (event.target.files[0]) {
      const isFound = documentsList?.some((element) => {
        if (element.title === event.target.files[0].name) {
          return true;
        }
      });
      if (!isFound) {
        setDocumentsList([
          ...documentsList,
          {
            title: event.target.files[0].name,
          },
        ]);
        setFile(event.target.files[0]);
        setError('');
      } else {
        setError('File with same name already exist!');
      }
    }
  };
  return (
    <>
      {editForm && (
        <p
          className="flex text-textGray ml-1 cursor-pointer py-2 items-center"
          style={{ cursor: editForm ? 'pointer' : 'default' }}
          onClick={editForm ? onUploadDocumentClick : undefined}
        >
          <img className="mr-2 w-5 h-5" src={addCircleIcon} />
          {t('addDocument')}
        </p>
      )}
      <input
        type="file"
        id="document"
        accept="application/pdf"
        ref={document}
        style={{ display: 'none' }}
        onChange={handleUploadDocument}
      />
      {error !== '' && editForm && (
        <Alert severity="error" className="mt-4">
          {error}
        </Alert>
      )}
    </>
  );
};

export default DocumentUpload;
