import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../../libary/axios-home";

export const initialState = {
  ratings: [],
};

export const fetchCaseRatingsByIdApi = createAsyncThunk(
  "caseRatings/fetchCaseRatingsByIdApi",
  async ({ id }) => {
    try {
      const response = await api_url.get("user/problem/" + id + "/rating");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getCaseRatingsByIdSlice = createSlice({
  name: "getCaseRatingsByIdSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCaseRatingsByIdApi.pending, (state, action) => {
      // Add user to the state array
      state.status = "loading";
    });
    builder.addCase(fetchCaseRatingsByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeded";
      state.ratings = action.payload;
    });
    builder.addCase(fetchCaseRatingsByIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getCaseRatingsByIdSlice.reducer;
