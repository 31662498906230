import singlephone3 from "../../assets/images/single-phone3.png";
import singlephone4 from "../../assets/images/single-phone4.png";
import elipse61 from "../../assets/images/Ellipse61.svg";

const LandingContentPart4 = () => {
  return (
    <div className="flex h-full pb-4 relative ">
      <img
        src={elipse61}
        className="absolute right-0 -bottom-72"
        style={{ width: "965px" }}
      />
      <div className="flex flex-col-reverse lg:flex-row custom-container">
        <div className="w-full lg:w-3/6 mt-4  pt-10 lg:pt-20 ">
          <div className="text-center">
            <img
              className="logo inline-block relative z-50"
              src={singlephone4}
            />
            <img
              className="logo inline-block -ml-14 sm:-ml-4 relative z-40"
              src={singlephone3}
            />
          </div>
        </div>

        <div className="w-full lg:w-3/6 mt-8  pt-10 lg:pt-40 lg:pl-0 lg:pr-40 pl-8 pr-8">
          <h3 className="mb-8" style={{ color: "#13044F", fontSize: "32px" }}>
            3. Du kopplas ihop med en av våra fastighetsexperter
          </h3>
          <p
            className="opacity-70"
            style={{ color: "#13044F", fontSize: "18px" }}
          >
            Du får snabbt en tid med en av våra fastighetsexperter som hjälper
            dig med felsökning av ditt ärende genom videosamtal med målet att
            vägleda dig till en åtgärd.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart4;
