import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../libary/axios-home";

const initialState = {
  response: {},
};

export const fetchLoginApi = createAsyncThunk(
  "auth/fetchLoginApi",
  async ({ email, password }) => {
    try {
      const response = await api_url.post("login/admin", { email, password });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const authSlice = createSlice({
  name: "authSlice",
  initialState,

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchLoginApi.pending, (state, action) => {
      // Add user to the state array
      state.status = "loading";
    });
    builder.addCase(fetchLoginApi.fulfilled, (state, action) => {
      // Add user to the state array
      localStorage.setItem("token", action.payload.access_token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.status = "succeeded";
      state.response = action.payload;
    });
    builder.addCase(fetchLoginApi.rejected, (state, action) => {
      // Add user to the state array
      state.status = "failed";
    });
  },
});

// Action creators are generated for each case reducer function
export const { changeName } = authSlice.actions;

export default authSlice.reducer;
