import React, { useEffect, useState } from 'react';
import { ReactComponent as TrashIcon } from '../../../../assets/images/tabler_trash.svg';
import { ReactComponent as EditIcon } from '../../../../assets/images/tabler_edit.svg';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  deleteCategoryById,
  updateCategoryById,
} from '../../../../api/Categories/Categories';
import Button from '../../../Common/Button';
import { useForm } from 'react-hook-form';
import Input from '../../../Common/Input';
import IconUpload from '../../../Common/IconUpload';
import ErrorMessage from '../../../Common/ErrorMessage';
import useTranslate from '../../../../hooks/useTranslate';
import { FetchCategoryIcon } from '../../../../helpers/categoryIcons';

const SingleSubCategory = ({
  subCategory,
  selectedIcon,
  setSelectedIcon,
  setIsOpen,
}) => {
  const queryClient = useQueryClient();
  const [isEditing, setIsEditing] = useState(false);
  const t = useTranslate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    clearErrors();
  }, [selectedIcon]);

  // Mutation for deleting a subcategory
  const {
    mutateAsync: deleteSubCategoryMutation,
    isLoading: deleteIsLoading,
    isError: deleteIsError,
  } = useMutation(deleteCategoryById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['settingsCategories']);
    },
  });

  // Mutation for editing a subcategory
  const {
    mutateAsync: editSubCategoryMutation,
    isLoading: editIsLoading,
    isError: editIsError,
  } = useMutation(updateCategoryById, {
    onSuccess: () => {
      queryClient.invalidateQueries(['settingsCategories']);
      setIsEditing(false);
    },
  });

  const deleteHandler = () => {
    deleteSubCategoryMutation(subCategory.id);
  };

  const editHandler = () => {
    setIsEditing(true);
    reset(subCategory);
  };

  const submitFormHandler = (data) => {
    if (selectedIcon === '') {
      setError('name', {
        type: 'custom',
        message: t('anIconIsRequired'),
      });
      return;
    }
    editSubCategoryMutation({
      id: subCategory.id,
      name: data.name,
      icon: selectedIcon,
    });
    setSelectedIcon('');
  };

  return (
    <div
      className="border-b border-slate-200  flex items-center py-6 pb-7 pl-4 gap-3"
      key={subCategory.id}
    >
      {isEditing ? (
        <form className="flex flex-col pr-4 w-full">
          <div className="flex flex-col w-1/2">
            <div className="flex gap-4 w-full items-center">
              <IconUpload
                setModalOpen={setIsOpen}
                selectedIcon={selectedIcon}
              />
              <Input
                register={register}
                placeholder={`${t('subcategoryName')}...`}
                type="Text"
                name="name"
                required={t('thisFieldIsRequired')}
                isError={errors?.name?.message}
                noLabel
              />
            </div>
            <ErrorMessage>{errors?.name?.message}</ErrorMessage>
          </div>
          <div className="flex gap-3 ml-auto mt-3">
            <Button
              onClick={() => {
                setIsEditing(false);
                setSelectedIcon('');
              }}
            >
              {t('cancel')}
            </Button>
            <Button onClick={handleSubmit(submitFormHandler)}>
              {t('save')}
            </Button>
          </div>
        </form>
      ) : (
        <>
          <FetchCategoryIcon
            name={subCategory.icon}
            strokeColor={'strokePurple'}
          />
          <span className="font-medium font-poppins text-textBlack">
            {subCategory.name}
          </span>
          <EditIcon
            className="inline-block ml-auto cursor-pointer"
            onClick={() => {
              editHandler();
              setSelectedIcon('');
            }}
          />
          <TrashIcon
            className="inline-block mr-5 cursor-pointer"
            onClick={deleteHandler}
          />
        </>
      )}
    </div>
  );
};

export default SingleSubCategory;
