import { useTranslation } from 'react-i18next';
import React from 'react';
import SuperAdminDashboard from './Dashboard/SuperAdmin/SuperAdminDashboard.jsx';
import CompanyAdminDashboard from './Dashboard/CompanyAdmin/CompanyAdminDashboard.jsx';
import Heading from './Common/Heading.jsx';
import useAuth from '../hooks/useAuth.js';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const { role_id } = user;

  return (
    <div className="flex w-full px-8 mb-4 flex-col gap-5">
      <Heading>{t('dashboard')}</Heading>
      {role_id === 2 ? <CompanyAdminDashboard /> : <SuperAdminDashboard />}
    </div>
  );
};

export default Dashboard;
