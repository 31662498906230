import api_url from "../../../libary/axios-home";

export const editRoT = async (formData) => {
  const { text, id } = formData;
  const { data } = await api_url.put(`/settings/update/${id}`, {
    administrator_id: 1,
    item_key: "rot",
    type: "rot",
    item_value: text,
  });
  return data;
};

export const fetchRoT = async () => {
  const { data } = await api_url.get(`settings/status/rot`);
  return data;
};
