import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api_url from '../libary/axios-home';

const initialState = {
  categories: [],
  subcategories: [],
  municipalities: [],
  administrators: [],

}

 export const fetchCategoriesApi = createAsyncThunk(
  'categories/fetchCategoriesApi',
  async () => {
    try {
      const response = await api_url.get('categories' );
     
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)
export const fetchSubcategoriesApi = createAsyncThunk(
  'subcategories/fetchSubcategoriesApi',
  async () => {
    try {
      const response = await api_url.get('categories/subcategories' );
      
      return response.data.response;
    } catch (error) {
      console.error(error);
    }
  }
)
export const fetchMunicipalitiesApi = createAsyncThunk(
  'municipalities/fetchMunicipalitiesApi',
  async () => {
    try {
      const response = await api_url.get('municipalities' );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)

export const fetchAdminApi = createAsyncThunk(
  'administrators/fetchAdminApi',
  async () => {
    try {
      const response = await api_url.get('administrators' );
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
)

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState,

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCategoriesApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchCategoriesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.categories = action.payload;
    })
    builder.addCase(fetchCategoriesApi.rejected, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchMunicipalitiesApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchMunicipalitiesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.municipalities = action.payload;
    });
    builder.addCase(fetchMunicipalitiesApi.rejected, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchSubcategoriesApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchSubcategoriesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.subcategories = action.payload;
    });
    builder.addCase(fetchSubcategoriesApi.rejected, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchAdminApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchAdminApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.administrators = action.payload;
    });
    builder.addCase(fetchAdminApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
})




export default filterSlice.reducer