import { Box, LinearProgress, Tab, Tabs } from '@material-ui/core';
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import closeButton from '../../../assets/close-button.svg';
import chevronLeft from '../../../assets/arrows/chevron-left.svg';
import '../../../components/Cases/Cases.css';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import { fetchCaseById } from '../../../api/Case/Case';
import RatingsComponent from '../../../components/Cases/CaseDetailComponents/RatingsComponent';
import ImageViewer from 'react-simple-image-viewer';
import { useQuery } from '@tanstack/react-query';
import useTranslate from '../../../hooks/useTranslate';
import useAuth from '../../../hooks/useAuth';
import DetailsTab from '../../../components/Cases/CaseDetailComponents/DetailsTab';
import CaseChat from '../../../components/Cases/CaseDetailComponents/CaseChat';
import { DateTime } from 'luxon';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CommonDetailView({
  tabs,
  userDetails,
  setUserDetails,
  setActiveRow,
  role,
  caseStatus,
}) {
  const t = useTranslate();
  const [value, setValue] = useState(t('case'));
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [images, setImages] = useState([]);
  const { user } = useAuth();

  const {
    data: selectedCase,
    isLoading: selectedCaseLoading,
    isError: selectedCaseError,
  } = useQuery(
    [
      'selectedCase',
      user?.role_id !== 2 ? userDetails.problem_id : userDetails.id,
    ],
    fetchCaseById,
    {}
  );

  useEffect(() => {
    setImages(selectedCase?.images.map((image) => image.path));
  }, [selectedCase]);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-[35%]">
      <div className="flex flex-col ml-4 rounded-xl cases-box-shadow table-container">
        <div className="flex cursor-pointer justify-between case-detail-bottom-border">
          {role === 1 ? (
            <div className="flex py-4 px-6">
              <img
                src={
                  userDetails?.users_profile_image === null
                    ? PlaceholderImage
                    : userDetails?.users_profile_image
                }
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="user"
              />
              <div className="px-4">
                <p className="case-header-name font-medium">
                  {userDetails?.user_name} {userDetails?.user_last_name}
                </p>
                <p className="case-subheader-name font-normal text-sm">
                  {userDetails?.user_post_code} {userDetails?.user_street}
                </p>
              </div>
            </div>
          ) : role === 2 ? (
            <div className="flex py-4 px-6">
              <img
                src={
                  !userDetails?.user?.profile_image
                    ? PlaceholderImage
                    : userDetails?.user?.profile_image
                }
                className="w-10 h-10 rounded-full min-w-[40px] object-cover"
                alt="user"
              />
              <div className="px-4">
                <p className="case-header-name font-medium">
                  {userDetails?.user?.name} {userDetails?.user?.last_name}
                </p>
                <p className="case-subheader-name font-normal text-sm">
                  {userDetails?.user?.post_code} {userDetails?.user?.street}
                </p>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className="flex items-start pt-6 px-6">
            <img
              src={closeButton}
              height="12"
              width="12"
              onClick={() => {
                setUserDetails(null);
                setActiveRow(null);
              }}
            ></img>
          </div>
        </div>
        <div className="flex flex-row tab-header case-detail-bottom-border">
          <Box className="">
            <Tabs
              value={value}
              onChange={handleChange}
              TabIndicatorProps={{
                sx: { backgroundColor: '#2D12D6' },
              }}
            >
              {tabs.map((tab, index) => (
                <Tab value={tab} label={tab} key={index} />
              ))}
            </Tabs>
          </Box>
        </div>
        <TabPanel value={value} index={tabs[0]}>
          {selectedCaseLoading ? (
            <Box sx={{ width: '100%', padding: '20px 0' }}>
              <LinearProgress />
            </Box>
          ) : (
            <>
              {user?.role_id !== 2 && (
                <div className="flex flex-row justify-between case-detail-bottom-border items-center">
                  <p className="case-header-name text-left px-5 py-4 font-medium pr-2">
                    {caseStatus === 'ongoing' || caseStatus === 'completed'
                      ? t('bookedHomeVisit')
                      : t('suggestedTime')}
                  </p>
                  <p className="case-header-name font-normal text-right px-6 py-4 pl-2 flex flex-col">
                    {caseStatus === 'new' ? (
                      <>
                        {selectedCase?.problem?.time_slots ? (
                          <>
                            <span>
                              {selectedCase?.problem?.time_slots?.date_from} |{' '}
                              {DateTime.fromISO(
                                selectedCase?.problem?.time_slots?.time_from,
                                {
                                  zone: 'Europe/Stockholm',
                                }
                              ).toFormat('HH:mm')}
                            </span>
                            <span>
                              {selectedCase?.problem?.time_slots?.date_to} |{' '}
                              {DateTime.fromISO(
                                selectedCase?.problem?.time_slots?.time_to,
                                {
                                  zone: 'Europe/Stockholm',
                                }
                              ).toFormat('HH:mm')}
                            </span>
                          </>
                        ) : (
                          '-'
                        )}
                      </>
                    ) : caseStatus === 'ongoing' ||
                      caseStatus === 'completed' ? (
                      selectedCase?.problem?.accepted_appointment ? (
                        <>
                          <span>
                            {
                              selectedCase?.problem?.accepted_appointment
                                ?.time_slots?.date_from
                            }{' '}
                            |{' '}
                            {DateTime.fromISO(
                              selectedCase?.problem?.accepted_appointment
                                ?.time_slots?.time_from,
                              {
                                zone: 'Europe/Stockholm',
                              }
                            ).toFormat('HH:mm')}
                          </span>
                          <span>
                            {
                              selectedCase?.problem?.accepted_appointment
                                ?.time_slots?.date_to
                            }{' '}
                            |{' '}
                            {DateTime.fromISO(
                              selectedCase?.problem?.accepted_appointment
                                ?.time_slots?.time_to,
                              {
                                zone: 'Europe/Stockholm',
                              }
                            ).toFormat('HH:mm')}
                          </span>
                        </>
                      ) : (
                        '-'
                      )
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
              )}
              <div className="flex flex-col case-header-name font-medium px-5 py-4">
                <div className="flex flex-row pb-4">
                  <p>{selectedCase?.problem?.category?.parent?.name}</p>
                  <img src={chevronLeft} className="px-2" />
                  <p>{selectedCase?.problem?.category?.name}</p>
                </div>

                <div className="case-subheader-name font-normal text-sm">
                  <p className="max-w-xs">
                    {selectedCase?.problem?.description}
                  </p>
                </div>
                <div className="grid grid-cols-3">
                  {images?.map((image, index) => (
                    <img
                      className="pr-2 pt-2 object-fill h-[100px] w-[120px]"
                      src={image}
                      key={index}
                      onClick={() => openImageViewer(index)}
                      alt={image?.name}
                    />
                  ))}
                  {isViewerOpen && (
                    <ImageViewer
                      src={images}
                      currentIndex={currentImage}
                      disableScroll={false}
                      closeOnClickOutside={true}
                      onClose={closeImageViewer}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={tabs[1]}>
          <DetailsTab userDetails={userDetails} caseStatus={caseStatus} />
        </TabPanel>
        {tabs[2] && (
          <TabPanel value={value} index={tabs[2]}>
            {(user?.role_id === 1 && userDetails?.appointment_id !== null) ||
            (user?.role_id === 2 && userDetails?.appointments?.length > 0) ? (
              <CaseChat userDetails={userDetails} caseStatus={caseStatus} />
            ) : (
              t('noData')
            )}
          </TabPanel>
        )}
        {tabs[3] && (
          <TabPanel value={value} index={tabs[3]}>
            <RatingsComponent
              userDetails={userDetails}
              caseStatus={caseStatus}
            />
          </TabPanel>
        )}
      </div>
    </div>
  );
}
