import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../Common/Input';
import Button from '../Common/Button';
import ImageUpload from '../../containers/MainContentContainer/CommonImageUpload/ImageUpload';
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import CategoryDropdown from '../Dropdowns/CategoryDropdown';
import { ReactComponent as Minus } from '../../assets/Minus.svg';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import PlaceholderImage from '../../assets/placeholderImg.png';
import Modal from '../Common/Modal';
import CategoryModal from '../Organisms/CategoryModal';
import ErrorMessage from '../Common/ErrorMessage';
import DocumentUpload from '../Common/DocumentUpload';
import pdfIcon from '../../assets/images/pdf-icon.svg';
import {
  cleanCategories,
  removeCategory,
} from '../../helpers/Expert/filterExpertCategories';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createExpert, updateExpert } from '../../api/Experts/Experts';
import ExpertInputFields from './ExpertInputFields/ExpertInputFields';
import useTranslate from '../../hooks/useTranslate';
import { getAllCategoriesWithSubcategories } from '../../api/Categories/Categories';
import DocumentDeleteModal from '../Common/DocumentDeleteModal';
import { LinearProgress } from '@mui/material';
import DeleteUserModal from '../Common/DeleteUserModal';

const ExpertForm = ({ isCreate, expertData, expertLoading, isEdit }) => {
  const [categories, setCategories] = useState([]);
  const [newCategories, setNewCategories] = useState([expertData?.categories]);
  const [documentsList, setDocumentsList] = useState(
    expertData?.documents || []
  );
  const [toggleCreate, setToggleCreate, searchData] = useOutletContext();
  const location = useLocation();
  const { disabledform } = location.state != null ? location.state : false;

  const baseUrl = process.env.REACT_APP_BASE_URL.slice(0, -4);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteDocumentModal, setDeleteDocumentModal] = useState(false);
  const [deleteDocument, setDeleteDocument] = useState(null);
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [subCategoryModal, setSubCategoryModal] = useState(false);
  const [subParentId, setSubParentId] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [isEditing, setIsEditing] = useState(disabledform);
  const [isOpen, setIsOpen] = useState(false);
  let params = useParams();
  const id = params.id;
  const navigate = useNavigate();
  const t = useTranslate();
  const queryClient = useQueryClient();
  const inputFields = ExpertInputFields();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    reset,
    setValue,
    setError,
  } = useForm({ mode: 'all' });

  // Function for resetting fields
  const resetFields = () => {
    setValue('name', '');
    setValue('last_name', '');
    setValue('phone', '');
    setValue('email', '');
    setValue('password', '');
    setValue('profile_image', PlaceholderImage);
  };

  // Mutation for updating expert
  const { mutateAsync: updateExpertMutation, isLoading: updateExpertLoading } =
    useMutation(updateExpert, {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSearch']);
        queryClient.invalidateQueries(['expert']);
        setToggleCreate(false);
        setIsEditing(false);
        setNewCategories(expertData?.categories);
        setUploadDocuments([]);
      },
      onError: (error) => {
        if (
          error?.response?.status === 422 ||
          error?.response?.status === 409
        ) {
          setErrorMsg(t('emailTaken'));
        }
      },
    });

  // Mutation for creating expert
  const { mutateAsync: createExpertMutation, isLoading: createExpertLoading } =
    useMutation(createExpert, {
      onSuccess: () => {
        queryClient.invalidateQueries(['userSearch']);
        queryClient.invalidateQueries(['expert']);
        setToggleCreate(false);
        setIsEditing(false);
        setNewCategories(expertData?.categories);
      },
      onError: (error) => {
        if (error.response.status === 422 || error.response.status === 409) {
          setErrorMsg(t('emailTaken'));
        }
      },
    });

  const { data: allCategoriesData, isLoading: allCategoriesLoading } = useQuery(
    ['allCategories'],
    getAllCategoriesWithSubcategories,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (isCreate) {
          setCategories(data);
          setNewCategories(data);
        }
      },
    }
  );

  useEffect(() => {
    setValue('documents', uploadDocuments, { shouldDirty: true });
  }, [uploadDocuments]);

  useEffect(() => {
    if (isCreate) {
      setIsEditing(true);
      reset({});
      setDocumentsList([]);
      resetFields();
      setNewCategories(cleanCategories(allCategoriesData));
    } else {
      setCategories(expertData?.categories);
      setNewCategories(expertData?.categories);
      setDocumentsList(expertData?.documents);
      reset(expertData);
      setValue('profile_image', '', { shouldDirty: false });
      setValue('documents', [], { shouldDirty: false });
    }
  }, [expertData, isCreate, id]);

  // Function for handling submit functionality
  const submitHandler = (data) => {
    if (data.password !== '' && data.password.length >= 8) {
      if (data.password !== data.confirmPassword) {
        setError('password', {
          type: 'custom',
          message: t('passwordMustMatch'),
        });
        setError('confirmPassword', {
          type: 'custom',
          message: t('passwordMustMatch'),
        });
        return;
      }
    }

    let documentArray;
    if (data.documents === expertData?.documents) {
      documentArray = [];
    } else {
      documentArray = data.documents;
    }

    newCategories.forEach((cat) =>
      cat.subcategories.some((subC) => subC.assigned === true)
        ? (cat.assigned = true)
        : (cat.assigned = false)
    );

    if (isCreate) {
      createExpertMutation({
        ...data,
        categories: newCategories,
        documents: documentArray,
      });
      setUploadDocuments([]);
    } else {
      if (data.password !== '' && data.password.length < 8) {
        setError('password', {
          type: 'custom',
          message: t('passwordShort'),
        });
      } else if (isValid) {
        updateExpertMutation({
          ...data,
          categories: newCategories,
          documents: documentArray,
        });
        setUploadDocuments([]);
      }
    }
  };

  // function for setting subcategory add functionality
  const setSubContent = (id) => {
    setSubParentId(id);
    setSubCategoryModal(true);
    setIsOpen(true);
  };

  const handleDeleteModal = (document) => {
    setDeleteDocument(document);
    setDeleteDocumentModal(true);
  };

  return (
    <>
      {expertLoading && !isEditing ? (
        <div>{t('loading')}</div>
      ) : (
        <>
          <Modal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disableBackdrop
            className="px-4 py-8 w-full max-w-md"
          >
            <CategoryModal
              allCategoriesLoading={allCategoriesLoading}
              setIsOpen={setIsOpen}
              editableCategories={newCategories}
              setEditableCategories={setNewCategories}
              originCategories={categories}
              setSubCategoryModal={setSubCategoryModal}
              subCategoryModal={subCategoryModal}
              subParentId={subParentId}
              setSubParentId={setSubParentId}
            />
          </Modal>
          <form id="expert-form">
            <div className="items-center gap-3 flex mb-3">
              <label
                className={`block text-sm font-medium ${
                  !isEditing ? 'text-gray-400 ' : 'text-gray-700'
                } w-52`}
              >
                {t('image')}:
              </label>
              <div className="w-full">
                <ImageUpload
                  setValue={setValue}
                  valueName="profile_image"
                  editForm={isEditing}
                  currentImg={
                    isCreate
                      ? PlaceholderImage
                      : expertData?.profile_image
                      ? expertData?.profile_image
                      : PlaceholderImage
                  }
                />
              </div>
            </div>
            {inputFields.map((item, index) => {
              return (
                <Fragment key={index}>
                  <Input
                    register={register}
                    placeholder={item.placeholder}
                    type={item.type}
                    name={item.name}
                    label={item.label}
                    max={item.max}
                    pattern={item.pattern ? item.pattern : null}
                    required={item.required ? item.required : false}
                    disabled={!isEditing}
                    isError={item.error(errors)}
                    labelWidth="w-52"
                  />
                  <ErrorMessage>{item.error(errors)}</ErrorMessage>
                </Fragment>
              );
            })}
            {(isCreate || isEditing) && (
              <>
                <Input
                  register={register}
                  placeholder={t('password')}
                  type={'password'}
                  name={'password'}
                  label={t('password')}
                  max={20}
                  min={isCreate ? 8 : null}
                  minValidation={t('passwordShort')}
                  required={isCreate ? t('thisFieldIsRequired') : false}
                  disabled={!isEditing}
                  isError={errors?.password?.message}
                  labelWidth="w-52"
                />
                <ErrorMessage>{errors?.password?.message}</ErrorMessage>
                <Input
                  register={register}
                  placeholder={t('confirmPassword')}
                  type={'password'}
                  name={'confirmPassword'}
                  label={t('confirmPassword')}
                  max={20}
                  min={isCreate ? 8 : null}
                  minValidation={t('passwordShort')}
                  required={isCreate ? t('thisFieldIsRequired') : false}
                  disabled={!isEditing}
                  isError={errors?.password?.message}
                  labelWidth="w-52"
                />
                <ErrorMessage>{errors?.confirmPassword?.message}</ErrorMessage>
              </>
            )}

            <div className="flex gap-3 items-center mt-1">
              <div className="flex w-full ">
                <label
                  className={`block text-sm font-medium ${
                    !isEditing ? 'text-gray-400 ' : 'text-gray-700'
                  } w-52 mr-3`}
                >
                  {t('categories')}:
                </label>
                <div className="w-full flex flex-col gap-3">
                  {newCategories?.filter((cat) =>
                    cat?.subcategories?.some((subC) => subC.assigned)
                  ).length === 0 && !isEditing ? (
                    <span className="text-sm">{t('noCategories')}</span>
                  ) : (
                    newCategories
                      ?.filter((cat) =>
                        cat?.subcategories?.some((subC) => subC.assigned)
                      )
                      ?.map((item, index) => {
                        return (
                          <div key={index} className="flex items-center gap-4">
                            <CategoryDropdown
                              category={item}
                              data={item?.subcategories}
                              disabled={!isEditing}
                              setEditableCategories={setNewCategories}
                              setCategories={setNewCategories}
                              categoryIdx={index}
                              setIsOpen={setIsOpen}
                              setSubContent={setSubContent}
                            />
                            {isEditing && (
                              <Minus
                                className="w-5 cursor-pointer"
                                onClick={() => {
                                  setNewCategories(
                                    removeCategory(newCategories, item.id)
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })
                  )}
                  {isEditing && (
                    <div
                      className="flex items-center gap-2 text-textGray cursor-pointer w-fit"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <Plus className="w-5" />
                      <span>{t('addCategory')}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`flex ${!isEditing ? 'gap-3' : 'gap-2'} mt-4`}>
              <label
                className={`block text-sm font-medium w-52 mt-4 ${
                  !isEditing ? 'text-gray-400 ' : 'text-gray-700 '
                }`}
              >
                {t('documents')}:
              </label>
              <div className="w-full">
                {documentsList?.length > 0
                  ? documentsList?.map((document, index) => (
                      <span
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <a
                          href={baseUrl + '/storage/' + document?.path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            <img
                              src={pdfIcon}
                              className="inline-block py-2"
                              alt="pdf"
                            />
                            {document?.title}
                          </span>
                        </a>
                        {isEditing && (
                          <Minus
                            className="cursor-pointer float-right ml-4"
                            onClick={() =>
                              isEditing
                                ? handleDeleteModal(document)
                                : undefined
                            }
                          />
                        )}
                      </span>
                    ))
                  : !isEditing && (
                      <p className="text-sm pt-4">{t('noDocuments')}</p>
                    )}
                <DocumentUpload
                  editForm={isEditing}
                  setValue={setValue}
                  documentsList={documentsList}
                  setDocumentsList={setDocumentsList}
                  uploadDocuments={uploadDocuments}
                  setUploadDocuments={setUploadDocuments}
                  valueName="documents"
                />
                <DocumentDeleteModal
                  setDeleteDocumentModal={setDeleteDocumentModal}
                  deleteDocumentModal={deleteDocumentModal}
                  deleteDocument={deleteDocument}
                  setDeleteDocument={setDeleteDocument}
                  setUploadDocuments={setUploadDocuments}
                  setDocumentsList={setDocumentsList}
                />
              </div>
            </div>
            {errorMsg !== '' && (
              <div className="text-red-600 text-sm w-full my-4 text-right">
                {errorMsg}
              </div>
            )}
            {updateExpertLoading || createExpertLoading ? (
              <LinearProgress className="mt-12" />
            ) : (
              <div
                className={`flex w-full mt-12 ${
                  !isCreate ? 'justify-between' : 'justify-end'
                }`}
              >
                {!isCreate && (
                  <Button
                    type="button"
                    variant="transparent"
                    onClick={() => setDeleteModal(true)}
                  >
                    {t('delete')}
                  </Button>
                )}
                <div className="flex gap-5">
                  {isEditing ? (
                    <>
                      <Button
                        type="button"
                        variant="transparent"
                        onClick={() => {
                          setIsEditing(false);
                          if (isCreate) {
                            setToggleCreate(false);
                            navigate('/d/experts');
                          }
                          reset();
                          setToggleCreate(false);
                          setNewCategories(expertData?.categories);
                        }}
                      >
                        {t('cancel')}
                      </Button>
                      <Button
                        onClick={handleSubmit(submitHandler)}
                        form="expert-form"
                      >
                        {t('save')}
                      </Button>
                    </>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => {
                        setIsEditing(true);
                      }}
                    >
                      {t('edit')}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </form>
          <Modal
            isOpen={deleteModal}
            setIsOpen={setDeleteModal}
            className="p-4 max-w-md w-full"
          >
            <DeleteUserModal
              userDetails={expertData}
              setDeleteModal={setDeleteModal}
            />
          </Modal>
        </>
      )}
    </>
  );
};

export default ExpertForm;
