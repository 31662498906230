import { Fragment, useState } from "react";
import { TextField } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import useTranslate from "../../../hooks/useTranslate";

export default function ShowHidePassword({ name, register }) {
  const [passwordType, setPasswordType] = useState("password");
  const t = useTranslate();

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <Fragment>
      <TextField
        name={name}
        type={passwordType}
        autoComplete="off"
        variant="outlined"
        {...register(`${name}`, {
          required: {
            value: true,
            message: t('thisFieldIsRequired'),
          },
          minLength: {
            value: 8,
            message: t('passwordShort'),
          },
        })}
        fullWidth
        InputProps={{
          endAdornment: (
            <span className="cursor-pointer" onClick={togglePassword}>
              {passwordType === "password" ? (
                <VisibilityOffOutlinedIcon color="disabled" />
              ) : (
                <RemoveRedEyeOutlinedIcon />
              )}
            </span>
          ),
        }}
      />
    </Fragment>
  );
}
