import React, { useEffect, useState } from 'react';
import '../../Cases/Cases.css';
import './HomeVisitComponent.css';

import { Box, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import InfoComponent from './CaseDetailTabComponents/InfoComponent';
import OfferComponent from './CaseDetailTabComponents/OfferComponent';
import BillingComponent from './CaseDetailTabComponents/BillingComponent';
import useTranslate from '../../../hooks/useTranslate';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function HomeVisitComponent({
  userDetails,
  selectedCaseDetails,
  caseStatus,
}) {
  const t = useTranslate();
  const [value, setValue] = useState(t('info'));
  const tabs = [t('info'), t('offers'), t('bill')];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(t('info'));
  }, [selectedCaseDetails]);

  tabs[1] =
    caseStatus === 'new'
      ? t('newCaseOffer')
      : caseStatus === 'ongoing'
      ? t('ongoingCaseOffer')
      : t('offers');

  return (
    <>
      <div className="flex flex-row justify-between items-center py-4 px-6 title-background case-detail-bottom-border">
        <span className="case-header-name font-medium">{t('homeVisit')}</span>
        <span className="text-sm py-2 px-6 status-house">
          {userDetails?.dwelling_type
            ? userDetails?.dwelling_type === 'house'
              ? t('house')
              : userDetails?.dwelling_type === 'apartment'
              ? t('apartment')
              : userDetails?.dwelling_type
            : '-'}
        </span>
      </div>
      <div className="flex flex-row tab-header case-detail-bottom-border title-background">
        <Box className="">
          <Tabs
            textColor="secondary"
            indicatorColor="secondary"
            value={value}
            onChange={handleChange}
          >
            {tabs
              .filter((tab, index) => {
                if (caseStatus === 'new') {
                  return index !== 2;
                } else {
                  return tab;
                }
              })
              .map((tab, index) => (
                <Tab
                  value={tab}
                  label={tab}
                  key={index}
                  disabled={
                    tab === t('bill') &&
                    selectedCaseDetails?.appointments.length <= 0
                  }
                />
              ))}
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={tabs[0]}>
        <InfoComponent
          userDetails={userDetails}
          selectedCaseDetails={selectedCaseDetails}
        />
      </TabPanel>
      <TabPanel value={value} index={tabs[1]}>
        <OfferComponent
          userDetails={userDetails}
          caseStatus={caseStatus}
          selectedCaseDetails={selectedCaseDetails}
        />
      </TabPanel>
      <TabPanel value={value} index={tabs[2]}>
        <BillingComponent userDetails={userDetails} />
      </TabPanel>
    </>
  );
}
