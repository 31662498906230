import React from 'react';
import { FilterButton } from '../../containers/Filters/Filters';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useTranslate from '../../hooks/useTranslate';

export default function Support() {
  const t = useTranslate();
  return (
    <div className="flex flex-col justify-center content-center w-2/6 m-auto py-20 text-center">
      <h1 className="text-2xl">{t('needHelp')}</h1>
      <p className="text-slate-500 text-sm pt-4 pb-6">
        {t('contactSupportMessage')}
      </p>
      <FilterButton
        sx={{
          fontSize: '16px',
          borderRadius: '4px',
          margin: 'auto !important',
        }}
        onClick={() => (window.location = 'https://mendy.se/kontakt')}
      >
        <span className="normal-case text-xl px-8">
          <MailOutlineIcon className="mr-2" />
          {t('emailSupport')}
        </span>
      </FilterButton>
    </div>
  );
}
