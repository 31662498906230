import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useTranslate from "../../../hooks/useTranslate";
import Button from "../../Common/Button";
import Input from "../../Common/Input";
import TextArea from "../../Common/TextArea";

const DenialInfo = ({ companyDetails }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { register, reset } = useForm();
  const t = useTranslate();

  useEffect(() => {
    reset({
      description: companyDetails?.description,
      reason: companyDetails?.denied_reasons[0]?.text,
    });
  }, [companyDetails]);

  return (
    <div className="self-stretch">
      <form className="w-full flex flex-col h-full">
        <Input
          type="text"
          name="reason"
          placeholder={`${t("reason")}...`}
          register={register}
          required={true}
          label={`${t("reasonForDenial")}:`}
          labelWidth="w-44"
          readOnly={!isEdit}
        />
        <div className="flex items-center gap-3 w-full">
          <label
            className={"block text-left text-sm font-medium text-gray-700 w-44"}
          >
            {t("detailedDescription")}:
          </label>
          <TextArea
            type="text"
            name="description"
            placeholder={`${t("description")}...`}
            required={true}
            register={register}
            readOnly={!isEdit}
          />
        </div>
        <div className="ml-auto gap-3 mt-40 flex">
         {isEdit && <Button
         variant="transparent"
            onClick={() => {
              setIsEdit(false);
              reset({
                description: companyDetails?.description,
                reason: companyDetails?.denied_reasons[0]?.text,
              });
            }}
          >
            {t("cancel")}
          </Button>}
          <Button
            onClick={() => {
              setIsEdit(true);
            }}
            className="border-2 border-transparent"
          >
            {t("edit")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default DenialInfo;
