import React from 'react';
import { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';

const SingleSubCategory = ({ setTempCategory, subCategory }) => {
  const [isChecked, setIsChecked] = useState();

  const updateCategory = (id) => {
    setTempCategory((prev) => {
      prev.subcategories.forEach((subCategory) => {
        if (subCategory.id === id)
          subCategory.assigned = isChecked ? false : true;
      });
      return prev;
    });

    setIsChecked(!isChecked);
  };

  return (
    <div key={subCategory.id}>
      <div
        className=" flex w-full px-20 items-center py-6 cursor-pointer transition-all"
        key={subCategory.id}
      >
        <FetchCategoryIcon
          name={subCategory?.icon}
          strokeColor={'strokePurple'}
        />
        <span className="font-poppins font-medium text-tablePrimary ml-3">
          {subCategory.name}
        </span>
        <div className="ml-auto">
          <Checkbox
            className=""
            onChange={() => {
              updateCategory(subCategory.id);
            }}
          />
        </div>
      </div>
      <hr className="h-px bg-slate-50 mx-16" />
    </div>
  );
};

export default SingleSubCategory;
