import { useForm } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRegisterApi } from '../../features/registerSlice';
import { useEffect } from 'react';
import { registerCompany } from '../../api/Register/Register';
import { useMutation } from '@tanstack/react-query';
import Button from '../Common/Button';

const RegisterComponentPart4 = ({ formData, setFormData, setValue }) => {
  const {
    register,
    getValues,
    setError,
    clearErrors,
    getFieldState,
    trigger,
    formState: { errors },
  } = useForm();

  const t = useTranslate();

  const {
    mutateAsync: registerCompanyMutation,
    isLoading: registerCompanyLoading,
  } = useMutation(registerCompany, {
    onSuccess: () => {
      setValue(5);
    },
    onError: () => {
      setError('global', {
        message: t('registrationFailed'),
      });
    },
  });

  const goToNext = async () => {
    clearErrors('global');
    await trigger(['password', 'passwordConfirm']);

    if (getValues('password') !== getValues('passwordConfirm')) {
      setError('passwordConfirm', {
        type: 'required',
        message: t('passwordMustMatch'),
      });
      return;
    }
    if (
      !getFieldState('password').invalid &&
      !getFieldState('passwordConfirm').invalid
    ) {
      if (formData.form_valid === true) {
        const {
          companyName,
          organisationalNumber,
          companyEmail,
          companyPhone,
          companyAddress,
          adminName,
          adminLastName,
          adminPhone,
          adminEmail,
          password,
        } = formData;

        registerCompanyMutation({
          company_name: companyName,
          code: organisationalNumber,
          company_phone: companyPhone,
          company_email: companyEmail,
          company_address: companyAddress,
          name: adminName,
          last_name: adminLastName,
          phone: adminPhone,
          email: adminEmail,
          password: password,
        });
      }

      //here should add if response ok redirect to succes if error show error screen
    }
  };
  const goToPrevious = () => {
    clearErrors('global');
    setValue(2);
  };

  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('createPassword')}
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('choosePassword')}
          </p>

          <div>
            <label htmlFor="password" className="text-sm">
              {t('password')}
            </label>
            <input
              type="password"
              name="password"
              className="w-full p-2 text-primary border rounded-md outline-none transition duration-150 ease-in-out mb-1 text-base"
              id="password"
              placeholder={t('enter') + ' ' + t('password')}
              autoComplete="on"
              style={{ color: '#13044F' }}
              {...register('password', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 8,
                  message: t('passwordShort'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  password: e.target.value,
                });
              }}
              value={formData.password}
            />
            {errors.password && (
              <span className="text-red-600 text-sm">
                {errors.password?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="password_confirm" className="text-sm">
              {t('repeatPassword')}
            </label>
            <input
              type="password"
              name="passwordConfirm"
              className="w-full p-2 text-primary border rounded-md outline-none transition duration-150 ease-in-out mb-1 text-base"
              id="passwordConfirm"
              placeholder={t('repeatPassword')}
              autoComplete="on"
              style={{ color: '#13044F' }}
              {...register('passwordConfirm', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 8,
                  message: t('passwordShort'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  passwordConfirm: e.target.value,
                });
              }}
              value={formData.passwordConfirm}
            />

            {errors.passwordConfirm && (
              <span className="text-red-600 text-sm">
                {errors.passwordConfirm?.message}
              </span>
            )}
            {errors.global && (
              <span className="text-red-600 text-sm">
                {errors.global?.message}
              </span>
            )}
          </div>

          <div className="flex justify-end items-center mt-6 gap-4">
            <Button onClick={goToPrevious} variant="transparent">
              {t('previous')}
            </Button>
            <Button type="submit" onClick={goToNext}>
              {t('finish')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComponentPart4;
