import * as React from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import './Video.css';
import { useRef } from 'react';
import { ReactComponent as Microhpone } from '../../assets/videoCall/Microphone.svg';
import { ReactComponent as MicrohponeMuted } from '../../assets/videoCall/MicOff.svg';
import { ReactComponent as Camera } from '../../assets/videoCall/Camera.svg';
import { ReactComponent as CameraMuted } from '../../assets/videoCall/CameraOff.svg';
import { ReactComponent as Hangup } from '../../assets/AbandonCall.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import history from '../../helpers/customRoutes/history';
import Modal from '../Common/Modal';
import Button from '../Common/Button';
import useTranslate from '../../hooks/useTranslate';
import {
  sendCallNotification,
  sendExpiredNotification,
} from '../../api/VideoCalls/Notification';
import PlaceholderImage from '../../assets/placeholderImg.png';

const APP_ID = '755629c7e15d49b3a832f72471d83bf0';

const defaultUser = {
  profile_image:
    'https://staging-mendy-admin.zendev.se/storage/user/81/profile_images/user81.jpeg',
  user_name: 'Adna Adnasson',
};
const TestCall = () => {
  const [engine, setEngine] = React.useState(null);
  const t = useTranslate();
  const engineRef = useRef(null);
  const [remoteAudioMuted, setRemoteAudioMuted] = React.useState(false);
  const [remoteVideoMuted, setRemoteVideoMuted] = React.useState(false);
  const [localAudioMuted, setLocalAudioMuted] = React.useState(false);
  const [localVideoMuted, setLocalVideoMuted] = React.useState(false);
  const [localVideoTrack, setLocalVideoTrack] = React.useState(null);
  const [localAudioTrack, setLocalAudioTrack] = React.useState(null);
  const localVideoTrackRef = useRef();
  const localAudioTrackRef = useRef();
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [userJoined, setUserJoined] = React.useState(false);
  const location = useLocation();
  const { room_name, user_name, user_image } = location?.state?.callContent;
  console.log(location.state.callContent);
  const localContainer = useRef(null);
  const remoteContainer = useRef(null);
  React.useEffect(() => {
    InitializeClient();
    return () => {
      engineRef?.current?.leave();
      engineRef?.current?.unsubscribe();
      DestroyClient();
    };
  }, []);
  React.useEffect(() => {
    console.log(localContainer.current);
  }, [localContainer]);
  const InitializeClient = async () => {
    const eng = await AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    engineRef.current = eng;
    console.log('Engine created');
    eng.on('user-published', async (user, mediaType) => {
      console.log('USER VIDEO', user.videoTrack);
      console.log('USER AUDIO', user.videoTrack);
      await eng.subscribe(user, mediaType);
      console.log('subscribe success');
      console.log('Media TYPE JE ', mediaType);

      if (mediaType === 'video') {
        console.log('video unmuted');
        setRemoteVideoMuted(false);
        user.videoTrack.play(remoteContainer.current);
      }
      if (mediaType === 'audio') {
        console.log('audio unmuted');
        setRemoteAudioMuted(false);
        user.audioTrack.play();
      }
    });
    eng.on('user-unpublished', (user, mediaType) => {
      console.log('Audio', user.audioTrack);
      console.log('Video', user.videoTrack);
      if (user.audioTrack === undefined) setRemoteAudioMuted(true);
      if (user.videoTrack === undefined) setRemoteVideoMuted(true);
    });
    eng.on('user-left', (user, reason) => {
      console.log('User left', user, reason);
      localVideoTrackRef.current?.close();
      localAudioTrackRef.current?.close();
      history.replace('/work-description', location?.state?.callContent);
    });
    eng.on('user-joined', (user) => {
      setUserJoined(true);
    });
    joinChannel(eng);
  };
  const joinChannel = async (eng) => {
    await eng.join(APP_ID, room_name, null);
    console.log(room_name);
    const audio = await AgoraRTC.createMicrophoneAudioTrack();
    const video = await AgoraRTC.createCameraVideoTrack();
    setLocalAudioTrack(audio);
    setLocalVideoTrack(video);
    localVideoTrackRef.current = video;
    localAudioTrackRef.current = audio;
    await eng?.publish([audio, video]);
    video.play(localContainer.current);
  };
  const leaveChannel = async () => {
    localVideoTrack?.close();
    localAudioTrack?.close();
    if (!userJoined && location.state.callContent.user_id !== undefined) {
      sendExpiredNotification({ user_id: location.state.callContent.user_id });
      sendCallNotification(location.state.callContent.user_id, {
        type: 'call_ended',
      });
    }
    await engineRef.current?.leave();
    if (userJoined)
      history.replace('/work-description', location?.state?.callContent);
    else history.replace('/d/cases/ongoing-cases');
    setConfirmModal(false);
    console.log('You left the channel');
  };
  const handleAudioMute = () => {
    if (localAudioMuted) {
      engineRef.current?.publish(localAudioTrack);
      setLocalAudioMuted(false);
    } else {
      engineRef.current?.unpublish(localAudioTrack);
      setLocalAudioMuted(true);
    }
  };
  const handleVideoMute = async () => {
    if (localVideoMuted) {
      await engineRef.current?.publish(localVideoTrack);
      localVideoTrack.play(localContainer.current);
      localVideoTrack.setMuted(false);
      setLocalVideoMuted(false);
    } else {
      await engineRef.current?.unpublish(localVideoTrack);
      localVideoTrack.setMuted(true);
      setLocalVideoMuted(true);
    }
  };
  const DestroyClient = () => {
    setRemoteAudioMuted(false);
    setRemoteVideoMuted(false);
    setLocalAudioMuted(false);
    setLocalVideoMuted(false);
    setLocalVideoTrack(null);
    localVideoTrackRef.current = null;
    localAudioTrackRef.current = null;
    setLocalAudioTrack(null);
    setUserJoined(false);
    localContainer.current = null;
    remoteContainer.current = null;
  };
  return (
    <div className="video-page-container">
      {remoteAudioMuted && <MicrohponeMuted className="remote-audio-muted" />}
      {remoteVideoMuted && <CameraMuted className="remote-video-muted" />}
      <div className="video-call-container">
        {userJoined ? (
          <div className="video remote-video" ref={remoteContainer} />
        ) : (
          <div className="calling-user bg-slate-800">
            <div className="pulse">
              <img
                src={user_image || PlaceholderImage}
                className="user-avatar"
                alt="user-profile"
              />
            </div>
            <div className="text-white mt-10">{user_name}</div>
            <div className="text-white mt-5">{t('calling')}</div>
          </div>
        )}
        <div
          className={(localVideoMuted ? `hidden` : ``) + ` video local-video`}
          ref={localContainer}
        />
      </div>
      <div className="controls-container">
        {localAudioMuted ? (
          <MicrohponeMuted
            height="40"
            className="local-control"
            onClick={handleAudioMute}
          />
        ) : (
          <Microhpone
            height="40"
            className="local-control"
            onClick={handleAudioMute}
          />
        )}
        {localVideoMuted ? (
          <CameraMuted
            height="40"
            className="local-control"
            onClick={handleVideoMute}
          />
        ) : (
          <Camera
            height="40"
            className="local-control"
            onClick={handleVideoMute}
          />
        )}
        <Hangup
          height="40"
          className="hangup-btn"
          onClick={() => setConfirmModal(true)}
        />
        <Modal
          isOpen={confirmModal}
          setIsOpen={setConfirmModal}
          className="max-w-md p-4"
        >
          <div className="case-header-name font-medium w-[60%] text-center mx-auto">
            <span>{t('areYouSure')}</span>
          </div>
          <div className="flex gap-4 mt-4">
            <Button className="flex-1" onClick={() => setConfirmModal(false)}>
              {t('no')}
            </Button>
            <Button className="flex-1" onClick={leaveChannel}>
              {t('yes')}
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default TestCall;
