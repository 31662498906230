import phones2 from "../../assets/images/phones2.png";

const LandingContentPart5 = () => {
  return (
    <div className="flex h-full mb-20 sm:mb-36 custom-container flex-col lg:flex-row  mt-20 sm:mt-16 ">
      <div className="w-full lg:w-3/6 mt-2 pt-2 lg:pt-52 lg:pl-40 lg:pr-9 pl-8 pr-8">
        <h3 className="mb-8" style={{ color: "#13044F", fontSize: "32px" }}>
          4. Du är klar
        </h3>
        <p
          className="opacity-70"
          style={{ color: "#13044F", fontSize: "18px" }}
        >
          Ditt ärende är åtgärdat eller så får du en problembeskrivning av ditt
          ärende för att du ska kunna boka en certifierad hantverkare.
        </p>
      </div>
      <div className="w-full lg:w-3/6 mt-2">
        {/* <div style={{backgroundImage: "url(" + elipse59 + ")", position:"absolute",right:"-140px",top:"-100px",width:"1000px",height:"1000px",borderRadius:"50%"}}></div> */}
        <div className="text-center pt-10 lg:pt-20 lg:pt-30 relative">
          <img
            id="phones1"
            className="logo inline-block px-8 sm:px-0 lg:-ml-11"
            src={phones2}
            style={{ maxWidth: "700px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart5;
