import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AdminForm from "../../Common/AdminForm";
import { fetchAdminByIdApi } from "../../../features/admin/getAdminByIdSlice";
import { updateAdminByIdApi } from "../../../features/admin/putAdminByIdSlice";
import { Box, LinearProgress } from "@mui/material";
import useTranslate from "../../../hooks/useTranslate";
import Button from "../../Common/Button";

const AdminFormTab = ({ user }) => {
  const [editForm, setEditForm] = useState(false);
  const admin = useSelector((state) => state.getAdminById.admin);
  const updateStatus = useSelector((state) => state.putAdminById.status);
  const status = useSelector((state) => state.getAdminById.status);
  const dispatch = useDispatch();
  const t = useTranslate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isDirty, isValid },
  } = useForm({ defaultValues: admin, mode: "onChange" });

  useEffect(() => {
    if (admin) {
      reset(admin);
    }
  }, [admin]);

  useEffect(() => {
    if (user[0]) {
      dispatch(fetchAdminByIdApi({ id: user[0]?.id }));
    } else {
      reset(null);
    }
  }, [user]);

  const onSubmit = (data) => {
    if (user[0]) {
      if (isDirty && isValid) {
        dispatch(updateAdminByIdApi({ id: data.id, data })).then(() => {
          dispatch(fetchAdminByIdApi({ id: user[0]?.id }));
        });
      }
    }
  };

  return (
    <Fragment>
      {status === "loading" ? (
        <Box sx={{ width: "100%", padding: "20px 0" }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <AdminForm
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            register={register}
            user={admin}
            errors={errors}
            setValue={setValue}
            reset={reset}
            isDirty={isDirty}
            isValid={isValid}
            editForm={editForm}
            formName="admin-form"
          />
          <div className="flex mt-16 px-4 pt-10">
            <div className="flex justify-start w-full items-center">
              {updateStatus === "loading" ? (
                <Box sx={{ width: "100%", padding: "20px 0" }}>
                  <LinearProgress />
                </Box>
              ) : (
                <span className="border-linear rounded-md p-0.5 ml-auto">
                  <Button
                    disabled={editForm && !isValid}
                    form="admin-form"
                    type="submit"
                    name="edit"
                    onClick={
                      !editForm
                        ? () => setEditForm(true)
                        : () => setEditForm(false)
                    }
                  >
                    {!editForm ? t("edit") : t("save")}
                  </Button>
                </span>
              )}
            </div>
          </div>
        </>
      )}
    </Fragment>
  );
};

export default AdminFormTab;
