import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../Card';
import HorizontalBarChart from './HorizontalBarChart';
import DataDisplay from './DataDisplay';
import { useQuery } from '@tanstack/react-query';
import {
  expertCaseStatus,
  handymanCaseStatus,
  urgentCasesStatuses,
  videoStatus,
} from '../../../api/Dashboard/Dashboard';
import SuperProfitChart from './SuperProfitChart';

const SuperAdminDashboard = () => {
  const { t } = useTranslation();
  const [totalProfit, setTotalProfit] = useState('');

  const {
    data: expertData,
    isLoading: expertDataLoading,
    isError: expertDataError,
  } = useQuery(['dashboardExpertData'], expertCaseStatus);

  const {
    data: handymanData,
    isLoading: handymanDataLoading,
    isError: handymanDataError,
  } = useQuery(['dashboardHandymanData'], handymanCaseStatus);

  const {
    data: urgentCaseData,
    isLoading: urgentCaseDataLoading,
    isError: urgentCaseDataError,
  } = useQuery(['dashboardUrgentCases'], urgentCasesStatuses);

  const {
    data: videoHomeData,
    isLoading: videoHomeDataLoading,
    isError: videoHomeDataError,
  } = useQuery(['dashboardVideoHome'], videoStatus);

  if (
    expertDataError ||
    handymanDataError ||
    urgentCaseDataError ||
    videoHomeDataError
  )
    return <div>{t('error')}</div>;

  return (
    <div>
      {expertDataLoading ||
      handymanDataLoading ||
      urgentCaseDataLoading ||
      videoHomeDataLoading ? (
        <div>{t('loading')}</div>
      ) : (
        <>
          <div className="flex gap-3">
            <Card
              title={t('experts')}
              title2={t('handymen')}
              width={'w-[800px]'}
            >
              <HorizontalBarChart data={expertData} />
              <HorizontalBarChart data={handymanData} />
            </Card>
            <div>
              <Card width="w-fit" height={'h-[180px]'}>
                <DataDisplay
                  data={urgentCaseData?.nonUrgentCasesOlderThan24Hours}
                  description={t('nonUrgentOlder24h')}
                />
                <DataDisplay
                  data={urgentCaseData?.urgentCasesOlderThan2Hours}
                  description={t('urgentOlder2h')}
                />
                <DataDisplay
                  data={urgentCaseData?.noneUrgentMissedCases}
                  description={t('numberNonUrgentMissedCases')}
                />
              </Card>
              <Card title={t('cases')} width="w-fit">
                <DataDisplay
                  data={videoHomeData?.videoCalls}
                  description={t('videoCalls')}
                />
                <DataDisplay
                  data={videoHomeData?.homeVisits}
                  description={t('homeVisits')}
                />
              </Card>
            </div>
          </div>
          <div className="flex gap-3">
            <Card
              width={'w-1/2'}
              title="Profit"
              title2={totalProfit + ' SEK'}
              height={'h-full'}
            >
              <div className="w-full p-10 h-96">
                <SuperProfitChart setTotalProfit={setTotalProfit} />
              </div>
            </Card>
          </div>{' '}
        </>
      )}
    </div>
  );
};

export default SuperAdminDashboard;
