import React from 'react';
import { ReactComponent as AbandonCall } from '../../../../assets/AbandonCall.svg';
import useAuth from '../../../../hooks/useAuth';
import useTranslate from '../../../../hooks/useTranslate';
import PlaceholderImage from '../../../../assets/placeholderImg.png';

const CallRinging = ({ setModalOpen, setDisableOverlay, problem }) => {
  const { client } = useAuth();
  const t = useTranslate();

  return (
    <div className="bg-violet-700 w-full h-full flex flex-col items-center justify-center p-24">
      <div
        className="h-32 w-32 rounded-full after:content-[''] after:bg-slate-200 after:animate-[ping_2s_ease-in-out_infinite]
       after:absolute relative after:w-full after:h-full after:-z-10 after:rounded-full isolate after:inset-0 after:opacity-40"
      >
        <img
          src={
            problem?.user?.profile_image === null
              ? PlaceholderImage
              : problem?.user?.profile_image
          }
          alt="user"
          className="w-full h-full rounded-full "
        />
      </div>
      <span className="font-poppins mt-5 text-white inline-block">
        {problem?.user?.name + ' ' + problem?.user?.last_name}
      </span>
      <span className="text-xs font-poppins text-gray-400 inline-block">
        {t('calling')}
      </span>
      <AbandonCall
        className="mt-24 cursor-pointer"
        onClick={() => {
          client.cancelLocalInvitation();
          setModalOpen(false);
          setDisableOverlay(false);
        }}
      />
    </div>
  );
};

export default CallRinging;
