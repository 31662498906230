import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import PageHeading from '../../components/Organisms/PageHeading';
import useTranslate from '../../hooks/useTranslate';

const HandymenPage = () => {
  const [createToggle, setCreateToggle] = useState(false);
  const [searchDataLoading, setSearchDataLoading] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  const t = useTranslate();

  return (
    <div className="w-full">
      <PageHeading
        userRole={4}
        title={t('handymen')}
        addButtonName={`+ ${t('add')} ${t('handyman')}`}
        hasSearch
        buttonClick={() => {
          setCreateToggle(true);
          navigate('/d/handymen');
        }}
        setSearchData={setSearchData}
        setSearchDataLoading={setSearchDataLoading}
      />
      <Outlet
        context={[createToggle, setCreateToggle, searchData, searchDataLoading]}
      />
    </div>
  );
};

export default HandymenPage;
