import { LinearProgress, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyFaqApi } from "../../features/settings/getCompanyFaqSlice";
import useAuth from "../../hooks/useAuth";
import useTranslate from "../../hooks/useTranslate";

export default function Faq() {
  const faqList = useSelector((state) => state.getCompanyFaq.faqList);
  const status = useSelector((state) => state.getCompanyFaq.status);
  const { user } = useAuth();
  const t = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCompanyFaqApi({ id: user.company_id }));
  }, []);
  return (
    <div>
      {status === "loading" ? (
        <Box sx={{ width: "100%", padding: "20px 0" }}>
          <LinearProgress />
        </Box>
      ) : faqList?.length > 0 ? (
        faqList?.map((item, index) => (
          <div
            key={index}
            className="border-b border-slate-800/[.1] py-6 px-6 last:border-0"
          >
            <p className="text-slate-900 font-medium">{item?.question}</p>
            <p className="text-slate-500 py-4">{item?.answer}</p>
          </div>
        ))
      ) : (
        <span className="text-2xl text-slate-900 flex justify-center p-8">{t('noData')}</span>
      )}
    </div>
  );
}
