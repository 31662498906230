import { Rating } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { postReview } from '../../../../api/VideoCalls/Reviews';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';

const UserRating = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const t = useTranslate();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const { state } = useLocation();
  const problem_id = state.problem_id;
  const user_id = state.user_id;
  const expert_id = state.expert_id;
  const { mutateAsync: submitUserReview } = useMutation(postReview, {
    onSuccess: () => {
      queryClient.invalidateQueries(['expertOngoingProblems']);
      navigate('/d/cases/ongoing-cases');
    },
  });

  const submitHandler = ({ description }) => {
    submitUserReview({
      rating: value,
      review: description,
      problem_id: problem_id,
      giver_id: expert_id,
      receiver_id: user_id,
    });
  };

  return (
    <div className="flex flex-col font-poppins w-6/12 m-auto w-96 p-5 py-8 items-center justify-center">
      <span className="text-3xl violetPrimary mb-6">{t('rateTheUser')}</span>
      <Rating
        name="simple-controlled"
        value={value}
        size="large"
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className="mb-6"
      />
      <div className="flex flex-col w-full px-4 h-64">
        <span className="text-xs text-gray-400">
          {t('whatCanYouSayAboutThisUser')}
        </span>
        <form>
          <textarea
            defaultValue=""
            placeholder={`${t('description')}...`}
            className="shadow-sm focus:ring-indigo-500 border focus:border-indigo-500 block
            w-full sm:text-sm rounded-md py-2 px-2 outline-none mb-2 mt-2 h-full self-stretch"
            {...register('description')}
            name="description"
          />
          <ErrorMessage>{errors?.description?.message}</ErrorMessage>
          <Button
            className="mt-3 w-full"
            type="button"
            onClick={handleSubmit(submitHandler)}
          >
            {t('finish')}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default UserRating;
