import { Navigate, Routes, Route } from 'react-router-dom';
import NewCases from '../../components/Cases/NewCases/NewCases';
import OngoingCases from '../../components/Cases/OngoingCases/OngoingCases';
import FinishedCases from '../../components/Cases/FinishedCases/FinishedCases';
import Dashboard from '../../components/Dashboard';
import ActiveCompanies from '../../components/Companies/ActiveCompanies/ActiveCompanies';
import WaitingCompanies from '../../components/Companies/WaitingCompanies/WaitingCompanies';
import DeniedCompanies from '../../components/Companies/DeniedCompanies/DeniedCompanies';
import SupportProgress from '../../components/Support/SupportProgress/SupportProgress';
import Experts from '../../components/Experts/Experts';
import ExpertEdit from '../../components/Experts/ExpertEdit';
import ExpertCases from '../../components/Experts/ExpertCases';
import Handymen from '../../components/Handymen/Handymen';
import HandymanEdit from '../../components/Handymen/HandymanInfo/HandymanEdit';
import HandymanCases from '../../components/Handymen/HandymanInfo/HandymanCases';
import Admin from '../../components/Admin/Admin';
import Settings from '../../components/Settings/Settings';
import useAuth from '../../hooks/useAuth';
import ExpertsPage from '../../pages/experts/ExpertsPage';
import HandymenPage from '../../pages/handymen/HandymenPage';
import UsersPage from '../../pages/users/UsersPage';
import UsersList from '../../components/Users/UsersList';
import CompaniesPage from '../../pages/companies/CompaniesPage';
import CasesPage from '../../pages/cases/CasesPage';
import ExpertFinishedCases from '../../components/Experts/ExpertAdmin/CaseTable/ExpertFinishedCases';
import ExpertNewCases from '../../components/Experts/ExpertAdmin/CaseTable/ExpertNewCases';
import ExpertAcceptedCases from '../../components/Experts/ExpertAdmin/CaseTable/ExpertAcceptedCases';
import ExpertNonUrgentCases from '../../components/Experts/ExpertAdmin/CaseTable/ExpertNonUrgentCases';

const MainContentContainer = () => {
  const { user } = useAuth();

  return (
    <div className="outside-container-background mt-10">
      <div className="px-4 pl-56 py-2 main-container-background">
        <Routes>
          {user.role_id === 3 ? (
            <Route>
              <Route path="cases" element={<CasesPage />}>
                <Route index path="new-cases" element={<ExpertNewCases />} />
                <Route
                  path="new-nonUrgent-cases"
                  element={<ExpertNonUrgentCases />}
                />
                <Route path="ongoing-cases" element={<ExpertAcceptedCases />} />
                <Route
                  path="finished-cases"
                  element={<ExpertFinishedCases />}
                />
              </Route>
            </Route>
          ) : user.role_id === 1 ? (
            <Route>
              <Route path="companies" element={<CompaniesPage />}>
                <Route index path="active" element={<ActiveCompanies />} />
                <Route path="waiting" element={<WaitingCompanies />} />
                <Route path="denied" element={<DeniedCompanies />} />
              </Route>
              <Route path="experts" element={<ExpertsPage />}>
                <Route index element={<Experts />} />
                <Route path="add" element={<Experts />} />
                <Route path="edit/:id" element={<Experts />} />
                <Route path="cases" element={<ExpertCases />} />
              </Route>
              <Route path="users" element={<UsersPage />}>
                <Route index element={<UsersList />} />
              </Route>
              <Route path="support-progress" element={<SupportProgress />} />
            </Route>
          ) : (
            <Route>
              <Route path="admin" element={<Admin />} />
              <Route path="handymen" element={<HandymenPage />}>
                <Route index element={<Handymen />} />
                <Route path="info/:id" element={<HandymanEdit />} />
                <Route path="cases" element={<HandymanCases />} />
              </Route>
            </Route>
          )}
          {user.role_id !== 3 && (
            <Route>
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="cases" element={<CasesPage />}>
                <Route path="new-cases" element={<NewCases />} />
                <Route path="ongoing-cases" element={<OngoingCases />} />
                <Route path="finished-cases" element={<FinishedCases />} />
              </Route>
              <Route path="settings" element={<Settings />} />
              <Route path="*" element={<Navigate to="/d/dashboard" />} />
            </Route>
          )}
        </Routes>
      </div>
    </div>
  );
};

export default MainContentContainer;
