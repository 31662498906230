export const getExpertCategories = (categories) => {
  let newArray = [];
  categories?.forEach((category) => {
    let cat = { ...category };
    let subClone = [...category.subcategories];
    let subArray = subClone.filter((subC) => subC.assigned);
    cat.subcategories = subArray;
    if (subArray.length > 0) {
      newArray.push(cat);
    }
  });
  return newArray;
};

export const removeCategory = (categories, id) => {
  let newArray = [];
  categories.forEach((category) => {
    if (category.id === id) {
      category.assigned = false;
      category.subcategories.forEach((subC) => (subC.assigned = false));
    }
    newArray.push(category);
  });
  return newArray;
};

export const addCategory = (categories, category) => {
  let newArray = [];
  if (categories.some((c) => c.id === category.id)) {
    categories.forEach((c) => {
      if (c.id !== category.id) newArray.push(c);
    });
    newArray.push(category);
    return newArray;
  } else {
    return (newArray = [...categories, category]);
  }
};

export const removeSubCategory = (categories, subCategoryId) => {
  let newArray = [];

  categories.forEach((category) => {
    let cat = { ...category };
    cat.subcategories.forEach((subC) => {
      if (subC.id === subCategoryId) subC.assigned = false;
    });
    newArray.push(cat);
  });

  return newArray;
};

export const cleanCategories = (categories) => {
  let newArray = [];
  categories?.forEach((category) => {
    let cat = { ...category };
    cat.subcategories.forEach((subC) => (subC.assigned = false));
    newArray.push(cat);
  });
  return newArray;
};

export const getCompanyCategories = (categories) => {
  let newArray = [];
  categories?.forEach((category) => {
    let cat = { ...category };
    let subClone = [...category.subcategories];
    let subArray = subClone.filter((subC) => subC.assigned);
    cat.subcategories = subArray;
    if (subArray.length > 0) {
      newArray.push(cat);
    }
  });
  return newArray;
};
