import { useState } from 'react';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import '../Companies.css';

import CompanyDetails from '../CompanyInfo/CompanyDetails';
import { useQuery } from '@tanstack/react-query';
import { fetchCompaniesActive } from '../../../api/Company/Company';
import ActiveCompaniesTable from './ActiveCompaniesTable';
import useTranslate from '../../../hooks/useTranslate';
import Modal from '../../Common/Modal';
import DeleteModal from '../../Admin/Modals/DeleteModal';
import { Box, LinearProgress } from '@mui/material';

const ActiveCompanies = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const [value, setValue] = useState(t('companyInfo'));

  const header = [
    t('company'),
    t('organisationalNumber'),
    t('adminContact'),
    t('handymen'),
    '',
  ];
  const tabs = [t('companyInfo'), t('adminInfo'), t('handymen')];

  const {
    data: activeCompanies,
    isLoading: activeCompaniesLoading,
    isError,
  } = useQuery(['activeCompanies'], fetchCompaniesActive);

  const dataPreview = (
    rowsPerPage > 0
      ? activeCompanies?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : activeCompanies
  )?.map((row, index) => (
    <ActiveCompaniesTable
      key={index}
      row={row}
      index={index}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      setCompanyDetails={setCompanyDetails}
      setIsOpen={setDeleteModal}
      setCompanyId={setCompanyId}
    />
  ));

  return (
    <div className="flex px-8 my-4">
      {activeCompaniesLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <div className={`rounded-xl ${companyDetails ? 'w-7/12' : 'w-full'}`}>
            <CommonTable
              header={
                companyDetails
                  ? header.filter((item, index) => index < 1)
                  : header
              }
              tableData={dataPreview}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={activeCompanies ? activeCompanies.length : 0}
            />
            <Modal
              isOpen={deleteModal}
              setIsOpen={setDeleteModal}
              className="max-w-md"
            >
              <DeleteModal setIsOpen={setDeleteModal} companyId={companyId} />
            </Modal>
          </div>

          {activeRow !== null && (
            <CompanyDetails
              companyDetails={companyDetails}
              setCompanyDetails={setCompanyDetails}
              setActiveRow={setActiveRow}
              tabs={tabs}
              setValue={setValue}
              activeCompanies
            />
          )}
        </>
      )}
    </div>
  );
};

export default ActiveCompanies;
