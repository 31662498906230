import React from 'react';
import MainContentContainer from '../containers/MainContentContainer/MainContentContainer';
import Sidebar from '../containers/Sidebar/Sidebar';
import Topbar from '../containers/Topbar/Topbar';

const AdminPanel = () => {
  return (
    <>
      <Topbar />
      <div className="flex mt-7 h-[calc(100vh_-_28px)]">
        <Sidebar />
        <div className="w-full gray-background">
          <MainContentContainer />
        </div>
      </div>
    </>
  );
};

export default AdminPanel;
