import React from 'react';
import { FetchCategoryIcon } from '../../../../helpers/categoryIcons';

const SingleCategory = ({
  category,
  selectedCategory,
  categoryClick,
}) => {
  return (
    <div
      className={
        (category.id === selectedCategory?.id &&
          'bg-highlightPurple') +
        ' border-b border-slate-200  flex items-center py-6 pb-7 pl-4 gap-3 hover:bg-highlightPurple transition-all cursor-pointer'
      }
      key={category.id}
      onClick={() => {
        categoryClick(category.id);
      }}
    >
      <FetchCategoryIcon
        name={category?.icon}
        strokeColor={'strokeBlue'}
      />
      <span className="font-medium font-poppins">
        {category.name}
      </span>
      <span className="inline-block ml-auto mr-5">{'>'}</span>
    </div>
  );
};

export default SingleCategory;
