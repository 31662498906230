import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  expert: [],
  categories: [],
  subcategories: [],
};

export const fetchExpertApi = createAsyncThunk(
  "getExpertByIdSlice/fetchExpertApi",

  async ({ id }) => {
    try {
      const response = await api_url.get("user/" + id + "/categories");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getExpertByIdSlice = createSlice({
  name: "getExpertByIdSlice ",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchExpertApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchExpertApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.expert = action.payload;

      let categories = action.payload.categories.filter(
        (item) => item["parent_id"] == null
      ); //filter to get only categories,not subcategories
      state.categories = categories;

      //set subcategories
      let subcategories = action.payload.categories.filter(
        (item) => item["parent_id"] !== null
      ); //filter to get only subcategories
      state.subcategories = subcategories;
    });
    builder.addCase(fetchExpertApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getExpertByIdSlice.reducer;
