import React from 'react';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';
import useTranslate from '../../hooks/useTranslate';

const CategoriesSelect = ({
  categories,
  activeCategory,
  setActiveCategory,
  setSelectedCategory,
}) => {
  const t = useTranslate();
  var sortOrder = ['VVS', 'Vitvaror', 'EL', 'Lås'];
  categories.sort(function (a, b) {
    return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name);
  });

  return (
    <>
      <div className="w-full mt-4">
        {categories?.length > 0 ? (
          categories.map((category, index) => (
            <div key={category.id}>
              <div
                className={
                  (activeCategory === index && 'bg-purple-100') +
                  ' flex w-full px-20 hover:bg-purple-100 py-6 cursor-pointer transition-all'
                }
                onClick={() => {
                  setActiveCategory(index);
                  setSelectedCategory(category.id);
                }}
              >
                <FetchCategoryIcon
                  name={category.icon}
                  strokeColor={'strokePurple'}
                />
                <span className="font-poppins font-medium text-tablePrimary ml-3">
                  {category.name}
                </span>
              </div>
              <hr className="h-px bg-slate-50 mx-16" />
            </div>
          ))
        ) : (
          <div className="font-medium w-full text-center">
            {t('noCategories')}
          </div>
        )}
      </div>
    </>
  );
};

export default CategoriesSelect;
