import './Topbar.css';
import { Navigate, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import logo from "../../assets/logo/logo.svg";
// import homi from "../../assets/logo/homi.svg";
import logo from '../../assets/logo/logonew.svg';
import bell from '../../assets/logo/tabler_bell.svg';
import line from '../../assets/logo/line_47.svg';
import handymanImage from '../../assets/handyman-image.svg';
import closeButton from '../../assets/close-button.svg';
import useAuth from '../../hooks/useAuth';
import { useState } from 'react';
import { Typography } from '@material-ui/core';
import useTranslate from '../../hooks/useTranslate';
import Button from '../../components/Common/Button';
import { ReactComponent as RefreshIcon } from '../../assets/refreshTabler.svg';

const options = [
  {
    name: 'John Doe',
    notification: 'Test notification',
  },
  {
    name: 'Will Klein',
    notification: 'Test notification',
  },
  {
    name: 'Jack Morris',
    notification: 'Test notification',
  },
  {
    name: 'Filip Klarn',
    notification: 'Test notification',
  },
  {
    name: 'Dennis Eddison',
    notification: 'Test notification',
  },
];

const Topbar = () => {
  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const t = useTranslate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };
  const { user } = useAuth();
  const navigate = useNavigate();
  const handleAdmin = () => {
    navigate('admin');
  };
  //const milica=useSelector((state) => state.app.milica)
  return (
    <header className="fixed inset-x-0 p-4 -mt-7 mb-1 text-center items-center flex font-extrabold w-full topbar-background z-10 h-14">
      <div className="flex content-center w-full justify-between">
        <div className="flex  cursor-pointer">
          <img src={logo} style={{ width: '120px' }} />
          {/*<img className="px-4" src={homi} />*/}
        </div>
        <div className="flex justify-self-end justify-end content-center items-center">
          {/*<img
            src={bell}
            className="cursor-pointer"
            onClick={handleClick}
            />

          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: "18rem",
                marginLeft: "-1rem",
                borderRadius: "10px",
                boxShadow: "0px 2px 5px rgba(84, 0, 151, 0.11)",
                border: "solid",
                borderWidth: "1px",
                borderColor: "linear-gradient(90deg, #3912D6 0%, #A312D6 100%)",
              },
            }}
          >
            <Typography
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px 20px",
                fontFamily: "Poppins",
                fontWeight: 400,
                lineHeight: "38px",
                color: "#2D12D6",
              }}
            >
              {t("notifications")}
              <img
                className="cursor-pointer"
                onClick={handleClose}
                src={closeButton}
              />
            </Typography>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                selected={option.name === "John Doe"}
                onClick={handleClose}
                sx={{
                  width: "100%",
                  padding: "1rem 1.5rem!important",
                }}
              >
                <img src={handymanImage} />
                <Typography
                  component={"span"}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    fontFamily: "Poppins",
                    marginLeft: "8px",
                  }}
                >
                  <p className="w-full text-left text-base ledaing-6 font-color">
                    {option.name}
                  </p>
                  <p className="w-full text-left text-slate-500">
                    {option.notification}
                  </p>
                </Typography>
              </MenuItem>
            ))}
          </Menu>
          <img src={line} className="ml-4" />*/}
          <div className="h-full">
            <RefreshIcon
              className="strokePurple w-8 h-full cursor-pointer"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
          <p
            className="font-normal text-base ml-4 topbar-text-color cursor-pointer"
            onClick={user.role_id === 2 ? handleAdmin : undefined}
          >
            {user
              ? user?.name + ' ' + user?.last_name
              : 'Unknown user'}
          </p>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
