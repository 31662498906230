import { Box, Tab, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./CompanyForm.css";
import closeButton from "../../../assets/close-button.svg";
import { useLocation } from "react-router-dom";
import AdminFormTab from "./AdminFormTab";
import CompanyFormTab from "./CompanyFormTab";
import DenialInfo from "../DeniedCompanies/DenialInfo";
import useTranslate from "../../../hooks/useTranslate";
import CompanyHandymen from "./CompanyHandymen";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const t = useTranslate();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function CompanyDetails({
  companyDetails,
  setCompanyDetails,
  setActiveRow,
  tabs,
  activeCompanies,
}) {
  const t = useTranslate();
  const [value, setValue] = useState(t("companyInfo"));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="flex flex-col ml-4 pb-6 rounded-xl cases-box-shadow table-container px-4 w-10/12">
      <div className="flex justify-end pt-6 px-6">
        <img
          src={closeButton}
          height="12"
          width="12"
          onClick={() => {
            setCompanyDetails(null);
            setActiveRow(null);
          }}
          className="cursor-pointer"
          alt="close"
        />
      </div>
      <div className="flex flex-row tab-header case-detail-bottom-border">
        <Box className="">
          <Tabs value={value} onChange={handleChange}>
            {tabs.map((tab, index) => (
              <Tab value={tab} label={tab} key={index} />
            ))}
          </Tabs>
        </Box>
      </div>
      <TabPanel value={value} index={tabs[0]}>
        <CompanyFormTab companyDetails={companyDetails} />
      </TabPanel>
      <TabPanel value={value} index={tabs[1]}>
        {companyDetails.administrators[0] ? (
          <AdminFormTab user={companyDetails.administrators} />
        ) : (
          <h1 className="text-center text-2xl font-medium p-6">
            {t("noData")}
          </h1>
        )}
      </TabPanel>
      <TabPanel value={value} index={tabs[2]}>
        {activeCompanies ? (
          <CompanyHandymen companyDetails={companyDetails} />
        ) : (
          <DenialInfo companyDetails={companyDetails} />
        )}
      </TabPanel>
    </div>
  );
}
