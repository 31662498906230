import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
const SupportProgress = () => {
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  return (
    <div className="mx-4 my-4 text-center text-2xl font-extrabold text-purple-500"></div>
  );
};

export default SupportProgress;
