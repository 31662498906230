import singlephone from "../../assets/images/single-phone.png";
import singlephone2 from "../../assets/images/single-phone2.png";
import categoryicons from "../../assets/images/category-icons.png";

const LandingContentPart1 = () => {
  return (
    <div className="flex h-full mt-24 mb-10 sm:mb-16 custom-container flex-col lg:flex-row">
      <div className="w-full lg:w-3/6 mt-8 pt-24 lg:pt-52 lg:pl-40 lg:pr-9 pl-8 pr-8">
        <h3 className="mb-8" style={{ color: "#13044F", fontSize: "32px" }}>
          2. Boka videomöte
        </h3>
        <p
          className="opacity-70"
          style={{ color: "#13044F", fontSize: "18px" }}
        >
          Registrera ditt ärende dygnet runt i appen. Starta din bokning genom
          att välja kategori utifrån ditt behov.
        </p>
        <img className="logo inline-block mt-9" src={categoryicons} />
      </div>
      <div className="w-full lg:w-3/6 mt-8 pt-2 lg:pt-20">
        <div className="text-center">
          <img
            className="logo inline-block relative z-40 pt-12"
            src={singlephone}
          />
          <img
            className="logo inline-block -ml-24 sm:-ml-8 relative z-50"
            src={singlephone2}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart1;
