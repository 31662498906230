import { TableRow } from '@material-ui/core';
import React from 'react';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import useTranslate from '../../../hooks/useTranslate';
import Button from '../../Common/Button';
import PlaceholderImage from '../../../assets/placeholderImg.png';

const WaitingCompaniesTable = ({
  row,
  index,
  activeRow,
  setCompanyDetails,
  setActiveRow,
  companyImage,
  setIsDenied,
  handleAccept,
  handleDeny,
}) => {
  const t = useTranslate();

  return (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow === index && 'active-row'}`}
      onClick={() => {
        setCompanyDetails(row);
        setActiveRow(index);
      }}
    >
      <StyledTableCell>
        <div className="flex gap-3">
          <img
            src={row?.logo ? row?.logo : PlaceholderImage}
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            alt="companyLogo"
            className="rounded-full w-10 h-10 object-cover"
          />
          <div>
            <p className="font-medium text-base leading-6 font-color">
              {row?.company_name}
            </p>
            <span className="font-color">
              {row?.post_code} {row?.company_address}
            </span>
          </div>
        </div>
      </StyledTableCell>
      {activeRow === null && (
        <>
          <StyledTableCell>
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.code}
                </p>
                <span className="font-color">{row?.company_email}</span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.name
                      ? row?.administrators[0]?.name
                      : '-'
                    : '-'}{' '}
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.last_name
                      ? row?.administrators[0]?.last_name
                      : '-'
                    : '-'}
                </p>
                <span className="font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.phone
                      ? row?.administrators[0]?.phone
                      : 'No admin phone'
                    : 'No admin phone'}
                </span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex justify-end pr-8 gap-3">
              <span className="border-linear mr-2 rounded-md p-0.5">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeny(row?.id);
                  }}
                >
                  {t('deny')}
                </Button>
              </span>
              <span className="border-linear rounded-md p-0.5">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAccept(row?.id, true);
                  }}
                >
                  {t('accept')}
                </Button>
              </span>
            </div>
          </StyledTableCell>
        </>
      )}
    </TableRow>
  );
};

export default WaitingCompaniesTable;
