import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '../../Common/Button';
import Input from '../../Common/Input';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  getAllPrices,
  updatePrices,
} from '../../../api/Settings/Prices/Prices';
import { LinearProgress } from '@mui/material';
import useTranslate from '../../../hooks/useTranslate';

const PricesTime = () => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();
  const t = useTranslate();
  // Query for fetching the prices
  const {
    data: priceData,
    isLoading: pricesLoading,
    isError: pricesError,
  } = useQuery(['Prices'], getAllPrices, {
    onSuccess: (data) => {
      reset({
        expert_video_call_1: data.find(
          (item) => item.item_key === 'expert_video_call_1'
        ).item_value,
        expert_video_call_2: data.find(
          (item) => item.item_key === 'expert_video_call_2'
        ).item_value,
      });
    },
  });

  const { mutateAsync: editPriceMutation } = useMutation(updatePrices, {
    onSuccess: () => {
      setIsEditing(false);
      queryClient.invalidateQueries(['Prices']);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const submitEdit = (data) => {
    editPriceMutation({
      price1: data.expert_video_call_1,
      price2: data.expert_video_call_2,
      id1: priceData[0].id,
      id2: priceData[1].id,
    });
  };

  if (pricesError) return <div>{t('errorRequest')}</div>;

  return (
    <div>
      {pricesLoading ? (
        <LinearProgress />
      ) : (
        <form className="flex flex-col">
          <div className="w-96">
            <Input
              label={`${t('priceForVideoCall')} (kr):`}
              type="number"
              name="expert_video_call_1"
              register={register}
              labelWidth="w-full"
              disabled={!isEditing}
            />
            <Input
              label={`${t('priceForVideoCall')} 2 (kr):`}
              type="number"
              name="expert_video_call_2"
              register={register}
              labelWidth="w-full"
              disabled={!isEditing}
            />
          </div>
          <div className="flex ml-auto gap-3">
            {isEditing ? (
              <>
                <Button
                  onClick={() => {
                    setIsEditing(false);
                    reset({
                      expert_video_call_1: priceData.find(
                        (item) => item.item_key === 'expert_video_call_1'
                      ).item_value,
                      expert_video_call_2: priceData.find(
                        (item) => item.item_key === 'expert_video_call_2'
                      ).item_value,
                    });
                  }}
                >
                  {t('cancel')}
                </Button>
                <Button onClick={handleSubmit(submitEdit)}>{t('save')}</Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                {t('edit')}
              </Button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default PricesTime;
