import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  admin: {},
};

export const fetchAdminByIdApi = createAsyncThunk(
  "getAdminById/fetchAdminByIdApi",
  async ({ id }) => {
    try {
      const response = await api_url.get("administrators/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getAdminByIdSlice = createSlice({
  name: "getAdminById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchAdminByIdApi.pending, (state, action) => {
      state.status = "loading";
      // Add user to the state array
    });
    builder.addCase(fetchAdminByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeeded";
      state.admin = action.payload;
    });
    builder.addCase(fetchAdminByIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getAdminByIdSlice.reducer;
