import api_url from '../../libary/axios-home';

export const fetchCompanyCategoriesById = async ({ queryKey }) => {
  const [_, selectedId] = queryKey;
  const { data } = await api_url.get(`companies/${selectedId}/categories`);
  return data;
};

export const fetchCompaniesWaiting = async () => {
  const { data } = await api_url.get(`/companies/status/waiting`);
  return data;
};

export const fetchCompaniesDenied = async () => {
  const { data } = await api_url.get(`/companies/status/denied`);
  return data;
};

export const fetchCompaniesActive = async () => {
  const { data } = await api_url.get(`/companies/status/accepted`);
  return data;
};

export const setCompanyApprovalStatus = async ({
  id,
  state,
  deniedReasons,
  description,
}) => {
  const body =
    state === true
      ? { approval_status: 2 }
      : {
          approval_status: 3,
          denied_reasons: deniedReasons,
          description,
        };
  const { data } = await api_url.put(`companies/${id}/approval-status`, body);
  return data;
};

export const updateCompany = async (formData) => {
  const { id, categories } = formData;
  const { data } = await api_url.put(`companies/update/${id}`, {
    id: id,
    categories: categories,
  });
  return data;
};

export const updateCompanyCategoryPrices = async (formData) => {
  const { id, parent_id, price, currency } = formData;
  const { data } = await api_url.put(`companies/update/${id}/categories`, {
    parent_id: parent_id,
    price: price,
    currency: currency,
  });
  return data;
};

export const deleteCompanyById = async (id) => {
  const { data } = await api_url.delete(`companies/delete/${id}`);
  return data;
};

export const fetchCompanyProfit = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(`companies/profit/${id}`);
  return data;
};

export const fetchCompanyHandymenByRepairs = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `problems/dashboard/company-admin/${id}/handymen-by-repairs`
  );
  return data;
};

export const fetchCompanyAppointmentsHomeVisit = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `problems/dashboard/company-admin/${id}/appointments-home-visit`
  );
  return data;
};

export const fetchCompanyCasesByStatus = async ({ queryKey }) => {
  const [_, id, status] = queryKey;
  const { data } = await api_url.get(`problems/company/${id}/status/${status}`);
  return data;
};
