import api_url from '../../libary/axios-home';

export const fetchAllExperts = async () => {
  const { data } = await api_url.get('expert/categories/rating');
  return data;
};

export const fetchExpertById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get('user/' + id + '/categories');
  return data;
};

export const fetchNewProblemsExpert = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `/problems/dashboard/expert/${id}/status/new`
  );
  return data;
};

export const fetchOngoingProblemsExpert = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `/problems/dashboard/expert/${id}/status/ongoing`
  );
  return data;
};

export const fetchCompletedProblemsExpert = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `/problems/dashboard/expert/${id}/status/completed`
  );
  return data;
};

export const fetchExpertProblems = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(`problems/expert/${id}/status/ongoing`);
  return data;
};

export const updateApprovalExpert = async ({ id, status }) => {
  const { data } = await api_url.put(`expert/${id}/approval-status`, {
    approval_status: status === true ? 2 : 3,
  });
  return data;
};

export const updateExpert = async (formData) => {
  const { id, password } = formData;
  delete formData.approval_status;
  delete formData.approval_status_by;
  const body = { ...formData };
  if (password === '') delete body.password;
  if (formData.documents.length === 0) delete formData.documents;
  const { data } = await api_url.put(`user/update/${id}`, {
    ...body,
  });
  return data;
};

export const createExpert = async (formData) => {
  const { data } = await api_url.post(`register/expert/super-admin`, {
    ...formData,
  });
  return data;
};

export const getNewProblems = async () => {
  const { data } = await api_url.get('/problems/status/new');
  return data;
};

export const expertProblems = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    `problems/expert/${id}/status/ongoing-completed`
  );
  return data;
};

export const updateProblem = async (formData) => {
  const { data } = await api_url.put(
    `/problems/update/${formData.problem_id}`,
    formData.body
  );
  return data;
};

export const updateExpertOpinion = async ({ id, expert_opinion }) => {
  const { data } = await api_url.patch(`problems/${id}/update/expert/opinion`, {
    expert_opinion,
  });
  return data;
};
