import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ReactComponent as XIcon } from '../../../../assets/xMarker.svg';
import { ReactComponent as Plus } from '../../../../assets/Plus.svg';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import TextArea from '../../../Common/TextArea';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createDeniedReason,
  getAllDeniedReasons,
} from '../../../../api/Settings/MessageSlides/MessageSlides';
import DeniedReasons from '../DeniedReasons';
import useTranslate from '../../../../hooks/useTranslate';

const ModalDenied = ({ setIsOpen, handleApproval }) => {
  const [reasonsArray, setReasonsArray] = useState([]);
  const [isAddingReason, setIsAddingReason] = useState(false);
  const [reasonInput, setReasonInput] = useState('');
  const t = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: 'all' });

  const submitHandler = (data) => {
    handleApproval(reasonsArray, data.description);
  };

  const { mutateAsync: deniedReasonMutation } = useMutation(
    createDeniedReason,
    {
      onSuccess: () => {
        fetchDeniedReasons();
        setIsAddingReason(false);
        setReasonInput('');
      },
    }
  );

  const {
    data: deniedReasons,
    isLoading,
    isError,
    refetch: fetchDeniedReasons,
  } = useQuery(['companyDeniedReasons'], getAllDeniedReasons, {
    refetchOnWindowFocus: false,
  });

  const handleInputChange = (e) => {
    setReasonInput(e.target.value);
  };

  const handleReasonSubmit = () => {
    deniedReasonMutation(reasonInput);
  };

  const handleChange = (reason, checked) => {
    if (checked) {
      setReasonsArray((prev) => [...prev, reason]);
    } else {
      setReasonsArray((prev) => prev.filter((item) => item !== reason));
    }
  };

  return (
    <div className="flex flex-col relative items-center justify-center py-4 px-6">
      <XIcon
        className="absolute h-5 top-0 right-4 cursor-pointer"
        onClick={() => {
          setIsOpen(false);
        }}
      />
      <div className="flex flex-col gap-2 font-poppins items-center">
        <span className="text-violet-700 text-lg">
          {t('denyTheCompanyRequest')}
        </span>
        <span className="text-sm text-gray-400">
          {t('whyIsThisCompanyDenied')}
        </span>
      </div>
      <div className="w-full flex-col pl-6 mt-6 font-poppins text-sm text-gray-400 flex h-72 overflow-auto">
        {deniedReasons?.map((reason) => (
          <DeniedReasons
            key={reason.id}
            reason={reason}
            handleChange={handleChange}
          />
        ))}
      </div>
      {isAddingReason ? (
        <div className="w-full flex flex-col px-6 mt-5">
          <input
            type="text"
            placeholder={`${t('reason')}...`}
            required={true}
            onChange={handleInputChange}
            value={reasonInput}
            className="focus:ring-indigo-500  focus:border-indigo-500 shadow-sm border block
            w-full sm:text-sm rounded-md py-2 px-2 outline-none mb-3"
          />
          <div className="flex gap-3 ml-auto">
            <Button
              onClick={() => {
                setIsAddingReason(false);
                setReasonInput('');
              }}
            >
              {t('cancel')}
            </Button>
            <Button onClick={handleReasonSubmit}>{t('add')}</Button>
          </div>
        </div>
      ) : (
        <div
          className="flex gap-3 mt-3 pl-3 font-poppins text-sm text-gray-400 mr-auto ml-6 cursor-pointer"
          onClick={() => {
            setIsAddingReason(true);
          }}
        >
          <Plus />
          <span>{t('addAReason')}</span>
        </div>
      )}
      <form className="flex flex-col w-full mt-5 px-6 font-poppins">
        <label className="text-sm">Description</label>
        <div className="h-36 mb-2">
          <TextArea
            register={register}
            name="description"
            type="text"
            isError={errors?.deniedReason?.message}
            required={true}
            placeholder={`${t('reason')}...`}
          />
        </div>
        <ErrorMessage>{errors?.deniedReason?.message}</ErrorMessage>
        <span className="place-self-end text-gray-400 text-xs font-poppins mt-2">
          {t('thisWillNotBeSentToACompanyAdmin')}
        </span>
        <div className="flex  ml-auto gap-3 mt-5">
          <Button
            onClick={() => {
              setIsOpen(false);
              setReasonsArray([]);
            }}
          >
            {t('cancel')}
          </Button>
          <Button onClick={handleSubmit(submitHandler)}>{t('deny')}</Button>
        </div>
      </form>
    </div>
  );
};

export default ModalDenied;
