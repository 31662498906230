import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  handymen: [],
};

export const fetchHandymenByCompanyIdApi = createAsyncThunk(
  "getHandymenByCompanyId/fetchHandymenByCompanyIdApi",
  async ({ id }) => {
    try {
      const response = await api_url.get("handyman/company/" + id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getHandymenByCompanyIdSlice = createSlice({
  name: "getHandymenByCompanyId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchHandymenByCompanyIdApi.pending, (state, action) => {
      state.status = "loading";
      // Add user to the state array
    });
    builder.addCase(fetchHandymenByCompanyIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeeded";
      state.handymen = action.payload;
    });
    builder.addCase(fetchHandymenByCompanyIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getHandymenByCompanyIdSlice.reducer;
