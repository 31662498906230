import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  categories: [],
};

export const fetchCategoriesSubcategoriesApi = createAsyncThunk(
  "getCategoriesSubcategories/fetchCategoriesSubcategories",
  async () => {
    try {
      const response = await api_url.get("categories/subcategories");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getCategoriesSubcategoriesSlice = createSlice({
  name: "getCategoriesSubcategories",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(
      fetchCategoriesSubcategoriesApi.pending,
      (state, action) => {
        // Add user to the state array
        state.status = "loading";
      }
    );
    builder.addCase(
      fetchCategoriesSubcategoriesApi.fulfilled,
      (state, action) => {
        state.status = "succeeded";
        // Add user to the state array
        state.categories = action.payload;
      }
    );
    builder.addCase(
      fetchCategoriesSubcategoriesApi.rejected,
      (state, action) => {
        // Add user to the state array
      }
    );
  },
});

export default getCategoriesSubcategoriesSlice.reducer;
