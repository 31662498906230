import api_url from "../../../libary/axios-home";

export const getTermsByUserRole = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(`/terms-conditions/user-role/${id}`);
  return data;
};

export const createToS = async ({ title, subtitle, text, version, type }) => {
  const { data } = await api_url.post("/terms-conditions/create", {
    administrator_id: 1,
    title: title,
    subtitle: subtitle,
    text: text,
    version: version,
    required_new_accept: true,
    user_type: type,
    is_latest_version: true,
  });

  return data;
};

export const updateToS = async (formData) => {
  const { checked, data: dataContent } = formData;
  const { title, text, subtitle, user_role, version, id } = dataContent;
  const { data } = checked
    ? await api_url.post(`/terms-conditions/create/new-version/${id}`, {
        title,
        subtitle,
        text,
        version,
        required_new_accept: checked ? 1 : 0,
        user_role: user_role,
      })
    : await api_url.put(`/terms-conditions/update/${id}`, {
        title,
        subtitle,
        text,
        version,
        required_new_accept: checked ? 1 : 0,
        user_type: user_role,
      });
  return data;
};
