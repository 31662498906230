import { Box, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { useState } from "react";

export const Tabs = ({ tabs,children }) => {
  const [value, setValue] = useState("1");

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <TabContext value={value}>
        <Box sx={{}}>
          <TabList onChange={handleChange} aria-label="Tabs">
            {tabs?.map((tab, index) => (
              <Tab label={tab.label} value={(index+1).toString()} key={tab.label} />
            ))}
          </TabList>
        </Box>
        {children}
      </TabContext>
    </Box>
  );
};
