import React, { useState, Fragment, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../Common/ErrorMessage';
import Input from '../../Common/Input';
import Button from '../../Common/Button';
import { ReactComponent as Star } from '../../../assets/images/tabler_star.svg';
import SelectBox from '../../Common/SelectBox';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getAllCities } from '../../../api/City/City';
import { CircularProgress } from '@material-ui/core';
import { editUser } from '../../../api/Users/Users';
import useTranslate from '../../../hooks/useTranslate';
import UserInputFields from './UserInputFields';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import Modal from '../../Common/Modal';
import DeleteUserModal from '../../Common/DeleteUserModal';

const EditForm = ({ user, setSidePanel, userLoading, selectedUserDetails }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({ mode: 'all', defaultValues: user });
  const t = useTranslate();
  const inputFields = UserInputFields();

  // Reset the form and return to disabled fields on every user fetch
  useEffect(() => {
    setIsEditing(false);
    reset(user);
  }, [user]);

  // Fetch all cities
  const { data: cityData, isLoading: citiesLoading } = useQuery(
    ['cities'],
    getAllCities,
    { refetchOnWindowFocus: false }
  );

  const { mutateAsync: updateUser } = useMutation(editUser, {
    onSuccess: () => {
      setIsEditing(false);
      queryClient.invalidateQueries(['userSearch']);
    },
  });

  const submitHandler = (data) => {
    updateUser(data);
  };

  return citiesLoading ? (
    <CircularProgress />
  ) : userLoading ? (
    <CircularProgress />
  ) : (
    <div className="flex w-full flex-col gap-3 p-4">
      <div className="flex gap-3 px-3 py-3 pb-1 items-center relative">
        <span
          className="absolute right-2 top-0 font-poppins text-textGray cursor-pointer"
          onClick={() => {
            setIsEditing(false);
            setSidePanel(false);
          }}
        >
          x
        </span>
        <img
          src={user?.profile_image ? user?.profile_image : PlaceholderImage}
          alt="user"
          className="rounded-full h-10 w-10 object-cover"
        />
        <div className="flex flex-col">
          <span className="font-poppins font-medium text-headingActive">
            {user?.name}
          </span>
          <div className="flex gap-2">
            <Star className="w-3" />
            <span className="font-poppins text-sm text-textGray">
              {selectedUserDetails?.avg_rating}
            </span>
          </div>
        </div>
      </div>
      <hr className="h-px bg-slate-200 w-full " />
      <form className="px-3 py-3">
        <Controller
          name="city"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="mb-2">
              <label className="block text-left text-sm font-poppins text-gray-700 ">
                {t('city')}
              </label>
              <SelectBox
                data={cityData}
                onChange={onChange}
                value={value}
                isEditing={!isEditing}
                defaultValue={
                  cityData?.filter((city) => city?.id === user?.city_id)[0]
                    ?.name
                }
              />
            </div>
          )}
        />
        <Controller
          name="dwelling"
          control={control}
          render={({ field: { onChange, value } }) => (
            <div className="mb-2">
              <label className="block text-left text-sm font-poppins text-gray-700 ">
                {t('house/apartment')}
              </label>
              <SelectBox
                data={[{ name: 'house' }, { name: 'apartment' }]}
                isEditing={!isEditing}
                onChange={onChange}
                value={value}
                defaultValue={user?.dwelling_type}
              />
            </div>
          )}
        />
        {inputFields.map((item, index) => {
          return (
            <Fragment key={index}>
              <label className="block text-left text-sm font-poppins text-gray-700 ">
                {item.label}
              </label>
              <Input
                register={register}
                placeholder={item.placeholder}
                type={item.type}
                name={item.name}
                max={item.max}
                pattern={item.pattern ? item.pattern : null}
                required={item.required ? item.required : false}
                disabled={!isEditing}
                isError={item.error(errors)}
                noLabel
              />
              <ErrorMessage>{item.error(errors)}</ErrorMessage>
            </Fragment>
          );
        })}

        <div className="flex gap-3 mt-6 w-full items-center justify-center">
          <Button
            className="w-1/2"
            type="button"
            onClick={() => setDeleteModal(true)}
          >
            {t('delete')}
          </Button>
          {isEditing ? (
            <Button onClick={handleSubmit(submitHandler)}>{t('save')}</Button>
          ) : (
            <Button
              className="w-1/2"
              onClick={() => {
                setIsEditing(true);
              }}
              type="button"
            >
              {t('edit')}
            </Button>
          )}
        </div>
      </form>
      <Modal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        className="p-4 max-w-md w-full"
      >
        <DeleteUserModal
          userDetails={selectedUserDetails}
          setDeleteModal={setDeleteModal}
        />
      </Modal>
    </div>
  );
};

export default EditForm;
