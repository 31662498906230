import { styled } from '@material-ui/core';
import { InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import Button from '../../../../Common/Button';

const PriceField = styled(TextField)(() => ({
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
}));

const ItemBox = ({ setShowNewItem, setMaterialsList }) => {
  //Translations
  const t = useTranslate();

  const [newItem, setNewItem] = useState({
    name: '',
    price: 0,
    quantity: 0,
    currency: 'SEK',
  });

  const handleAdd = () => {
    if (newItem.name === '' || newItem.price <= 0 || newItem.quantity <= 0) {
      return;
    }
    setMaterialsList((oldArray) => [...oldArray, newItem]);
  };

  return (
    <div className="flex flex-col gap-3">
      <div>
        <p>{t('itemName')}</p>
        <TextField
          type="text"
          fullWidth
          onChange={(event) =>
            setNewItem({ ...newItem, name: event.target.value })
          }
        />
      </div>
      <div>
        <p>{t('price')}</p>
        <PriceField
          type="number"
          fullWidth
          onChange={(event) =>
            setNewItem({ ...newItem, price: event.target.value })
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">SEK</InputAdornment>,
          }}
        />
      </div>
      <div>
        <p>{t('itemQuantity')}</p>
        <TextField
          type="number"
          fullWidth
          onChange={(event) =>
            setNewItem({ ...newItem, quantity: event.target.value })
          }
        />
      </div>
      <div className="flex gap-3 items-center justify-end">
        <Button onClick={() => setShowNewItem(false)}>{t('cancel')}</Button>
        <Button
          onClick={() => {
            setShowNewItem(false);
            handleAdd();
          }}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};
export default ItemBox;
