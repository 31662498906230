import { useForm } from 'react-hook-form';
import useTranslate from '../../hooks/useTranslate';
import Button from '../Common/Button';

const RegisterComponentPart1 = ({ formData, setFormData, setValue }) => {
  const {
    register,
    trigger,
    getFieldState,
    formState: { errors },
  } = useForm({});
  const t = useTranslate();

  const goToNext = async () => {
    await trigger(['companyName', 'organisationalNumber']);
    if (
      !getFieldState('companyName').invalid &&
      !getFieldState('organisationalNumber').invalid
    ) {
      setValue(1);
    }
  };
  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('companyName')}
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('basicInfoCompany')}
          </p>

          <div>
            <label htmlFor="company_name" className="text-sm">
              {t('companyName')}
            </label>
            <input
              type="text"
              name="companyName"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="companyName"
              placeholder={t('name')}
              style={{ color: '#13044F' }}
              {...register('companyName', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 3,
                  message: t('nameIsTooShort'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  companyName: e.target.value,
                });
              }}
              value={formData.companyName}
            />
            {errors.companyName && (
              <span className="text-red-600 text-sm">
                {errors.companyName?.message}
              </span>
            )}
          </div>
          <div>
            <label htmlFor="organisational_number" className="text-sm">
              {t('organisationalNumber')}
            </label>
            <input
              type="text"
              name="organisationalNumber"
              className="w-full p-2 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-1 text-base"
              id="organisationalNumber"
              placeholder={`${t('organisationalNumber')}...`}
              style={{ color: '#13044F' }}
              {...register('organisationalNumber', {
                required: {
                  value: true,
                  message: t('thisFieldIsRequired'),
                },
                minLength: {
                  value: 10,
                  message: t('enterValidOrgNumber'),
                },
              })}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  organisationalNumber: e.target.value,
                });
              }}
              value={formData.organisationalNumber}
            />
            {errors.organisationalNumber && (
              <span className="text-red-600 text-sm">
                {errors.organisationalNumber?.message}
              </span>
            )}
          </div>

          <div className="flex justify-end items-center mt-6">
            <Button onClick={goToNext} type="submit">
              {t('next')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterComponentPart1;
