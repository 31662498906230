import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchRegisterApi } from '../../features/registerSlice';
import sucess_img from '../../assets/images/registration_success.png';
import useTranslate from '../../hooks/useTranslate';

const RegisterComponentPart5 = ({ formData, setValue }) => {
  const isFirstRun = useRef(true);
  const dispatch = useDispatch();
  const t = useTranslate();

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }
  }, []);

  //destructuring the object from values
  const {
    companyName,
    organisationalNumber,
    companyEmail,
    companyPhone,
    companyAddress,
    adminName,
    adminLastName,
    adminPhone,
    adminEmail,
    password,
    passwordConfirm,
  } = formData;

  if (
    companyName === '' ||
    organisationalNumber === '' ||
    companyEmail === '' ||
    companyAddress === '' ||
    companyPhone === '' ||
    adminName === '' ||
    adminLastName === '' ||
    adminPhone === '' ||
    adminEmail === '' ||
    password === '' ||
    passwordConfirm === ''
  ) {
    formData.form_valid = false;
  } else {
    formData.form_valid = true;
  }

  const onSubmit = () => {
    if (formData.form_valid === true) {
      dispatch(
        fetchRegisterApi({
          company_name: companyName,
          code: organisationalNumber,
          company_phone: companyPhone,
          company_email: companyEmail,
          company_address: companyAddress,
          name: adminName,
          last_name: adminLastName,
          phone: adminPhone,
          email: adminEmail,
          password: password,
        })
      );
      //here should add if response ok redirect to succes if error show error screen
      setValue(5);
    }
  };
  const goToPrevious = () => {
    setValue(3);
  };

  return (
    <>
      <div
        className="h-screen bg-gray-bg1 w-full"
        style={{ background: '#F9F9F9' }}
      >
        <div className="py-20 px-32" style={{ color: '#13044F' }}>
          <h1
            className="text-primary mt-4 mb-2.5 leading-9"
            style={{ fontSize: '32px', color: '#13044F' }}
          >
            {t('finish')}
          </h1>
          <p className="mb-12 text-lg" style={{ color: '#909090' }}>
            {t('successWaitingAdmin')}
          </p>

          <div>
            <img className="pl-24" src={sucess_img} />
          </div>

          <div className="flex justify-end items-center mt-6">
            <button
              onClick={goToPrevious}
              className="border-2 p-2 ml-5 w-40 rounded-md uppercase text-white"
              style={{
                color: 'rgb(57, 18, 214)',
                border: '2px solid rgb(57, 18, 214)',
              }}
            >
              {t('previous')}
            </button>
            <button
              onClick={onSubmit}
              className="border-2 p-2 ml-5 w-36 rounded-md uppercase text-white"
              style={{
                background:
                  ' linear-gradient(90deg, #3912D6 0%, #A312D6 100%)',
              }}
            >
              {t('finish')}
            </button>
          </div>
          {formData.form_valid == false && (
            <span className="text-red-600 text-sm">
              Please check your form data
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export default RegisterComponentPart5;
