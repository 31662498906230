import React, { useEffect } from 'react';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchAllUsers, fetchUserById } from '../../api/Users/Users';
import CommonTable from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import UserTable from './UserTable';
import EditForm from './UserForms/EditForm';
import { LinearProgress } from '@material-ui/core';
import useTranslate from '../../hooks/useTranslate';
import Modal from '../Common/Modal';
import DeleteUserModal from '../Common/DeleteUserModal';
import { useOutletContext } from 'react-router-dom';

const UsersList = () => {
  const [toggleCreate, setToggleCreate, searchData, searchDataLoading] =
    useOutletContext();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedId, setSelectedId] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [sidePanel, setSidePanel] = useState(false);
  const t = useTranslate();

  useEffect(() => {
    setPage(0);
  }, [searchData]);

  const header = [
    t('user'),
    t('contact'),
    t('house/apartment'),
    t('rating'),
    '',
  ];

  const {
    data: selectedUser,
    isLoading: selectedUserLoading,
    isError: selectedUserError,
    refetch: fetchUser,
  } = useQuery(['selectedUser', selectedId], fetchUserById, {
    enabled: selectedId !== false ? true : false,
  });

  const dataPreview = (
    rowsPerPage > 0
      ? searchData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : searchData
  )?.map((row, index) => (
    <UserTable
      key={index}
      row={row}
      index={index}
      fetchUser={fetchUser}
      setSelectedId={setSelectedId}
      setSelectedUserDetails={setSelectedUserDetails}
      setSidePanel={setSidePanel}
      setDeleteModal={setDeleteModal}
    />
  ));

  return (
    <div className="flex w-full gap-3 px-8">
      <div className="my-4 w-full ">
        {searchDataLoading ? (
          <LinearProgress />
        ) : (
          <>
            <CommonTable
              header={header}
              tableData={dataPreview}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={searchData ? searchData?.length : 0}
              page={page}
            />
            <Modal
              isOpen={deleteModal}
              setIsOpen={setDeleteModal}
              className="p-4 max-w-md w-full"
            >
              <DeleteUserModal
                userDetails={selectedUserDetails}
                setDeleteModal={setDeleteModal}
              />
            </Modal>
          </>
        )}
      </div>
      {sidePanel && (
        <div className="w-1/4 my-4 bg-white rounded-lg table-shadow ">
          <EditForm
            user={selectedUser}
            setSidePanel={setSidePanel}
            userLoading={selectedUserLoading}
            selectedUserDetails={selectedUserDetails}
          />
        </div>
      )}
    </div>
  );
};

export default UsersList;
