import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import swedish from './translations/swe.json';
import eng from './translations/swe.json';

i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  resources: {
    en: {
      translation: eng,
    },
    swe: {
      translation: swedish,
    },
  },
});
