import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";


export const initialState = {
 status:""
};



export const deleteExpertApi = createAsyncThunk(
  'deleteExpertSlice/deleteExpertApi',
  async ({ id,logged_in_user_role_id}) => {
    const response = await fetch(
      "https://homi-adm.zendev.se/api/user/"+ id+"/role/3/delete",
      {
          method: "DELETE",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              logged_in_user_role_id,   
          }),
      }
  )
    const response2 = await response.json()
   
 
    return response2

  }
)

const deleteExpertSlice= createSlice({
  name: "deleteExpertSlice",
  initialState,
  reducers: {

  },
  
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(deleteExpertApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(deleteExpertApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = action.payload;
      
      if(action.payload==true){
        alert("Sucess 200");
        window.location.href="/d/experts";
      }
      
     else
     alert("Error! Cannot delete expert")
  
 
    })
    builder.addCase(deleteExpertApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default deleteExpertSlice.reducer;