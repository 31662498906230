import { ReactComponent as ArrowVertical } from '../assets/categoryIcons/ArrowVertical.svg';
import { ReactComponent as BatteryAutomotive } from '../assets/categoryIcons/BatteryAutomotive.svg';
import { ReactComponent as BatteryCharging } from '../assets/categoryIcons/BatteryCharging.svg';
import { ReactComponent as Boiler } from '../assets/categoryIcons/Boiler.svg';
import { ReactComponent as BrokenPipe } from '../assets/categoryIcons/BrokenPipe.svg';
import { ReactComponent as BulbOff } from '../assets/categoryIcons/BulbOff.svg';
import { ReactComponent as Components } from '../assets/categoryIcons/Components.svg';
import { ReactComponent as Door } from '../assets/categoryIcons/Door.svg';
import { ReactComponent as DoorLock } from '../assets/categoryIcons/DoorLock.svg';
import { ReactComponent as Dots } from '../assets/categoryIcons/Dots.svg';
import { ReactComponent as Droplet } from '../assets/categoryIcons/Droplet.svg';
import { ReactComponent as Drenage } from '../assets/categoryIcons/Drenage.svg';
import { ReactComponent as Dryer } from '../assets/categoryIcons/Dryer.svg';
import { ReactComponent as ElectricalIssues } from '../assets/categoryIcons/ElectricalIssues.svg';
import { ReactComponent as ElectricHeating } from '../assets/categoryIcons/ElectricHeating.svg';
import { ReactComponent as FloorDrain } from '../assets/categoryIcons/FloorDrain.svg';
import { ReactComponent as FloorHeating } from '../assets/categoryIcons/FloorHeating.svg';
import { ReactComponent as Hood } from '../assets/categoryIcons/Hood.svg';
import { ReactComponent as Key } from '../assets/categoryIcons/Key.svg';
import { ReactComponent as KitchenTools } from '../assets/categoryIcons/KitchenTools.svg';
import { ReactComponent as Lock } from '../assets/categoryIcons/Lock.svg';
import { ReactComponent as LockAccess } from '../assets/categoryIcons/LockAccess.svg';
import { ReactComponent as LockAccessV2 } from '../assets/categoryIcons/LockAccessV2.svg';
import { ReactComponent as Plug } from '../assets/categoryIcons/Plug.svg';
import { ReactComponent as PlugCabinets } from '../assets/categoryIcons/PlugCabinets.svg';
import { ReactComponent as Radiator } from '../assets/categoryIcons/Radiator.svg';
import { ReactComponent as Refrigerator } from '../assets/categoryIcons/Refrigerator.svg';
import { ReactComponent as Sink } from '../assets/categoryIcons/Sink.svg';
import { ReactComponent as Snowflake } from '../assets/categoryIcons/Snowflake.svg';
import { ReactComponent as Stove } from '../assets/categoryIcons/Stove.svg';
import { ReactComponent as Switch } from '../assets/categoryIcons/Switch.svg';
import { ReactComponent as Tap } from '../assets/categoryIcons/Tap.svg';
import { ReactComponent as Temperature } from '../assets/categoryIcons/Temperature.svg';
import { ReactComponent as Toilet } from '../assets/categoryIcons/Toilet.svg';
import { ReactComponent as WashingMachine } from '../assets/categoryIcons/WashingMachine.svg';
import { ReactComponent as WaterBattery } from '../assets/categoryIcons/WaterBattery.svg';

export const FetchCategoryIcon = ({ name, strokeColor }) => {
  if (name === 'ArrowVertical')
    return <ArrowVertical className={strokeColor + ' min-24'} />;
  if (name === 'BatteryAutomotive')
    return <BatteryAutomotive className={strokeColor + ' min-24'} />;
  if (name === 'BatteryCharging')
    return <BatteryCharging className={strokeColor + ' min-24'} />;
  if (name === 'Boiler')
    return <Boiler className={strokeColor + ' min-24'} />;
  if (name === 'BrokenPipe')
    return <BrokenPipe className={strokeColor + ' min-24'} />;
  if (name === 'BulbOff')
    return <BulbOff className={strokeColor + ' min-24'} />;
  if (name === 'Components')
    return <Components className={strokeColor + ' min-24'} />;
  if (name === 'Door')
    return <Door className={strokeColor + ' min-24'} />;
  if (name === 'DoorLock')
    return <DoorLock className={strokeColor + ' min-24'} />;
  if (name === 'Drenage')
    return <Drenage className={strokeColor + ' min-24'} />;
  if (name === 'Dots')
    return <Dots className={strokeColor + ' min-24'} />;
  if (name === 'Droplet')
    return <Droplet className={strokeColor + ' min-24'} />;
  if (name === 'Dryer')
    return <Dryer className={strokeColor + ' min-24'} />;
  if (name === 'ElectricalIssues')
    return <ElectricalIssues className={strokeColor + ' min-24'} />;
  if (name === 'ElectricHeating')
    return <ElectricHeating className={strokeColor + ' min-24'} />;
  if (name === 'FloorDrain')
    return <FloorDrain className={strokeColor + ' min-24'} />;
  if (name === 'FloorHeating')
    return <FloorHeating className={strokeColor + ' min-24'} />;
  if (name === 'Hood')
    return <Hood className={strokeColor + ' min-24'} />;
  if (name === 'Key')
    return <Key className={strokeColor + ' min-24'} />;
  if (name === 'KitchenTools')
    return <KitchenTools className={strokeColor + ' min-24'} />;
  if (name === 'Lock')
    return <Lock className={strokeColor + ' min-24'} />;
  if (name === 'LockAccess')
    return <LockAccess className={strokeColor + ' min-24'} />;
  if (name === 'LockAccessV2')
    return <LockAccessV2 className={strokeColor + ' min-24'} />;
  if (name === 'Plug')
    return <Plug className={strokeColor + ' min-24'} />;
  if (name === 'PlugCabinets')
    return <PlugCabinets className={strokeColor + ' min-24'} />;
  if (name === 'Radiator')
    return <Radiator className={strokeColor + ' min-24'} />;
  if (name === 'Refrigerator')
    return <Refrigerator className={strokeColor + ' min-24'} />;
  if (name === 'Sink')
    return <Sink className={strokeColor + ' min-24'} />;
  if (name === 'Snowflake')
    return <Snowflake className={strokeColor + ' min-24'} />;
  if (name === 'Stove')
    return <Stove className={strokeColor + ' min-24'} />;
  if (name === 'Switch')
    return <Switch className={strokeColor + ' min-24'} />;
  if (name === 'Tap')
    return <Tap className={strokeColor + ' min-24'} />;
  if (name === 'Temperature')
    return <Temperature className={strokeColor + ' min-24'} />;
  if (name === 'Toilet')
    return <Toilet className={strokeColor + ' min-24'} />;
  if (name === 'WashingMachine')
    return <WashingMachine className={strokeColor + ' min-24'} />;
  if (name === 'WaterBattery')
    return <WaterBattery className={strokeColor + ' min-24'} />;
};
