import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../libary/axios-home";

const initialState = {
  categories: [],
};

export const fetchCategoriesApi = createAsyncThunk(
  "users/fetchCategoriesApi",
  async () => {
    try {
      const response = await api_url.get("categories");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCategoriesApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchCategoriesApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.categories = action.payload;
    });
    builder.addCase(fetchCategoriesApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

// Action creators are generated for each case reducer function

export default appSlice.reducer;
