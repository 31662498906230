/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import chevronDown from "../../assets/arrows/chevron-down.svg";
import { api_url } from "../../libary/axios-home";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAdminApi,
  fetchCategoriesApi,
  fetchMunicipalitiesApi,
  fetchSubcategoriesApi,
} from "../../features/filterSlice";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FilterDropdown = ({ item }) => {
  const [selected, setSelected] = useState({ id: 0, name: "All" });
  const [filterData, setFilterData] = useState([]);
  const dispatch = useDispatch();

  const municipalities = useSelector((state) => state.filter.municipalities);
  const status = useSelector((state) => state.filter.status);

  useEffect(() => {
    dispatch(fetchMunicipalitiesApi());
  }, []);

  /*
  const categories = useSelector((state) => state.filter.categories);
  const subcategories = useSelector((state) => state.filter.subcategories);
  const administrators = useSelector((state) => state.filter.administrators);


  const location = useLocation()

  const path = location.pathname

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCategoriesApi());
    dispatch(fetchMunicipalitiesApi());
    dispatch(fetchSubcategoriesApi());
    dispatch(fetchAdminApi());
  }, []);
  
  useEffect(() => {
    switch (path) {
      case "/d/new-cases" || "/d/ongoing-cases" || "/d/finished-cases":
        setFilterData(categories)
        break;
      case "/d/active-companies" ||
        "/d/waiting-companies" ||
        "/d/denied-companies":
        setFilterData(administrators)
        break;
      case "/d/experts":
        break;
      case "/d/users":
        break;

      default:
    }
  }, [location]);*/

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="mt-1 mr-4 relative custom-dropdown ">
            <Listbox.Label className="block mb-1 text-sm font-medium text-gray-700 ">
              {item.name}
            </Listbox.Label>
            <Listbox.Button className="relative bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm w-56">
              <span className="flex items-center">
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <img src={chevronDown}></img>
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm w-56">
                {municipalities?.map((municipality) => (
                  <Listbox.Option
                    key={municipality.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={municipality}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {municipality.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default FilterDropdown;
