import { Box, Tab, Tabs } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import closeButton from '../../../assets/close-button.svg';
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom';
import HandymanCases from './HandymanCases';
import HandymanForm from './HandymanForm';
import {
  fetchHandymenByCompanyId,
  fetchHandymanById,
} from '../../../api/Handyman/Handyman';
import { useQuery } from '@tanstack/react-query';
import { LinearProgress, TableRow } from '@mui/material';
import CommonTable, {
  StyledTableCell,
} from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import useTranslate from '../../../hooks/useTranslate';
import useAuth from '../../../hooks/useAuth';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }} className="handyman-form">
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function HandymanEdit() {
  const t = useTranslate();
  const [value, setValue] = useState(t('handyman') + ' info');
  const [toggleCreate, setToggleCreate, searchData, searchDataLoading] =
    useOutletContext();
  const navigate = useNavigate();
  const { user } = useAuth();
  let params = useParams();
  const id = params.id;

  //Pagination
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  // Fetch targeted Handyman
  const {
    data: handymanData,
    isLoading: handymanLoading,
    refetch,
  } = useQuery(['handyman', id], fetchHandymanById, {
    refetchOnWindowFocus: false,
  });

  const tabs = [`${t('handyman')} info`, t('cases')];
  const header_small = [t('handyman')];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const location = useLocation();

  const dataPreview_small = (
    rowsPerPage > 0
      ? searchData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : searchData
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={id == row.id ? 'table-highlight-purple' : ''}
    >
      <StyledTableCell
        component={Link}
        to={'/d/handymen/info/' + row.id}
        state={{ disabledform: false }}
      >
        <div className="flex items-center">
          <img
            src={row?.profile_image ? row?.profile_image : PlaceholderImage}
            alt="Handyman"
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            className="w-10 h-10 rounded-full object-cover"
          />
          <div className="px-4">
            <p className="font-medium text-base leading-6 font-color">
              {row?.name} {row?.last_name}
            </p>
          </div>
        </div>
      </StyledTableCell>
    </TableRow>
  ));

  return (
    <div className="flex w-full my-4 px-8 gap-4">
      <div id="expert_small_table_container" className="w-3/4">
        {searchDataLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <CommonTable
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={searchData ? searchData.length : 0}
            header={header_small}
            tableData={dataPreview_small}
          />
        )}
      </div>
      <div className="flex flex-col ml-4 pb-6 rounded-xl cases-box-shadow table-container px-4 w-10/12">
        <div className="flex justify-end pt-6 px-6">
          <img
            className="cursor-pointer"
            src={closeButton}
            height="12"
            width="12"
            onClick={() => {
              navigate('/d/handymen');
            }}
          ></img>
        </div>
        <div className="flex flex-row tab-header case-detail-bottom-border">
          <Box className="">
            <Tabs value={value} onChange={handleChange}>
              {tabs.map((tab, index) => (
                <Tab value={tab} label={tab} key={index + 'tab'} />
              ))}
            </Tabs>
          </Box>
        </div>
        <TabPanel value={value} index={tabs[0]}>
          <HandymanForm
            isEdit={toggleCreate ? false : true}
            handymanLoading={handymanLoading}
            handymanData={handymanData}
            isCreate={toggleCreate}
            setToggleCreate={setToggleCreate}
          />
        </TabPanel>
        <TabPanel value={value} index={tabs[1]}>
          <HandymanCases />
        </TabPanel>
      </div>
    </div>
  );
}
