import api_url from '../../libary/axios-home';

export const sendExpiredNotification = async (content) => {
  const { user_id } = content;
  const { data } = await api_url.post('notifications/send-notification/user', {
    id: user_id,
  });
  return data;
};

export const sendCallNotification = async (id, content) => {
  console.log(id);
  console.log(content);
  const data = await api_url.post(`notifications/call-invite/${id}`, {
    notification_data: content,
  });
  return data;
};