import React, { useState } from "react";
import TermsForm from "./TermsConditions/TermsForm";
import TermsConditions from "./TermsConditions/TermsConditions";

const TermsConditionsTab = ({
  setTermsPreview,
  description,
  setTermsValue,
  rotValue,
  setRotValue,
  termsValue,
  setTermsPreviewValue
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const handleCancleForm = () => {
    setIsEditing(false);
    setTermsPreview(false);
    setTermsPreviewValue("")
  };
  

  return (
    <div>
      {isEditing ? (
        <TermsForm
          description={description}
          setTermsValue={setTermsValue}
          cancleEditing={handleCancleForm}
          data={termsValue}
          setTermsPreviewValue={setTermsPreviewValue}
        />
      ) : (
        <TermsConditions
          setIsEditing={setIsEditing}
          setTermsPreview={setTermsPreview}
          rotValue={rotValue}
          setRotValue={setRotValue}
          setTermsValue={setTermsValue}
        />
      )}
    </div>
  );
};

export default TermsConditionsTab;
