import api_url from '../../../libary/axios-home';

export const getAllMessageSlides = async () => {
  const { data } = await api_url.get(
    '/settings/status/welcome_slides'
  );
  return data;
};

export const getAllDeniedReasons = async () => {
  const { data } = await api_url.get('/denied-reasons');
  return data;
};

export const createDeniedReason = async (message) => {
  const { data } = await api_url.post('/denied-reasons/create', {
    administrator_id: 1,
    text: message,
  });
  return data;
};

export const editDeniedReason = async ({ messageInput, id }) => {
  const { data } = await api_url.put(`/denied-reasons/update/${id}`, {
    text: messageInput,
  });
  return data;
};

export const deleteDeniedReason = async (id) => {
  const { data } = await api_url.delete(
    `/denied-reasons/update/${id}`
  );
  return data;
};

export const createMessageSlide = async (message) => {
  const { data } = await api_url.post('/settings/create', {
    administrator_id: 1,
    item_key: 'messages',
    type: 'welcome_slides',
    item_value: message,
  });
  return data;
};

export const editMessageSlide = async (formData) => {
  const { messageInput: message, id: messageId } = formData;
  const { data } = await api_url.put(
    `/settings/update/${messageId}`,
    {
      administrator_id: 1,
      item_key: 'messages',
      type: 'welcome_slides',
      item_value: message,
    }
  );
  return data;
};

export const deleteMessageSlide = async (messageId) => {
  const { data } = await api_url.post(
    `/settings/delete/${messageId}`
  );
  return data;
};
