import React, { Fragment, useRef } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Button from "../Common/Button";
import iconEdit from "../../assets/images/tabler_edit.svg";
import { useState } from "react";
import { TextField, Tooltip } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateCompany } from "../../api/Company/Company";
import useAuth from "../../hooks/useAuth";
import useTranslate from "../../hooks/useTranslate";
import { FetchCategoryIcon } from "../../helpers/categoryIcons";

const PriceTextField = styled(TextField)(() => ({
  marginBottom: "15px",
  maxWidth: "308px",
  marginLeft: "auto",
  marginBottom: "auto",
  marginTop: "auto",
  "& .MuiInputBase-input.": {
    paddingRight: "0",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    textFillColor: "#13044F",
    fontWeight: "400",
    fontSize: "22px",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      border: "none",
    },
    "& .MuiTypography-colorTextSecondary": {
      fontFamily: "Poppins",
      fontSize: "22px",
      color: "#13044F",
    },
    "& .MuiTypography-colorTextSecondary": {
      fontFamily: "Poppins",
      fontSize: "22px",
      color: "#13044F",
    },
  },
  "& .MuiTypography-colorTextSecondary": {
    fontFamily: "Poppins",
    fontSize: "22px",
    color: "#13044F",
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  borderLeft: "none",
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    width: "100%",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    padding: "0 12px !important",
    height: "71px",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "none",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "12px",
  display: "flex",
  justifyContent: "flex-end",
}));

export default function SubcategoryInput({ subcategory, newCategories }) {
  const priceRef = useRef(0);

  const [expanded, setExpanded] = useState(false);
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const t = useTranslate();

  const { mutateAsync: updateCompanyMutation } = useMutation(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(["companyCategories"]);
    },
  });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSaveCategory = () => {
    if (priceRef.current.value === "0" || priceRef.current.value === null) {
      subcategory.assigned = false;
      subcategory.price = 0;
    } else {
      subcategory.assigned = true;
      subcategory.price = priceRef.current.value;
      subcategory.currency = "SEK";
    }

    updateCompanyMutation({
      id: user.company_id,
      categories: newCategories,
    });
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={!expanded && <img src={iconEdit} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography
          sx={{
            width: "33%",
            flexShrink: 0,
            textAlign: "left",
            display: "flex",
            alignItems: "center",
            color: "#13044F",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          {subcategory ? (
            <Fragment>
              <FetchCategoryIcon
                name={subcategory?.icon}
                strokeColor={"strokeBlue"}
              />
              <span className="font-poppins font-medium ml-4">
                {subcategory.name}
              </span>
            </Fragment>
          ) : (
            t("categoryName")
          )}
          {subcategory.icon_all && (
            <Tooltip title={t("subcategoryPricing")}>
              <ErrorOutlineOutlinedIcon
                sx={{ fontSize: "16px", marginLeft: "16px" }}
              />
            </Tooltip>
          )}
        </Typography>
        <PriceTextField
          inputRef={priceRef}
          type="number"
          key={subcategory?.price}
          id="category-price"
          variant={expanded ? "outlined" : "standard"}
          disabled={!expanded}
          onClick={(event) => event.stopPropagation()}
          defaultValue={subcategory?.price ? subcategory?.price : ""}
          placeholder={!subcategory?.assigned ? t("add") : ""}
          inputProps={{
            style: {
              textAlign: "right",
              fontWeight: "400",
              fontFamily: "Poppins !important",
              fontSize: "22px",
              color: "#13044F",
              paddingRight: "0!important",
            },
          }}
          InputProps={{
            disableUnderline: true,
            endAdornment: !expanded && (
              <InputAdornment
                position="end"
                sx={{
                  border: expanded ? "1px solid #13044F" : "none",
                }}
              >
                {subcategory?.currency ? subcategory?.currency : ""}
              </InputAdornment>
            ),
          }}
          fullWidth
          sx={{
            marginRight: !expanded ? "25px" : "0",
            border: expanded ? "1px solid #13044F" : "none",
            borderRadius: "6px",
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Typography sx={{ display: "flex" }} component="span">
          <Button
            onClick={() => setExpanded(false)}
            className="w-36 h-12"
            variant="transparent"
          >
            {t("cancel")}
          </Button>
          <Button
            form="subcategories"
            onClick={() => {
              setExpanded(false);
              handleSaveCategory();
            }}
            className="w-36 h-12 ml-5"
          >
            {t("save")}
          </Button>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
