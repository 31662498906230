import React from 'react';
import { ReactComponent as CompanyAccepted } from '../../../../assets/CompanyAccepted.svg';
import { ReactComponent as XIcon } from '../../../../assets/xMarker.svg';
import useTranslate from '../../../../hooks/useTranslate';

const ModalAccepted = ({ setIsOpen, isEmailValid }) => {
  const t = useTranslate();

  return (
    <div className="flex flex-col gap-6 items-center justify-center py-8 relative">
      <XIcon
        className="absolute h-5 top-0 right-4 cursor-pointer"
        onClick={() => {
          setIsOpen(false);
        }}
      />
      <CompanyAccepted />
      <span className="font-poppins text-3xl text-violet-700">
        {t('companyIsAccepted')}
      </span>
      {!isEmailValid && (
        <span className="font-poppins text-md text-violet-700 text-center px-4">
          This company email is not valid, you should notify company about
          this issue.
        </span>
      )}
    </div>
  );
};

export default ModalAccepted;
