import { Link } from 'react-router-dom';
import phones1 from '../../assets/images/phones1.png';
import elipse59 from '../../assets/images/Ellipse59.svg';
import Button from '../../components/Common/Button';
import useTranslate from '../../hooks/useTranslate';

const LandingContentPart1 = () => {
  const t = useTranslate();
  return (
    <div className="flex h-full mt-7 custom-container flex-col lg:flex-row">
      <img
        src={elipse59}
        className="absolute right-0"
        style={{ height: '1055px' }}
      />
      <div className="w-full lg:w-3/6 mt-8 pt-10 sm:pt-20 xl:pt-44 lg:pl-40 pl-8 pr-8 lg:pr-0">
        <h1
          className="mb-8 font-medium"
          style={{ color: '#13044F', fontSize: '44px' }}
        >
          Ett enkelt och tillgängligt sätt att hantera ditt hem.
        </h1>
        <p
          className="opacity-70"
          style={{ color: '#13044F', fontSize: '18px' }}
        >
          Mendy mobilapp kopplar ihop bostadsägare och hyresgäster med
          personliga experter som hjälper till att ta hand om alla
          reparationsbehov i hemmet. Genom videosamtal hjälper vi våra kunder
          med expertrådgivning och felsökning av bland annat vitvaror, VVS,
          värmepumpar, elinstallationer och lås.
        </p>
        <div className="pt-14 flex flex-col sm:flex-none sm:flex-row gap-4">
          <Link to="/login">
            <Button variant="transparent">{t('learnMore')}</Button>
          </Link>
          <Link to="/register">
            <Button>{t('register')}</Button>
          </Link>
        </div>
      </div>

      <div className="w-full lg:w-3/6 mt-8">
        {/* <div style={{backgroundImage: "url(" + elipse59 + ")", position:"absolute",right:"-140px",top:"-100px",width:"1000px",height:"1000px",borderRadius:"50%"}}></div> */}
        <div className="text-center pt-8 lg:pt-28 lg:pt-44 relative">
          <img
            id="phones1"
            className="logo inline-block lg:-ml-11 px-8 sm:px-0"
            src={phones1}
            style={{ maxWidth: '700px' }}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart1;
