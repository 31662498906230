import React from 'react';
import userImageUrl from '../../../../assets/user-image.svg';
import useTranslate from '../../../../hooks/useTranslate';
import PlaceholderImage from '../../../../assets/placeholderImg.png';
import { DateTime } from 'luxon';

export default function ({ userDetails, selectedCaseDetails }) {
  const t = useTranslate();

  return (
    <div>
      {selectedCaseDetails?.appointments.length > 0 ? (
        <div className="flex flex-row justify-between py-4 px-6 case-detail-bottom-border">
          <span className="case-header-name font-normal text-base">
            {selectedCaseDetails?.appointments?.length > 0
              ? selectedCaseDetails?.appointments[0]?.time_slots?.date_from
              : '-'}
          </span>
          <span className="case-header-name font-normal text-base ">
            {' '}
            {selectedCaseDetails?.appointments?.length > 0
              ? DateTime.fromSQL(
                  selectedCaseDetails?.appointments[0]?.time_slots?.time_from
                ).toFormat('HH:mm')
              : '-'}
          </span>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-row justify-between py-4 px-6 case-detail-bottom-border">
            <span className="case-header-name font-normal text-base">
              {selectedCaseDetails?.time_slots
                ? selectedCaseDetails?.time_slots?.date_from
                  ? selectedCaseDetails?.time_slots?.date_from
                  : '-'
                : '-'}
            </span>
            <span className="case-header-name font-normal text-base ">
              {' '}
              {selectedCaseDetails?.time_slots
                ? selectedCaseDetails?.time_slots?.time_from
                  ? DateTime.fromSQL(
                      selectedCaseDetails?.time_slots?.time_from
                    ).toFormat('HH:mm')
                  : '-'
                : '-'}
            </span>
          </div>
          <div className="flex flex-row justify-between py-4 px-6 case-detail-bottom-border">
            <span className="case-header-name font-normal text-base">
              {selectedCaseDetails?.time_slots
                ? selectedCaseDetails?.time_slots?.date_to
                  ? selectedCaseDetails?.time_slots?.date_to
                  : '-'
                : '-'}
            </span>
            <span className="case-header-name font-normal text-base ">
              {' '}
              {selectedCaseDetails?.time_slots
                ? selectedCaseDetails?.time_slots?.time_to
                  ? DateTime.fromSQL(
                      selectedCaseDetails?.time_slots?.time_to
                    ).toFormat('HH:mm')
                  : '-'
                : '-'}
            </span>
          </div>
        </div>
      )}
      <div className="flex flex-col py-4 px-6 case-detail-bottom-border">
        <span className="case-header-name font-normal pb-4">
          <span className="font-medium">{t('address')}:</span>{' '}
          {selectedCaseDetails?.street}
        </span>
        <span className="case-header-name font-normal pb-4">
          <span className="font-medium">{t('postalNumber')}:</span>{' '}
          {selectedCaseDetails?.postal_number}{' '}
          {userDetails?.problem_municipality ||
            userDetails?.problem_municipality_name}
        </span>
        <span className="case-header-name font-normal ">
          <span className="font-medium">{t('city')}:</span>{' '}
          {userDetails?.city?.name ? userDetails?.city?.name : '-'}
        </span>
      </div>
      {selectedCaseDetails?.appointments.length > 0 && (
        <>
          <div className="flex flex-col items-start py-4 px-6 case-detail-bottom-border">
            <span className="case-header-name font-medium pb-4">
              {t('handyman')}
            </span>
            <div className="flex ">
              <img
                src={
                  selectedCaseDetails?.appointments?.length > 0
                    ? selectedCaseDetails?.appointments[0].handyman
                        ?.profile_image
                      ? selectedCaseDetails?.appointments[0].handyman
                          ?.profile_image
                      : PlaceholderImage
                    : PlaceholderImage
                }
                style={{
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                }}
                alt="Handyman"
              />
              <span className="flex flex-col px-2.5">
                <span className="case-header-name font-medium text-sm">
                  {selectedCaseDetails?.appointments?.length > 0
                    ? selectedCaseDetails?.appointments[0]?.handyman?.name + ' '
                    : '- '}
                  {selectedCaseDetails?.appointments?.length > 0
                    ? selectedCaseDetails?.appointments[0]?.handyman?.last_name
                    : '-'}
                </span>
                <span className="text-sm font-normal expert-title">
                  {userDetails?.appointments?.handyman?.handyman_role}
                </span>
              </span>
            </div>
          </div>
          <div className="flex flex-col  py-4 px-6 ">
            <span className="case-header-name font-medium pb-4">
              {t('descriptionByHandyman')}
            </span>
            <span className="font-normal expert-title text-sm max-w-xs">
              {selectedCaseDetails?.appointments?.length > 0
                ? selectedCaseDetails?.appointments[0]?.additional_info
                  ? selectedCaseDetails?.appointments[0]?.additional_info
                  : t('noData')
                : '-'}
            </span>
          </div>
        </>
      )}
    </div>
  );
}
