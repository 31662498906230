import React, { useState, useEffect } from "react";
import { useRef } from "react";
import AddAPhotoIcon from "../../../assets/images/camera-plus.svg";
import { Alert, Badge } from "@mui/material";

const badgeStyle = {
  "& .MuiBadge-badge": {
    color: "#FFFF",
    backgroundColor: "#2D12D6",
    width: 35,
    height: 35,
    borderRadius: "6px",
  },
};

export default function ImageUpload({
  setValue,
  valueName,
  editForm,
  currentImg,
}) {
  const profileImg = useRef(null);

  const [base64Url, setBase64Url] = useState("");
  const [file, setFile] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    setProfilePhoto(null);
  }, [currentImg]);

  const onProfileImgClick = () => {
    // `current` points to the mounted file input element
    profileImg.current.click();
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (file) {
      getBase64(file)
        .then((result) => {
          file["base64"] = result;
          setBase64Url(result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [file]);

  useEffect(() => {
    setValue(valueName.toString(), base64Url, { shouldDirty: true });
  }, [base64Url]);

  const handleFileInputChange = (e) => {
    setError("");
    if (e.target.files[0].size / 1024 > 5120) {
      setError("File is too large!");
      return;
    }
    setProfilePhoto(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  return (
    <span>
      {editForm ? (
        <Badge
          color="action"
          variant="rounded"
          badgeContent={<img src={AddAPhotoIcon} />}
          style={{ cursor: editForm ? "pointer" : "default" }}
          sx={badgeStyle}
          onClick={editForm ? onProfileImgClick : undefined}
        >
          <img
            className="w-20 h-20 rounded-md"
            src={profilePhoto ? profilePhoto : currentImg}
          />
          <input
            type="file"
            name="file"
            accept="image/*"
            ref={profileImg}
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
        </Badge>
      ) : (
        <img
          className="w-20 h-20 rounded-md"
          src={profilePhoto ? profilePhoto : currentImg}
        />
      )}
      {error !== "" && (
        <Alert severity="error" className="mt-4">
          {error}
        </Alert>
      )}
    </span>
  );
}
