import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import useDebounce from '../../../../../hooks/useDebounce';
import { useMutation } from '@tanstack/react-query';
import { FetchCategoryIcon } from '../../../../../helpers/categoryIcons';
import { searchHandymanByCategoryId } from '../../../../../api/Handyman/Handyman';

const HandymenList = ({ setSelectedHandyman, selectedCaseDetails }) => {
  //Translations
  const t = useTranslate();

  //Select box
  const [open, setOpen] = useState(false);

  //Search
  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  // Mutation for user search
  const { mutateAsync: searchMutation, isLoading: searchLoading } = useMutation(
    searchHandymanByCategoryId,
    {
      onSuccess: (data) => {
        setSearchData(data);
      },
    }
  );

  useEffect(() => {
    const callSearchMutation = async () => {
      await searchMutation({
        categoryId: selectedCaseDetails?.id,
        searchTerm: searchTerm,
      });
    };
    if (debouncedSearchTerm !== undefined) {
      callSearchMutation();
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="flex flex-col gap-2">
      <p className="font-normal text-md">{t('handyman')}:</p>
      <div className="w-full">
        <TextField
          onChange={(event) => {
            setSearchTerm(event.target.value);
            setOpen(true);
          }}
          value={searchTerm}
          fullWidth
          type="search"
          variant="outlined"
          className="outline-none rounded-l-md px-4 py-1.5 search-bar-font-color  placeholder:text-small min-w-0 flex-1 flex-shrink"
          onClick={() => setOpen(!open)}
          placeholder={`${t('search')}...`}
        />
      </div>
      <div className="table-container rounded-xl w-full min-wh-[400px] relative">
        {open && (
          <div className="bg-white shadow-md px-2 rounded-md absolute z-10 w-full">
            {searchData
              ?.filter((i, index) => index < 5)
              .map((handyman, index) => (
                <span
                  className="flex justify-between items-center border-b cursor-pointer"
                  key={index}
                  onClick={() => {
                    setSelectedHandyman(handyman);
                    setSearchTerm(handyman.name + ' ' + handyman.last_name);
                    setOpen(false);
                  }}
                >
                  <p className="cursor-pointer py-2 ">
                    {handyman.name} {handyman.last_name}
                  </p>
                  <span className="flex gap-1">
                    {handyman?.categories
                      ?.filter((i, index) => index < 3)
                      .map((category, index) => (
                        <FetchCategoryIcon
                          key={index}
                          strokeColor={'strokeBlue'}
                          name={category?.icon}
                        />
                      ))}
                  </span>
                </span>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default HandymenList;
