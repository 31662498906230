import React from 'react';
import {
  Box,
  IconButton,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import './CommonTable.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import lastPageIcon from '../../../assets/arrows/last-page-button.svg';
import firstPageIcon from '../../../assets/arrows/first-page-button.svg';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import useTranslate from '../../../hooks/useTranslate';
import { Fragment } from 'react';

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: '#13044F',
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '1.75rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: 'Poppins',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'center',
    cursor: 'pointer',
    paddingLeft: '1.75rem',
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(count / rowsPerPage) - 1)
    );
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? (
          <img src={lastPageIcon} alt="lastPage" />
        ) : (
          <img src={firstPageIcon} alt="firstPage" />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      {/* <Pagination hideNextButton hidePrevButton count={3} /> */}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? (
          <img src={firstPageIcon} alt="first-page" />
        ) : (
          <img src={lastPageIcon} alt="last-page" />
        )}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function CommonTable({
  header,
  tableData,
  page,
  count,
  rowsPerPage,
  setPage,
}) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const t = useTranslate();

  return (
    <div className="common-table bg-white rounded-xl table-shadow w-full">
      {count !== 0 ? (
        <TableContainer className="font-poppins flex items-center">
          <Table>
            <TableHead>
              {header[0] === 'Company' ? (
                <TableRow>
                  {/* <StyledTableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    inputProps={{
                     "aria-label": "select all companies",
                    }}
                  />
                </StyledTableCell> */}

                  {header?.map((item, index) => (
                    <Fragment key={item}>
                    <StyledTableCell key={item}>
                      {item}
                    </StyledTableCell>
                    </Fragment>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {header?.map((item, index) => (
                    <Fragment key={item}>
                    <StyledTableCell
                      className={
                        item === 'Status' ? 'status-header' : item === 'Brådskande karaktär'  ? 'status-header' : ''
                      }
                      key={index}
                    >
                      {item}
                    </StyledTableCell>
                    </Fragment>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody columns={tableData}>{tableData}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${t('showing')} ${from} ${t('to')} ${to} ${t(
                      'of'
                    )} ${
                      count !== -1 ? count : `${t('moreThan')} ${to}`
                    }`
                  }
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <h1 className="text-center text-2xl font-medium p-6">
          {t('noData')}
        </h1>
      )}
    </div>
  );
}
