import { Checkbox } from '@material-ui/core';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateToS } from '../../../../api/Settings/TermsAndConditions/TermsAndConditions';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import Input from '../../../Common/Input';
import TextArea from '../../../Common/TextArea';

const TermsEditor = ({ cancleEditing, data, setTermsPreviewValue }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm({ mode: 'all' });
  const t = useTranslate();
  const [checked, setChecked] = useState(false);
  const queryClient = useQueryClient();

  const { mutateAsync: editToSMutation } = useMutation(updateToS, {
    onSuccess: () => {
      cancleEditing(true);
      reset();
      setTermsPreviewValue('');
      queryClient.invalidateQueries(['ToS']);
    },
  });

  useEffect(() => {
    reset(data);
  }, []);

  const submitHandler = (data) => {
    editToSMutation({ data, checked });
  };

  return (
    <div className="h-full">
      <form className="h-full">
        <div className="flex gap-10 h-full items-stretch">
          <div className="w-1/2 h-full">
            <Input
              register={register}
              placeholder={`${t('title')}...`}
              type="text"
              name="title"
              label={`${t('title')}:`}
              required={t('thisFieldIsRequired')}
              isError={errors.title?.message}
            />
            <ErrorMessage>{errors.title?.message}</ErrorMessage>
            <Input
              register={register}
              placeholder={`${t('subtitle')}...`}
              type="text"
              name="subtitle"
              label={`${t('subtitle')}:`}
              required={t('thisFieldIsRequired')}
              isError={errors.subtitle?.message}
            />
            <ErrorMessage>{errors.title?.message}</ErrorMessage>
            <Input
              register={register}
              placeholder={`${t('version')}...`}
              type="text"
              name="version"
              label={`${t('version')}:`}
              required={t('thisFieldIsRequired')}
              isError={errors.version?.message}
            />
            <ErrorMessage>{errors.version?.message}</ErrorMessage>
          </div>
          <div className="w-1/2 self-stretch mb-6 ">
            <TextArea
              register={register}
              placeholder={`${t('content')}...`}
              type="text"
              name="text"
              label={`${t('content')}:`}
              required={t('thisFieldIsRequired')}
              isError={errors?.text?.message}
              onChange={() => {
                setTermsPreviewValue(getValues('text'));
              }}
            />
            <ErrorMessage>{errors?.text?.message}</ErrorMessage>
          </div>
        </div>

        <div className="flex gap-3 justify-end mt-4">
          <div className="mr-auto">
            <Checkbox
              name="newAccept"
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            />
            <label className="text-sm">{t('radicalChange')}</label>
          </div>
          <Button onClick={cancleEditing}>{t('cancel')}</Button>
          <Button onClick={handleSubmit(submitHandler)}>{t('save')}</Button>
        </div>
      </form>
    </div>
  );
};

export default TermsEditor;
