import api_url from "../../libary/axios-home";

export const updateHandyman = async (formData) => {
  const { id } = formData;
  delete formData.approval_status;
  delete formData.approval_status_by;
  if (formData.documents.length === 0) delete formData.documents;
  const { data } = await api_url.put(`user/update/${id}`, {
    ...formData,
  });
  return data;
};

export const createHandyman = async (formData) => {
  const { data } = await api_url.post(`register/handyman`, {
    ...formData,
    logged_user_role_id: 1,
  });
  return data;
};

export const fetchHandymenByCompanyId = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get("handyman/company/" + id);
  return data;
};

export const fetchHandymanById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get("user/" + id + "/categories");
  return data;
};

export const fetchCompletedCasesByHandymanId = async ({ queryKey }) => {
  const [_, id] = queryKey;
  const { data } = await api_url.get(
    "problems/handyman/" + id + "/status/completed"
  );
  return data;
};

export const searchHandymanByCategoryId = async (searchParams) => {
  const { categoryId, searchTerm } = searchParams;
  const { data } = await api_url.get(`handyman/filter/${categoryId}`, {
    params: {
      search: searchTerm,
    },
  });
  return data;
};
