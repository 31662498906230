import React from 'react';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { ReactComponent as TrashIcon } from '../../assets/images/tabler_trash.svg';
import { ReactComponent as EditIcon } from '../../assets/images/tabler_edit.svg';
import { ReactComponent as Star } from '../../assets/images/tabler_star.svg';
import PlaceholderImage from '../../assets/placeholderImg.png';
import useTranslate from '../../hooks/useTranslate';

const UserTable = ({
  row,
  index,
  fetchUser,
  setSelectedId,
  setSelectedUserDetails,
  setSidePanel,
  setDeleteModal,
}) => {
  const t = useTranslate();

  return (
    <TableRow row={row} key={index}>
      <StyledTableCell
        onClick={() => {
          setSelectedId(row?.id);
          setSelectedUserDetails(row);
          setSidePanel(true);
        }}
      >
        <div className="flex">
          <img
            src={row?.profile_image ? row?.profile_image : PlaceholderImage}
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            alt="User"
            className="w-10 h-10 rounded-full min-w-[40px] object-cover"
          />
          <div className="flex flex-col px-4">
            <span className="font-medium text-base leading-6 font-color">
              {row?.name ? row?.name : '_'}{' '}
              {row?.last_name ? row?.last_name : '-'}
            </span>
            <span className="font-color">
              {row?.post_code ? row?.post_code : '-'}{' '}
              {row?.street ? row?.street : '-'}
            </span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell
        onClick={() => {
          setSelectedId(row?.id);
          setSelectedUserDetails(row);
          setSidePanel(true);
        }}
      >
        <div className="flex flex-col">
          <span className="font-medium text-base leading-6 font-color">
            {row?.phone}
          </span>
          <span className="font-color">{row.email}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell
        onClick={() => {
          setSelectedId(row?.id);
          setSelectedUserDetails(row);
          setSidePanel(true);
        }}
      >
        {row?.dwelling_type === 'house' ? (
          <div className="flex items-center pl-10 ml-2 status-house">
            {row?.dwelling_type ? t('house') : '-'}
          </div>
        ) : (
          <div className="flex items-center pl-10 ml-2 status-apartment">
            {row?.dwelling_type ? t('apartment') : '-'}
          </div>
        )}
      </StyledTableCell>
      <StyledTableCell
        onClick={() => {
          setSelectedId(row?.id);
          setSelectedUserDetails(row);
          setSidePanel(true);
        }}
      >
        <span className="flex gap-2">
          <Star />
          {row?.avg_rating ? row?.avg_rating : 4}
        </span>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex gap-3  justify-end pr-5">
          <EditIcon />
          <TrashIcon
            onClick={() => {
              setDeleteModal(true);
              setSelectedUserDetails(row);
            }}
          />
        </div>
      </StyledTableCell>
    </TableRow>
  );
};

export default UserTable;
