import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {
  expert: [],
};

export const fetchExpertProblemApi = createAsyncThunk(
  "getExpertProblem/fetchExpertProblemApi",
  async ({ id }) => {
    try {
      const response = await api_url.get("expert/" + id + "/problem-status/completed");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getExpertProblemSlice = createSlice({
  name: "getExpertProblemSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchExpertProblemApi.pending, (state, action) => {
      // Add user to the state array
    });
    builder.addCase(fetchExpertProblemApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.expert = action.payload;
    });
    builder.addCase(fetchExpertProblemApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default getExpertProblemSlice.reducer;
