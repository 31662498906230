import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useAuth from '../../../../hooks/useAuth';
import { fetchCompanyHandymenByRepairs } from '../../../../api/Company/Company';
import { useQuery } from '@tanstack/react-query';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

//Chart options
export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#13044F',
      padding: 10,
      usePointStyle: false,
      titleColor: '#ffffff',
      titleAlign: 'center',
      titleFont: { size: '14px' },
      callbacks: {
        labelColor: function (context) {
          return {
            borderWidth: 2,
            borderRadius: 2,
          };
        },
        labelTextColor: function (context) {
          return '#ffffff';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        borderDash: [5, 2],
      },
    },
    y: {
      grid: {
        borderDash: [5, 2],
      },
      ticks: {
        precision: 0,
        beginAtZero: true,
      },
    },
  },
};

const TopHandymanChart = () => {
  const { user } = useAuth();

  // Fetch top handymen
  const {
    data: companyTopHandymen,
    isLoading: companyTopHandymenLoading,
    isError: companyTopHandymenError,
  } = useQuery(
    ['companyTopHandymen', user?.company_id],
    fetchCompanyHandymenByRepairs,
    {
      onSuccess: () => {},
    }
  );

  //Labels data
  const labels = companyTopHandymen?.labels?.map((item) => {
    return new Date(item);
  });

  //Top 5 handymen data
  const handyman0Data = companyTopHandymen?.data[0]
    ? Object.entries(companyTopHandymen?.data[0]?.appointmentsPer30Days)?.map(
        (appointment) => {
          return appointment[1]?.count;
        }
      )
    : [];

  const handyman1Data = companyTopHandymen?.data[1]
    ? Object.entries(companyTopHandymen?.data[1]?.appointmentsPer30Days)?.map(
        (appointment) => {
          return appointment[1]?.count;
        }
      )
    : [];

  const handyman2Data = companyTopHandymen?.data[2]
    ? Object.entries(companyTopHandymen?.data[2]?.appointmentsPer30Days)?.map(
        (appointment) => {
          return appointment[1]?.count;
        }
      )
    : [];

  const handyman3Data = companyTopHandymen?.data[3]
    ? Object.entries(companyTopHandymen?.data[3]?.appointmentsPer30Days)?.map(
        (appointment) => {
          return appointment[1]?.count;
        }
      )
    : [];

  const handyman4Data = companyTopHandymen?.data[4]
    ? Object.entries(companyTopHandymen?.data[4]?.appointmentsPer30Days)?.map(
        (appointment) => {
          return appointment[1]?.count;
        }
      )
    : [];

  //Chart data
  const data = {
    labels: labels?.reverse()?.map((item) => {
      return item.getDate();
    }),
    datasets: [
      {
        label: `${companyTopHandymen?.data[0]?.handyman?.name} ${companyTopHandymen?.data[0]?.handyman?.last_name}`,
        data: labels?.map((label, index) => handyman0Data[index]),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        pointBackgroundColor: 'white',
        pointRadius: 4,
      },
      {
        label: `${companyTopHandymen?.data[1]?.handyman?.name} ${companyTopHandymen?.data[1]?.handyman?.last_name}`,
        data: labels?.map((label, index) => handyman1Data[index]),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointBackgroundColor: 'white',
        pointRadius: 4,
      },
      {
        label: `${companyTopHandymen?.data[2]?.handyman?.name} ${companyTopHandymen?.data[2]?.handyman?.last_name}`,
        data: labels?.map((label, index) => handyman2Data[index]),
        borderColor: 'rgb(181, 23, 158)',
        backgroundColor: 'rgba(181, 23, 158, 0.5)',
        pointBackgroundColor: 'white',
        pointRadius: 4,
      },
      {
        label: `${companyTopHandymen?.data[3]?.handyman?.name} ${companyTopHandymen?.data[3]?.handyman?.last_name}`,
        data: labels?.map((label, index) => handyman3Data[index]),
        borderColor: 'rgb(145, 145, 145)',
        backgroundColor: 'rgba(145, 145, 145, 0.5)',
        pointBackgroundColor: 'white',
        pointRadius: 4,
      },
      {
        label: `${companyTopHandymen?.data[4]?.handyman?.name} ${companyTopHandymen?.data[4]?.handyman?.last_name}`,
        data: labels?.map((label, index) => handyman4Data[index]),
        borderColor: 'rgb(72, 12, 168)',
        backgroundColor: 'rgba(72, 12, 168, 0.5)',
        pointBackgroundColor: 'white',
        pointRadius: 4,
      },
    ],
  };

  return <Line options={options} data={data} width={25} height={50} />;
};

export default TopHandymanChart;
