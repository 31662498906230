import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {};

export const updateCompanyByIdApi = createAsyncThunk(
  "putCompanyById/updateCompanyByIdApi",
  async ({ id, data }) => {
    try {
      const response = await api_url.put("companies/update/" + id, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const putCompanyByIdSlice = createSlice({
  name: "putCompanyByIdSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(updateCompanyByIdApi.pending, (state, action) => {
      state.status = "loading";
      // Add user to the state array
    });
    builder.addCase(updateCompanyByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeeded";
    });
    builder.addCase(updateCompanyByIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default putCompanyByIdSlice.reducer;
