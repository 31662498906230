import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";

export const initialState = {};

export const removeUserByIdApi = createAsyncThunk(
  "deleteUserById/removeUserByIdApi",
  async (id) => {
    try {
      const response = await api_url.delete("user/" + id + "/delete");
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const deleteUserByIdSlice = createSlice({
  name: "deleteUserByIdSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(removeUserByIdApi.pending, (state, action) => {
      state.status = "loading";
      // Add user to the state array
    });
    builder.addCase(removeUserByIdApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.status = "succeeded";
    });
    builder.addCase(removeUserByIdApi.rejected, (state, action) => {
      // Add user to the state array
    });
  },
});

export default deleteUserByIdSlice.reducer;
