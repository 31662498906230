import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as Minus } from '../../assets/Minus.svg';
import { removeSubCategory } from '../../helpers/Expert/filterExpertCategories';
import useTranslate from '../../hooks/useTranslate';
import { FetchCategoryIcon } from '../../helpers/categoryIcons';

const CategoryDropdown = ({
  data,
  category,
  disabled,
  setEditableCategories,
  setIsOpen,
  setSubContent,
}) => {
  const [selected, setSelected] = useState(data ? data[0] : '');
  const t = useTranslate();
  return (
    <div className="flex items-center gap-3 w-full">
      <Listbox
        value={selected}
        onChange={setSelected}
        as="div"
        className="w-full"
      >
        <div className="relative">
          <Listbox.Button
            className="relative w-full cursor-default rounded-lg h-12
               bg-white py-2 pl-3 pr-10 text-left  shadow-sm border block
                sm:text-sm px-2 outline-none flex gap-3 items-center"
          >
            <FetchCategoryIcon
              name={category?.icon}
              strokeColor={'strokeBlack'}
            />
            <span className="block truncate">{category?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <KeyboardArrowDownIcon />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md
                 bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10"
            >
              {data
                ?.filter((item) => item.assigned === true)
                .map((data, dataIdx) => (
                  <Listbox.Option
                    disabled={disabled}
                    key={dataIdx}
                    className={`relative cursor-default select-none py-2 pl-5 pr-4
                  text-gray-900`}
                    value={data}
                  >
                    <>
                      <div className="flex gap-3">
                        <FetchCategoryIcon
                          name={data.icon}
                          strokeColor={'strokeBlack'}
                        />
                        <span
                          className={`block truncate font-normal`}
                        >
                          {data.name}
                        </span>
                      </div>
                      {!disabled && (
                        <Minus
                          className="w-4 absolute inset-y-0 right-3 top-2 flex items-center cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditableCategories((prevState) =>
                              removeSubCategory(prevState, data.id)
                            );
                          }}
                        />
                      )}
                    </>
                  </Listbox.Option>
                ))}
              {!disabled && (
                <div
                  className="border-t border-solid border-slate-200 flex py-2 px-4 gap-2 cursor-pointer items-center"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSubContent(category?.id);
                    setIsOpen(true);
                  }}
                >
                  <Plus className="w-4" />
                  <span className="text-textGray">
                    {t('addASubcategory')}
                  </span>
                </div>
              )}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default CategoryDropdown;
