import React, { useState } from 'react';
import { imageArray } from '../../../../../helpers/categoriesArray';
import Search from '../../../../../containers/Breadcrumb/Search/Search';
import Button from '../../../../Common/Button';
import useTranslate from '../../../../../hooks/useTranslate';
import { FetchCategoryIcon } from '../../../../../helpers/categoryIcons';

const IconSelection = ({
  selectedCategory,
  setSelectedIcon,
  setIsOpen,
  selectedIcon,
}) => {
  const [iconSelected, setIconSelected] = useState('');
  const t = useTranslate();

  return (
    <div className="px-12 py-4 flex flex-col gap-5 items-center">
      <span className="font-poppins text-xl text-headingActive text-center inline-block">
        {t('chooseIconFor')}{' '}
        <span className="text-indigo-500">
          {selectedCategory?.name}:
        </span>
      </span>
      <div className="flex flex-col w-80 justify-center items-center mb-3">
        <Search placeholder="Search..." />
        <div className=" overflow-auto  flex items-center justify-center flex-wrap w-full mt-5">
          {imageArray.map((item) => (
            <div
              key={item}
              className={
                `hover:border-violetPrimary rounded-lg transition-all
                    cursor-pointer border border-dashed
                    w-11 h-11 flex items-center justify-center ` +
                (iconSelected == item
                  ? ' border-violetPrimary'
                  : ' border-transparent')
              }
              onClick={() => {
                setIconSelected(item);
              }}
            >
              <FetchCategoryIcon
                name={item}
                strokeColor={'strokeBlack'}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-3 ml-auto">
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            setSelectedIcon(iconSelected);
            setIsOpen(false);
          }}
        >
          {t('save')}
        </Button>
      </div>
    </div>
  );
};

export default IconSelection;
