import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api_url from "../../libary/axios-home";


export const initialState = {
  companiesStatus: []
};

export const fetchCompaniesByStatusApi = createAsyncThunk(
  'activeCompanies/fetchCompaniesByStatusApi',
  async ({status}) => {
    try {
      const response = await api_url.get('companies/status/'+status );
      return response.data;

    } catch (error) {
      console.error(error);
    }

  }
)

const companiesByStatusSlice = createSlice({
  name: "companiesByStatusSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchCompaniesByStatusApi.pending, (state, action) => {
      // Add user to the state array
    })
    builder.addCase(fetchCompaniesByStatusApi.fulfilled, (state, action) => {
      // Add user to the state array
      state.companiesStatus = action.payload;
    })
    builder.addCase(fetchCompaniesByStatusApi.rejected, (state, action) => {
      // Add user to the state array
    })
  },
});



export default companiesByStatusSlice.reducer;