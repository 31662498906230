import rate_handyman from "../../assets/images/rate-handyman.png";
import rate_user from "../../assets/images/rate-user.png";

const LandingContentPart6 = () => {
  return (
    <div className="flex h-full pb-32 sm:pb-44 relative ">
      <div className="flex flex-col-reverse lg:flex-row custom-container">
        <div className="w-full lg:w-3/6 mt-4  pt-16 lg:pt-2 ">
          <div className="text-center">
            <img className="logo inline-block" src={rate_user} />
            <img className="logo inline-block -mt-16 -ml-20 sm:-ml-12" src={rate_handyman} />
          </div>
        </div>

        <div className="w-full lg:w-3/6 mt-8  pt-0 lg:pt-20 lg:pl-0 lg:pr-40 pl-8 pr-8">
          <h3 className="mb-8" style={{ color: "#13044F", fontSize: "32px" }}>
            5. Kvalitetssäkring
          </h3>
          <p
            className="opacity-70"
            style={{ color: "#13044F", fontSize: "18px" }}
          >
            Både du och vår fastighetsexpert har möjlighet att betygsätta
            varandra när videosamtalet är genomfört. Detta hjälper oss att
            förbättra och kvalitetssäkra den service som vi erbjuder dig.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart6;
