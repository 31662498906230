import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { fetchCompanyCategoriesById } from "../../api/Company/Company";
import SubcategoryInput from "./SubcategoryInput";
import { LinearProgress, Box } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../../hooks/useAuth";
import CategoryInput from "./CategoryInput";
import { FetchCategoryIcon } from "../../helpers/categoryIcons";

const globalCategory = {
  name: "Vitvaror",
  icon_all: <ArrowForwardIosIcon />,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-full"
    >
      {value === index && (
        <Box>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Prices() {
  const [value, setValue] = useState(0);
  const { user } = useAuth();

  const { data: categoriesList, isLoading: categoriesLoading } = useQuery(
    ["companyCategories", user?.company_id],
    fetchCompanyCategoriesById,
    {
      onSuccess: () => {},
    }
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      {categoriesLoading ? (
        <Box sx={{ width: "100%", padding: "20px 0" }}>
          <LinearProgress />
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            marginBottom: "4rem",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: "20%",
              "& .MuiInputBase-input.Mui-disabled": {
                textFillColor: "#13044F",
                fontWeight: "400",
                fontSize: "22px",
              },
            }}
            indicatorColor="secondary"
            TabIndicatorProps={{
              style: {
                width: "100%",
                backgrouncColor: "#7209B7",
                opacity: "0.08",
              },
            }}
          >
            {categoriesList?.map((category, index) => (
              <Tab
                sx={{
                  width: "100%",
                  display: "flex !important",
                  borderBottom: "solid 1px rgb(189,189,189, 0.3) !important",
                  textFillColor: "#13044F",
                  color: "#13044F",
                  fontWeight: "500 !important",
                }}
                key={index}
                icon={
                  <ArrowForwardIosIcon
                    sx={{
                      fontSize: 14,
                      color: "#13044F",
                      marginLeft: "auto!important",
                    }}
                  />
                }
                iconPosition="end"
                label={
                  <Fragment>
                    <FetchCategoryIcon
                      name={category?.icon}
                      strokeColor={"strokeBlue"}
                    />
                    <span className="ml-4">{category?.name}</span>
                  </Fragment>
                }
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
          {categoriesList?.map((category, index) => (
            <TabPanel key={index} value={value} index={index}>
              <CategoryInput category={category} />
              {category?.subcategories.map((subcategory, index) => (
                <SubcategoryInput
                  key={index}
                  category={category}
                  subcategory={subcategory}
                  newCategories={categoriesList}
                />
              ))}
            </TabPanel>
          ))}
        </Box>
      )}
    </Fragment>
  );
}
