import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { fetchPlatformProfit } from '../../../api/Admin/Admin';
import { useQuery } from '@tanstack/react-query';
import { Box, CircularProgress } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  Filler
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: '#13044F',
      padding: 10,
      usePointStyle: false,
      titleColor: '#ffffff',
      titleAlign: 'center',
      titleFont: { size: '14px' },
      callbacks: {
        labelColor: function (context) {
          return {
            borderWidth: 2,
            borderRadius: 2,
          };
        },
        labelTextColor: function (context) {
          return '#ffffff';
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        borderDash: [5, 2],
      },
    },
    y: {
      grid: {
        borderDash: [5, 2],
      },
    },
  },
};

const SuperProfitChart = ({ setTotalProfit }) => {
  // Fetch company profit
  const {
    data: platformProfit,
    isLoading: platformProfitLoading,
    isError: platformProfitError,
  } = useQuery(['platformProfit'], fetchPlatformProfit, {
    onSuccess: (data) => {
      setTotalProfit(data?.total_profit);
    },
  });

  const labels = platformProfit?.profit_by_day?.map((item) => {
    return new Date(item?.date);
  });

  const profitData = platformProfit?.profit_by_day?.map((item) => {
    return item?.profit;
  });

  const data = {
    labels: labels?.reverse().map((item) => {
      return item.getDate();
    }),
    datasets: [
      {
        fill: true,
        label: 'Profit',
        data: labels?.map((label, index) => profitData[labels?.length - 1 - index]),
        borderColor: 'rgba(181,23,158, 0)',
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, 'rgba(181,23,158, 1)');
          gradient.addColorStop(1, 'rgba(72,12,168, 1)');
          return gradient;
        },
        pointBackgroundColor: 'white',
        pointRadius: 0,
      },
    ],
  };

  return (
    <>
      {platformProfitLoading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Line options={options} data={data} width={25} height={50} />
      )}
    </>
  );
};

export default SuperProfitChart;
