import { Box, LinearProgress, TableRow } from '@material-ui/core';
import { Fragment, useState } from 'react';
import CommonTable from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import CommonDetailView from '../../../containers/MainContentContainer/CommonDetailViewComponent/CommonDetailView';
import rateImageUrl from '../../../assets/images/tabler_star.svg';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import PlaceholderImage from '../../../assets/placeholderImg.png';
import '../Cases.css';
import { FetchCategoryIcon } from '../../../helpers/categoryIcons';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { fetchCompanyCasesByStatus } from '../../../api/Company/Company';
import { useQuery } from '@tanstack/react-query';
import { fetchNewCases } from '../../../api/Case/Case';

const NewCases = () => {
  const { user } = useAuth();
  const role = user.role_id;
  const companyId = user.company_id;
  const caseStatus = 'new';

  const [userDetails, setUserDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const [value, setValue] = useState(t('case'));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const header = [
    t('user'),
    t('category'),
    t('rating'),
    t('hasOffers'),
    t('newCasesSuggestedTime'),
    t('urgency'),
  ];
  const superTabs = [t('case'), t('details'), t('chat'), t('ratings')];
  const companyTabs = [t('case'), t('details')];

  const {
    data: companyNewCases,
    isLoading: companyNewCasesLoading,
    isCompanyCasesError,
  } = useQuery(
    ['newCompanyCases', companyId, caseStatus],
    fetchCompanyCasesByStatus,
    {
      onSuccess: () => {},
      enabled: user.role_id === 2,
    }
  );

  const {
    data: newCases,
    isLoading: allNewCasesLoading,
    isAllNewCasesError,
  } = useQuery(['newAllCases', companyId, caseStatus], fetchNewCases, {
    onSuccess: () => {},
    enabled: user.role_id === 1,
  });

  const tableViewData =
    role === 1 ? newCases : role === 2 ? companyNewCases : null;

  const dataPreview = (
    rowsPerPage > 0
      ? tableViewData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : tableViewData
  )?.map((row, index) => (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow === index && 'active-row'}`}
      onClick={() => {
        setUserDetails(row);
        setActiveRow(index);
      }}
    >
      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-72 gap-3">
            <img
              src={
                row?.users_profile_image === null
                  ? PlaceholderImage
                  : row?.users_profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="user"
            />
            <div className="w-72 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.user_name} {row?.user_last_name}
              </p>
              <span className="font-color truncate">
                {row?.user_post_code} {row?.user_street}
              </span>
            </div>
          </div>
        ) : role === 2 ? (
          <div className="flex w-72 gap-3">
            <img
              src={
                row?.user?.profile_image === null
                  ? PlaceholderImage
                  : row?.user?.profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              className="w-10 h-10 rounded-full min-w-[40px] object-cover"
              alt="user"
            />
            <div className="w-72 truncate">
              <p className="font-medium text-base leading-6 font-color truncate">
                {row?.user?.name} {row?.user?.last_name}
              </p>
              <span className="font-color truncate">
                {row?.user?.post_code} {row?.user?.street}
              </span>
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>
      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-44 items-center">
            <FetchCategoryIcon
              name={row?.problem_category_icon}
              strokeColor="strokeBlue"
            />
            <div className="ml-2">
              <p className="font-medium text-base leading-6 font-color">
                {row?.problem_parent_category_name}
              </p>
              <span className="font-color">{row?.problem_category_name}</span>
            </div>
          </div>
        ) : role === 2 ? (
          <div className="flex w-44 items-center">
            <FetchCategoryIcon
              name={row?.category?.icon}
              strokeColor="strokeBlue"
            />
            <div className="ml-2">
              <p className="font-medium text-base leading-6 font-color">
                {row?.category?.parent?.name}
              </p>
              <span className="font-color">{row?.category?.name}</span>
            </div>
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>
      <StyledTableCell>
        {role === 1 ? (
          <div className="flex w-36">
            {row?.avg_rating ? (
              <>
                <img src={rateImageUrl} alt="Rating" />
                <div className="px-4">
                  <p className="name-title">{row?.avg_rating}</p>
                </div>
              </>
            ) : (
              <p className="font-color">{t('noData')}</p>
            )}
          </div>
        ) : role === 2 ? (
          <div className="flex w-24">
            {row?.user?.avg_rating ? (
              <>
                <img src={rateImageUrl} alt="Rating" />
                <div className="px-4">
                  <p className="name-title">{row?.user?.avg_rating}</p>
                </div>
              </>
            ) : (
              <p className="font-color">{t('noData')}</p>
            )}
          </div>
        ) : (
          ''
        )}
      </StyledTableCell>
      {role === 2 && (
        <StyledTableCell className="text-center">
          <span
            className={
              `p-1 pb-2 rounded-md text-white mr-10 ` +
              (!row?.appointments.length <= 0
                ? `bg-[#44b700] `
                : `bg-[#D80000] `)
            }
          >
            {!row?.appointments.length <= 0 ? <CheckIcon /> : <CloseIcon />}
          </span>
        </StyledTableCell>
      )}
      {role === 1 ? (
        activeRow === null && (
          <StyledTableCell>
            <p className="font-medium text-base leading-6 font-color">
              {DateTime.fromISO(row?.created_at, {
                zone: 'Europe/Stockholm',
              }).toFormat('HH:mm')}
            </p>
            <span className="font-color">
              {DateTime.fromISO(row?.created_at, {
                zone: 'Europe/Stockholm',
              }).toFormat('yyyy-LL-dd')}
            </span>
          </StyledTableCell>
        )
      ) : role === 2 ? (
        <StyledTableCell>
          {row?.time_slots ? (
            <div className="flex flex-col">
              <span className="font-medium text-base">
                {DateTime.fromISO(row?.time_slots?.time_from).toFormat('HH:mm')}
                -{DateTime.fromISO(row?.time_slots?.time_to).toFormat('HH:mm')}
              </span>

              <span>{row?.time_slots?.date_from}</span>
            </div>
          ) : (
            '-'
          )}
        </StyledTableCell>
      ) : (
        ''
      )}

      {role === 1 ? (
        <StyledTableCell>
          <div className="flex items-center justify-center">
            <span
              className={
                row?.is_urgent === true ? 'status-urgent' : 'status-non-urgent'
              }
            >
              {row?.is_urgent === true ? t('urgent') : t('nonUrgent')}
            </span>
          </div>
        </StyledTableCell>
      ) : role === 2 ? (
        activeRow === null && (
          <StyledTableCell>
            <div className="flex items-center justify-center">
              <span
                className={
                  row?.is_urgent === true
                    ? 'status-urgent'
                    : 'status-non-urgent'
                }
              >
                {row?.is_urgent === true ? t('urgent') : t('nonUrgent')}
              </span>
            </div>
          </StyledTableCell>
        )
      ) : (
        ''
      )}
    </TableRow>
  ));

  return (
    <div className={`px-8 my-4  overflow-hidden ${userDetails && 'flex'}`}>
      <div className={`rounded-xl ${userDetails && 'table-width-onclick'}`}>
        <Fragment>
          {companyNewCasesLoading && allNewCasesLoading ? (
            <Box sx={{ width: '100%', padding: '20px 0' }}>
              <LinearProgress />
            </Box>
          ) : (
            <CommonTable
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={tableViewData ? tableViewData.length : 0}
              header={
                userDetails
                  ? role === 1
                    ? header.filter((i) => i !== header[3] && i !== header[4])
                    : role === 2
                    ? header.filter((i) => i !== header[5])
                    : header
                  : role === 1
                  ? header.filter((i) => i !== header[3])
                  : header
              }
              tableData={dataPreview}
            />
          )}
        </Fragment>
      </div>
      {userDetails && (
        <CommonDetailView
          caseStatus={caseStatus}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          setActiveRow={setActiveRow}
          tabs={user.role_id === 1 ? superTabs : companyTabs}
          setValue={setValue}
          role={role}
        />
      )}
    </div>
  );
};

export default NewCases;
