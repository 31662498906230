Input.defaultProps = {
  labelWidth: 'w-24',
};

export default function Input({
  type,
  name,
  id,
  placeholder,
  value,
  onChange,
  label,
  register,
  required,
  pattern,
  max,
  min,
  disabled,
  isError,
  minValidation,
  labelWidth,
  noLabel,
  readOnly,
}) {
  return (
    <div className="flex items-center gap-3 w-full">
      {!noLabel && (
        <label
          htmlFor={id}
          className={
            `block text-left text-sm font-medium ${
              disabled ? 'text-gray-400 ' : 'text-gray-700 '
            }` + labelWidth
          }
        >
          {label}
        </label>
      )}
      <div className="mt-1 mb-2 w-full">
        <input
          type={type}
          name={name}
          id={id}
          readOnly={readOnly}
          value={value}
          onChange={onChange}
          max={max}
          disabled={disabled}
          aria-invalid={true}
          className={
            (isError
              ? 'ring-red-500 border-red-500'
              : 'focus:ring-indigo-500  focus:border-indigo-500') +
            ` shadow-sm border block
            w-full sm:text-sm rounded-md py-2 px-2 outline-none`
          }
          placeholder={placeholder}
          {...register(name, {
            required: required ? required : false,
            pattern: pattern ? pattern : null,
            maxLength: max ? max : null,
            minLength: min
              ? {
                  value: min,
                  message: minValidation,
                }
              : null,
          })}
        />
      </div>
    </div>
  );
}
