export const imageArray = [
  'ArrowVertical',
  'BatteryAutomotive',
  'BatteryCharging',
  'Boiler',
  'BrokenPipe',
  'Dots',
  'BulbOff',
  'Components',
  'Door',
  'DoorLock',
  'Drenage',
  'Droplet',
  'Dryer',
  'ElectricalIssues',
  'ElectricHeating',
  'FloorHeating',
  'FloorDrain',
  'Hood',
  'Key',
  'KitchenTools',
  'Lock',
  'LockAccess',
  'LockAccessV2',
  'Plug',
  'PlugCabinets',
  'Radiator',
  'Refrigerator',
  'Sink',
  'Snowflake',
  'Stove',
  'Switch',
  'Tap',
  'Temperature',
  'Toilet',
  'WashingMachine',
  'WaterBattery',
];
