import React, { useRef, useState } from 'react';
import useTranslate from '../../../../../hooks/useTranslate';
import HandymenList from './HandymenList';
import { DateTime } from 'luxon';
import { TextField } from '@material-ui/core';
import ItemBox from './ItemBox';
import Button from '../../../../Common/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { sendNewOffer } from '../../../../../api/Case/Case';
import circlePlus from '../../../../../assets/images/circle-plus.svg';
import iconDelete from '../../../../../assets/images/tabler_trash.svg';
import { Box, LinearProgress, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const OfferTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    padding: '0.5rem',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0!important',
  },
  '& label.Mui-focused': {
    color: '#480CA8',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#480CA8',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#480CA8',
    },
    '&:hover fieldset': {
      borderColor: '#480CA8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#480CA8',
    },
  },
}));

const CreateOfferModal = ({
  selectedCaseDetails,
  setOfferModal,
  caseStatus,
  editOffer,
}) => {
  //Translations
  const t = useTranslate();

  //Handyman
  const [selectedHandyman, setSelectedHandyman] = useState(null);

  //Materials
  const [materialsList, setMaterialsList] = useState(
    caseStatus === 'ongoing' ? editOffer?.appointments[0]?.items : []
  );
  const [showNewItem, setShowNewItem] = useState(false);

  //Time
  const [valueStartTime, setStartTime] = useState(
    selectedCaseDetails?.time_slots?.time_from
  );
  const [valueEndTime, setEndTime] = useState(
    selectedCaseDetails?.time_slots?.time_to
  );

  //Description
  const offerDescription = useRef();

  //Error message
  const [errorMsg, setErrorMsg] = useState('');

  // Mutation for sending new offer
  const queryClient = useQueryClient();

  const {
    mutateAsync: sendNewOfferMutation,
    isLoading: sendNewOfferLoading,
    isError: sendNewOfferyError,
  } = useMutation(sendNewOffer, {
    onSuccess: (data) => {
      setOfferModal(false);
      queryClient.invalidateQueries(['selectedCaseOffer']);
      queryClient.invalidateQueries(['selectedCaseDetails']);
      queryClient.invalidateQueries(['newCompanyCases']);
    },
    onError: (error) => {
      if (error.response.status === 409) {
        setErrorMsg(t('handymanBusy'));
      } else {
        setErrorMsg(t('offerFailed'));
      }
    },
  });

  //Send offer
  const handleSendOffer = () => {
    if (
      selectedHandyman === null ||
      offerDescription.current.value === '' ||
      offerDescription.current.value.length < 20
    ) {
      setErrorMsg(t('offerFailed'));
      return;
    }

    sendNewOfferMutation({
      handyman_id: selectedHandyman?.id,
      date_from: selectedCaseDetails?.time_slots?.date_from,
      date_to: selectedCaseDetails?.time_slots?.date_to,
      time_from: DateTime.fromISO(valueStartTime).toFormat('HH:mm:ss'),
      time_to: DateTime.fromISO(valueEndTime).toFormat('HH:mm:ss'),
      items: materialsList,
      problem_id: selectedCaseDetails.id,
      description: offerDescription.current.value,
    });
  };

  //Remove item from list
  const handleRemoveItem = (item) => {
    var array = [...materialsList];
    var index = array.indexOf(item);
    if (index !== -1) {
      array.splice(index, 1);
    }
    setMaterialsList(array);
  };

  return (
    <div className="flex flex-col p-8 gap-4">
      <div className="case-header-name font-medium w-full text-center">
        <span>{t('newCaseOffer')}</span>
      </div>
      <HandymenList
        setSelectedHandyman={setSelectedHandyman}
        selectedCaseDetails={selectedCaseDetails}
      />
      <TextField
        label={t('offerTimeStart')}
        helperText={
          'Min: ' +
          DateTime.fromISO(selectedCaseDetails?.time_slots?.time_from).toFormat(
            'HH:mm'
          )
        }
        value={valueStartTime}
        onChange={(e) => setStartTime(e.target.value)}
        type="time"
      />
      <TextField
        label={t('offerTimeEnd')}
        helperText={
          'Max: ' +
          DateTime.fromISO(selectedCaseDetails?.time_slots?.time_to).toFormat(
            'HH:mm'
          )
        }
        value={valueEndTime}
        onChange={(e) => setEndTime(e.target.value)}
        type="time"
      />
      <div className="my-2">
        <p className="font-normal text-md mb-2">{t('description')}:</p>
        <OfferTextField
          inputRef={offerDescription}
          defaultValue={
            caseStatus === 'ongoing'
              ? editOffer?.appointments[0]?.description
              : ''
          }
          variant="outlined"
          fullWidth
          multiline
          minRows={8}
          maxRows={10}
          placeholder={t('offerDescription')}
          helperText={t('minimumCharacters')}
          FormHelperTextProps={{
            className: '!ml-0',
          }}
        />
      </div>
      {materialsList?.length > 0 && (
        <div className="mt-2">
          {materialsList?.map((item, index) => (
            <div className="flex flex-col gap-2 mt-2" key={index}>
              <div className="flex justify-between">
                <p>
                  {t('itemName')}: {item.name}
                </p>
                <img
                  className="cursor-pointer"
                  onClick={() => handleRemoveItem(item)}
                  src={iconDelete}
                  alt="delete"
                />
              </div>
              <div className="flex justify-between">
                <p>
                  {t('price')}: {item.price}
                </p>
                <p>
                  {t('itemQuantity')}: {item.quantity}
                </p>
              </div>
              <hr />
            </div>
          ))}
        </div>
      )}

      {!showNewItem && (
        <span
          className="cursor-pointer flex gap-2 my-2"
          onClick={() => setShowNewItem(true)}
        >
          <img src={circlePlus} alt="add" />
          <p>{t('addNewItem')}</p>
        </span>
      )}
      {showNewItem && (
        <ItemBox
          setShowNewItem={setShowNewItem}
          setMaterialsList={setMaterialsList}
        />
      )}
      {errorMsg !== '' && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-2 mb-2 rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{t('offerFailed')}</span>
        </div>
      )}
      {!showNewItem &&
        (sendNewOfferLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <div className="flex gap-3 justify-between text-red">
            <Button onClick={() => setOfferModal(false)}>{t('cancel')}</Button>
            {caseStatus === 'ongoing' ? (
              <Link to="/d/cases/finished-cases">
                <Button onClick={() => handleSendOffer()}>{t('save')}</Button>
              </Link>
            ) : (
              <Button onClick={() => handleSendOffer()}>{t('save')}</Button>
            )}
          </div>
        ))}
    </div>
  );
};
export default CreateOfferModal;
