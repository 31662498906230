import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchNewProblemsExpert } from '../../../../api/Experts/Experts';
import CommonTable from '../../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import useTranslate from '../../../../hooks/useTranslate';
import OngoingTable from './OngoingTable';
import { useMemo } from 'react';
import useAuth from '../../../../hooks/useAuth';

const ExpertNewCases = () => {
  const [caseDetails, setCaseDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const { user } = useAuth();
  const { id } = user;

  const header = [
    t('user'),
    t('category'),
    t('descriptionByCustomer'),
    t('images'),
    t('created'),
    '',
  ];

  const {
    data: newCases,
    isError,
    refetch: refetchProblems,
  } = useQuery(['newCasesExpert', id], fetchNewProblemsExpert, {
    refetchInterval: 300000,
  });

  const filteredList = useMemo(
    () => newCases?.filter((item) => item.is_urgent === true),
    [newCases]
  );

  const distantFuture = new Date(8640000000000000);

  filteredList?.sort((a, b) => {
    let dateA =
      a?.time_slots?.date_from && a?.time_slots?.time_from
        ? new Date(a?.time_slots?.date_from + 'T' + a?.time_slots?.time_from)
        : distantFuture;
    let dateB =
      b?.time_slots?.date_from && b?.time_slots?.time_from
        ? new Date(b?.time_slots?.date_from + 'T' + b?.time_slots?.time_from)
        : distantFuture;
    return dateA.getTime() - dateB.getTime();
  });

  const dataPreview = (
    rowsPerPage > 0
      ? filteredList?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : filteredList
  )?.map((row, index) => (
    <OngoingTable
      key={index}
      row={row}
      index={index}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      setCaseDetails={setCaseDetails}
      refetchProblems={refetchProblems}
    />
  ));

  if (isError) return <div>{t('errorRequest')}</div>;

  return (
    <>
      <div className="flex px-8 my-4">
        <div className="rounded-xl w-full">
          <CommonTable
            header={header}
            tableData={dataPreview}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            count={filteredList ? filteredList.length : 0}
          />
        </div>
      </div>
    </>
  );
};

export default ExpertNewCases;
