import React, { useState } from 'react';
import Card from '../Card';
import HomevisitsChart from './Charts/HomevisitsChart';
import CompanyProfitChart from './Charts/CompanyProfitChart';
import TopHandymanChart from './Charts/TopHandymanChart';

const CompanyAdminDashboard = () => {
  const [totalProfit, setTotalProfit] = useState('');

  return (
    <div>
      <div className="flex gap-3">
        <Card
          width={'w-1/2'}
          title="Antal utförda uppdrag senaste månaden"
          height={'h-full'}
        >
          <div className="w-full p-10 h-96">
            <TopHandymanChart />
          </div>
        </Card>
        <Card width={'w-1/2'} center title="Hembesök" height={'h-full'}>
          <HomevisitsChart />
        </Card>
      </div>
      <div className="flex gap-3">
        <Card
          width={'w-1/2'}
          title="Omsättning"
          title2={totalProfit + ' SEK'}
          height={'h-full'}
        >
          <div className="w-full p-10 h-96">
            <CompanyProfitChart setTotalProfit={setTotalProfit} />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CompanyAdminDashboard;
