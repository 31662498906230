import React from 'react';
import { useForm } from 'react-hook-form';
import useTranslate from '../../../../hooks/useTranslate';
import Button from '../../../Common/Button';
import ErrorMessage from '../../../Common/ErrorMessage';
import IconUpload from '../../../Common/IconUpload';
import Input from '../../../Common/Input';

const SubCategoryForm = ({
  setIsCreatingSubCategory,
  subCategorySubmitHandler,
  selectedIcon,
  setSelectedIcon,
  setIsOpen,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm({ mode: 'all' });
  const t = useTranslate();

  return (
    <form className="flex flex-col items-center pr-4 border-b border-slate-200 py-4 pb-5">
      <div className="flex items-center w-1/2 mr-auto pl-4">
        <div className="flex flex-col w-full">
          <div className="flex items-center gap-4">
            <IconUpload setModalOpen={setIsOpen} selectedIcon={selectedIcon} />
            <Input
              register={register}
              placeholder={`${t('subCategoryName')}...`}
              type="Text"
              name="subCategoryName"
              required={t('thisFieldIsRequired')}
              isError={errors?.subCategoryName?.message}
              noLabel
            />
          </div>
          <ErrorMessage>{errors?.subCategoryName?.message}</ErrorMessage>
        </div>
      </div>
      <div className="flex gap-3 ml-auto mt-3">
        <Button
          onClick={() => {
            setIsCreatingSubCategory(false);
            setSelectedIcon('');
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleSubmit((data) => {
            if (selectedIcon === '') {
              setError('subCategoryName', {
                type: 'custom',
                message: t('anIconIsRequired'),
              });
              return;
            } else subCategorySubmitHandler(data);
          })}
        >
          {t('save')}
        </Button>
      </div>
    </form>
  );
};

export default SubCategoryForm;
