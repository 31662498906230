import React from "react";
import MessageSection from "../MessageSection/MessageSection";
import { useQuery } from "@tanstack/react-query";
import {
  getAllDeniedReasons,
  getAllMessageSlides,
} from "../../../api/Settings/MessageSlides/MessageSlides";
import { LinearProgress } from "@mui/material";
import useTranslate from "../../../hooks/useTranslate";

const Messages = () => {
  const t = useTranslate();

  const { data: messageSlides, isLoading: messagesLoading } = useQuery(
    ["messageSlides"],
    getAllMessageSlides
  );

  const { data: deniedReasons, isLoading: deniedReasonsLoading } = useQuery(
    ["deniedReasons"],
    getAllDeniedReasons
  );

  if (messagesLoading || deniedReasonsLoading) return <LinearProgress />;

  return (
    <div>
      <MessageSection
        title={t('welcomeMessageSlides')}
        messages={messageSlides}
        welcomeMessages
      />
      <hr className="h-2 gradient-purple w-full my-6" />
      <MessageSection
        title={t('predefinedReasonsForDeniedCompanies')}
        messages={deniedReasons}
        deniedReasons
      />
    </div>
  );
};

export default Messages;
