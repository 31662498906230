import React, { useState } from "react";
import {
  createDeniedReason,
  createMessageSlide,
} from "../../../api/Settings/MessageSlides/MessageSlides";
import Button from "../../Common/Button";
import SingleMessage from "./SingleMessage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useTranslate from "../../../hooks/useTranslate";

const MessageSection = ({ messages, title, deniedReasons }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const queryClient = useQueryClient();
  const t = useTranslate();

  // Mutation for submitting new welcome message slides
  const {
    mutateAsync: submitMessageMutation,
    isLoading: submitMessageLoading,
  } = useMutation(deniedReasons ? createDeniedReason : createMessageSlide, {
    onSuccess: () => {
      setIsCreating(false);
      setMessageInput("");
      queryClient.invalidateQueries([
        deniedReasons ? "deniedReasons" : "messageSlides",
      ]);
    },
  });

  // Handler for adding new slides
  const handleAddSlide = () => {
    setIsCreating(true);
  };

  // Handler for Cancel button
  const handleCancelCreating = () => {
    setIsCreating(false);
    setMessageInput("");
  };

  // State update for textarea
  const handleMessageInput = (e) => {
    setMessageInput(e.target.value);
  };

  // Handler for submitting new welcome messages
  const handleSubmitSlide = () => {
    submitMessageMutation(messageInput);
  };

  return (
    <>
      <div className="flex w-full justify-between items-center">
        <span className="text-violetPrimary text-lg">{title}</span>
        <Button onClick={handleAddSlide}>{`+ ${t('addMessage')}`}</Button>
      </div>

      <div className="flex flex-col w-full gap-3 mt-4 h-60 overflow-auto">
        {isCreating && (
          <div className="flex flex-col w-full mt-4">
            <textarea
              className="border resize-none w-full  hover:border-indigo-500 focus:border-indigo-500 outline-none focus:outline-none rounded-lg border-slate-300 p-3 text-sm text-left font-poppins text-fontActive"
              placeholder="Write slide message..."
              value={messageInput}
              onChange={handleMessageInput}
            />
            <div className="flex justify-end gap-3 mt-4">
              <Button onClick={handleCancelCreating}>{t('cancel')}</Button>
              <Button onClick={handleSubmitSlide}>{t('save')}</Button>
            </div>
          </div>
        )}
        {messages?.map((message, index) => (
          <SingleMessage
            key={message.id}
            message={message}
            deniedReasons={deniedReasons}
          />
        ))}
      </div>
    </>
  );
};

export default MessageSection;
