import aStoreIcon from "../../assets/images/a-store-icon.svg";
import gStoreIcon from "../../assets/images/g-store-icon.svg";
import elipse60 from "../../assets/images/Ellipse60.svg";

const LandingContentPart2 = () => {
  return (
    <div className="flex h-full custom-container flex-col-reverse lg:flex-row">
      <img src={elipse60} className="absolute left-0" />
      <div className="w-full lg:w-4/6 mt-36 sm:mt-6 sm:pt-4 sm:pt-8 lg:pt-44 lg:pr-28 lg:pl-40 pl-8 pr-8">
        <h2 className="mb-14 lg:mb-24 font-medium" style={{ color: "#13044F", fontSize: "38px" }}>
          Så här fungerar det!
        </h2>
        <h3 className="mb-6" style={{ color: "#13044F", fontSize: "32px" }}>
          1. Ladda ner vår app och skapa ett konto
        </h3>
        <p
          className="opacity-70"
          style={{ color: "#13044F", fontSize: "18px" }}
        >
          Skapa ett konto genom att ladda ner mendy från App Store eller Google
          Play.
        </p>
        <div className="flex flex-col sm:flex-row mt-6">
          <img
            className="cursor-pointer max-w-[70%] sm:max-w-none"
            src={gStoreIcon}
          />
          <img
            className="mt-4 sm:ml-4 sm:mt-0 cursor-pointer max-w-[70%] sm:max-w-none"
            src={aStoreIcon}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingContentPart2;
