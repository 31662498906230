import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { fetchChatById } from '../../../api/Chats/Chats';
import useAuth from '../../../hooks/useAuth';
import useTranslate from '../../../hooks/useTranslate';
import ChatBubble from './CaseChat/ChatBubble';

const CaseChat = ({ userDetails }) => {
  const { user } = useAuth();
  const t = useTranslate();
  const {
    data: chatData,
    isLoading: chatLoading,
    isError: chatError,
  } = useQuery(
    [
      'caseChat',
      user?.role_id === 1
        ? userDetails?.appointment_id
        : userDetails?.appointments[0]?.id,
    ],
    fetchChatById,
    {
      enabled: false,
    }
  );

  //if (chatError) return <div>{t('errorRequest')}</div>;

  return (
    <span>{t('comingSoon')}...</span>
    /*<>
      {chatLoading ? (
        <div>{t('loading')}</div>
      ) : (
        <div>
          {chatData?.messages.map((message) => {
            if (chatData?.appointment.handyman_id === message?.sender_id) {
              return (
                <ChatBubble
                  variant={'handyman'}
                  name={userDetails?.handyman_name}
                  timeStamp={message?.sent_at.split(' ')[1].slice(0, 5)}
                >
                  {message?.message}
                </ChatBubble>
              );
            } else {
              return (
                <ChatBubble
                  variant={'user'}
                  name={userDetails?.user_name}
                  timeStamp={message?.sent_at.split(' ')[1].slice(0, 5)}
                >
                  {message?.message}
                </ChatBubble>
              );
            }
          })}
        </div>
      )}
        </>*/
  );
};

export default CaseChat;
