import React from 'react';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../../assets/placeholderImg.png';
import Button from '../../../Common/Button';
import useTranslate from '../../../../hooks/useTranslate';
import { useCallback } from 'react';
import { useState } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import { FetchCategoryIcon } from '../../../../helpers/categoryIcons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { cancelExpertCall } from '../../../../api/Case/Case';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { Box, CircularProgress, Tooltip } from '@mui/material';

const AcceptedTable = ({
  row,
  index,
  activeRow,
  setActiveRow,
  setCaseDetails,
  setIsModalOpen,
}) => {
  const t = useTranslate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [...row?.images];
  const filteredImages = images?.map((image) => image.path);
  const queryClient = useQueryClient();

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const {
    mutateAsync: cancelExpertCallMutation,
    isLoading: cancelExpertCallLoading,
    isError,
  } = useMutation(cancelExpertCall, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['expertOngoingProblems']);
      toast.success(t('callIsCancelled'), { duration: 5000 });
    },
  });

  const handleCancelCase = (selectedCase) => {
    if (selectedCase?.is_urgent) {
      toast.error(t('cancelDisabled'), { duration: 5000 });
      return;
    } else {
      const date1 = DateTime.fromISO(
        `${row?.time_slots?.date_from}T${row?.time_slots?.time_from}`
      );
      const date2 = DateTime.now();
      const diff = date1.diff(date2, ['hours']);
      const diffHours = diff.toObject();

      if (diffHours.hours < 2) {
        toast.error(t('cancelDisabled'), { duration: 5000 });
        return;
      } else {
        cancelExpertCallMutation({
          id: selectedCase?.id,
        });
      }
    }
  };

  const handlePaidCall = (e) => {
    e.stopPropagation();
    setCaseDetails(row);
    setIsModalOpen(true);
  };

  return (
    <TableRow
      row={row}
      key={index}
      onClick={() => {
        setCaseDetails(row);
        setActiveRow(index);
      }}
    >
      <StyledTableCell>
        <div className="flex w-56 gap-3">
          <div className="min-photo rounded">
            <img
              src={
                row?.user?.profile_image === null
                  ? PlaceholderImage
                  : row?.user?.profile_image
              }
              onError={({ currentTarget }) => {
                currentTarget.src = PlaceholderImage;
              }}
              alt="user"
              className="w-full h-full rounded-full object-cover"
            />
          </div>
          <div className="truncate w-56">
            <p className="font-semibold text-base leading-6 font-color ">
              {row?.user?.name} {row?.user?.last_name}
            </p>
            <span className="font-color">
              {row?.user?.post_code} {row?.user?.street}
            </span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex w-44 gap-3 items-center">
          <FetchCategoryIcon
            name={row?.category?.icon}
            strokeColor="strokePurple"
          />
          <div className="">
            <p className="font-semibold text-base leading-6 font-color">
              {row?.category?.parent?.name}
            </p>
            <span className="font-color">{row?.category?.name}</span>
          </div>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <Tooltip title={<p className="text-base">{row?.description}</p>}>
          <div className="flex flex-wrap w-40">
            <span className="line-clamp-3">{row?.description}</span>
          </div>
        </Tooltip>
      </StyledTableCell>
      <StyledTableCell>
        <div className="flex items-center">
          {filteredImages.slice(0, 2).map((src, index) => (
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              className="max-w-70 h-14"
              key={index}
              style={{ margin: '2px' }}
              alt=""
            />
          ))}
          {isViewerOpen && (
            <ImageViewer
              backgroundStyle={{
                backgroundColor: '#7209b760',
                zIndex: '99',
                transition: '0.3s ease-out',
              }}
              src={filteredImages}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
          )}
          <p className="text-indigo-800 font-medium text-base leading-6">
            {filteredImages?.length > 2 ? `+${filteredImages?.length - 2}` : ''}
          </p>
        </div>
      </StyledTableCell>
      <StyledTableCell>
        <div className="font-medium text-base">
          {DateTime.fromISO(row?.time_slots?.time_from, {
            zone: 'Europe/Stockholm',
          }).toFormat('HH:mm')}
        </div>
        <div>{row?.time_slots?.date_from}</div>
      </StyledTableCell>
      <StyledTableCell className="w-52">
        <div className="flex justify-evenly">
          {cancelExpertCallLoading ? (
            <Box sx={{ width: '100%', padding: '1px 0', textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <div className="flex gap-4">
              <Button
                className="flex-1"
                variant="transparent"
                onClick={() => {
                  handleCancelCase(row);
                }}
              >
                {t('cancel')}
              </Button>
              <Tooltip title={row?.payment_id ? '' : t('callUnpaid')}>
                <span>
                  <Button
                    disabled={row?.payment_id ? false : true}
                    className="flex-1"
                    onClick={
                      row?.payment_id
                        ? (e) => {
                            handlePaidCall(e);
                          }
                        : undefined
                    }
                  >
                    {t('ring')}
                  </Button>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </StyledTableCell>
    </TableRow>
  );
};

export default AcceptedTable;
