import React from 'react';
import iconDelete from '../../../assets/images/tabler_trash.svg';
import iconEdit from '../../../assets/images/tabler_edit.svg';
import { TableRow } from '@material-ui/core';
import { StyledTableCell } from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import PlaceholderImage from '../../../assets/placeholderImg.png';

const DeniedCompaniesTable = ({
  row,
  index,
  activeRow,
  setActiveRow,
  setCompanyDetails,
}) => {
  return (
    <TableRow
      row={row}
      key={index}
      className={`${activeRow === index && 'active-row'}`}
      onClick={() => {
        setCompanyDetails(row);
        setActiveRow(index);
      }}
    >
      <StyledTableCell>
        <div className="flex gap-3 w-64">
          <img
            src={row?.logo ? row?.logo : PlaceholderImage}
            onError={({ currentTarget }) => {
              currentTarget.src = PlaceholderImage;
            }}
            alt="companyLogo"
            className="rounded-full w-10 h-10 object-cover"
          />
          <div>
            <p className="font-medium text-base leading-6 font-color">
              {row?.company_name}
            </p>
            <span className="font-color">
              {row?.post_code} {row?.company_address}
            </span>
          </div>
        </div>
      </StyledTableCell>
      {activeRow === null && (
        <>
          <StyledTableCell>
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.code}
                </p>
                <span className="font-color">{row?.company_email}</span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex">
              <div>
                <p className="font-medium text-base leading-6 font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.name
                      ? row?.administrators[0]?.name
                      : '-'
                    : '-'}{' '}
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.last_name
                      ? row?.administrators[0]?.last_name
                      : '-'
                    : '-'}
                </p>
                <span className="font-color">
                  {row?.administrators?.length > 0
                    ? row?.administrators[0]?.phone
                      ? row?.administrators[0]?.phone
                      : 'No admin phone'
                    : 'No admin phone'}
                </span>
              </div>
            </div>
          </StyledTableCell>
          <StyledTableCell>
            {row.handymen?.length > 0 ? (
              <div className="flex">
                <div className="flex ml-3">
                  {row?.handymen?.slice(0, 3).map((handyman, index) => (
                    <img
                      key={index}
                      src={
                        handyman?.profile_image
                          ? handyman?.profile_image
                          : PlaceholderImage
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.src = PlaceholderImage;
                      }}
                      alt="handyman"
                      className="rounded-full w-10 h-10 -ml-3 min-w-[40px] object-cover"
                    />
                  ))}
                </div>
                {row?.handymen?.length > 3 && (
                  <span
                    className="rounded-full w-10 h-10 -ml-3 text-white flex justify-center items-center font-poppins text-sm font-normal"
                    style={{
                      background:
                        'linear-gradient(90deg, #3912D6 0%, #A312D6 100%)',
                    }}
                  >
                    +{row?.handymen?.length - 3}
                  </span>
                )}
              </div>
            ) : (
              'No handymen'
            )}
          </StyledTableCell>
          <StyledTableCell>
            <div className="flex">
              <img src={iconEdit} className="pr-2" alt="edit" />
              <img src={iconDelete} alt="delete" />
            </div>
          </StyledTableCell>
        </>
      )}
    </TableRow>
  );
};

export default DeniedCompaniesTable;
