import { Checkbox, TableRow } from '@material-ui/core';
import CommonTable, {
  StyledTableCell,
} from '../../../containers/MainContentContainer/CommonTableComponent/CommonTable';
import '../Companies.css';

import { useEffect, useState } from 'react';
import CompanyDetails from '../CompanyInfo/CompanyDetails';
import { fetchCompaniesByStatusApi } from '../../../features/companies/getCompaniesByStatus';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { fetchCompaniesDenied } from '../../../api/Company/Company';
import DeniedCompaniesTable from './DeniedCompaniesTable';
import useTranslate from '../../../hooks/useTranslate';
import { Box, LinearProgress } from '@mui/material';

const DeniedCompanies = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const t = useTranslate();
  const [value, setValue] = useState(t('companyInfo'));
  const {
    data: deniedCompanies,
    isLoading: deniedCompaniesLoading,
    isError,
  } = useQuery(['deniedCompanies'], fetchCompaniesDenied);

  const header = [
    t('company'),
    t('organisationalNumber'),
    t('adminContact'),
    t('handymen'),
    '',
  ];
  const tabs = [t('companyInfo'), t('adminInfo'), t('denialInfo')];

  const dataPreview = (
    rowsPerPage > 0
      ? deniedCompanies?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : deniedCompanies
  )?.map((row, index) => (
    <DeniedCompaniesTable
      key={index}
      row={row}
      index={index}
      activeRow={activeRow}
      setActiveRow={setActiveRow}
      setCompanyDetails={setCompanyDetails}
    />
  ));
  return (
    <div className="flex px-8 my-4">
      {deniedCompaniesLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <>
          <div className="rounded-xl w-full">
            <CommonTable
              header={
                companyDetails
                  ? header.filter((item, index) => index < 1)
                  : header
              }
              tableData={dataPreview}
              page={page}
              rowsPerPage={rowsPerPage}
              setPage={setPage}
              count={deniedCompanies ? deniedCompanies.length : 0}
            ></CommonTable>
          </div>
          {activeRow !== null && (
            <CompanyDetails
              companyDetails={companyDetails}
              setCompanyDetails={setCompanyDetails}
              setActiveRow={setActiveRow}
              tabs={tabs}
              setValue={setValue}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DeniedCompanies;
