import React, { useState, useEffect } from 'react';
import { FormControl, TextField } from '@mui/material';
import ImageUpload from '../../containers/MainContentContainer/CommonImageUpload/ImageUpload';
import DocumentUpload from './DocumentUpload';
import removeIcon from '../../assets/images/icon-minus.svg';
import pdfIcon from '../../assets/images/pdf-icon.svg';
import DocumentDeleteModal from './DocumentDeleteModal';
import useTranslate from '../../hooks/useTranslate';

const CompanyForm = ({
  role,
  company,
  register,
  handleSubmit,
  onSubmit,
  setValue,
  errors,
  reset,
  editForm,
  formName,
}) => {
  const [documentsList, setDocumentsList] = useState(
    company?.documents
  );
  const [uploadDocuments, setUploadDocuments] = useState([]);
  const [deleteDocumentModal, setDeleteDocumentModal] =
    useState(false);
  const [deleteDocument, setDeleteDocument] = useState(null);
  const t = useTranslate();

  useEffect(() => {
    setDocumentsList(company?.documents);
  }, [company]);

  useEffect(() => {
    setValue('documents', uploadDocuments, { shouldDirty: true });
  }, [uploadDocuments]);

  const handleDeleteModal = (document) => {
    setDeleteDocument(document);
    setDeleteDocumentModal(true);
  };

  return (
    <form id={formName} onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="pb-6 w-full">
        <div className="flex flex-col py-4">
          {role === 2 && (
            <div className="flex flex-row items-center py-2 px-4">
              <span
                className="text-base label-color font-normal w-7/12 pr-4"
                style={{ color: editForm ? '#13044F' : '' }}
              >
                {t('companyLogo')}:
              </span>
              <div className="flex flex-col w-full">
                <ImageUpload
                  setValue={setValue}
                  editForm={editForm}
                  valueName="logo"
                  currentImg={company?.logo}
                />
              </div>
            </div>
          )}
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('company')} {t('nameSmall')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="outlined-basic1"
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={company?.company_name}
                {...register('company_name', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 3,
                    message: t('nameIsTooShort'),
                  },
                })}
              />
              {errors.company_name && (
                <span className="text-red-600 text-sm">
                  {errors.company_name?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('organisationalNumber')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                id="outlined-basi2c"
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={company?.code}
                {...register('code', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 10,
                    message: t('enterValidOrgNumber'),
                  },
                })}
              />
              {errors.code && (
                <span className="text-red-600 text-sm">
                  {errors.code?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('company')} {t('email')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                variant="outlined"
                type="email"
                fullWidth
                disabled={!editForm}
                defaultValue={company?.company_email}
                {...register('company_email', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t('invalidEmail'),
                  },
                })}
              />
              {errors.company_email && (
                <span className="text-red-600 text-sm">
                  {errors.company_email?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row items-center py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('company')} adress:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                variant="outlined"
                fullWidth
                disabled={!editForm}
                defaultValue={company?.company_address}
                {...register('company_address', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 5,
                    message: t('enterValidAdress'),
                  },
                })}
              />
              {errors.company_address && (
                <span className="text-red-600 text-sm">
                  {errors.company_address?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('company')} {t('description')}:
            </span>
            <span className="flex flex-col w-full">
              <TextField
                className="company-description"
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                maxRows={6}
                disabled={!editForm}
                defaultValue={company?.description}
                {...register('description', {
                  required: {
                    value: true,
                    message: t('thisFieldIsRequired'),
                  },
                  minLength: {
                    value: 50,
                    message: t('descriptionTooShort'),
                  },
                })}
              />
              {errors.description && (
                <span className="text-red-600 text-sm">
                  {errors.description?.message}
                </span>
              )}
            </span>
          </div>
          <div className="flex flex-row py-2 px-4">
            <span
              className="text-base label-color font-normal w-7/12 pr-4 pt-3"
              style={{ color: editForm ? '#13044F' : '' }}
            >
              {t('company')} {t('documents')}:
            </span>
            <div className="flex flex-col w-full">
              {documentsList?.length > 0
                ? documentsList?.map((document, index) => (
                    <span
                      key={index}
                      className="flex justify-between"
                    >
                      <a
                        href={document?.path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span>
                          <img
                            src={pdfIcon}
                            className="inline-block py-2"
                          />
                          {document?.title}
                        </span>
                      </a>
                      {editForm && (
                        <img
                          className="cursor-pointer float-right ml-4"
                          src={removeIcon}
                          onClick={() =>
                            editForm
                              ? handleDeleteModal(document)
                              : undefined
                          }
                        />
                      )}
                    </span>
                  ))
                : !editForm && (
                    <span
                      className="text-base label-color font-normal w-7/12 pr-4 pt-3"
                      style={{ color: editForm ? '#13044F' : '' }}
                    >
                      {t('noDocuments')}
                    </span>
                  )}
              <DocumentUpload
                editForm={editForm}
                setValue={setValue}
                documentsList={documentsList}
                setDocumentsList={setDocumentsList}
                uploadDocuments={uploadDocuments}
                setUploadDocuments={setUploadDocuments}
                valueName="documents"
              />
              <DocumentDeleteModal
                setDeleteDocumentModal={setDeleteDocumentModal}
                deleteDocumentModal={deleteDocumentModal}
                deleteDocument={deleteDocument}
                setUploadDocuments={setUploadDocuments}
                setDeleteDocument={setDeleteDocument}
                setDocumentsList={setDocumentsList}
              />
            </div>
          </div>
        </div>
      </FormControl>
    </form>
  );
};

export default CompanyForm;
