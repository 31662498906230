import Button from '../Common/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteUser } from '../../api/Users/Users';
import useTranslate from '../../hooks/useTranslate';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../helpers/capitalizeFirstLetter';
import { Box, LinearProgress } from '@mui/material';

export default function DeleteUserModal({ setDeleteModal, userDetails }) {
  const queryClient = useQueryClient();
  const [userRole, setUserRole] = useState('');
  const t = useTranslate();

  const { mutateAsync: deleteUserMutation, isLoading: deleteUserLoading } =
    useMutation(deleteUser, {
      onSuccess: () => {
        setDeleteModal(false);
        if (userDetails?.role_id === 3) {
          queryClient.invalidateQueries(['userSearch']);
          queryClient.invalidateQueries(['expert']);
        } else if (userDetails?.role_id === 4) {
          queryClient.invalidateQueries(['userSearch']);
          queryClient.invalidateQueries(['handyman']);
        } else if (userDetails?.role_id === 5) {
          queryClient.invalidateQueries(['userSearch']);
          queryClient.invalidateQueries(['selectedUser']);
        }
      },
    });

  useEffect(() => {
    if (userDetails.role_id === 3) {
      setUserRole(t('expert'));
    } else if (userDetails.role_id === 4) {
      setUserRole(t('handyman'));
    } else if (userDetails.role_id === 5) {
      setUserRole(t('user'));
    }
  }, [userDetails]);

  const handleDelete = () => {
    deleteUserMutation(userDetails.id);
  };

  return (
    <div className="text-center py-10 px-6 font-poppins">
      <h3 className="delete-title">
        {capitalizeFirstLetter(t('delete'))} {userRole.toLowerCase()}?
      </h3>
      <p className="delete-description mt-2">
        {t('confirmText')} {userRole.toLowerCase()}?
      </p>
      <div className="flex justify-evenly mt-10">
        {deleteUserLoading ? (
          <Box sx={{ width: '100%', padding: '20px 0' }}>
            <LinearProgress />
          </Box>
        ) : (
          <>
            <Button
              variant="modalTransparent"
              onClick={() => setDeleteModal(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={() => {
                setDeleteModal(false);
                handleDelete();
              }}
              variant="modalContained"
            >
              {t('delete')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}
