import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  createCategory,
  getAllCategoriesWithSubcategories,
} from "../../../api/Categories/Categories";
import Button from "../../Common/Button";
import SingleCategory from "./Categories/SingleCategory";
import SingleSubCategory from "./Categories/SingleSubCategory";
import SelectedCategory from "./Categories/SelectedCategory";
import Input from "../../Common/Input";
import { useForm } from "react-hook-form";
import Modal from "../../Common/Modal";
import IconUpload from "../../Common/IconUpload";
import IconSelection from "./Categories/Modals/IconSelection";
import SubCategoryForm from "./Categories/SubCategoryForm";
import ErrorMessage from "../../Common/ErrorMessage";
import useTranslate from "../../../hooks/useTranslate";

const Categories = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isCreatingSubCategory, setIsCreatingSubCategory] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const t = useTranslate();

  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
  } = useForm({ mode: "all" });

  useEffect(() => {
    clearErrors();
  }, [selectedIcon]);

  // Query for fetching all categories
  const {
    data: allCategories,
    isLoading: allCategoriesLoading,
    isError: allCategoriesError,
  } = useQuery(["settingsCategories"], getAllCategoriesWithSubcategories);

  // Mutation for creating a new category
  const {
    mutateAsync: createCategoryMutation,
    isLoading: createCategoryLoading,
    isError: createCategoryError,
  } = useMutation(createCategory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["settingsCategories"]);
      setIsCreating(false);
      setIsCreatingSubCategory(false);
      reset();
    },
  });

  // Update the selected category when we change the selected category name
  useEffect(() => {
    if (selectedCategory) {
      const category = allCategories.find(
        (item) => item.id === selectedCategory?.id
      );
      setSelectedCategory(category);
    }
  }, [allCategories]);

  // Set selected category state to the clicked category on category list
  const handleCategoryClick = (id) => {
    const category = allCategories.find((item) => item.id === id);
    setSelectedCategory(category);
  };

  // Event handler for submitting a new category
  const submitHandler = (data) => {
    if (selectedIcon === "") {
      setError("categoryName", {
        type: "custom",
        message: t('anIconIsRequired'),
      });
      return;
    } else {
      createCategoryMutation({
        name: data.categoryName,
        description: data.categoryDescription,
        icon: selectedIcon,
      });
    }
  };

  const subCategorySubmitHandler = (data) => {
    if (selectedIcon === "") {
      setError("subCategoryName", {
        type: "custom",
        message: t('anIconIsRequired'),
      });
      return;
    } else {
      createCategoryMutation({
        name: data.subCategoryName,
        description: "subcategory",
        parent_id: selectedCategory.id,
        icon: selectedIcon
      });
      setSelectedIcon("");
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <IconSelection
          selectedCategory={selectedCategory}
          setSelectedIcon={setSelectedIcon}
          setIsOpen={setIsOpen}
          selectedIcon={selectedIcon}
        />
      </Modal>
      <div className="w-full flex">
        <div className="w-1/4 border-r border-slate-200  h-730 overflow-auto">
          {allCategories?.map((category, index) => (
            <SingleCategory
              category={category}
              key={index}
              categoryClick={handleCategoryClick}
              selectedCategory={selectedCategory}
            />
          ))}
        </div>

        {selectedCategory ? ( 
          <div className="w-3/4 h-730 overflow-auto">
            <SelectedCategory
              selectedCategory={selectedCategory}
              toggleCreating={setIsCreatingSubCategory}
              setIsOpen={setIsOpen}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
            <div>

            {isCreatingSubCategory && (
              <SubCategoryForm
                setIsCreatingSubCategory={setIsCreatingSubCategory}
                subCategorySubmitHandler={subCategorySubmitHandler}
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
                setIsOpen={setIsOpen}
              />
            )}
            {selectedCategory?.subcategories?.map((subCategory, idx) => (
              <SingleSubCategory
                subCategory={subCategory}
                key={idx}
                selectedIcon={selectedIcon}
                setSelectedIcon={setSelectedIcon}
                setIsOpen={setIsOpen}
              />
            ))}
          </div>
            </div>
        ) : (
          <div className="w-3/4 flex items-center justify-center">
            {isCreating ? (
              <form
                className="flex flex-col"
                onSubmit={handleSubmit((data) => submitHandler(data))}
              >
                <div className="">
                  <div className="flex gap-3 items-center mb-1">
                    <IconUpload
                      setModalOpen={setIsOpen}
                      selectedIcon={selectedIcon}
                    />
                    <Input
                      register={register}
                      placeholder={`${t('categoryName')}...`}
                      type="Text"
                      name="categoryName"
                      required={t('thisFieldIsRequired')}
                      isError={errors?.categoryName?.message}
                      noLabel
                    />
                  </div>
                  <ErrorMessage>{errors?.categoryName?.message}</ErrorMessage>
                </div>
                <Input
                  register={register}
                  placeholder={`${t('description')}...`}
                  type="Text"
                  name="categoryDescription"
                  required={t('thisFieldIsRequired')}
                  isError={errors?.categoryDescription?.message}
                  noLabel
                />
                <ErrorMessage>
                  {errors?.categoryDescription?.message}
                </ErrorMessage>
                <div className="flex gap-3 ml-auto mt-3">
                  <Button
                    onClick={() => {
                      setIsCreating(false);
                      setSelectedIcon("");
                      reset();
                    }}
                  >
                    {t('cancel')}
                  </Button>
                  <Button type="submit">{t('save')}</Button>
                </div>
              </form>
            ) : (
              <div className="gap-5 flex flex-col">
                <span className="font-medium font-poppins">
                 {t('selectACategoryFromTheCategoryList')}
                  <br /> {t('onTheLeft')}
                </span>
                <span className="font-medium font-poppins">{t('or')}</span>
                <Button
                  onClick={() => {
                    setIsCreating(true);
                  }}
                >
                {`+ ${t('addACategory')}`}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Categories;
