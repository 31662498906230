import './Sidebar.css';
import chevronUp from '../../assets/arrows/chevron-up.svg';
import chevronDown from '../../assets/arrows/chevron-down.svg';
import { useContext, useEffect, useState } from 'react';
import { fetchLogoutApi } from '../../features/logoutSlice';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { changeName, changeSubName } from '../../features/linkSlice';
import AuthContext from '../../context/AuthProvider';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import Modal from '../../components/Common/Modal';

const Sidebar = () => {
  const [subarrayActive, setSubarrayActive] = useState('');
  const dispatch = useDispatch();
  const status = useSelector((state) => state.logout.status);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const { setLoggedIn, user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (status) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [status]);

  const superAdminSidebarArray = [
    {
      name: t('dashboard'),
      id: 1,
      link: 'dashboard',
    },
    {
      name: t('cases'),
      id: 2,
      link: 'cases/new-cases',
      subarray: [
        {
          name: t('new'),
          link: 'cases/new-cases',
          id: 1,
        },
        {
          name: t('ongoing'),
          link: 'cases/ongoing-cases',
          id: 2,
        },
        {
          name: t('finished'),
          link: 'cases/finished-cases',
          id: 3,
        },
      ],
    },
    {
      name: t('companies'),
      id: 4,
      link: 'companies/active',
      subarray: [
        {
          name: t('active'),
          link: 'companies/active',
          id: 1,
        },
        {
          name: t('waiting'),
          link: 'companies/waiting',
          id: 2,
        },
        {
          name: t('denied'),
          link: 'companies/denied',
          id: 3,
        },
      ],
    },
    {
      name: t('experts'),
      id: 5,
      link: 'experts',
    },
    {
      name: t('users'),
      id: 6,
      link: 'users',
    },
    {
      name: t('settings'),
      id: 7,
      link: 'settings',
    },
  ];

  const companyAdminSidebarArray = [
    {
      name: t('dashboard'),
      id: 1,
      link: 'dashboard',
    },
    {
      name: t('cases'),
      id: 2,
      link: 'cases/new-cases',
      subarray: [
        {
          name: t('new'),
          link: 'cases/new-cases',
          id: 1,
        },
        {
          name: t('ongoing'),
          link: 'cases/ongoing-cases',
          id: 2,
        },
        {
          name: t('finished'),
          link: 'cases/finished-cases',
          id: 3,
        },
      ],
    },
    {
      name: t('handymen'),
      id: 3,
      link: 'handymen',
    },
    {
      name: t('settings'),
      id: 7,
      link: 'settings',
    },
  ];

  const expertSideBar = [
    {
      name: t('cases'),
      id: 1,
      link: 'cases/new-cases',
      subarray: [
        {
          name: t('newUrgent'),
          link: 'cases/new-cases',
          id: 2,
        },
        {
          name: t('newNonUrgent'),
          link: 'cases/new-nonUrgent-cases',
          id: 3,
        },
        {
          name: t('ongoing'),
          link: 'cases/ongoing-cases',
          id: 4,
        },
        {
          name: t('finished'),
          link: 'cases/finished-cases',
          id: 5,
        },
      ],
    },
  ];

  const logout = async () => {
    dispatch(
      fetchLogoutApi({ access_token: localStorage.getItem('token') })
    ).then(() => {
      setLoggedIn(false);
      localStorage.clear();
      navigate('/login');
    });
  };

  let activeStyle = {
    color: '#2D12D6',
    fontWeight: 500,
  };
  let activeStyleSubtitle = {
    color: '#2D12D6',
    fontWeight: 500,
    borderLeft: '2px solid #2D12D6',
    paddingLeft: '1rem',
  };

  const roleArray =
    user.role_id === 1
      ? superAdminSidebarArray
      : user.role_id === 3
      ? expertSideBar
      : companyAdminSidebarArray;

  const sidebarList = roleArray.map((item, index) => (
    <div key={index}>
      <NavLink
        key={index}
        item={item}
        to={item.link}
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="py-4 flex justify-between"
        onClick={() => {
          changeLinkName(item.name);
          item?.subarray
            ? setSubarrayActive(subarrayActive === item.name ? '' : item.name)
            : setSubarrayActive('');
        }}
      >
        {item.name}
        {item?.subarray && (
          <img
            src={
              subarrayActive === item.name ||
              window.location.href.includes('/' + item.link.split('/')[0] + '/')
                ? chevronUp
                : chevronDown
            }
            alt=""
          ></img>
        )}
      </NavLink>
      {item?.subarray &&
        (subarrayActive === item.name ||
          window.location.href.includes('/' + item.link.split('/')[0] + '/')) &&
        item.subarray.map((subitem) => (
          <NavLink
            key={subitem.id}
            item={subitem}
            to={subitem.link}
            className="py-4 flex justify-between sidebar-border"
            style={({ isActive }) =>
              isActive ? activeStyleSubtitle : undefined
            }
            onClick={() => {
              changeSublinkName(subitem.name);
            }}
          >
            {subitem.name}
          </NavLink>
        ))}
    </div>
  ));

  const changeLinkName = (text) => {
    dispatch(changeName(text));
  };
  const changeSublinkName = (text) => {
    dispatch(changeSubName(text));
  };

  return (
    <div className="w-full max-w-200 mt-8  px-5 text-base sidebar-background primary-text-color fixed inset-0">
      <div className="flex flex-1 flex-col mt-8 h-93">
        {sidebarList}
        <div className="mt-auto text-base inline-flex ">
          <span onClick={logout}>
            <NavLink
              to="login"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              {t('logout')}
            </NavLink>
          </span>
        </div>
      </div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="max-w-lg">
        <div className="p-8 flex flex-col justify-center items-center">
          <h1 className="font-semibold text-2xl"> {t('loggingOut')}</h1>
          <CircularProgress className="mt-4" />
        </div>
      </Modal>
    </div>
  );
};

export default Sidebar;
