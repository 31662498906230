import axios from "axios";

const api_url = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_url.interceptors.request.use(
  (request) => {
    const auth = localStorage.getItem("token")
      ? `Bearer ${localStorage.getItem("token")}`
      : "";
    request.headers.common["Authorization"] = auth;
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

api_url.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401 && window.location.pathname !== "/login") {
      localStorage.clear();
      window.location = "/login";
    }
    return Promise.reject(error);
  }
);

export default api_url;
