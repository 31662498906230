import { styled } from '@material-ui/core';
import { Box, LinearProgress, TextField } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { updateExpertOpinion } from '../../../api/Experts/Experts';
import Button from '../../Common/Button';

const OpinionTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    padding: '0.5rem',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0!important',
  },
  '& label.Mui-focused': {
    color: '#480CA8',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#480CA8',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#480CA8',
    },
    '&:hover fieldset': {
      borderColor: '#480CA8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#480CA8',
    },
  },
}));

const EditDescriptionModal = ({
  expertDescription,
  caseId,
  setExpertOpinionModal,
}) => {
  const opinionRef = useRef();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { mutateAsync: setOpinionMutation, isLoading: opinionMutationLoading } =
    useMutation(updateExpertOpinion, {
      onSuccess: () => {
        queryClient.invalidateQueries(['selectedCaseDetails']);
        setExpertOpinionModal(false);
      },
    });

  const updateHandler = () => {
    setOpinionMutation({
      id: caseId,
      expert_opinion: opinionRef.current.value,
    });
  };

  return (
    <div className="flex flex-col px-8 py-6 w-full">
      <div className="flex flex-col text-base">
        <span className="case-header-name font-medium mb-2">
        {t('editExperDescription')}
        </span>
        <OpinionTextField
          inputRef={opinionRef}
          variant="outlined"
          fullWidth
          multiline
          minRows={8}
          maxRows={10}
          defaultValue={expertDescription}
        />
      </div>
      {opinionMutationLoading ? (
        <Box sx={{ width: '100%', padding: '20px 0' }}>
          <LinearProgress />
        </Box>
      ) : (
        <div className="flex gap-3">
          <Button
            className="max-w-[150px] mx-auto mt-4"
            onClick={() => setExpertOpinionModal(false)}
            variant="modalTransparent"
          >
            {t('cancel')}
          </Button>
          <Button
            className="max-w-[150px] mx-auto mt-4"
            onClick={() => updateHandler()}
          >
            {t('save')}
          </Button>
        </div>
      )}
    </div>
  );
};
export default EditDescriptionModal;
