import { configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/appSlice";
import authReducer from "../features/authSlice";
import linkReducer from "../features/linkSlice";
import registerReducer from "../features/registerSlice";
import newCasesReducer from "../features/cases/newCasesSlice";
import ongoingCasesReducer from "../features/cases/ongoingCasesSlice";
import finishedCasesReducer from "../features/cases/finishedCasesSlice";
import getExpertsReducer from "../features/experts/getExpertsSlice";
import getExpertByIdReducer from "../features/experts/getExpertByIdSlice";
import filterReducer from "../features/filterSlice";
import deleteExpertReducer from "../features/experts/deleteExpertSlice";
import getCategoriesReducer from "../features/categories/getCategoriesSlice";
import getCompanyByIdReducer from "../features/companies/getCompanyByIdSlice";
import activeCompaniesReducer from "../features/companies/getCompaniesByStatus";
import getExpertProblemReducer from "../features/experts/getExpertProblemSlice";
import getHandymenByCompanyIdReducer from "../features/handymen/getHandymenByCompanyIdSlice";
import getCategoriesSubcategoriesReducer from "../features/categories/getCategoriesSubcategoriesSlice";
import getCompletedCasesByHandymanReducer from "../features/handymen/getCompletedCasesByHandymanSlice";
import companyCasesReducer from "../features/cases/companyCases/companyCasesSlice";
import getCaseByIdReducer from "../features/cases/getCaseById";
import companiesByStatusReducer from "../features/companies/getCompaniesByStatus";
import putCompanyByIdReducer from "../features/companies/putCompanyByIdSlice";
import putAdminByIdReducer from "../features/admin/putAdminByIdSlice";
import getAdminByIdReducer from "../features/admin/getAdminByIdSlice";
import logoutReducer from "../features/logoutSlice";
import getCompanyFaqReducer from "../features/settings/getCompanyFaqSlice";
import getCaseRatingsByIdReducer from "../features/cases/companyCases/getCaseRatingsByIdSlice";
import putUserByIdReducer from "../features/user/putUserByIdSlice";
import deleteUserByIdReducer from "../features/user/deleteUserByIdSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    link: linkReducer,
    register: registerReducer,
    newCases: newCasesReducer,
    ongoingCases: ongoingCasesReducer,
    finishedCases: finishedCasesReducer,
    getExperts: getExpertsReducer,
    getExpertById: getExpertByIdReducer,
    filter: filterReducer,
    deleteExpert: deleteExpertReducer,
    getCategories: getCategoriesReducer,
    getCompanyById: getCompanyByIdReducer,
    activeCompanies: activeCompaniesReducer,
    getExpertProblems: getExpertProblemReducer,
    getHandymenByCompanyId: getHandymenByCompanyIdReducer,
    getCategoriesSubcategories: getCategoriesSubcategoriesReducer,
    getCompletedCasesByHandyman: getCompletedCasesByHandymanReducer,
    getCompanyCases: companyCasesReducer,
    getCaseById: getCaseByIdReducer,
    logout: logoutReducer,
    companiesStatus: companiesByStatusReducer,
    putCompanyById: putCompanyByIdReducer,
    putAdminById: putAdminByIdReducer,
    getAdminById: getAdminByIdReducer,
    getCompanyFaq: getCompanyFaqReducer,
    getCaseRatingsById: getCaseRatingsByIdReducer,
    putUserById: putUserByIdReducer,
    deleteUserById: deleteUserByIdReducer,
  },
});
